import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-help-persona-organizations-dialog',
  templateUrl: './help-persona-organizations-dialog.component.html',
  styleUrls: ['./help-persona-organizations-dialog.component.css']
})
export class HelpPersonaOrganizationsDialogComponent implements OnInit {
  currentStep: number;
  constructor(public dialogRef: MatDialogRef<HelpPersonaOrganizationsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.currentStep = this.data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
