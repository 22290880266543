import { Component, OnInit } from '@angular/core';
import { ConfirmationService,PrimeNGConfig, MessageService, MenuItem, SortEvent  } from 'primeng/api';
import { NavigationExtras, Router } from '@angular/router';
import { ProjectService } from '@data/service/project.service';
import { Project } from '@data/schema/Project';
import { TranslateService } from '@ngx-translate/core';
import {SearchItemService} from "@data/service/search-item.service";
import {CustomSortService} from "@shared/service/custom-sort.service";
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  projects: Project[];
  projectsTodo: Project[];
  projectsDoing: Project[];
  projectsDone: Project[];
  disabled: boolean = true;
  projectOnToggle: Project;
  projetRecherche: string;


  items: MenuItem[];

  constructor(
        private messageService: MessageService,
        private router: Router,
        private confirmationService: ConfirmationService,
        private projectService: ProjectService,
        private config: PrimeNGConfig,
        private translateService: TranslateService,
        private searchItemService: SearchItemService,
        private customSortService: CustomSortService,
  ) { }

  ngOnInit(): void {

    this.items = [
      {label:'Projets'},
    ];

    // this.translateService.setDefaultLang('fr');
    this.config.setTranslation({
      startsWith: "Commence par",
      addRule: "Ajouter une règle",
      matchAll: "Tous les filtres correspondent",
      matchAny: "Au moins un filtre correspond",
      contains: "Contient",
      notContains: "Ne contient pas",
      endsWith: "Termine par",
      equals: "Equivalent",
      notEquals: "Pas équivalent",
      noFilter: "Pas de filtre",
      lt: "Plus petit que",
      lte: "Plus petit ou égal",
      gt: "Plus grand",
      gte: "Plus grand ou égal",
      is: "Est",
      isNot: "N'est pas",
      before: "Avant",
      after: "Après",
      clear: "Effacer",
      apply: "Appliquer",
      removeRule: "Enlever la règle",
    });
    this.disabled = true;


    this.projectService.getAllProjects().subscribe(
      (res) => {
        //console.log(res);
        if (res.status === 200) {
          this.projects = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.projectsTodo = this.projects.filter(p=> p.status==="TODO")
        this.projectsDoing = this.projects.filter(p=> p.status==="DOING")
        this.projectsDone = this.projects.filter(p=> p.status==="DONE")
       }
    );

  }


  getRowProjects(project: Project) {
    this.projectOnToggle = project;
  }

  update() {

    if ( this.projectOnToggle.status=="DONE"){

      this.confirmationService.confirm({
        header: 'Modification impossible',
        message: "Ce projet est terminé.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false,
      });

    }
    else{
      let navigationExtras: NavigationExtras = {
        queryParams: {
            editMode: true
        }
      }
      this.router.navigate(['phoenix/show-project/', this.projectOnToggle.id], navigationExtras);
    }
  }

  confirm() {

    if (this.projectOnToggle.status=="DOING"){

      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message: "Ce projet est déjà démarré.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false,
      });
    }
    else if (this.projectOnToggle.status=="DONE"){

      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message: "Ce projet est terminé.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false,

      });
    }
    else{
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Les données seront supprimées définitivement.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.deleteProject();
        },
      });
    }

  }

  deleteProject(){
    this.projectService.deleteProject(this.projectOnToggle.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
          });
        }
      },
      (error) => {
        if (error.status === 400) {
          this.messageService.add({
            severity: 'error',
            summary: 'Impossible de supprimer le projet!',
          });
        }
      },
      () => { this.gotoList() }
    );

  }
    // redirect to list page
    gotoList() {
      this.redirectTo('/phoenix/project');
    }

    redirectTo(uri:string){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([uri]));
   }

   getProgress(project: Project){
    let done = 0;
    let total = 0;
    for (const pw of project.projectWorks){
      total += pw.quantityTotal;
    }
    for (const pwo of project.projectWorkOrders){
      for (const wopw of pwo.workOrder.listWorkOrderProjectWork){
        done += wopw.quantityDone
      }
    }
    return Math.trunc((done / total) * 100);
  }

  filterTableInputChangeTabProject(event, str, dt1,dt2,dt3,dt4) {
    this.projetRecherche = event;
    this.searchItemService.filterTableInputChange(this.projetRecherche, str, dt1,dt2,dt3,dt4);
  }

  handleChangeTabProject(event,str, dt1,dt2,dt3,dt4){
    this.searchItemService.handleChange(event,this.projetRecherche, dt1,dt2,dt3,dt4);
  }

  naturalSortProject(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

}
