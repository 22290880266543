import { Component, OnInit } from '@angular/core';
import { PersonaService } from '@data/service/persona.service';
import { MessageService, PrimeNGConfig, ConfirmationService, SortEvent } from 'primeng/api';
import { Skill } from '@data/schema/Skill';
import { Persona, PersonaSkills } from '@data/schema/Persona';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';
import {DialogService} from 'primeng/dynamicdialog';
import { SkillsOfPersonaDetailPersonaComponent } from './detail-persona.component';
import {CustomSortService} from "@shared/service/custom-sort.service";
import { OrganizationService } from '@data/service/organization.service';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { PermissionsList } from '@shared/enums/permissionsList';

@Component({
  selector: 'app-skills-of-personas',
  templateUrl: './skills-of-personas.component.html',
  styleUrls: ['./skills-of-personas.component.css'],
  providers: [DialogService]
})
export class SkillsOfPersonasComponent implements OnInit {
  userPools: number[] = [];

  constructor(
    private messageService: MessageService,
    private personaService: PersonaService,
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private organizationService: OrganizationService,
    private localStorageService : LocalStorageService,
    private customSortService: CustomSortService
  ) {}

  listAllPersonas: Persona[];
  selectedPersonas : Persona[] = [];
  skillsToDisplay : Skill[] = [];
  virtual : any;
  currentDate : Date = new Date();

  ngOnInit() {

    // this.translateService.setDefaultLang('fr');
    this.config.setTranslation({
      startsWith: "Commence par",
      addRule: "Ajouter une règle",
      matchAll: "Tous les filtres correspondent",
      matchAny: "Au moins un filtre correspond",
      contains: "Contient",
      notContains: "Ne contient pas",
      endsWith: "Termine par",
      equals: "Equivalent",
      notEquals: "Pas équivalent",
      noFilter: "Pas de filtre",
      lt: "Plus petit que",
      lte: "Plus petit ou égal",
      gt: "Plus grand",
      gte: "Plus grand ou égal",
      is: "Est",
      isNot: "N'est pas",
      before: "Avant",
      after: "Après",
      clear: "Effacer",
      apply: "Appliquer",
      removeRule: "Enlever la règle",
    });

    this.virtual = new Array(10);
    this.getPersonasByPools();

  }

  private getPersonaList() {
    this.personaService.getPersonaWithSkills(this.userPools.toString()).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listAllPersonas = res.body;
          for (let persona of this.listAllPersonas) {
            this.alphaNumericSort(persona.listSkill);
          }

        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => { }
    );
  }

  getPersonasByPools() {
    let hasPermissionToViewAll = this.localStorageService.hasPermission(PermissionsList.Kober_viewAll);
    if(hasPermissionToViewAll){
      this.getPersonaList();
    }else{
      this.getUserPools();
    }
  }

  // Add or remove skill of assignedSkill based on checkbox event
  selectPersona(event: any, persona: Persona) {
    if (event.checked){

      this.selectedPersonas.push(persona);
      for (const personaSkill of persona.listSkill){
        if (this.checkValidDate(personaSkill)){
          var duplicate = false;
          for (const skill of this.skillsToDisplay){
            if (personaSkill.skill.id === skill.id){
              duplicate = true
            }
          }
          if (!duplicate){
            this.skillsToDisplay.push(personaSkill.skill)
          }
        }
      }
    }
    else {
      this.selectedPersonas = this.selectedPersonas.filter(
        (item) => item.id !== persona.id
      );

      for (const personaSkill of persona.listSkill){

          var unique = true

          for (const selectPersona of this.selectedPersonas){
            for (const selectedSkill of selectPersona.listSkill){
              if (selectedSkill.skill.id === personaSkill.skill.id && this.checkValidDate(selectedSkill)){
                unique = false
              }
            }
          }
          if (unique){
            this.skillsToDisplay = this.skillsToDisplay.filter(
              (item) => item.id !== personaSkill.skill.id
            )
          }

      }

    }

  }

  checkValidDate(personaSkill: PersonaSkills){
    if (personaSkill.skill.type === 'HABILITATION' && personaSkill.skill.validity && new Date(personaSkill.endDate).getTime() < this.currentDate.getTime()){
      return false;
    }
    return true;
  }

  clear(table: Table) {
    table.clear();
  }

  confirm(event: Event) {
    this.confirmationService.confirm({
        target: event.target,
        message: 'Are you sure that you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            //confirm action
        },
        reject: () => {
            //reject action
        }
    });
}

  translate(lang: string) {
  this.translateService.use(lang);
  this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  show(persona:Persona) {
    const ref = this.dialogService.open(SkillsOfPersonaDetailPersonaComponent, {
        data: {
          persona: persona
        },
        header: 'Persona et compétences associées',
        width: '60%',
        styleClass: 'main-page',
        baseZIndex: 20000,
  });
  }

  naturalSort(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

  alphaNumericSort = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.skill.name.match(/\d+|\D+/g);
    const bPart = b.skill.name.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };

  getUserPools(){
    let partyId =  JSON.parse(sessionStorage.getItem("persona")).idParty
    this.organizationService.getUserPools(partyId).subscribe(
     (res) =>{
      let pools = res;
      if(pools && pools.length > 0){
        this.userPools = pools.map(pool => pool.id);
        this.getPersonaList();
      }else{
        this.userPools = [0];
      }
     }
   )
   }

}
