import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Persona, PersonaSkills } from '@data/schema/Persona';

@Component({
  selector: 'app-skills-of-persona-detail-persona',
  templateUrl: './detail-persona.component.html',
  styleUrls: ['./detail-persona.component.css'],
})
export class SkillsOfPersonaDetailPersonaComponent implements OnInit {

  persona: Persona;
  listHabilitation: PersonaSkills[] = []
  listSavoirFaire: PersonaSkills[] = []
  listSavoirEtre: PersonaSkills[] = []

  currentDate : Date = new Date();

  constructor(
    public config: DynamicDialogConfig
  ) {}

  alphaNumericSort = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.skill.name.match(/\d+|\D+/g);
    const bPart = b.skill.name.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };

  ngOnInit() {
    this.persona = this.config.data.persona

    this.alphaNumericSort(this.persona.listSkill)

    for (const personaSkills of this.persona.listSkill){

      if (personaSkills.skill.type === "HABILITATION"){
        this.listHabilitation.push(personaSkills)
      }
      if (personaSkills.skill.type === "SAVOIR_ETRE"){
        this.listSavoirEtre.push(personaSkills)
      }
      if (personaSkills.skill.type === "SAVOIR_FAIRE"){
        this.listSavoirFaire.push(personaSkills)
      }
    }
  }

  checkValidDate(personaSkill: PersonaSkills){
    if (personaSkill.skill.type === 'HABILITATION' && personaSkill.skill.validity && new Date(personaSkill.endDate).getTime() < this.currentDate.getTime()){
      return false;
    }
    return true;
  }

}
