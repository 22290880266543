<div class="card">
  <div class="p-grid">
      <div class="p-col p-text-right p-mr-4">
        <p-button (click)="RedirectToCreateOT()" tooltipPosition="bottom" [style]="{'background':'#2196F3'}" pTooltip="Créer un OT" p-button  label="Créer  +"></p-button>
      </div>
  </div>
</div>


<p-table
    #dt1
    [globalFilterFields]="[
    'workOrder.name',
    'workOrder.description',
    'company.name',
    'avancement',
    'status'
    ]"
    (sortFunction)="naturalSortWork($event)"
    [customSort]="true"
    sortField="workOrder.name"
    [sortOrder]='1'
    [value]="listProjectWorkOder" styleClass="p-datatable-sm p-datatable-striped " dataKey="workOrder.name" responsiveLayout="scroll">


    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between p-m-3">
        <div></div>
        <div>
          <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-left':'-30px'}">
            <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
            <input
              [style]="{'height': '45px'}"
              pInputText
              type="text"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Rechercher"
            />
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem"></th>

          <th pSortableColumn="workOrder.name">OT<p-sortIcon field="workOrder.name"></p-sortIcon></th>

          <th pSortableColumn="workOrder.description"> Description<p-sortIcon field="workOrder.description"></p-sortIcon></th>

          <th pSortableColumn="status">Statut  <p-sortIcon field="status"></p-sortIcon></th>

          <th pSortableColumn="company.name">Entreprise <p-sortIcon field="company.name"></p-sortIcon></th>

          <th pSortableColumn="avancement"> Avancement <p-sortIcon field="avancement"></p-sortIcon></th>
        </tr>
    </ng-template>

  <ng-template pTemplate="body" let-ot let-expanded="expanded">
    <tr>
      <td>
        <button type="button" pButton pRipple [pRowToggler]="ot" style=" height: 5px; width:5px;" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td>
        {{ot.workOrder.name}}
      </td>
      <td>{{ot.workOrder.description}}</td>

      <td *ngIf="ot.workOrder.status === 'TODO'">
        <span class="status-todo"> A faire </span>
      </td>

      <td *ngIf="ot.workOrder.status === 'DOING'">
        <span class="status-doing"> En cours </span>
      </td>

      <td *ngIf="ot.workOrder.status === 'DONE'">
        <span class="status-done"> Terminé  </span>
      </td>
      <td>{{ot.company?.name}}</td>
      <td>
        <app-progress-bar
        [progress]="getProgress(ot.workOrder)"
        [total]="100"
        color="blue">
      </app-progress-bar></td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-ot>
    <tr>
      <td colspan="6">
        <div class="p-p-3">

            <p-table
            #dt2
            [globalFilterFields]="[
            'work.name',
            'work.description',
            'quantityTotal',
            'quantityDone',
            'quantityTotal - quantityDone'

            ]"
            (sortFunction)="naturalSortWork($event)"
            [customSort]="true"
            sortField="projectWork.work.name"
            [sortOrder]='1'
            [value]="ot.workOrder.listWorkOrderProjectWork"
            styleClass="p-datatable-sm p-datatable-striped"
            dataKey="projectWork.work.name">

            <ng-template pTemplate="header">

    <tr>
      <th class="table-25 head" pSortableColumn="projectWork.work.name">Prestations <p-sortIcon field="projectWork.work.name"></p-sortIcon></th>
      <th class="table-40 head" pSortableColumn="projectWork.work.description">Commentaire <p-sortIcon field="projectWork.work.description"></p-sortIcon></th>
      <th class="table-15 p-text-right head" pSortableColumn="quantityTotal"> Quantité totale <p-sortIcon field="quantityTotal"></p-sortIcon></th>

      <th class="table-15 p-text-right head" pSortableColumn="quantityTotal - quantityDone">Réalisée <p-sortIcon field="quantityTotal - quantityDone"></p-sortIcon></th>
      <th class="table-15 p-text-right head" pSortableColumn="quantityTotal - quantityDone">A réaliser <p-sortIcon field="quantityTotal - quantityDone"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-order>
    <tr>
      <td class="table-25 head">{{order.projectWork.work.name}}</td>
      <td class="table-40 head">{{order.projectWork.work.description}}</td>
      <td class="table-15 p-text-right head">{{order.quantityTotal}}</td>
      <td class="table-15 p-text-right head">{{order.quantityDone}}</td>
      <td class="table-15 p-text-right head">{{order.quantityTotal - order.quantityDone}}</td>
    </tr>
  </ng-template>
</p-table>
</div>
</td>
</tr>
</ng-template>
</p-table>
