<div class="p-col-12">
  <!--  SELECT THIS DIV IF ONE COLUMN FORM AND DELETE THE ONE ABOVE
        <div class="p-col-12 p-d-flex p-jc-center">-->
  <div class="p-grid p-fluid">
    <div class="p-col-6">
      <h3>Consommables</h3>
    </div>
    <div class="p-col-6">
      <app-filtre-tag (newArrayFilter)="filterArrayByTag($event)" [typeOfTag]="typeOfTag"></app-filtre-tag>
    </div>
  </div>
<div
  *ngFor="let consumable of listConsumableSelected; let index = index"
  class="p-grid p-col-12 p-d-flex"
>
  <div class="p-col-8">
    <div class="p-fluid">
      <div class="p-grid">
        <div *ngIf="index === 0" class="p-col-10">
          <label for="consumable" class="p-mb-2 p-md-2 p-mb-md-0">
            Consommable
          </label>
        </div>
        <div class="p-col-10">
          <p-dropdown
            id="material"
            [(ngModel)]="listConsumableSelected[index].materialDto"
            [options]="listMaterials[index]"
            placeholder="Selection"
            dataKey="id"
            optionLabel="label"
            name="label"
            [filter]="true"
            filterBy="label"
            (onHide)="selectConsumable()"
          ></p-dropdown>
          <p-message
            *ngIf="checkIsDuplicate(listConsumableSelected[index].materialDto)"
            severity="error"
            text="Ce consommable existe déjà."
          ></p-message>
          <p-message
            *ngIf="(listConsumableSelected.length-1) === index && errorNotFilled"
            severity="error"
            text="Veuillez sélectionner un consommable avant d'en ajouter un autre."
          ></p-message>
        </div>
        <div class="p-col-2 p-d-flex p-ai-center">
          <i *ngIf="!listConsumableSelected[index].materialDto.label" tooltipPosition="bottom" pTooltip="Sélectionner un consommable dans la liste" class="pi pi-question-circle"></i>
          <i *ngIf="listConsumableSelected[index].materialDto.label" tooltipPosition="bottom" [pTooltip]="listConsumableSelected[index].materialDto.description" class="pi pi-question-circle"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-2">
    <div class="p-fluid">
      <div class="p-grid">
        <div *ngIf="index === 0" class="p-col-10">
          <label for="unit" class="p-mb-2 p-md-2 p-mb-md-0"> Unité </label>
        </div>
        <div class="p-col-10">
          <input
            [(ngModel)]="listConsumableSelected[index].materialDto.unit.name"
            [disabled]="true"
            type="text"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-2">
    <div class="p-fluid">
      <div class="p-grid">
        <div *ngIf="index === 0" class="p-col-10">
          <label for="qty" class="p-mb-2 p-md-2 p-mb-md-0"> Quantité </label>
        </div>
        <div class="p-col-10">
          <p-inputNumber
            [(ngModel)]="listConsumableSelected[index].quantityMaterial"
            [min]="1"
          >
          </p-inputNumber>
        </div>
        <div class="p-col-2">
          <button (click)="deleteConsumable(index)" pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-sm p-ml-1"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="p-col-12 p-d-flex p-ai-center">
  <button
    (click)="addConsumable()"
    pButton
    pRipple
    type="button"
    icon="pi pi-plus"
    class="p-button-rounded p-button-text"
  ></button>
  <span (click)="addConsumable()">Ajouter un consommable</span>
</div>
