import { NgModule } from '@angular/core';

// PrimeNg
import {ConfirmationService, MessageService} from "primeng/api"

import {MenubarModule} from 'primeng/menubar';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {MultiSelectModule} from 'primeng/multiselect';
import {ToastModule} from 'primeng/toast';
import {TableModule} from 'primeng/table';
import {InputTextModule} from "primeng/inputtext";
import {PasswordModule} from 'primeng/password';
import {ButtonModule} from 'primeng/button';
import {SliderModule} from 'primeng/slider';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {CardModule} from 'primeng/card';
import {DropdownModule} from "primeng/dropdown";
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ListboxModule} from 'primeng/listbox';
import {CalendarModule} from "primeng/calendar";
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DialogModule} from 'primeng/dialog';
import {CheckboxModule} from 'primeng/checkbox';
import {FileUploadModule} from 'primeng/fileupload';
import {DragDropModule} from 'primeng/dragdrop';
import {RippleModule} from "primeng/ripple";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {InputSwitchModule} from "primeng/inputswitch";
import {InputNumberModule} from "primeng/inputnumber";
import {ToolbarModule} from 'primeng/toolbar';
import {StepsModule} from 'primeng/steps';
import {TooltipModule} from 'primeng/tooltip';
import {PanelModule} from 'primeng/panel';
import {TabViewModule} from 'primeng/tabview';
import {ChipModule} from 'primeng/chip';
import {SidebarModule} from 'primeng/sidebar';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {RatingModule} from 'primeng/rating';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DividerModule} from 'primeng/divider';
import {FieldsetModule} from 'primeng/fieldset';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TimelineModule} from 'primeng/timeline';
import {SplitButtonModule} from "primeng/splitbutton";
import {PanelMenuModule} from 'primeng/panelmenu';
import {MenuModule} from 'primeng/menu';
import {AccordionModule} from 'primeng/accordion';
import {TagModule} from 'primeng/tag';
import {SkeletonModule} from 'primeng/skeleton';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ProgressBarModule} from 'primeng/progressbar';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ChipsModule} from 'primeng/chips';
import {SelectButtonModule} from 'primeng/selectbutton';
import {BreadcrumbModule} from 'primeng/breadcrumb';

@NgModule({
  declarations: [],
  imports: [
    MenubarModule,
    ToastModule,
    MultiSelectModule,
    TableModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    CardModule,
    MessageModule,
    MessagesModule,
    DropdownModule,
    InputTextareaModule,
    ListboxModule,
    CalendarModule,
    FileUploadModule,
    AutoCompleteModule,
    DialogModule,
    DialogModule,
    CheckboxModule,
    DragDropModule,
    RippleModule,
    ConfirmDialogModule,
    CheckboxModule,
    InputSwitchModule,
    InputNumberModule,
    ToolbarModule,
    InputNumberModule,
    StepsModule,
    TooltipModule,
    PanelModule,
    TabViewModule,
    ChipModule,
    SidebarModule,
    AvatarModule,
    AvatarGroupModule,
    ScrollPanelModule,
    RatingModule,
    DynamicDialogModule,
    DividerModule,
    FieldsetModule,
    OverlayPanelModule,
    ToggleButtonModule,
    TimelineModule,
    PanelMenuModule,
    MenuModule,
    SplitButtonModule,
    RadioButtonModule,
    AccordionModule,
    TagModule,
    SkeletonModule,
    ConfirmPopupModule,
    TieredMenuModule,
    ButtonModule,
    SliderModule,
    ProgressBarModule,
    ColorPickerModule,
    MultiSelectModule,
    ChipsModule,
    SelectButtonModule,
    BreadcrumbModule
  ],
  exports: [
    MenubarModule,
    ToastModule,
    MultiSelectModule,
    TableModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    CardModule,
    MessageModule,
    MessagesModule,
    DropdownModule,
    InputTextareaModule,
    ListboxModule,
    CalendarModule,
    FileUploadModule,
    AutoCompleteModule,
    DialogModule,
    DialogModule,
    CheckboxModule,
    DragDropModule,
    RippleModule,
    ConfirmDialogModule,
    CheckboxModule,
    InputSwitchModule,
    InputNumberModule,
    ToolbarModule,
    InputNumberModule,
    StepsModule,
    TooltipModule,
    PanelModule,
    TabViewModule,
    ChipModule,
    SidebarModule,
    AvatarModule,
    AvatarGroupModule,
    ScrollPanelModule,
    RatingModule,
    DynamicDialogModule,
    DividerModule,
    FieldsetModule,
    OverlayPanelModule,
    ToggleButtonModule,
    TimelineModule,
    PanelMenuModule,
    MenuModule,
    SplitButtonModule,
    RadioButtonModule,
    AccordionModule,
    TagModule,
    SkeletonModule,
    ConfirmPopupModule,
    TieredMenuModule,
    ButtonModule,
    SliderModule,
    ProgressBarModule,
    ColorPickerModule,
    MultiSelectModule,
    ChipsModule,
    SelectButtonModule,
    BreadcrumbModule
  ],
  providers: [
    MessageService, ConfirmationService,
  ],

})
export class PrimengModule { }
