import {Component, Input, OnInit, ViewChild, HostListener} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, MessageService} from "primeng/api";
import {ContractService} from "@data/service/contract.service";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {ExportService} from "@shared/service/export.service";
import {Contracts} from "@data/schema/Contracts";
import {ContractFormGeneralComponent} from '../new-contract/contract-form-general/contract-form-general.component';
import {ContractWork} from '@data/schema/ContractWork';
import {Currency} from "@data/schema/Currency";
import { LocalStorageService } from '@shared/service/local-storage.service';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-contract-card',
  templateUrl: './show-contract.component.html',
  styleUrls: ['./show-contract.css']
})
export class ShowContractComponent implements OnInit, ComponentCanDeactivate {

  public submited: BehaviorSubject<boolean>;

  // @HostListener allows us to also guard against browser refresh, close, etc.
 @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (this.mode == "consultation" || this.submited.value ? true : false)
  }


  @Input() mode: String = "consultation";
  contractForm: FormGroup = null;
  private sub: any;
  id: number;
  contracts: Contracts;
  prestationErr: boolean = false;
  contractIsAttachedToWorkOrder: boolean = false;
  items: any = {};
  prestationsFrom: FormGroup = null;
  currency: Currency;

  @ViewChild(ContractFormGeneralComponent)
  private contractFormGeneral!: ContractFormGeneralComponent;
  contractToUpdate: FormGroup;
  prestationsPriceChanged: boolean;

  constructor(private messageService: MessageService, private fb: FormBuilder,
              private confirmationService: ConfirmationService,
              private contractService: ContractService,
              private router: Router,
              private route: ActivatedRoute,
              private exportService: ExportService,
              private localStorage: LocalStorageService) {
    this.route.queryParams.subscribe((params) => {
      if (params.mode) {
        this.mode = 'update';

      }
    });
    this.submited = new BehaviorSubject(false)
    this.currency = new Currency(JSON.parse(this.localStorage.getLocalStorageItem("currency")));
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
    this.getContract();
  }



  getContract() {
    this.contractService.GetContract(this.id).subscribe(
      (res) => {

        if (res.status === 200) {
          this.contracts = res.body;
          if(this.currency?.currencyCode){
            this.contracts.currency = this.currency.currencyCode;
          }
          this.contractForm = new FormGroup({
            contractId: new FormControl(this.contracts.contractId),
            contractName: new FormControl(this.contracts.contractName, Validators.required),
            description: new FormControl(this.contracts.description),
            creationDate: new FormControl(this.contracts.creationDate),
            effectiveDate: new FormControl(this.contracts.effectiveDate?new Date(this.contracts.effectiveDate):null, Validators.required),
            expiryDate: new FormControl(this.contracts.expiryDate?new Date(this.contracts.expiryDate):null,Validators.required),
            status: new FormControl(this.contracts.status),
            currency: new FormControl(this.currency?.currencyCode? this.currency.currencyCode:this.contracts.currency, Validators.required),
            company: new FormControl(this.contracts.company, Validators.required),
            contractWorks: new FormArray([])

          })
          //this.isContractAttachedToWorkOrder();
          this.buildPrestations();
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l\'opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.generateBreadCrumb()
      }
    );
  }

  generateBreadCrumb() {
    this.items.update = [
      {label: 'Contrats', routerLink: '/phoenix/work-order'},
      {label: "Modification du contrat " + this.contracts.contractName, styleClass: 'ariane-current'}
    ];
    this.items.show = [
      {label: 'Contrats', routerLink: '/phoenix/work-order'},
      {label: "Contrat " + this.contracts.contractName, styleClass: 'ariane-current'}
    ];
  }

  get contractFormControls() {
    return this.contractForm.controls;
  }

  get prestationControls() {
    return this.contractFormControls.contractWorks as FormArray;
  }

  get contractToUpdateContractWorks() {
    return this.contractToUpdate.controls.contractWorks as FormArray;
  }

  get prestationsBeforeUpdate() {
    return this.prestationsFrom.controls.contractWorks as FormArray;
  }

  buildPrestations() {
    //init prestation with before update contract data
    this.prestationsFrom = new FormGroup({
      contractWorks: new FormArray([])
    })

    if (this.prestationsBeforeUpdate.valid === true && this.contractForm.value.currency !== null) {
      this.contracts.contractWorks.forEach(contractWork => {
        this.prestationsBeforeUpdate.push(this.fb.group({
          contractWorkId: [contractWork.contractWorkId, [Validators.required]],
          workId: [contractWork.workId, [Validators.required]],
          price: [contractWork.price, [Validators.required]],

        }))
        this.prestationErr = false;
      })


    } else {
      this.prestationErr = true;
    }

  }

  back() {

        this.confirmationService.confirm({
          header: 'Confirmation',
          message:
            'Vous allez quitter la page, les données seront perdues.' +
            '<br>' +
            'Voulez vous continuer ?',
          icon: 'pi pi-exclamation-triangle',
          acceptButtonStyleClass: 'p-button-outlined',
          acceptIcon: 'null',
          rejectIcon: 'null',
          acceptLabel: 'Quitter',
          rejectLabel: 'Annuler',
          accept: () => {
            this.submited.next(true)
            this.router.navigate(['/phoenix/contract']);
          },
        });


  }

  combinePrestationBeforeAndAfterUpdate() {
    this.contractToUpdate = new FormGroup({
      contractId: new FormControl(this.contracts.contractId),
      contractName: new FormControl(this.contractForm.value.contractName, Validators.required),
      description: new FormControl(this.contractForm.value.description),
      creationDate: new FormControl(this.contractForm.value.creationDate),
      effectiveDate: new FormControl(this.contractForm.value.effectiveDate),
      expiryDate: new FormControl(this.contractForm.value.expiryDate),
      status: new FormControl(this.contractForm.value.status),
      currency: new FormControl(this.contractForm.value.currency, Validators.required),
      company: new FormControl(this.contracts.company, Validators.required),
      contractWorks: new FormArray([])

    })

    for (let prestaBeforeUpdate of this.prestationsBeforeUpdate.controls) {
      this.contractToUpdateContractWorks.push(prestaBeforeUpdate);
    }
    for (let prestaAfterUpdate of this.prestationControls.controls) {
      this.contractToUpdateContractWorks.push(prestaAfterUpdate);
    }

  }

  editContract() {
    this.contractService.editContract(this.contractToUpdate).subscribe(
      (res) => {
        if (res.status === 200) {
           if (res.body.code === 607) {
            this.confirmationService.confirm({
              header: 'Action impossible',
              message: 'Ce contrat est associé à des OT BL. Impossible de le désactiver',
              icon: 'pi pi-exclamation-triangle',
              acceptIcon: 'null',
              rejectIcon: 'null',
              acceptLabel: 'J\'ai compris',
              rejectVisible: false,
              accept: () => {
              },
            });
          } else if (res.body.code === 610) {
            this.confirmationService.confirm({
              header: 'Action impossible',
              message: "Ce contrat est en vigueur, impossible de modifier la date d'entré en vigueur",
              icon: 'pi pi-exclamation-triangle',
              acceptIcon: 'null',
              rejectIcon: 'null',
              acceptLabel: 'J\'ai compris',
              rejectVisible: false,
              accept: () => {
              },
            });
          }
        }
      },
      (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
      },
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });
        this.returnList();
      }
    );
  }

  priceHasChanged(event: any) {
    this.prestationsPriceChanged = event;
  }

  updateContract() {
    this.combinePrestationBeforeAndAfterUpdate();
    if (this.contractIsAttachedToWorkOrder && this.prestationsPriceChanged) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        message: 'Ce contrat est associé à des OT BL en cours. La modification du prix des prestations impactera le budget des OT et des projets liés.',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.editContract();
        },
      });
    } else {
      this.editContract();
    }

  }

  returnList() {
    this.submited.next(true)
    this.redirectTo('/phoenix/contract');
  }

  redirectTo(uri: string) {
    this.submited.next(true)
    this.router
      .navigateByUrl('/', {skipLocationChange: true})
      .then(() => this.router.navigate([uri]));
  }

  update() {
    this.mode = "update";
    this.contractFormGeneral.filterContractsNamesForUpdate();
  }


  submit() {
    let validForm = true;
    if (this.contractForm.valid) {
      this.updateContract();
    }
  }

  isContractAttachedToWorkOrder() {
    this.contractService.isAttachedToWorkOrder(this.contracts.contractId).subscribe(
      (res) => {
        if (res.status === 200) {

          this.contractIsAttachedToWorkOrder = res.body;
        }
      }
      , (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Échec de l\'opération : veuillez réessayer'
        });
      },
      () => {
      }
);
  }



}
