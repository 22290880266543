<h2 class="p-text-center">{{ 'assigner_les_competences' | translate }}  </h2>
<div class="p-fluid p-grid">
  <div class="p-col-12 p-md-12 p-lg-9">
    <app-filtre-tag (newArrayFilter)="filterArrayByTag($event)" [typeOfTag]="typeOfTag"></app-filtre-tag>
  </div>
  <div class="p-col-12 p-md-12 p-lg-3">
    <span class="p-input-icon-left p-ml-auto">
      <i class="pi pi-search"></i>
      <input
        p pInputText
        type="text"
        (input)="dt.filterGlobal($event.target.value, 'contains');dt1.filterGlobal($event.target.value, 'contains');
              dt2.filterGlobal($event.target.value, 'contains');dt3.filterGlobal($event.target.value, 'contains')"
              placeholder="{{ 'recherche' | translate }}"
      />
    </span>
  </div>
</div>
<div
  class="p-grid p-d-flex p-jc-center p-mt-5"
  [style]="{'margin-bottom': '10%' }"
>
  <div class="p-grid p-col-12 p-m-3 p-p-3 main-content p-shadow-4">
    <!----
      PANEL THAT DISPLAY BOTH TABLES AND SELECTED RESULTS
      ---->
    <p-panel [showHeader]="false">
      <!----
      TABVIEW THAT SHOW : ALL/HABILITATION/SAVOIR-ETRE/SAVOIR FAIRE
      ---->
      <p-tabView>
        <!----
        SHOW ALL SKILLS
        ---->
        <p-tabPanel header="{{ 'TOUT' | translate }}">
          <p-table
            #dt
            (sortFunction)="naturalSort($event)"
            [customSort]="true"
            sortField="name"
            [sortOrder]='1'
            dataKey="name"
            [value]="skill"
            [(selection)]="skillSelected"
            selectionMode="multiple"
            (onRowSelect)="assignSkills($event)"
            (onRowUnSelect)="removeSkills($event)"
            styleClass=" p-datatable-striped"
            scrollHeight="40vh"
            class ="table"
            [globalFilterFields]="[
              'name',
              'type',
            ]"
          >

            <ng-template pTemplate="header">
              <tr>
                <th width="100"></th>
                <th id="name" pSortableColumn="name">
                  {{ 'NOM' | translate }}
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th id="type" pSortableColumn="type">
                   {{ 'TYPE' | translate }}
                  <p-sortIcon field="type"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-skill>
              <tr >
                <td width="100">
                  <p-tableCheckbox [value]="skill"></p-tableCheckbox>
                </td>
                <td
                  pTooltip="{{
                    skill.description ? skill.description : 'Pas de description'
                  }}"
                >
                  {{ skill.name }}
                </td>
                <td
                  pTooltip="{{
                    skill.description ? skill.description : 'Pas de description'
                  }}"
                >
                  <span *ngIf="skill.type === 'HABILITATION'">{{ 'HABILITATION' | translate }}</span>
                  <span *ngIf="skill.type === 'SAVOIR_ETRE'">{{ 'SAVOIR_ETRE' | translate }}</span>
                  <span *ngIf="skill.type === 'SAVOIR_FAIRE'">{{ 'SAVOIR_FAIRE' | translate }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <!----
        SHOW ALL HABILITATION
        ---->
        <p-tabPanel header="{{ 'HABILITATION' | translate }}">
          <p-table
            #dt1
            (sortFunction)="naturalSort($event)"
            [customSort]="true"
            sortField="name"
            [sortOrder]='1'
            dataKey="name"
            [value]="skill"
            [scrollable]="true"
            [(selection)]="skillSelected"
            selectionMode="multiple"
            (onRowSelect)="assignSkills($event)"
            (onRowUnSelect)="removeSkills($event)"
            scrollHeight="200px"
            [globalFilterFields]="[
              'id',
              'name',
            ]"
          >

            <ng-template pTemplate="header">
              <tr>
                <th width="100"></th>
                <th id="name" pSortableColumn="name">
                  {{ 'NOM' | translate }}
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-skill>
              <tr *ngIf="skill?.type === 'HABILITATION'" >
                <td width="100">
                  <p-tableCheckbox [value]="skill"></p-tableCheckbox>
                </td>
                <td
                  pTooltip="{{
                    skill.description ? skill.description : 'Pas de description'
                  }}"
                >
                  {{ skill.name }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <!----
        SHOW ALL SAVOIR FAIRE
        ---->
        <p-tabPanel header="{{ 'SAVOIR_FAIRE' | translate }}">
          <p-table
            #dt2
            (sortFunction)="naturalSort($event)"
            [customSort]="true"
            sortField="name"
            [sortOrder]='1'
            dataKey="name"
            [value]="skill"
            [(selection)]="skillSelected"
            selectionMode="multiple"
            (onRowSelect)="assignSkills($event)"
            (onRowUnSelect)="removeSkills($event)"
            [scrollable]="true"
            scrollHeight="200px"
            [globalFilterFields]="[
              'id',
              'name',
            ]"
          >

            <ng-template pTemplate="header">
              <tr>
                <th width="100"></th>
                <th id="name" pSortableColumn="name">
                  {{ 'NOM' | translate }}
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-skill>
              <tr *ngIf="skill?.type === 'SAVOIR_FAIRE'" >
                <td width="100">
                  <p-tableCheckbox [value]="skill"></p-tableCheckbox>
                </td>
                <td
                  pTooltip="{{
                    skill.description ? skill.description : 'Pas de description'
                  }}"
                >
                  {{ skill.name }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <!----
        SHOW ALL SAVOIR ETRE
        ---->
        <p-tabPanel header="{{ 'SAVOIR_ETRE' | translate }}">
          <p-table
            #dt3
            (sortFunction)="naturalSort($event)"
            [customSort]="true"
            sortField="name"
            [sortOrder]='1'
            dataKey="name"
            [value]="skill"
            [scrollable]="true"
            [(selection)]="skillSelected"
            scrollHeight="200px"
            selectionMode="multiple"
            (onRowSelect)="assignSkills($event)"
            (onRowUnSelect)="removeSkills($event)"
            [globalFilterFields]="[
              'id',
              'name',
            ]"
          >

            <ng-template pTemplate="header">
              <tr>
                <th width="100"></th>
                <th id="name" pSortableColumn="name">
                  {{ 'NOM' | translate }}
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-skill>
              <tr *ngIf="skill?.type === 'SAVOIR_ETRE'" >
                <td width="100">
                  <p-tableCheckbox [value]="skill"></p-tableCheckbox>
                </td>
                <td
                  pTooltip="{{
                    skill.description ? skill.description : 'Pas de description'
                  }}"
                >
                  {{ skill.name }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
      </p-tabView>

      <!----
      DISPLAY SELECTED SKILLS FROM TAB
      ---->
      <ng-template pTemplate="footer">
        <h3>{{ 'COMPETENCES_SELECTIONNEES'| translate }}</h3>
        <div *ngFor="let skill of skillSelected; let i = index">
          <div class="p-m-1" *ngIf="skill.type === 'HABILITATION'">
              <p-chip
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-custom-basic"
            ></p-chip>
              <!-- <p-chip
              *ngIf="skill.type === 'HABILITATION'"
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-info"
            ></p-chip>
            <p-chip
              *ngIf="skill.type === 'SAVOIR_ETRE'"
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-success"
            ></p-chip>
            <p-chip
              *ngIf="skill.type === 'SAVOIR_FAIRE'"
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-warning"
            ></p-chip> -->

            <span
              *ngIf="skill?.type === 'HABILITATION' && skill?.validity"
            >
                Valide du
                <p-calendar
                  pTooltip="La modification de la date début de validité entrainera la modification automatique de la date de fin (calcul en fonction de la durée de validité de l'habilitation)"
                  dateFormat="dd.mm.yy"
                  [(ngModel)]="assignedSkills[i].startDate"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="1970:2050"
                  (onClose)="startDateOnChange(i)"
                  [style]="{'width':'130px'}"
                ></p-calendar>

                au
                <p-calendar
                  dateFormat="dd.mm.yy"
                  [(ngModel)]="assignedSkills[i].endDate"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="1970:2050"
                  [style]="{'width':'130px'}"
                ></p-calendar>

            </span>
          </div>
        </div>
        <span class="p-m-1" *ngFor="let skill of skillSelected; let i = index">

              <p-chip
              *ngIf="skill.type !== 'HABILITATION'"
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-custom-basic"
            ></p-chip>
              <!-- <p-chip
              *ngIf="skill.type === 'HABILITATION'"
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-info"
            ></p-chip>
            <p-chip
              *ngIf="skill.type === 'SAVOIR_ETRE'"
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-success"
            ></p-chip>
            <p-chip
              *ngIf="skill.type === 'SAVOIR_FAIRE'"
              [tooltipDisabled]="!skill.validity"
              [pTooltip]="getValidity(skill.year_duration, skill.month_duration, skill.day_duration)"
              label="{{ skill.name }}"
              (onRemove)="removeSkill($event, skill)"
              [removable]="true"
              styleClass="chip-warning"
            ></p-chip> -->
            </span>


      </ng-template>
    </p-panel>
  </div>
  <div [style]="{ height: '13vh' }"></div>
</div>
<p-sidebar
  class="p-text-right"
  [style]="{ height: '13vh' }"
  [visible]="true"
  [blockScroll]="true"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="bottom"
>

  <p-button (onClick)="save()" styleClass="p-mr-2" label="Valider"></p-button>
</p-sidebar>
