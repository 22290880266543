import {Component, Input, IterableDiffers, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {ProjectWork} from "@data/schema/Project";
import {WorkOrderProjectWork} from "@data/schema/WorkOrder";
import {MaterialWork} from "@data/schema/Work";
import {Router} from "@angular/router";
import {WorkOrderService} from "@data/service/work-order.service";

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {

  formG: FormGroup;
  @Input() editMode: boolean;
  @Input() attachedCompany: any;
  // optional parameter listProjectWork
  @Input() listProjectWork?: ProjectWork[];
  // optional parameter listWorkOrderProjectWork
  @Input() listWorkOrderProjectWork?: WorkOrderProjectWork[];
  newItem: any = {};
  editItem: any = {};
  // allow to check multiclick
  submitSaveVoucher: boolean = false;
  @Input() selectedMateriel: any;
  // list item for voucher save
  listItem: any = null;
  // allow to check if array input changed
  iterableDiffer: any;

  // list work material for consumable
  // list work material for tools
  @Input() listWorkMaterialConsumable: MaterialWork[] = []
  listWorkMaterialTool: MaterialWork[] = [];

  @Input() fromWorKOrder: Boolean;
  @Input() id: number;
  // boolean to display voucher popup
  displayBon: boolean = false;

  constructor( private iterableDiffers: IterableDiffers, private confirmationService: ConfirmationService,
               private router: Router,
               private fb: FormBuilder, public workOrderService: WorkOrderService, private messageService: MessageService) { }


  ngOnInit(): void {
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([uri]));
  }
  generateVoucherForm() {
    this.formG = new FormGroup({
      voucherDate: new FormControl(null, Validators.required),
      voucherRef: new FormControl(null),
      conso: new FormControl(null, Validators.required),
      workOrder: this.fb.group({
        id: new FormControl(this.id),
      }),
      voucherMaterialWorks: new FormArray([])
    })
  }

  sendAtMaterial(event: any) {
    this.newItem = event;
    this.editItem = {};
  }

  getAtMaterial(event: any) {
    this.editItem = event;
    this.newItem = {};
  }

  getAllAtMaterial(event: any) {
    this.listItem = event;
    for (let i = 0; i < this.listItem.listItem.length; i++) {
      this.listItem.listItem[i]["quantiteSortie"] = this.listItem.listItem[i].formG[0].quantity;
      //this.listItem.listItem[i]["quantiteRestante"] = this.listItem.listItem[i].cons.quantityMaterial - this.listItem.listItem[i].formG[0].quantity;
    }
  }

  openVoucher() {
    // generateNewForm
    if (this.attachedCompany) {
      this.generateVoucherForm();
      this.voucherControls.push(
        this.fb.group({
          materialWork: this.fb.group({
            id: new FormControl(null, Validators.required),
          }),
          quantity: new FormControl(1, Validators.required),
          quantityTotal: new FormControl(null),
        }),
      )
      // empty variable
      this.newItem = {};
      this.editItem = {};
      this.listItem = null;
      this.displayBon = true;
    } else {
      this.checkCompany();
    }
  }

  saveVoucher() {
    if (this.editItem.edit !== true && this.editItem.lastMat !== true) {
      if (this.listItem.listItem.length > 0 && this.submitSaveVoucher === false) {
        this.submitSaveVoucher = true;
        this.formG.value.voucherMaterialWorks.pop();
        for (let obj of this.listItem.listItem) {
          this.formG.value.voucherMaterialWorks.push(obj.formG[0]);
        }
        this.workOrderService.createVoucher(this.formG.value).subscribe(
          res => {
            if (res.status == 200) {
              this.messageService.add({severity: 'success', summary: 'Opération réussie', detail: res.message});
              this.displayBon = false;
              this.redirectTo("/phoenix/show-work-order/" + this.id);
            }
          }, error => {
            this.messageService.add({severity: 'error', summary: 'Échec de l’opération : veuillez réessayer'});
          },
          () => {
            this.submitSaveVoucher = false;
          })
      }
    }
  }

  hideDialog() {
    this.confirmationService.confirm({
      message: 'Vous allez quitter la page, les données seront perdues.' + '<br>' +
        'Voulez vous continuer ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      key: 'cd',
      acceptButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.displayBon = false;
        this.messageService.add({severity: 'info', summary: 'Modification annulée', detail: ''});
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      }
    });
  }

  get voucherFormControls() {
    return this.formG.controls;
  }

  get voucherControls() {
    return this.voucherFormControls.voucherMaterialWorks as FormArray;
  }

  get workOrderIdControls() {
    return this.voucherControls.at(0);
  }

  checkCompany() {
    this.confirmationService.confirm({
      header: 'Action impossible',
      message:
        'Vous devez affecter l\'OT à une entreprise pour saisir un bon de sortie.',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: "J'ai compris",
      rejectVisible: false,
    });
  }
}
