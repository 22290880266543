import { Injectable } from '@angular/core';
import { SkillService } from '@data/service/skill.service';
import {MaterialService} from "@data/service/material.service";
import { TagService } from '@data/service/tag.service';
import { StockService } from '@data/service/stock.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(private skillService: SkillService, private materialService: MaterialService,private tagService: TagService, public stockService: StockService) { }

  getListEntitiesByTag(typeOfTag, selectedTags) {
    if (typeOfTag === "skill") {
      return this.skillService.getSkillListByTag(selectedTags);
    }
   if (typeOfTag === "material") {
      return this.materialService.getMaterialsListByTag(selectedTags);
    } if (typeOfTag === "otherUsage"){
      return new Observable(() => {})
    }
  }

  resetFilterByTypeOfTag(typeOfTag) {
    if (typeOfTag === "skill") {
      return this.skillService.getListSkillWithTags();
    }
    if (typeOfTag === "material") {
      return this.materialService.getAllMaterials();
    }
    if (typeOfTag === "otherUsage"){
      return new Observable((observer) => {})
    }
  }

  getAllTagsByTypeOfTag(typeOfTag){
    if (typeOfTag === "skill") {
      return this.skillService.getTagSkill();
    }
    else if (typeOfTag === "material") {
      return this.materialService.getTagsMaterial();
    } else {
      return this.tagService.getAllTags();
    }
  }
  getStatusGroupName(group: string){
    let statusGroup = '';
    switch (group) {
      case 'to-plan':
        statusGroup = "A planifier"
        break;
    case 'not-started':
        statusGroup = "Non démarrés"
        break;
    case 'in-progress':
        statusGroup = "En cours"
        break;
    case 'to-plan':
        statusGroup = "A planifier"
        break;
    case 'to-validate':
        statusGroup = "A valider"
        break;
    case 'on-hold':
        statusGroup = "En attente"
        break;
    case 'in-error':
        statusGroup = "En erreur"
        break;
    case 'ended':
        statusGroup = "Terminés"
        break;
    default:  
    }
    return statusGroup
  }
}
