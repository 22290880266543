<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
    <div
      class="p-col-6  p-nogutter p-jc-center p-m-3 p-p-3 main-content p-shadow-4"
    >

    <div class="p-grid">
      <div class="p-col-12">
        <h2>{{ 'competences_personas' | translate }}</h2>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12 p-md-12 p-lg-12">
        <app-filtre-tag (newArrayFilter)="filterArrayByTag($event)" [typeOfTag]="typeOfTag"></app-filtre-tag>
      </div>
    </div>


      <div>
        <div>

          <!----
        PANEL THAT DISPLAY BOTH TABLES AND SELECTED RESULTS
        ---->
          <p-panel [showHeader]="false">
            <!----
        TABVIEW THAT SHOW : ALL/HABILITATION/SAVOIR-ETRE/SAVOIR FAIRE
        ---->
            <p-tabView>
              <!----
          SHOW ALL SKILLS
          ---->
              <p-tabPanel header="{{ 'TOUT' | translate }}">
                <ng-container *ngIf="!listSkill || !listAllPersonas">
                  <p-table
                    #dt1
                    (sortFunction)="naturalSort($event)"
                    [customSort]="true"
                    sortField="name"
                    [sortOrder]='1'
                    dataKey="name"
                    [value]="virtual"
                    class="table"
                    [scrollable]="true"
                    scrollHeight="40vh"
                    [paginator]="true"
                    [rows]="10"
                    styleClass="p-datatable-sm"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [globalFilterFields]="['name', 'description']"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                            pInputText
                            type="text"
                            [value]="searchedSkill!== undefined?searchedSkill:''"
                            (input)="filterTableInputChangeTabSkill($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                            placeholder="{{ 'recherche' | translate }}"
                          />
                        </span>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th class="table-10">
                          <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                        </th>
                        <th id="name" pSortableColumn="name">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'NOM' | translate }}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="name"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="name"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="description" pSortableColumn="description">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Description
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="description"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="description"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-virtual>
                      <tr>
                        <td>
                          <p-skeleton></p-skeleton>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-container>
                <ng-container *ngIf="listSkill && listAllPersonas">
                  <p-table
                    dataKey="name"
                    #dt1
                    (sortFunction)="naturalSort($event)"
                    [customSort]="true"
                    sortField="name"
                    [sortOrder]='1'
                    dataKey="name"
                    [value]="listSkill"
                    [(selection)]="selectedSkills"
                    class="table"
                    [scrollable]="true"
                    scrollHeight="40vh"
                    [paginator]="true"
                    [rows]="10"
                    styleClass="p-datatable-sm"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [globalFilterFields]="['name', 'description']"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        <!-- <button
                          (click)="confirm($event)"
                          class="p-button-outlined filter-clear"
                          pButton
                          icon="pi pi-filter"
                          label="Colonnes"
                        ></button> -->
                        <button
                          pButton
                          label="{{'FILTRES'|translate}}"
                          class="p-button-outlined filter-clear"
                          icon="pi pi-filter-slash"
                          (click)="clear(dt1)"
                        ></button>
                        <button pButton label="{{'COMPETENCES'|translate}}" class="p-button-outlined filter-clear" icon="pi pi-filter-slash" (click)="clearSkills()"></button>
                        <span class="p-input-icon-left p-ml-auto">
                          <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                              pInputText
                              type="text"
                              [value]="searchedSkill!== undefined?searchedSkill:''"
                              (input)="filterTableInputChangeTabSkill($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                              placeholder="{{ 'recherche' | translate }}"
                            />
                          </span>
                        </span>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th class="table-10">
                          <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                        </th>
                        <th id="name" pSortableColumn="name">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'NOM' | translate }}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="name"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="name"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="description" pSortableColumn="description">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Description
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="description"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="description"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-skill>
                      <tr>
                        <td class="table-10">
                          <div class="p-d-flex p-ai-center">
                            <p-tableCheckbox [value]="skill"
                                             (click)="selectSkill($event, skill)"></p-tableCheckbox>
                          </div>
                        </td>
                        <td>
                          {{ skill.name }}
                        </td>
                        <td>
                          {{ skill.description }}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-container>
              </p-tabPanel>
              <!-- SHOW HAB -->
              <p-tabPanel header="{{ 'HABILITATION' | translate }}">
                <ng-container *ngIf="!listSkill || !listAllPersonas">
                  <p-table
                    #dt2
                    (sortFunction)="naturalSort($event)"
                    [customSort]="true"
                    sortField="name"
                    [sortOrder]='1'
                    dataKey="name"
                    [value]="virtual"
                    class="table"
                    [scrollable]="true"
                    scrollHeight="40vh"
                    [paginator]="true"
                    [rows]="10"
                    styleClass="p-datatable-sm"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [globalFilterFields]="['name', 'description']"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                            pInputText
                            type="text"
                            [value]="searchedSkill!== undefined?searchedSkill:''"
                            (input)="filterTableInputChangeTabSkill($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                            placeholder="{{ 'recherche' | translate }}"
                          />
                        </span>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th class="table-10">
                          <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                        </th>
                        <th id="name" pSortableColumn="name">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'NOM' | translate }}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="name"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="name"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="description" pSortableColumn="description">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Description
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="description"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="description"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-virtual>
                      <tr>
                        <td>
                          <p-skeleton></p-skeleton>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-container>
                <ng-container *ngIf="listSkill && listAllPersonas">
                  <p-table
                    dataKey="name"
                    #dt2
                    (sortFunction)="naturalSort($event)"
                    [customSort]="true"
                    sortField="name"
                    [sortOrder]='1'
                    dataKey="name"
                    [(selection)]="selectedSkills"
                    [value]="listHabilitation"
                    class="table"
                    [scrollable]="true"
                    scrollHeight="40vh"
                    [paginator]="true"
                    [rows]="10"
                    styleClass="p-datatable-sm"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [globalFilterFields]="['name', 'description']"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        <!-- <button
                          (click)="confirm($event)"
                          class="p-button-outlined filter-clear"
                          pButton
                          icon="pi pi-filter"
                          label="Colonnes"
                        ></button> -->
                        <button
                          pButton
                          label="{{'FILTRES'|translate}}"
                          class="p-button-outlined filter-clear"
                          icon="pi pi-filter-slash"
                          (click)="clear(dt2)"
                        ></button>
                        <span class="p-input-icon-left p-ml-auto">
                          <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                              pInputText
                              type="text"
                              [value]="searchedSkill!== undefined?searchedSkill:''"
                              (input)="filterTableInputChangeTabSkill($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                              placeholder="{{ 'recherche' | translate }}"
                            />
                          </span>
                        </span>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th class="table-10">
                          <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                        </th>
                        <th id="name" pSortableColumn="name">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'NOM' | translate }}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="name"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="name"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="description" pSortableColumn="description">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Description
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="description"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="description"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-skill>
                      <tr *ngIf="skill?.type === 'HABILITATION'">
                        <td class="table-10">
                          <div class="p-d-flex p-ai-center">
                            <p-tableCheckbox [value]="skill"
                                             (click)="selectSkill($event, skill)">
                            </p-tableCheckbox>
                          </div>
                        </td>
                        <td>
                          {{ skill.name }}
                        </td>
                        <td>
                          {{ skill.description }}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-container>
              </p-tabPanel>
              <!---SHOW SF -->
              <p-tabPanel header="{{ 'SAVOIR_FAIRE' | translate }}">
                <ng-container *ngIf="!listSkill || !listAllPersonas">
                  <p-table
                    #dt3
                    (sortFunction)="naturalSort($event)"
                    [customSort]="true"
                    sortField="name"
                    [sortOrder]='1'
                    dataKey="name"
                    [value]="virtual"
                    class="table"
                    [scrollable]="true"
                    scrollHeight="40vh"
                    [paginator]="true"
                    [rows]="10"
                    styleClass="p-datatable-sm"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [globalFilterFields]="['name', 'description']"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                            pInputText
                            type="text"
                            [value]="searchedSkill!== undefined?searchedSkill:''"
                            (input)="filterTableInputChangeTabSkill($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                            placeholder="{{ 'recherche' | translate }}"
                          />
                        </span>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th class="table-10">
                          <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                        </th>
                        <th id="name" pSortableColumn="name">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'NOM' | translate }}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="name"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="name"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="description" pSortableColumn="description">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Description
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="description"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="description"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-virtual>
                      <tr>
                        <td>
                          <p-skeleton></p-skeleton>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-container>
                <ng-container *ngIf="listSkill && listAllPersonas">
                  <p-table
                    dataKey="name"
                    #dt3
                    (sortFunction)="naturalSort($event)"
                    [customSort]="true"
                    sortField="name"
                    [sortOrder]='1'
                    dataKey="name"
                    [value]="listSavoirFaire"
                    [(selection)]="selectedSkills"
                    class="table"
                    [scrollable]="true"
                    scrollHeight="40vh"
                    [paginator]="true"
                    [rows]="10"
                    styleClass="p-datatable-sm"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [globalFilterFields]="['name', 'description']"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        <!-- <button
                          (click)="confirm($event)"
                          class="p-button-outlined filter-clear"
                          pButton
                          icon="pi pi-filter"
                          label="Colonnes"
                        ></button> -->
                        <button
                          pButton
                          label="{{'FILTRES'|translate}}"
                          class="p-button-outlined filter-clear"
                          icon="pi pi-filter-slash"
                          (click)="clear(dt3)"
                        ></button>
                        <span class="p-input-icon-left p-ml-auto">
                          <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                              pInputText
                              type="text"
                              [value]="searchedSkill!== undefined?searchedSkill:''"
                              (input)="filterTableInputChangeTabSkill($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                              placeholder="{{ 'recherche' | translate }}"
                            />
                          </span>
                        </span>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th class="table-10">
                          <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                        </th>
                        <th id="name" pSortableColumn="name">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'NOM' | translate }}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="name"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="name"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="description" pSortableColumn="description">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Description
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="description"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="description"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-skill>
                      <tr *ngIf="skill?.type === 'SAVOIR_FAIRE'">
                        <td class="table-10">
                          <div class="p-d-flex p-ai-center">
                            <p-tableCheckbox [value]="skill"
                                             (click)="selectSkill($event, skill)"></p-tableCheckbox>
                          </div>
                        </td>
                        <td>
                          {{ skill.name }}
                        </td>
                        <td>
                          {{ skill.description }}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-container>
              </p-tabPanel>
              <!-- SHOW SE-->
              <p-tabPanel header="{{ 'SAVOIR_ETRE' | translate }}">
                <ng-container *ngIf="!listSkill || !listAllPersonas">
                  <p-table
                    #dt4
                    (sortFunction)="naturalSort($event)"
                    [customSort]="true"
                    sortField="name"
                    [sortOrder]='1'
                    dataKey="name"
                    [value]="virtual"
                    class="table"
                    [scrollable]="true"
                    scrollHeight="40vh"
                    [paginator]="true"
                    [rows]="10"
                    styleClass="p-datatable-sm"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [globalFilterFields]="['name', 'description']"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                            pInputText
                            type="text"
                            [value]="searchedSkill!== undefined?searchedSkill:''"
                            (input)="filterTableInputChangeTabSkill($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                            placeholder="{{ 'recherche' | translate }}"
                          />
                        </span>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th class="table-10">
                          <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                        </th>
                        <th id="name" pSortableColumn="name">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'NOM' | translate }}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="name"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="name"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="description" pSortableColumn="description">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Description
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="description"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="description"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-virtual>
                      <tr>
                        <td>
                          <p-skeleton></p-skeleton>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-container>
                <ng-container *ngIf="listSkill && listAllPersonas">
                  <p-table
                    dataKey="name"
                    #dt4
                    (sortFunction)="naturalSort($event)"
                    [customSort]="true"
                    sortField="name"
                    [sortOrder]='1'
                    dataKey="name"
                    [value]="listSavoirEtre"
                    [(selection)]="selectedSkills"
                    class="table"
                    [scrollable]="true"
                    scrollHeight="40vh"
                    [paginator]="true"
                    [rows]="10"
                    styleClass="p-datatable-sm"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [globalFilterFields]="['name', 'description']"
                  >
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        <!-- <button
                          (click)="confirm($event)"
                          class="p-button-outlined filter-clear"
                          pButton
                          icon="pi pi-filter"
                          label="Colonnes"
                        ></button> -->
                        <button
                          pButton
                          label="{{'FILTRES'|translate}}"
                          class="p-button-outlined filter-clear"
                          icon="pi pi-filter-slash"
                          (click)="clear(dt4)"
                        ></button>
                        <span class="p-input-icon-left p-ml-auto">
                          <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                              pInputText
                              type="text"
                              [value]="searchedSkill!== undefined?searchedSkill:''"
                              (input)="filterTableInputChangeTabSkill($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                              placeholder="recherche"
                            />
                          </span>
                        </span>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th class="table-10">
                          <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                        </th>
                        <th id="name" pSortableColumn="name">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'NOM' | translate }}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="name"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="name"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                        <th id="description" pSortableColumn="description">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'DESCRIPTION' | translate }}
                            <div class="p-d-flex p-flex-column p-ai-center">
                              <p-columnFilter
                                type="text"
                                field="description"
                                display="menu"
                              ></p-columnFilter>
                              <p-sortIcon field="description"></p-sortIcon>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-skill>
                      <tr *ngIf="skill?.type === 'SAVOIR_ETRE'">
                        <td class="table-10">
                          <div class="p-d-flex p-ai-center">
                            <p-tableCheckbox [value]="skill"
                                             (click)="selectSkill($event, skill)"></p-tableCheckbox>
                          </div>
                        </td>
                        <td>
                          {{ skill.name }}
                        </td>
                        <td>
                          {{ skill.description }}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-container>
              </p-tabPanel>
            </p-tabView>

            <!----
        DISPLAY SELECTED SKILLS FROM TAB
        ---->
          </p-panel>
        </div>
      </div>
    </div>
    <div
      class="p-col-5 p-jc-center p-m-3 p-p-3 main-content p-shadow-4 skills-col"
    >
    <h2>Personas</h2>
    <span *ngFor="let skill of selectedSkills">
        <p-chip
          [label]="skill.name" [removable]="true" (onRemove)="removeSkill($event, skill)" styleClass="p-mb-2 p-ml-2 p-mr-2 chip-custom-basic">
        </p-chip>
        <!-- <p-chip
          *ngIf="skill.type === 'HABILITATION'"
          [label]="skill.name" [removable]="true" (onRemove)="removeSkill($event, skill)" styleClass="p-mb-2 p-ml-2 p-mr-2 chip-info">
        </p-chip>
        <p-chip
          *ngIf="skill.type === 'SAVOIR_ETRE'"
          [label]="skill.name" [removable]="true" (onRemove)="removeSkill($event, skill)" styleClass="p-mb-2 p-ml-2 p-mr-2 chip-success">
        </p-chip>
        <p-chip
          *ngIf="skill.type === 'SAVOIR_FAIRE'"
          [label]="skill.name" [removable]="true" (onRemove)="removeSkill($event, skill)" styleClass="p-mb-2 p-ml-2 p-mr-2 chip-warning">
        </p-chip> -->
      </span>
      <ng-container>
        <p-table
          dataKey="lastName"
          #dt10
          (sortFunction)="naturalSort($event)"
          [customSort]="true"
          sortField="lastName"
          [sortOrder]='1'
          dataKey="lastNamey"
          [value]="listPersonasToDisplay"
          id="tablePersona"
          class="table"
          [scrollable]="true"
          scrollHeight="40vh"
          [paginator]="true"
          [rows]="10"
          styleClass="p-datatable-sm"
          [rowsPerPageOptions]="[10, 25, 50]"
          [globalFilterFields]="[
            'lastName',
            'firstName'
          ]"
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <div class="p-field-checkbox">
                <p-checkbox class="p-mr-2 p-mb-2" [(ngModel)]="displayTechnician" label="{{ 'INTERVENANTS'| translate }}" binary="true" inputId="binary"
                            (onChange)="selectTechnician($event)"></p-checkbox>
              </div>
              <span class="p-input-icon-left p-ml-auto">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      dt10.filterGlobal($event.target.value, 'contains')
                    "
                    placeholder="{{ 'recherche' | translate }}"
                  />
                </span>
              </span>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th class="table-10"></th>
              <th class="table-20"></th>
              <th id="lastName" pSortableColumn="lastName">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'NOM' | translate }}
                  <div class="p-d-flex p-flex-column p-ai-center">
                    <p-sortIcon field="lastName"></p-sortIcon>
                  </div>
                </div>
              </th>
              <th class="table-10"></th>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="body"
            let-listPersonasToDisplay
            let-expanded="expanded"
          >
            <tr>
              <td class="table-10">
                <div class="p-d-flex p-ai-center">
                  <button
                    type="button"
                    pButton
                    pRipple
                    [pRowToggler]="listPersonasToDisplay"
                    class="
                      p-button-text p-button-rounded p-button-plain
                    "
                    [icon]="
                      expanded
                        ? 'pi pi-chevron-down'
                        : 'pi pi-chevron-right'
                    "
                  ></button>
                </div>
              </td>
              <td class="table-20">
                <p-avatar styleClass="p-mr-2" size="xlarge" shape="circle"><img style="object-fit: cover; " src="{{ listPersonasToDisplay?.photo }}"
                /></p-avatar>
              </td>
              <td>
                {{ listPersonasToDisplay.lastName }} {{ listPersonasToDisplay.firstName }}
              </td>
              <td class="table-10">
                <button (click)="show(listPersonasToDisplay)" pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-listPersonasToDisplay>
            <tr>
              <td colspan="4">
                <span *ngIf="listPersonasToDisplay.listSkill.length === 0">
                  <p-message severity="warn" text="Aucune compétence pour ce persona" styleClass="p-mr-2"></p-message>
                </span>
                <span *ngFor="let skill of listPersonasToDisplay.listSkill, let i = index">
                  <p-tag styleClass="chip-custom-basic p-mb-1 p-mr-1" *ngIf="
                            skill.skill.type === 'HABILITATION' &&
                            checkValidDate(skill)
                          ">
                            {{ skill.skill.name }}
                          </p-tag>
                          <p-tag styleClass="chip-custom-basic p-mb-1 p-mr-1" *ngIf="skill.skill.type === 'SAVOIR_ETRE'">{{ skill.skill.name }}</p-tag>
                          <p-tag styleClass="chip-custom-basic p-mb-1 p-mr-1" *ngIf="skill.skill.type === 'SAVOIR_FAIRE'">{{ skill.skill.name }}</p-tag>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ng-container>
  </div>
</div>
<p-confirmPopup></p-confirmPopup>
