<div class="p-p-2">
  <div class="p-grid">
    <div class="p-col-1"></div>
    <div class="p-col">{{ 'NOM_FAMILLE' | translate }}</div>
    <div class="p-col">{{ 'DEUXIEME_NOM'| translate }}</div>
    <div class="p-col">{{ 'NOM_PUBLIC' | translate }}</div>
    <div class="p-col">{{ 'POSTE' | translate }}</div>
  </div>
  <div class="p-grid p-d-flex p-ai-center">
    <div class="p-col-1"><p-avatar styleClass="p-mr-2" size="xlarge" shape="circle"><img style="object-fit: cover; " src="{{ persona?.photo }}"
      /></p-avatar></div>
    <div class="p-col">{{ persona.lastName }} {{ persona.firstName }}</div>
    <div class="p-col">{{ persona.middleName }}</div>
    <div class="p-col">{{ persona.publicName }}</div>
    <div class="p-col"> <ng-container
      *ngFor="let post of persona.listProfil; let i = index"
    >
      <p-chip
        label="{{ post.name }}"
        class="postChips"
      ></p-chip>
    </ng-container>
    </div>
  </div>
</div>

<div class="p-mt-4 p-p-2">
  <h3>{{ 'HABILITATION' | translate }}</h3>
  <div>
    <p-accordion styleClass="list-skill">
      <div *ngFor="let habilitation of listHabilitation">
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="p-col-12 p-grid p-nogutter p-d-flex p-ai-center p-jc-between">
              <div>
                {{ habilitation.skill.name }} <span class="p-ml-1" *ngIf="habilitation.skill.validity"> valide du {{ habilitation.startDate | date: "fullDate":'fr' }} au {{ habilitation.endDate | date: "fullDate":'fr' }}</span>
              </div>
              <div class="p-d-flex p-ai-center p-text-right">
                <p-tag
                  severity="danger"
                  value="Date échue"
                  icon="pi pi-exclamation-triangle"
                  *ngIf="
                  !checkValidDate(habilitation)
                  "
                ></p-tag>
              </div>
            </div>

          </ng-template>
          Description : {{ habilitation.skill.description }}
        </p-accordionTab>
      </div>
    </p-accordion>
  </div>
</div>

<div class="p-mt-4 p-p-2">
  <h3>{{ 'SAVOIR_FAIRE'| translate }}</h3>
  <div>
    <p-accordion styleClass="list-skill">
      <div *ngFor="let savoirFaire of listSavoirFaire">
        <p-accordionTab>
          <ng-template pTemplate="header">
            {{ savoirFaire.skill.name }}

          </ng-template>
          Description : {{ savoirFaire.skill.description }}
        </p-accordionTab>
      </div>
    </p-accordion>
  </div>
</div>

<div class="p-mt-4 p-p-2">
  <h3>{{ 'SAVOIR_ETRE' | translate }}</h3>
  <div>
    <p-accordion styleClass="list-skill">
      <div *ngFor="let savoirEtre of listSavoirEtre">
        <p-accordionTab>
          <ng-template pTemplate="header">
            {{ savoirEtre.skill.name }}

          </ng-template>
          Description : {{ savoirEtre.skill.description }}
        </p-accordionTab>
      </div>
    </p-accordion>
  </div>
</div>
