import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkOrder } from '@data/schema/WorkOrder';
import {Currency} from "@data/schema/Currency";
import { LocalStorageService } from '@shared/service/local-storage.service';

@Component({
  selector: 'app-work-order-form-detail',
  templateUrl: './work-order-form-detail.component.html',
  styleUrls: ['./work-order-form-detail.component.css']
})
export class WorkOrderFormDetailComponent implements OnInit {

  @Input() workOrder: WorkOrder;
  @Input() id: number;
  @Output() workOrderChange = new EventEmitter();
  change() {
    this.workOrderChange.emit(this.workOrder);
  }

  @Output() isChangeListWork = new EventEmitter();
  changeListWork() {
    this.isChangeListWork.emit(this.workOrder.listWorkOrderProjectWork);
  }

  @Input() editMode: boolean;
  fromWorKOrder: boolean = true;

  currency: Currency;

  constructor(private localStorage: LocalStorageService) {
    this.currency = JSON.parse(this.localStorage.getLocalStorageItem("currency"));
   }

  ngOnInit(): void {
  }

  updateListCompany(event : any){
    this.changeListWork();
  }

  

}
