import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CompanyService } from '@data/service/company.service';
import { Company } from '@data/schema/Company';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationService,SortEvent } from 'primeng/api';
import { PersonaService } from '@data/service/persona.service';
import {DataService} from '@shared/service/data.service';
import {CustomSortService} from "@shared/service/custom-sort.service";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
})
export class CompanyComponent implements OnInit {
  company: Company[];
  disabled: boolean = true;
  display: string;
  companyOnToggle: Company;
  listLinkedCompany: number[];

  constructor(
    private messageService: MessageService,
    private companyService: CompanyService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private personaService: PersonaService,
    private dataService: DataService,
    private customSortService: CustomSortService
  ) {}

  ngOnInit() {
    this.disabled = true;
    this.display = 'Inactive(s)';
    this.personaService.getListCompanyIdOfPersona().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listLinkedCompany = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail:  '{{"toast_ECHEC_OPERATION_REESSAYER"|translate}}',
          });
        }
      },
      () => {}
    );

    this.companyService.getListAllEnableCompany().subscribe(
      (res) => {
        if (res.status === 200) {
          this.company = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: '{{"toast_ECHEC_OPERATION_REESSAYER"|translate}}',
          });
        }
      },
      () => {}
    );
  }
  onPress() {
    if (this.disabled === false) {
      this.companyService.getListAllEnableCompany().subscribe(
        (res) => {
          if (res.status === 200) {
            this.company = res.body;
          }
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur',
              detail: '{{"toast_ECHEC_OPERATION_REESSAYER"|translate}}',
            });
          }
        },
        () => {}
      );
      this.disabled = true;
      this.display = 'Inactive(s)';
    } else {
      this.companyService.getAllDisableCompany().subscribe(
        (res) => {
          if (res.status === 200) {
            this.company = res.body;
          }
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur',
              detail: '{{"toast_ECHEC_OPERATION_REESSAYER"|translate}}',
            });
          }
        },
        () => {}
      );
      this.disabled = false;
      this.display = 'Active(s)';
    }
  }

  getRowCompany(company: Company) {
    this.companyOnToggle = company;
  }

  update() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        editMode: true,
      },
    };
    this.router.navigate(
      ['/phoenix/show-company/', this.companyOnToggle.companyId],
      navigationExtras
    );
  }

  confirm() {
    if (!this.companyOnToggle.enabled) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          "Vous allez activer l'entreprise ." +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Activer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.activate(this.companyOnToggle);
          this.router.navigate(['/phoenix/company']);
          this.messageService.add({
            severity: 'success',
            summary: '{{"toast_OPERATION_REUSSI"|translate}}',
          });
        },
      });
    } else if (this.companyOnToggle.enabled) {
      if (this.checkCompanyAlreadyUsed(this.companyOnToggle.companyId)) {
        this.confirmationService.confirm({
          header: 'Désactivation impossible',
          message:
            "{{'texte_message_exception_ASSOCIATED_PERSONA'|translate}}",
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: 'null',
          acceptLabel: "J'ai compris",
          rejectVisible: false,
        });
      } else {
        this.confirmationService.confirm({
          header: 'Confirmation',
          message:
            "Vous allez désactiver l'entreprise ." +
            '<br>' +
            'Voulez vous continuer ?',
          icon: 'pi pi-exclamation-triangle',
          acceptButtonStyleClass: 'p-button-outlined',
          acceptIcon: 'null',
          rejectIcon: 'null',
          acceptLabel: '{{"bouton_message_exception_DESACTIVER"|translate}}',
          rejectLabel: '{{"bouton_message_exception_ANNULER"|translate}}',
          accept: () => {
            this.disable(this.companyOnToggle);
            this.router.navigate(['/phoenix/company']);
            this.messageService.add({
              severity: 'success',
              summary: '{{"toast_OPERATION_REUSSI"|translate}}',
            });
          },
        });
      }
    }
  }

  activate(companyToDelete: Company) {
    this.company = this.company.filter(
      (company) => company.companyId !== companyToDelete.companyId
    );
    // Call API in the service to soft delete Persona
    companyToDelete.enabled = false;
    this.companyService.activateCompanyById(companyToDelete.companyId).subscribe(
      (res) => {
        // Deleted
        if (res.status === 200) {
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: '{{"toast_ECHEC_OPERATION_REESSAYER"|translate}}',
          });
        }
      },
      () => {}
    );
  }

  disable(companyToDelete: Company) {
    this.company = this.company.filter(
      (company) => company.companyId !== companyToDelete.companyId
    );
    companyToDelete.enabled = true;
    // Call API in the service to soft delete Persona
    this.companyService.disableCompany(companyToDelete.companyId).subscribe(
      (res) => {
        // Deleted
        if (res.status === 200) {
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: '{{"toast_ECHEC_OPERATION_REESSAYER"|translate}}',
          });
        }
      },
      () => {}
    );
  }

  checkCompanyAlreadyUsed(companyId: number) {
    if (this.listLinkedCompany.includes(companyId)) {
      return true;
    } else {
      return false;
    }
  }

  naturalSortCompany(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }
}
