import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Work } from '@data/schema/Work';
@Component({
  selector: 'app-work-show-general',
  templateUrl: './work-show-general.component.html',
  styleUrls: ['./work-show-general.component.css']
})
export class WorkShowGeneralComponent implements OnInit {
  @Input() work: Work;
  @Output() workChange = new EventEmitter();
  change() {
    this.workChange.emit(this.work);
  }
  constructor() { }

  ngOnInit(): void {
  }

}
