import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Branch, Tree } from '@data/schema/Tree';
import { TranslateService } from '@ngx-translate/core';

/**
 * Component to generate and display one breadcrumb based on route url
 */
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  /**
   * route url passed as param to detect change when component doesn't reload
   */
  @Input() route;

  /**
   * List of all possible breadcrumb based on route url
   */
  breadCrumb: Tree[];
  /**
   * Breadcrumb of current route
   */
  branch: Branch[];

  history: string[] = [];

  /**
   * Constructor with all routes and their possible breadcrumb
   */
  constructor(
    private router: Router,
    private translate: TranslateService,
  ) {

    this.breadCrumb = [
      {
        url: '/phoenix/settings/company',
        branch: [
          {
            text: this.translate.instant('ENTREPRISES'),
            link: ''
          }

        ]
      },
      {
        url: '/phoenix/settings/company/new-company',
        branch: [
          {
            text: this.translate.instant('ENTREPRISES'),
            link: '/phoenix/settings/company'
          },
          {
            text: this.translate.instant('AJOUTER_UNE_ENTREPRISE'),
            link: ''
          }

        ]
      },
      {
        url: '/phoenix/settings/company/company-overview',
        branch: [
          {
            text: this.translate.instant('ENTREPRISES'),
            link: '/phoenix/settings/company'
          },
          {
            text: this.translate.instant('FICHE_ENTREPRISE'),
            link: ''
          }

        ]
      },
      {
        url: '/phoenix/settings/my-organizations',
        branch: [
          {
            text: this.translate.instant('ORGANISATIONS_HIERARCHIQUES'),
            link: ''
          }
          ,
          {
            text: this.translate.instant('MES_ORGANISATIONS'),
            link: '/phoenix/settings/my-organizations'
          }
        ]
      },
      {
        url: '/phoenix/settings/new-organization',
        branch: [
          {
            text: this.translate.instant('ORGANISATIONS_HIERARCHIQUES'),
            link: ''
          }
          ,
          {
            text: this.translate.instant('MES_ORGANISATIONS'),
            link: '/phoenix/settings/my-organizations',
          },
          {
            text: this.translate.instant('AJOUTER_UNE_ORGANISATION'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/settings/personas-organizations',
        branch: [
          {
            text: this.translate.instant('ORGANISATIONS_HIERARCHIQUES'),
            link: ''
          },
          {
            text: "Organisations & personas",
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/settings/new-pool',
        branch: [
          {
            text: this.translate.instant('menu_PARAMETRAGE_METIER'),
            link: '/phoenix/settings/pools',
          },
          {
            text: this.translate.instant('POOLS'),
            link: '/phoenix/settings/pools',
          },
          {
            text: this.translate.instant('breadcrumb_AJOUTER_POOL'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo-list',
        branch: [
          {
            text: this.translate.instant('breadcrumb_ot'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=to-plan',
        branch: [
          {
            text: this.translate.instant('breadcrumb_ot'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_group_a_planifier'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=not-started',
        branch: [
          {
            text: this.translate.instant('breadcrumb_ot'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_group_non_demarre'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=in-progress',
        branch: [
          {
            text: this.translate.instant('breadcrumb_ot'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_group_en_cours'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=to-validate',
        branch: [
          {
            text: this.translate.instant('breadcrumb_ot'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_group_a_valider'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=on-hold',
        branch: [
          {
            text: this.translate.instant('breadcrumb_ot'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_group_en_attente'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=in-error',
        branch: [
          {
            text: this.translate.instant('breadcrumb_ot'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_group_en_erreur'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=ended',
        branch: [
          {
            text: this.translate.instant('ot'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_group_termine'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo/advanced-search',
        branch: [
          {
            text: this.translate.instant('ot'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_recherche'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo-list/viewByTeam',
        branch: [
          {
            text: this.translate.instant('ot'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_VUE_EQUIPE'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/wo-list/globalView',
        branch: [
          {
            text: this.translate.instant('ot'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_VUE_GLOBALE'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/work-order/:number',
        branch: [
          {
            text: this.translate.instant('ot'),
            link: '/phoenix/intervention/wo-list'
          },
          {
            text: '*previousPage',
            link: '*previousLink'
          },
          {
            text: this.translate.instant('breadcrumb_DETAILS_OT'),
            link: 'details'
          }
        ]
      },
      {
        url: '/phoenix/intervention/work-order-creation',
        branch: [
          {
            text: this.translate.instant('ot'),
            link: '/phoenix/intervention/wo-list'
          },
          {
            text: this.translate.instant('breadcrumb_CREER_OT'),
            link: ''
          }
        ]
      },
      {
        url: '/phoenix/intervention/team-management/teams',
        branch: [
          {
            text: this.translate.instant('breadcrumb_GESTION_EQUIPES'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_CONSULTATION_EQUIPES'),
            link: ''
          },
        ]
      },

      {
        url: '/phoenix/intervention/team-management/new-team',
        branch: [
          {
            text: this.translate.instant('breadcrumb_GESTION_EQUIPES'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_CONSULTATION_EQUIPES'),
            link: '/phoenix/intervention/team-management/teams'
          },
          {
            text: this.translate.instant('breadcrumb_CREATION_EQUIPE'),
            link: ''
          },
        ]
      },
      {
        url: '/phoenix/intervention/team-management/new-team!team',

        branch: [
          {
            text: this.translate.instant('breadcrumb_GESTION_EQUIPES'),
            link: ''
          },
          {
            text: this.translate.instant('breadcrumb_CONSULTATION_EQUIPES'),
            link: '/phoenix/intervention/team-management/teams'
          },
          {
            text: this.translate.instant('breadcrumb_MODIFICATION_EQUIPE'),
            link: ''
          },
        ]
      },
      {

           url: '/phoenix/intervention/team-management/technicians-list',
           branch: [
            {
              text: this.translate.instant('breadcrumb_GESTION_EQUIPES'),
              link: ''
            },
            {
              text: this.translate.instant('INTERVENANTS'),
              link: ''
            },
          ]
       },
      {
           url: '/phoenix/intervention/planning-technicians',
           branch: [
            {
              text: this.translate.instant('breadcrumb_GESTION_EQUIPES'),
              link: ''
            },
            {
              text: this.translate.instant('disponiblite_EQUIPES'),
              link: ''
            },
          ]
       },
      {
           url: '/phoenix/intervention/planning-interventions',
           branch: [
            {
              text: 'Planning',
              link: ''
            }
          ]
       },
      {
           url: '/phoenix/intervention/dashboard',
           branch: [
            {
              text: this.translate.instant('tableau_de_bord'),
              link: ''
            }
          ]
       },
       {
        url: '/phoenix/logistic/order',
        branch: [
         {
           text: this.translate.instant('COMMANDES'),
           link: ''
         }
       ]
    }
      ]
    }



  /**
   * Get Route url and check if route is on breadcrumb variable to return the path
   */
  ngOnInit(): void {
    this.history = this.getHistory();

    const indexSameRoute = this.history.indexOf(this.route);
    if (indexSameRoute >= 0) {
      this.history.splice(indexSameRoute, 1);
    }

    this.history.push(this.route);

    this.setHistory();

    var result = this.breadCrumb.find(obj => {

      let prefix = [obj.url];
      if (obj.url.includes(':')) {
        prefix = obj.url.split(':');
      }
      else if (obj.url.includes('!')){
        prefix = obj.url.split('!');
      }

      if (prefix.length > 1) {
        if (this.router.url.startsWith(prefix[0])) {
          if (obj.url.includes(':')){
            let param = this.router.url.split("/")
            obj.branch[obj.branch.length - 1].text += param[param.length - 1]
          }

          return true
        }

        return false
      }
      else {
        return obj.url === this.router.url;
      }


    })
    if (result) {
      for (const branch of result.branch) {
        if (branch.text.startsWith('*')) {
          let lastUrl = this.history[this.history.length - 2]
          if (lastUrl) {
            branch.text = this.convertTextKey(lastUrl);
            branch.link = lastUrl;
          }

        }
      }
      this.branch = result.branch
    }
  }

  /**
   * reload breadcrumb when detect change on router.url
   */
  ngOnChanges() {
    if (this.branch) {
      this.ngOnInit();
    }
  }

  /**
   *
   * @param uri
   */
  redirectTo(uri: string) {
    if (uri !== '') {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigateByUrl(uri));
    }

  }

  convertTextKey(key: string) {
    if (key) {
      switch (key.replace('*', '')) {
        case "/phoenix/intervention/wo-list":
          return this.translate.instant('menu_TOUS');
        case "/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=to-plan":
          return this.translate.instant('breadcrumb_group_a_planifier');
        case "/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=not-started":
          return this.translate.instant('breadcrumb_group_non_demarre');
        case "/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=in-progress":
          return this.translate.instant('breadcrumb_group_en_cours');
        case "/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=to-validate":
          return this.translate.instant('breadcrumb_group_a_valider');
        case "/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=on-hold":
          return this.translate.instant('breadcrumb_group_en_attente');
        case "/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=in-error" :
          return this.translate.instant('breadcrumb_group_en_erreur');
        case "/phoenix/intervention/wo-list/viewByStatusGroup?statusGroup=ended":
          return this.translate.instant('breadcrumb_group_termine');
        case "/phoenix/intervention/wo/advanced-search":
          return this.translate.instant('breadcrumb_recherche');
        case "/phoenix/intervention/wo-list/viewByTeam":
        return this.translate.instant('breadcrumb_VUE_EQUIPE');
        case "/phoenix/intervention/wo-list/globalView":
        return this.translate.instant('breadcrumb_VUE_GLOBALE');
        default:
          return null;
      }
    }
    else {
      return null;
    }

  }

  public getHistory() {
    if (JSON.parse(localStorage.getItem('browserHistory'))) {
      return JSON.parse(localStorage.getItem('browserHistory'));
    }
    else {
      return [];
    }
  }

  public setHistory() {
    localStorage.setItem('browserHistory', JSON.stringify(this.history));
  }

}
