import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Project } from '@data/schema/Project';
import { ProjectService } from '@data/service/project.service';

@Component({
  selector: 'app-project-form-general',
  templateUrl: './project-form-general.component.html',
  styleUrls: ['./project-form-general.component.css'],
})
export class ProjectFormGeneralComponent implements OnInit {
  @Input() project: Project;
  @Output() projectChange = new EventEmitter();
  change() {
    this.projectChange.emit(this.project);
  }

  listProject: Project[] = [];
  outputName: string[];
  errorNameRequired: boolean = false;


  constructor(
    private messageService: MessageService,
    private projectService: ProjectService
    ) {}

  ngOnInit(): void {
    this.projectService.getAllProjects().subscribe(
      (res) => {
        //console.log(res);
        if (res.status === 200) {
          this.listProject = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => { }
    );
  }

  filterName(event) {
    this.outputName = this.listProject
      .filter((c) =>
        c.name.toLowerCase().startsWith(event.query.toLowerCase())
      )
      .map((project) => project.name);
  }

  nameFieldValidator() {
    if (!this.project.name.trim()) {
      this.errorNameRequired = true;
    } else {
      this.errorNameRequired = false;
    }

    return this.errorNameRequired;
  }
}
