import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CustomMissingTranslationHandlerService implements MissingTranslationHandler {

  /**
   * 
   */
  constructor(
  ) { }

  /**
   * Triggered when something is not translated
   * Try to find elsewhere a translation
   * 
   * @param params 
   * @returns 
   */
  handle(params: MissingTranslationHandlerParams) {

    var defaultLocale = "fr"
    var locale = "fr"
    var translatedWord;

    // check language en memoire
    if (localStorage.getItem("language")){
      let languageSetting = JSON.parse(localStorage.getItem("language"))
      locale = languageSetting.locale // ex: fr-FR, en-EN

      // TODO
      // CHECK IF CUSTOM LOCALE
    }

    // recuperer la trad de la langue a prendre
    params.translateService.getTranslation(locale).subscribe(values => {

      translatedWord = eval("values." + params.key)
    });

    // Si pas de traduction dans la langue, on cherche dans le fichier par défaut
    if (!translatedWord && locale !== defaultLocale) {
      params.translateService.getTranslation(defaultLocale).subscribe(values => {

        translatedWord = eval("values." + params.key)
      });
    }

    return translatedWord;
  }
}
