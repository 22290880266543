import { Persona } from "./Persona";

export class Technician extends Persona {
    id : number;
    idPersona : number;
    address : string;
    characteristics : {};
    idParty: number;

    constructor(persona?: Persona, id? :number, idPersona? : number, address? :string, characteristics? : {},idParty?: number){
        super(persona);
        Object.assign(this, persona)
        this.id = id;
        this.idPersona = idPersona;
        this.address = address;
        this.characteristics = characteristics;
        this.idParty = idParty;


    }

    public isDefined(){
        return (

          this.address

        )
      }
}
