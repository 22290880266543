import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SidenavService } from '@shared/service/sidenav.service';
import { Persona } from '@data/schema/Persona';
import { Router } from '@angular/router';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { LoginService } from '@core/service/login.service';
import { CustomIconsService } from '@shared/service/custom-icons.service';
import { SearchService } from "@data/service/intervention/search/search.service";
import { SellerbotService } from '@data/service/sellerbot.service';

/**
 * Generate left menu
 */
@Component({
  selector: 'app-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [

    // animate sidenave
    trigger('onSideNavChange', [
      state('close',
        style({
          width: '60px'
        })
      ),
      state('open',
        style({
          width: '250px'
        })
      ),
      transition('close => open', animate('250ms ease-in')),
      transition('open => close', animate('250ms ease-in')),

    ]),
    trigger('animateText', [
      state('hide',
        style({
          'display': 'none',
          opacity: 0,
        })
      ),
      state('show',
        style({
          'display': 'block',
          opacity: 1,
        })
      ),
      transition('close => open', animate('350ms ease-in')),
      transition('open => close', animate('200ms ease-out')),
    ])

  ]
})
export class MenuLeftComponent implements OnInit, AfterViewInit {


  /**
   * user persona logged
   */
  persona: Persona;
  role:  String;

  /**
   * Determined if nav should be collapsed or not
   */
  sideNavState: boolean;

  /**
   * Determined if text should be displayed or not
   */
  linkText: boolean;

  /**
   * Determine which panel to open in menu
   */
  step: number = 0;

  @ViewChild('woListButton', { read: ElementRef }) woListButton: ElementRef<HTMLElement>;
  @ViewChild('toPlanButton', { read: ElementRef }) toPlanButton: ElementRef<HTMLElement>;
  @ViewChild('notStartedButton', { read: ElementRef }) notStartedButton: ElementRef<HTMLElement>;
  @ViewChild('inProgressButton', { read: ElementRef }) inProgressButton: ElementRef<HTMLElement>;
  @ViewChild('toValidateButton', { read: ElementRef }) toValidateButton: ElementRef<HTMLElement>;
  @ViewChild('onHoldButton', { read: ElementRef }) onHoldButton: ElementRef<HTMLElement>;
  @ViewChild('inErrorButton', { read: ElementRef }) inErrorButton: ElementRef<HTMLElement>;
  @ViewChild('endedButton', { read: ElementRef }) endedButton: ElementRef<HTMLElement>;


  /**
   * Inject service
   * @param _sidenavService
   * @param router
   * @param localStorageService
   */
  constructor(
    private _sidenavService: SidenavService,
    public router: Router,
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private customIconService: CustomIconsService,
    public searchService: SearchService) {
    this.customIconService.customIcon();
  }

  /**
   * Check local storage to get user preference about nav
   * If get preference : sidenavstate get preference
   * If not : true by default
   *
   * check current url and determine which panel to open by default
   */
  ngOnInit() {

    this.role = (sessionStorage.getItem("auth-role"))
    if (this.localStorageService.getLocalStorageItem("SIDENAVSTATE") === null) {
      this.sideNavState = true;
    }
    else {
      this.sideNavState = JSON.parse(this.localStorageService.getLocalStorageItem("SIDENAVSTATE"))
    }
    this.linkText = this.sideNavState
    this._sidenavService.sideNavState$.next(this.sideNavState)

    if (sessionStorage.getItem("persona") !== null) {
      this.persona = JSON.parse(sessionStorage.getItem("persona"))
    }

    if (this.router.url.startsWith('/phoenix/intervention/wo-list') || this.router.url.startsWith('/phoenix/intervention/work-order')) {
      this.setStep(0)
    }
    else if (this.router.url.startsWith('/phoenix/intervention/team-management')) {
      this.setStep(1)
    }

    this.searchService.indexMenuBehaviorSubject.subscribe(value => {
      switch (value) {
        case 1: {
          if (this.woListButton) {
            let el: HTMLElement = this.woListButton?.nativeElement;
            el ? el?.click() : '';
          }
          break;
        }
        case 2: {
          let el: HTMLElement = this.toPlanButton?.nativeElement;
          el?.click();
          break;
        }
        case 3: {
          let el: HTMLElement = this.notStartedButton?.nativeElement;
          el?.click();
          break;
        }
        case 4: {
          let el: HTMLElement = this.inProgressButton?.nativeElement;
          el?.click();
          break;
        }
        case 5: {
          let el: HTMLElement = this.toValidateButton?.nativeElement;
          el?.click();
          break;
        }
        case 6: {
          let el: HTMLElement = this.onHoldButton?.nativeElement;
          el?.click();
          break;
        }
        case 7: {
          let el: HTMLElement = this.inErrorButton?.nativeElement;
          el.click();
          break;
        }
        case 8: {
          let el: HTMLElement = this.endedButton?.nativeElement;
          el?.click();
          break;
        }
        default: {
          let el: HTMLElement = this.woListButton?.nativeElement;
          el?.click();
          break
        }
      }
    })

  }


  /**
   * Change sideNavState and store in local storage
   */
  onSinenavToggle() {
    this.sideNavState = !this.sideNavState
    this.localStorageService.setLocalStorageItem("SIDENAVSTATE", this.sideNavState)

    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200)
    this._sidenavService.sideNavState$.next(this.sideNavState)
  }

  /**
   * logout user and redirect to login page
   */
  logout() {
    this.loginService.logout();
    this.searchService.resetFilteredData();
    this.searchService.resetSearchForm();
    this.searchService.searchIsOpen = false;
    this.router.navigate(['/']);

  }

  setStep(index: number) {
    this.step = index;
  }

  ngAfterViewInit(): void {

  }

  goToUrl(url: string, statusGroup?:string) {
    this.router.navigate([url],{ queryParams: { statusGroup: statusGroup}})
  }
}
