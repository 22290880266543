<br>
<div class="card">
  <p-table

      #dt1
      [value]="project.projectWorks"
      (sortFunction)="naturalSortWorkSelected($event)"
      sortField="work.name"
      [sortOrder]='1'
      [customSort]="true"
      styleClass="p-datatable-striped p-datatable-sm"
      [globalFilterFields]="
      [
        'work.name',
        'comment',
        'quantityTotal',
        'quantityFree',
        'quantityTotal - quantityFree'
      ]"

      class="table">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-jc-between p-m-3">
          <div>
            <span [style]="{'font-weight': 'bold','margin-left':'-20px'}">Prestations sélectionnées</span>
          </div>
          <div>
            <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px'}">
              <i class="pi pi-search" [style]="{'margin-left':'10%','height': '45px','margin-top':'5px'}"></i>
              <input
              [style]="{'margin-left':'10%','height': '45px'}"
                pInputText
                type="text"
                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="Rechercher"
              />
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="table-25 head" pSortableColumn="work.name">Prestations<p-sortIcon field="work.name" ></p-sortIcon></th>
          <th class="table-35 head" pSortableColumn="comment">Commentaire<p-sortIcon field="comment" ></p-sortIcon></th>
          <th class="table-15 p-text-right head" pSortableColumn="quantityTotal"><span class="quantity">Quantité totale</span><p-sortIcon field="quantityTotal" ></p-sortIcon></th>
          <th class="table-15 p-text-right head" pSortableColumn="quantityFree"><span class="quantity">Planifiée</span><p-sortIcon field="quantity" ></p-sortIcon></th>
          <th class="table-15 p-text-right head" pSortableColumn="quantityTotal - quantityFree"><span class="quantity">A planifier</span><p-sortIcon field="quantityTotal - quantityFree" ></p-sortIcon></th>
          <th *ngIf="editMode" class="table-10 p-text-right head"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-projectWork let-index="rowIndex">
        <tr>
          <td [pTooltip]="projectWork.work.description" class="table-30">{{projectWork.work.name}}</td>
          <td class="table-40">{{projectWork.comment}}</td>
          <td class="table-15 p-text-right quantity"><span class="quantity">{{projectWork.quantityTotal | number:'1.0-0':'fr'}}</span></td>
          <td class="table-15 p-text-right quantity"><span class="quantity">{{projectWork.quantityTotal - projectWork.quantityFree| number:'1.0-0':'fr'}}</span></td>
          <td class="table-15 p-text-right quantity"><span class="quantity">{{projectWork.quantityFree | number:'1.0-0':'fr'}}</span></td>
          <td class="table-15">
            <button  *ngIf="editMode" pButton pRipple type="button"
            icon="pi pi-pencil" (click)="update(projectWork, index)" class="p-button-rounded p-button-text" pTooltip="Modifier"
            > </button>
            <button *ngIf="editMode && isInWorkOrder(projectWork)" pButton pRipple type="button"
            icon="pi pi-trash" (click)="delete(index)" class="p-button-rounded p-button-text" pTooltip="Supprimer"
            > </button>
          </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [draggable]="false"
  [modal]="true"
  header="{{editedProjectWork.work?.name}}"
  [(visible)]="modifMod"
  styleClass="main-page"
  [style]="{ width: '60%' }"
  (onShow)="setFocus()"
>

  <div class="p-grid">
    <div *ngIf="project.id" class="p-col-6">
      <label for="quantity" class="p-mb-2 p-md-2 p-mb-md-0">
        Quantité plannifiée dans des OT
      </label>
      <div class="p-mb-2 p-md-2 p-mb-md-0">
        {{ minQtyPlanned }}
      </div>
    </div>
    <div class="p-col-6">
      <label for="quantity" class="p-mb-2 p-md-2 p-mb-md-0">
        Quantité
      </label>
      <div>
        <p-inputNumber [(ngModel)]="editedProjectWork.quantityTotal" mode="decimal" [showButtons]="true" buttonLayout="horizontal"
        spinnerMode="horizontal" decrementButtonClass="p-button-primary" incrementButtonClass="p-button-primary"
        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" size=7 #input>
        </p-inputNumber>
        <p-message
        *ngIf="editedProjectWork.quantityTotal < minQtyPlanned && minQtyPlanned > 0"
        severity="error"
        text="Quantité minimum autorisée : {{ minQtyPlanned }} "
      ></p-message>
      <p-message
        *ngIf="editedProjectWork.quantityTotal < minQtyPlanned + 1 && minQtyPlanned === 0"
        severity="error"
        text="Quantité minimum autorisée : {{ minQtyPlanned + 1 }} "
      ></p-message>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <label for="description" class="p-mb-2 p-md-2 p-mb-md-0">
        Commentaire
      </label>
    </div>
    <div class="p-col-12 p-fluid">
      <!-- <textarea [(ngModel)]="project.description" (ngModelChange)="change()" [rows]="4" pInputTextarea> </textarea>  -->
      <textarea [(ngModel)]="editedProjectWork.comment"  [rows]="6" pInputTextarea>
        </textarea>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="p-text-right" >
      <!--RETOUR A LA LISTE-->
      <p-button styleClass="p-button-outlined p-mr-2" label="Annuler" (click)="annuler()"></p-button>

      <p-button styleClass="p-mr-2" label="Valider" (click)="saveUpdate()"></p-button>
    </div>
  </ng-template>

</p-dialog>
