import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,SimpleChanges} from '@angular/core';
import {MessageService, SortEvent} from 'primeng/api';
import {Project, ProjectWorkOrder} from '@data/schema/Project';
import {WorkOrder} from '@data/schema/WorkOrder';
import {ProjectService} from '@data/service/project.service';
import {WorkOrderService} from '@data/service/work-order.service';
import {BlService} from "@data/service/bl.service";
import {Company} from "@data/schema/Company";
import {CustomSortService} from "@shared/service/custom-sort.service";
import {WorkOrderRequestAttributes} from "@data/schema/WorkOrderRequestAttributes";

@Component({
  selector: 'app-work-order-form-general',
  templateUrl: './work-order-form-general.component.html',
  styleUrls: ['./work-order-form-general.component.css']
})
export class WorkOrderFormGeneralComponent implements OnInit, OnDestroy {

  @Input() workOrder: WorkOrder;
  @Input() project: Project;
  @Input() listCompany: Company[] = [];
  @Output() workOrderChange = new EventEmitter();
  goodValue: string;

  change() {
    this.workOrderChange.emit(this.workOrder);
  }

  listProject: Project[] = [];
  listProjectWorkOrder: ProjectWorkOrder[] = [];
  listProjectCompany: Company[] = [];
  listWorksCompany: any[];
  hasProject: WorkOrderRequestAttributes;
  projectLocalStorageName: string;
  projectLocalStorageId: number;
  projectLocalStorage: Project;
  isRedirectLocalStorage: boolean=false;

  projectSelected: Project;
  companySelected: Company;
  outputName: string[];

  errorNameRequired: boolean = false;
  errorProjectRequired: boolean = false;
  statusDoing: boolean = false;
  idCompany: number;
  canCompanyProcessWorks: boolean = false;

  constructor(
    private messageService: MessageService,
    private projectService: ProjectService,
    private workOrderService: WorkOrderService,
    private blService: BlService,
    private customSortService: CustomSortService
  ) {
  }

  alphaNumericSort = (arr = []) => {

      const sorter = (a:any, b:any) => {
      const isNumber = (v:any) => (+v).toString() === v;
      const aPart = a.name.match(/\d+|\D+/g);
      const bPart = b.name.match(/\d+|\D+/g);
      let i = 0; let len = Math.min(aPart.length, bPart.length);
      while (i < len && aPart[i] === bPart[i]) { i++; };
        if (i === len) {
            return aPart.length - bPart.length;
      };
      if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
      };
      return aPart[i].localeCompare(bPart[i]); };
      arr.sort(sorter);

  };

  ngOnInit(): void {


    this.projectLocalStorageName =  localStorage.projectName;

    this.projectLocalStorageId =  localStorage.projectId;

    if (localStorage.isRedirect == "true"){

      this.isRedirectLocalStorage = true;
    }
    else{

      this.isRedirectLocalStorage = false;
    }

    if (this.projectLocalStorageId){

        this.workOrder.project = new Project();

        this.workOrder.project.id = this.projectLocalStorageId;

        this.getProjectSelected(this.projectLocalStorageId)


    }



    this.projectService.getAllProjectsPw().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listProject = res.body;
          //let x = this.listProject.sort((a, b) => (a.name > b.name ? 1 : -1));
          this.alphaNumericSort(this.listProject);


        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        if (this.workOrder.id && this.workOrder.company && this.workOrder.company.companyId && !this.idCompany) {
          this.updateIdCompanySelected();
        }
      }
    );

    this.workOrderService.getAllWorkOrders().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listProjectWorkOrder = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

  selectProjectCompany(id: number) {

    const workOrderRequestAttributes: WorkOrderRequestAttributes = {
      projectId: id,
      isAttachedToProject: this.workOrder.hasProject,
      workOrderID: this.workOrder.id,
      alreadyCreated: this.workOrder.id !== null && this.workOrder.id !== undefined
    }

    this.blService.listProjectCompany(workOrderRequestAttributes).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listCompany = res.body;
          this.alphaNumericSort(this.listCompany);
          this.listProjectCompany = res.body;
          this.alphaNumericSort(this.listProjectCompany);
        }
      }
    );

  }

  resetCompany() {
    this.listCompany = this.listProjectCompany;
  }

  selectProject() {

    if (this.workOrder.project && this.projectSelected && this.workOrder.project.id !== this.projectSelected.id) {
      this.workOrder.listWorkOrderProjectWork = [];
      this.workOrder.company = null;

    }

    if (this.workOrder.project) {
      if (this.projectSelected && this.workOrder.project.id === this.projectSelected.id) {

      }
      else {
        this.errorProjectRequired = false;
        this.getProjectSelected(this.workOrder.project.id)
        this.workOrder.attachedToCompany = false;

      }

    }

  }

  async selectCompany() {

    this.workOrder.attachedToCompany = false;
    this.listWorksCompany = [];
    if (!this.checkProjetValidate()) {
      const workOrderRequestAttributes: WorkOrderRequestAttributes = {
        //projectId: !this.errorProjectRequired? null :this.workOrder.project.id,
        projectId: this.workOrder.project.id,
        isAttachedToProject: this.workOrder.hasProject,
        workOrderID: this.workOrder.id,
        alreadyCreated: this.workOrder.id !== null && this.workOrder.id !== undefined
      }
      if (!this.idCompany && this.workOrder.company) {
        this.getProjectWorksByCompany();
        this.updateIdCompanySelected();
        await this.companyCanProcessSelectedWorks();
        this.getWorksCompany(this.workOrder.company.companyId, workOrderRequestAttributes);
      }
      if (this.workOrder.company !== null && this.workOrder.company.companyId !== this.idCompany) {
        if (!this.workOrder.id && this.workOrder.listWorkOrderProjectWork) {
          await this.companyCanProcessSelectedWorks();

        }
        this.getProjectWorksByCompany();
        this.getWorksCompany(this.workOrder.company.companyId, workOrderRequestAttributes);
      }
      this.updateIdCompanySelected();

    }
  }

  getWorksCompany(id, workOrderRequestAttributes) {
    this.blService.getWorksCompany(id, workOrderRequestAttributes).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listWorksCompany = res.body;
          this.workOrder.listWorksCompany = this.listWorksCompany;
          this.workOrder.attachedToCompany = true;
        }
      }
    );
  }

  getProjectWorksByCompany() {
    this.workOrderService.projectWorksByCompany(this.workOrder.project.id, this.workOrder.company.companyId).subscribe(
      (res) => {
        if (res.status === 200) {
          this.workOrder.project.projectWorks = res.body;
        }
      });
  }

  checkProjetValidate() {
    this.errorProjectRequired = false;
    if (this.workOrder.hasProject && this.workOrder.project === null) {
      this.errorProjectRequired = true;
    }
    return this.errorProjectRequired;
  }

  getProjectSelected(id : number) {

    this.selectProjectCompany(id);
    this.getWorksByProject(id);


  }

  getWorksByProject(id : number) {
    this.projectService.getProjectById(id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.projectSelected = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.workOrder.project.projectWorks = this.projectSelected.projectWorks;
      }
    );
  }

  updateIdCompanySelected() {
    if (this.workOrder.company !== null) {
      this.idCompany = this.workOrder.company.companyId;
      this.workOrder.attachedToCompany = true;
    } else {
      this.idCompany = null;
      this.workOrder.attachedToCompany = false;
    }
  }


  handleSwitch(e) {
    if (!e.checked) {
      this.workOrder.project = null;
      this.workOrder.listWorkOrderProjectWork = [];
      this.selectProjectCompany(0);
    } else {
      this.listCompany = [];
    }
  }

  // AUTOCOMPLETION
  filterName(event) {
    // filter to get all persona where lastname = event.query and map to get their persona.lastname
    this.outputName = this.listProjectWorkOrder
      .filter((c) =>
        c.workOrder.name.toLowerCase().startsWith(event.query.toLowerCase())
      )
      .map((projectWorkOrder) => projectWorkOrder.workOrder.name);
  }

  nameFieldValidator() {
    if (!this.workOrder.name.trim()) {
      this.errorNameRequired = true;
    } else {
      this.errorNameRequired = false;
    }

    return this.errorNameRequired;
  }

  projectFieldValidator() {
    if (this.workOrder.hasProject && !this.workOrder.project) {
      this.errorProjectRequired = true;
    } else {
      this.errorProjectRequired = false;
    }

    return this.errorProjectRequired;
  }

  checkAllConditions() {
    this.projectFieldValidator();
    this.nameFieldValidator();

    return (this.errorProjectRequired || this.errorNameRequired)
  }


  clearCompany(event) {
    if (!this.workOrder.company) {
      this.workOrder.attachedToCompany = false;
      this.getWorksByProject(this.workOrder.project.id);
    }
  }

  companyCanProcessSelectedWorks() {
    this.canCompanyProcessWorks = false;
    let worksByCompany: any[] = [];
    this.workOrderService.projectWorksByCompany(this.workOrder.project.id, this.workOrder.company.companyId).subscribe(
      (res) => {
        if (res.status === 200) {
          worksByCompany = res.body;
          if (!worksByCompany || !this.compareWorks(worksByCompany, this.workOrder.listWorkOrderProjectWork)) {
            this.workOrder.listWorkOrderProjectWork = [];
          }

        }
      });
  }

  private compareWorks(worksByCompany: any[], listWorkOrderProjectWork: any[]) {
    let worksByCompanyIds = worksByCompany.map(a => a.work.idWork);
    let listWorkOrderProjectWorkIds = listWorkOrderProjectWork.map(a => a.projectWork.work.idWork);
    let equals = listWorkOrderProjectWorkIds.every(val => worksByCompanyIds.includes(val));
    return equals;
  }

  ngOnDestroy(): void {
    localStorage.removeItem('projectName');
    localStorage.removeItem('projectId');
    localStorage.removeItem('isRedirect');
  }

  naturalSortWork(event: any) {

    this.customSortService.naturalSort(event);

  }
}
