<ng-container *ngIf="editMode">
  <!--h4>Prestations sélectionnées</h4-->
  <br>
  <div class="card">
    <p-table
      [value]="workOrder.listWorkOrderProjectWork"
      styleClass="p-datatable-sm p-datatable-striped"
      #dt1
      class="table"
      (sortFunction)="naturalSortWork($event)"
      [customSort]="true"
      sortField="projectWork.work.name" [sortOrder]='1'
      [globalFilterFields]="[
        'projectWork.work.name',
        'projectWork.work.description',
        'projectWork.comment',
        'quantityTotal'
      ]"
    >

    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between p-m-3">
        <div>
          <span [style]="{'font-weight': 'bold','margin-left':'-20px'}">Prestations sélectionnées</span>
        </div>
        <div>
          <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-left':'-20px'}">
            <i class="pi pi-search" [style]="{'margin-left':'10%','height': '45px','margin-top':'5px'}"></i>
            <input
            [style]="{'margin-left':'10%','height': '45px'}"
              pInputText
              type="text"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Rechercher"
            />
         </span>
        </div>
      </div>
    </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="table-5 head"></th>
          <th class="table-20 head" pSortableColumn="projectWork.work.name">
            Prestations<p-sortIcon field="projectWork.work.name"></p-sortIcon>
          </th>
          <th class="table-25 head" pSortableColumn="projectWork.work.description">
            Description<p-sortIcon
              field="projectWork.work.description"
            ></p-sortIcon>
          </th>
          <th class="table-20 head" pSortableColumn="projectWork.comment">
            Commentaire<p-sortIcon field="projectWork.comment"></p-sortIcon>
          </th>
          <th class="table-15 p-text-right head" pSortableColumn="quantity">
            <span class="quantity"
              >Quantité<p-sortIcon field="quantity"></p-sortIcon
            ></span>
          </th>
          <th class="table-15 head"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-workOrderProjectWork>
        <tr>
          <td class="table-5"></td>
          <td class="table-20">
            {{ workOrderProjectWork.projectWork.work.name }}
          </td>
          <td class="table-30">
            {{ workOrderProjectWork.projectWork.work.description }}
          </td>
          <td class="table-20">
            {{ workOrderProjectWork.projectWork.comment }}
          </td>
          <td class="table-15 p-text-right quantity">
            <span class="quantity">{{
              workOrderProjectWork.quantityTotal | number: "1.0-0":"fr"
            }}</span>
          </td>
          <td class="table-15">
            <button
              *ngIf="editMode"
              pButton
              pRipple
              type="button"
              icon="pi pi-pencil"
              (click)="update(workOrderProjectWork)"
              class="p-button-rounded p-button-text"
              pTooltip="Modifier"
            ></button>
            <button
              *ngIf="editMode && workOrderProjectWork.quantityDone === 0"
              pButton
              pRipple
              type="button"
              icon="pi pi-trash"
              (click)="delete(workOrderProjectWork)"
              class="p-button-rounded p-button-text"
              pTooltip="Supprimer"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>

<ng-container *ngIf="!editMode">
  <div class="card">
    <p-table

      [value]="workOrder.listWorkOrderProjectWork"
      styleClass="p-datatable-striped"
      #dt1
      class="table"
      (sortFunction)="naturalSortWork($event)"
      [customSort]="true"
      sortField="projectWork.work.name"
      [sortOrder]='1'
      [globalFilterFields]="[

        'projectWork.work.name',
        'projectWork.comment',
        'quantityTotal',
        'quantityDone',
        'available'
      ]"
    >

    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between p-m-3">
        <div>
          <span [style]="{'font-weight': 'bold','margin-left':'-30px'}">Prestations sélectionnées</span>
        </div>
        <div>
          <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-left':'-20px'}">
            <i class="pi pi-search" [style]="{'margin-left':'12%','height': '45px','margin-top':'5px'}"></i>
            <input
            [style]="{'margin-left':'12%','height': '45px'}"
              pInputText
              type="text"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Rechercher"
            />
        </span>
        </div>
      </div>
    </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="head" pSortableColumn="projectWork.work.name">
            Prestations<p-sortIcon field="projectWork.work.name"></p-sortIcon>
          </th>
          <th class="head" pSortableColumn="projectWork.comment">
            Commentaire<p-sortIcon field="projectWork.comment"></p-sortIcon>
          </th>
          <th class="table-15 p-text-right head" pSortableColumn="quantityTotal">
            <span class="quantity">A réaliser<p-sortIcon field="quantityTotal"></p-sortIcon></span>
          </th>
          <th class="table-15 p-text-right head" pSortableColumn="quantityDone">
            <span class="quantity">Réalisées<p-sortIcon field="quantityDone"></p-sortIcon></span>
          </th>
          <th class="table-15 p-text-right head" pSortableColumn="available">
            <span class="quantity">Restantes<p-sortIcon field="available"></p-sortIcon></span>
          </th>
          <th class="head">Avancement</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-workOrderProjectWork>
        <tr>
          <td>
            {{ workOrderProjectWork.projectWork.work.name }}
          </td>
          <td>
            {{ workOrderProjectWork.projectWork.comment }}
          </td>
          <td class="p-text-right quantity">
            <span class="quantity">
              {{ workOrderProjectWork.quantityTotal }}
            </span>
          </td>
          <td class="p-text-right quantity">
            <span class="quantity">{{
              workOrderProjectWork.quantityDone
            }}</span>
          </td>
          <td class="p-text-right quantity">
            <span class="quantity">{{
              workOrderProjectWork.quantityTotal -
                workOrderProjectWork.quantityDone
            }}</span>
          </td>
          <!-- <td class="p-text-right quantity">
            <span class="quantity">Incoming</span>
          </td> -->
          <td>
            <app-progress-bar
              [progress]="workOrderProjectWork.quantityDone"
              [total]="workOrderProjectWork.quantityTotal"
              color="blue"
            >
            </app-progress-bar>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>

<p-dialog
  header="{{ editedWorkOrderProjectWork.projectWork?.work?.name }}"
  [(visible)]="displayModal"
  [modal]="true"
  [draggable]="false"
  styleClass="main-page"
  [style]="{ width: '60%' }"
  (onShow)="setFocus()"
>
  <div class="dialog-content p-grid p-jc-evenly">
    <div class="p-col-6">
      <div class="p-mb-2 head-title">Description</div>
      <div *ngIf="editedWorkOrderProjectWork.projectWork?.work?.description">
        {{ editedWorkOrderProjectWork.projectWork?.work?.description }}
      </div>
      <div *ngIf="!editedWorkOrderProjectWork.projectWork?.work?.description">
        Pas de description...
      </div>
    </div>
    <div class="p-col-6">
      <div class="p-mb-2 head-title">Commentaire</div>
      <div *ngIf="editedWorkOrderProjectWork.projectWork?.comment">
        {{ editedWorkOrderProjectWork.projectWork?.comment }}
      </div>
      <div *ngIf="!editedWorkOrderProjectWork.projectWork?.comment">
        Pas de commentaire...
      </div>
    </div>
    <div class="p-col-12 p-lg-2">
      <div class="p-text-right head-title p-mb-3">Quantité totale du projet</div>
      <!-- <div class="p-text-right">{{ calculateQuantityFree(addedProjectWork?.projectWork)  }} / {{ addedProjectWork.projectWork?.quantityTotal }}</div> -->
      <div class="p-text-right">
        {{ editedWorkOrderProjectWork.projectWork?.quantityTotal }}
      </div>
    </div>
    <div class="p-col-12 p-lg-2 p-offset-1">
      <div class="p-text-right head-title p-mb-3">Quantité disponible du projet</div>
      <!-- <div class="p-text-right">{{ calculateQuantityFree(addedProjectWork?.projectWork)  }} / {{ addedProjectWork.projectWork?.quantityTotal }}</div> -->
      <div class="p-text-right">
        {{ editedWorkOrderProjectWork.projectWork?.quantityFree }}
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="head-title p-text-center">Quantité pour l'OT *</div>
      <div class="p-text-center">
        <p-inputNumber
          [(ngModel)]="editedWorkOrderProjectWork.quantityTotal"
          mode="decimal"
          [showButtons]="true"
          buttonLayout="horizontal"
          spinnerMode="horizontal"
          decrementButtonClass="p-button-primary"
          incrementButtonClass="p-button-primary"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          size="4"
          min="1"
          #input
        >
        </p-inputNumber>
        <p-message
        *ngIf="editedWorkOrderProjectWork.quantityTotal > maxQuantityFreePossible"
        severity="error"
        text="Quantité maximum autorisée : {{ maxQuantityFreePossible }} "
      ></p-message>
      <p-message
        *ngIf="editedWorkOrderProjectWork.quantityTotal < editedWorkOrderProjectWork.quantityDone"
        severity="error"
        text="Quantité minimum autorisée : {{ editedWorkOrderProjectWork.quantityDone }} "
      ></p-message>
      </div>
    </div>
  </div>
  <div class="dialog-content p-grid p-jc-evenly">
    <div class="p-col-12 p-lg-2">
      <div class="p-text-right head-title p-mb-3">
        Quantité totale de l'OT
      </div>
      <div class="p-text-right">
        {{ totalWorkOrder }}
      </div>
    </div>
    <div class="p-col-12 p-lg-2 p-offset-1">
      <div class="p-text-right head-title p-mb-3">
        Quantité réalisée de l'OT
      </div>
      <div class="p-text-right">
          {{ editedWorkOrderProjectWork.quantityDone }}
      </div>
    </div>
    <div class="p-col-12 p-lg-4">

    </div>
  </div>

  <div class="p-text-right p-mt-5">
    <p-button
      styleClass="p-button-outlined p-mr-2"
      label="Annuler"
      (click)="annuler()"
    ></p-button>

    <p-button
      styleClass="p-mr-2"
      label="Valider"
      (click)="
      saveUpdate()
      "
    ></p-button>
  </div>
</p-dialog>
