import { Directive, HostListener, ElementRef, Input ,Output, EventEmitter} from '@angular/core';


@Directive({
    selector: '[hover-class]',
    outputs: ['isOnHover']
   
  })

export class MouseHoverDirective {

@Input('hover-class') hoverClass:any;  

isOnHover: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  constructor(public elementRef:ElementRef) { }

  @HostListener('mouseenter') onMouseEnter() {
    this.elementRef.nativeElement.classList.add(this.hoverClass);
    this.isOnHover.emit(true);
 }

  @HostListener('mouseleave') onMouseLeave() {
    this.elementRef.nativeElement.classList.remove(this.hoverClass);
    this.isOnHover.emit(false);
  }

}

