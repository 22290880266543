import {Component, OnInit, ViewChild} from '@angular/core';
import { Skill } from '@data/schema/Skill';
import { Persona, PersonaSkills } from '@data/schema/Persona';
import { PersonaService } from '@data/service/persona.service';
import { SkillService } from '@data/service/skill.service';
import { MessageService, PrimeNGConfig, SortEvent } from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import { SkillsOfPersonaDetailPersonaComponent } from '../skills-of-personas/detail-persona.component';
import { Technician } from '@data/schema/Technician';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';
import {Checkbox} from "primeng/checkbox";
import { SearchItemService } from '@data/service/search-item.service';
import {DataService} from '@shared/service/data.service';
import {CustomSortService} from "@shared/service/custom-sort.service";
import { OrganizationService } from '@data/service/organization.service';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { PermissionsList } from '@shared/enums/permissionsList';

@Component({
  selector: 'app-personas-of-skill',
  templateUrl: './personas-of-skill.component.html',
  styleUrls: ['./personas-of-skill.component.css'],
  providers: [DialogService]
})
export class PersonasOfSkillComponent implements OnInit {

  @ViewChild('p1')p1 : Checkbox;
  @ViewChild('dt1') dt1: Table;
  @ViewChild('dt2') dt2: Table;
  @ViewChild('dt3') dt3: Table;
  @ViewChild('dt4') dt4: Table;
  searchedSkill: string;
  listSkill : Skill[];
  selectedListSkill : Skill[];
  test: any[];
  listHabilitation: Skill[] = [];
  listSavoirFaire: Skill[] = [];
  listSavoirEtre: Skill[] = [];
  typeOfTag: string = "skill";
  listAllPersonas : Persona[];
  listPersonasToDisplay : Persona[] = [];
  listAllIntervenant : Technician[];
  displayTechnician : boolean = false;
  selectedSkills : Skill[] = [];
  virtual : any;
  currentDate : Date = new Date();
  userPools: number[] = [];

  constructor(
    private messageService: MessageService,
    private personaService: PersonaService,
    private skillService: SkillService,
    private dialogService: DialogService,
    private config: PrimeNGConfig,
    private searchItemService: SearchItemService,
    private organizationService: OrganizationService,
    private localStorageService : LocalStorageService,
    private customSortService: CustomSortService
  ) { }
  filterArrayByTag(newArrayFiltered: any) {
    this.listSkill = newArrayFiltered;
    this.listHabilitation = [];
    this.listSavoirEtre = [];
    this.listSavoirFaire = [];
    for (const skill of this.listSkill){
      if (skill.type === "HABILITATION"){
        this.listHabilitation.push(skill)
      }
      if (skill.type === "SAVOIR_ETRE"){
        this.listSavoirEtre.push(skill)
      }
      if (skill.type === "SAVOIR_FAIRE"){
        this.listSavoirFaire.push(skill)
      }
    }
  }
  ngOnInit(): void {

    this.personaService.getListTechnician().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listAllIntervenant = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {

      }
    );

    this.skillService.getListSkill().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listSkill = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        if(this.listSkill){
        for (const skill of this.listSkill){
          if (skill.type === "HABILITATION"){
            this.listHabilitation.push(skill)
          }
          if (skill.type === "SAVOIR_ETRE"){
            this.listSavoirEtre.push(skill)
          }
          if (skill.type === "SAVOIR_FAIRE"){
            this.listSavoirFaire.push(skill)
          }
        }
      }
      }
    );
    this.getPersonasByPools();

    //this.translateService.setDefaultLang('fr');
    this.config.setTranslation({
      startsWith: "Commence par",
      addRule: "Ajouter une règle",
      matchAll: "Tous les filtres correspondent",
      matchAny: "Au moins un filtre correspond",
      contains: "Contient",
      notContains: "Ne contient pas",
      endsWith: "Termine par",
      equals: "Equivalent",
      notEquals: "Pas équivalent",
      noFilter: "Pas de filtre",
      lt: "Plus petit que",
      lte: "Plus petit ou égal",
      gt: "Plus grand",
      gte: "Plus grand ou égal",
      is: "Est",
      isNot: "N'est pas",
      before: "Avant",
      after: "Après",
      clear: "Effacer",
      apply: "Appliquer",
      removeRule: "Enlever la règle",
    });

    this.virtual = new Array(10)

  }
  private getPersonaList() {
    this.personaService.getPersonaWithSkills(this.userPools.toString()).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listAllPersonas = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

  getPersonasByPools() {
    let hasPermissionToViewAll = this.localStorageService.hasPermission(PermissionsList.Kober_viewAll);
    if(hasPermissionToViewAll){
      this.getPersonaList();
    }else{
      this.getUserPools();
    }
  }

  selectSkill(event: any, skill: Skill){

    if (event.checked){
      let index = this.selectedSkills.indexOf(skill);
      // if checked => function ajout
      // si tableau vide et premier skill selectionne
      if (this.listPersonasToDisplay.length === 0 && this.selectedSkills.length <= 1){
        this.addPersonasWithSelectedSkill(skill);
      }
      // si tableau pas vide et skill selectionne
      else if (this.listPersonasToDisplay.length > 0){
        this.filterPersonasWithSelectedSkill(skill);
      }
      // si tableau de persona vide, pas besoin de traitement
    }
    else{
      // if unchecked => function retirer
      this.removePersonaWithSelectedSkill();

    }
  }

  // function ajout
  // boucles + check doublon
  addPersonasWithSelectedSkill(skill: Skill){
    for (const persona of this.listAllPersonas){
      if (this.isIntervenant(persona) && this.displayTechnician || !this.displayTechnician){
        if (this.hasSkill(persona, skill)){
          if (!this.isPresent(persona)){
            this.listPersonasToDisplay.push(persona)
          }
        }
      }
    }
  }

  // filtre les persona affichés qui ont ce skill
  filterPersonasWithSelectedSkill(skill: Skill){
    this.listPersonasToDisplay = this.listPersonasToDisplay.filter(
      (persona) => {
        return (
          (
            (this.isIntervenant(persona) && this.displayTechnician) || !this.displayTechnician)
            && this.hasSkill(persona, skill))
      })
  }

  hasSkill(persona: Persona, skill: Skill){
    for (const personaSkill of persona.listSkill){
      if (personaSkill.idSkill === skill.id && this.checkValidDate(personaSkill)){
        return true
      }
    }
    return false
  }

  // function retirer
  // regenerer tab
  removePersonaWithSelectedSkill(){
    var tmp = []
    this.alphaNumericSort(this.selectedSkills)
    if (this.selectedSkills.length > 0){
      this.alphaNumericSort(this.selectedSkills)
      for (const persona of this.listAllPersonas){
        if ((this.isIntervenant(persona) && this.displayTechnician) || !this.displayTechnician){
          if (this.hasOtherSelectedSkills(persona)){
            tmp.push(persona)
          }
        }
      }
    }

    this.listPersonasToDisplay = tmp
  }

  /// check doublon
  isPresent(persona: Persona){
    for (const displayedPersona of this.listPersonasToDisplay){
      if (displayedPersona.id === persona.id){
        return true;
      }
    }
    return false;
  }

  // check si le persona a les autres compétences restantes
  // renvoit false si le persona n'a pas les autres compétences
  // renvoit true si le persona a les autres compétences check
  hasOtherSelectedSkills(persona : Persona){

    for (const selectedSkill of this.selectedSkills){
      if (!this.hasSkill(persona, selectedSkill)){
        return false
      }
    }

    return true;
  }

  checkValidDate(personaSkill: PersonaSkills){
    if (personaSkill.skill.type === 'HABILITATION' && personaSkill.skill.validity && new Date(personaSkill.endDate).getTime() < this.currentDate.getTime()){
      return false;
    }
    return true;
  }

  show(persona:Persona) {
    const ref = this.dialogService.open(SkillsOfPersonaDetailPersonaComponent, {
        data: {
          persona: persona
        },
        header: 'Persona et compétences associées',
        width: '60%',
        styleClass: 'main-page',
        baseZIndex: 20000,
    });
  }

  selectTechnician(event : any){
    if (this.displayTechnician){
      this.listPersonasToDisplay = this.listPersonasToDisplay.filter((persona) =>{
        return (this.isIntervenant(persona))
      })
    }
    else{
      this.removePersonaWithSelectedSkill()
    }

  }

  isIntervenant(persona: Persona){
    for (const intervenant of this.listAllIntervenant){
      if (intervenant.idPersona === persona.id){
        return true
      }
    }
    return false
  }

  removeSkill(event: any, skill: Skill) {
    this.selectedSkills = this.selectedSkills.filter((item) => item !== skill);
    this.alphaNumericSort(this.selectedSkills)
    this.removePersonaWithSelectedSkill()
  }

  clearSkills(){
    this.selectedSkills = []
    this.listPersonasToDisplay = []
  }

  clear(table: Table) {
    table.clear();
  }

 filterTableInputChangeTabSkill(event, str, dt1,dt2,dt3,dt4) {
    this.searchedSkill = event;
    this.searchItemService.filterTableInputChange(this.searchedSkill, str, dt1,dt2,dt3,dt4);
  }

  handleChangeTabSkill(event,str, dt1,dt2,dt3,dt4){
    this.searchItemService.handleChange(event,this.searchedSkill, dt1,dt2,dt3,dt4);
  }

  naturalSort(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

  alphaNumericSort = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.name.match(/\d+|\D+/g);
    const bPart = b.name.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };

  getUserPools(){
    let partyId =  JSON.parse(sessionStorage.getItem("persona")).idParty
    this.organizationService.getUserPools(partyId).subscribe(
     (res) =>{
      let pools = res;
      if(pools && pools.length > 0){
        this.userPools = pools.map(pool => pool.id);
        this.getPersonaList();
      }else{
        this.userPools = [0];
      }
     }
   )
   }
}
