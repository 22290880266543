import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Project } from '@data/schema/Project';
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root',
})
export class ProjectService {

projectInformation = {
  project: new Project(),
};

  private apiURL = this.environment?.readConfig()?.urlApiGateway + 'bl/';

  constructor(private http: HttpClient, private environment: EnvService) {}

  public eraseProjectInformation() {
    this.projectInformation.project = new Project();
  }


  public getAllProjects(): Observable<any> {
    return this.http
      .get(this.apiURL + 'getAllProjects', { observe: 'response' as 'body' })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getAllProjectsPw(): Observable<any> {
    return this.http
      .get(this.apiURL + 'getAllProjectsPw', { observe: 'response' as 'body' })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  public getProjectById(id: number): Observable<any> {
    return this.http
      .get(this.apiURL + `getProjectById/` + id, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // Create project
  public createProject(project: Project): Observable<any> {
    return this.http.post(this.apiURL + `createProject`, project, {
      observe: 'response' as 'body',
    });
  }

  // UPDATE A PROJECT
  public updateProject(project: Project): Observable<any> {
    return this.http.put(this.apiURL + `updateProject`, project, {
      observe: 'response' as 'body',
    });
  }

  // DELETE A project
  public deleteProject(id: number): Observable<any> {
    return this.http.delete(this.apiURL + `deleteProject/` + id, {
      observe: 'response' as 'body',
    });
  }

  public isStarted(project: Project) {
    console.log(project);
    for (const projectWorkOrder of project.projectWorkOrders) {
      console.log(projectWorkOrder.workOrder.listWorkOrderProjectWork);
      for (const workOrderProjectWork of projectWorkOrder.workOrder
        .listWorkOrderProjectWork) {
        if (workOrderProjectWork.quantityDone > 0) {
          return true;
        }
      }
    }

    return false;
  }

  public getAllWorkOrdersAndCompanyByIdProject(id: number): Observable<any> {
    return this.http
      .get(this.apiURL + `getAllWorkOrdersAndCompanyByIdProject/` + id, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
