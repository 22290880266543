import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {CompanyService} from "@data/service/company.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {Company} from "@data/schema/Company";
import {Contracts} from "@data/schema/Contracts";
import {ContractService} from "@data/service/contract.service";
import {CurrencyService} from "@data/service/currency.service";
import {ContractCustomResponse} from "@data/schema/ContractCustomResponse";

@Component({
  selector: 'app-contract-form-company',
  templateUrl: './contract-form-company.component.html',
  styleUrls: ['./contract-form-company.component.css']
})
export class ContractFormCompanyComponent implements OnInit, OnChanges {
  cities: any [];
  selectedCity: string;
  @Input() formC: FormGroup;
  @Input() mode: String;
  @Input() contracts: Contracts;
  @Input() isSubmit: boolean;
  listOfCompany: Company[];
  company: Company;
  contractCustomResponse: ContractCustomResponse;
  invalidDatesOnCompanyChanged: boolean = false;
  @Output() invalidDatesOnChangeCompanyEvent = new EventEmitter();

  constructor(private messageService: MessageService,
              private companyService: CompanyService,
              private contractService: ContractService,
              private currencyService: CurrencyService,
              private confirmationService: ConfirmationService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
  }

   alphaNumericSort = (arr = []) => {
      const sorter = (a:any, b:any) => {
      const isNumber = (v:any) => (+v).toString() === v;
      const aPart = a.name.match(/\d+|\D+/g);
      const bPart = b.name.match(/\d+|\D+/g);
      let i = 0; let len = Math.min(aPart.length, bPart.length);
      while (i < len && aPart[i] === bPart[i]) { i++; };
        if (i === len) {
            return aPart.length - bPart.length;
      };
      if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
      };
      return aPart[i].localeCompare(bPart[i]); };
      arr.sort(sorter);


  };

  ngOnInit(): void {

    if (this.mode !== "update" && this.mode !== "consultation") {

      this.companyService.getListCompanyActive().subscribe((res) => {
          if (res.status === 200) {
            this.listOfCompany = res.body;
            if(this.listOfCompany){

              this.alphaNumericSort(this.listOfCompany);
            }

          }
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur',
              detail: 'Échec de l’opération : veuillez réessayer',
            });
          }
        },
        () => {
        }
      );

    }
  }
  invalidDatesOnChangeCompany(invalidDatesOnCompanyChanged) {
    this.invalidDatesOnChangeCompanyEvent.emit(invalidDatesOnCompanyChanged);
  }
   isContractDataValidForSave(event) {
    console.log(event);
    if (this.formC.controls.effectiveDate.value === null || this.formC.controls.expiryDate.value === null) {
      return;
    }

     this.contractService.isContractDataValidByDateForSave(this.formC).subscribe(
      res => {
        if (res.status === 200) {
          console.log(res);
          this.contractCustomResponse = res.body;
          console.log(this.contractCustomResponse);
           this.getMessageError(this.contractCustomResponse);

        }

      }, error => {
      }
    );
  }

  getMessageError(contractCustomResponse: ContractCustomResponse) {

    if (contractCustomResponse.code === 605) {
      this.invalidDatesOnCompanyChanged = true;
      this.confirmationService.confirm({
        header: 'Action impossible',
        message:
          'Un autre contrat contenant une ou plusieurs des prestations sélectionnées sera en vigueur aux mêmes dates : ' + contractCustomResponse.contract.contractName.link('phoenix/show-contract/'
          + contractCustomResponse.contract.contractId) + '.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
      });
      this.invalidDatesOnChangeCompany(this.invalidDatesOnCompanyChanged);
      //this.addValidatorCompany();
    } else if (contractCustomResponse.code === 606) {
      this.invalidDatesOnCompanyChanged = true;
      this.confirmationService.confirm({
        header: 'Action impossible',
        message:
          'Un contrat en vigueur aux mêmes dates contient déjà une ou plusieurs des prestations sélectionnées : ' + contractCustomResponse.contract.contractName.link('phoenix/show-contract/'
          + contractCustomResponse.contract.contractId) + '.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
      });
      this.invalidDatesOnChangeCompany(this.invalidDatesOnCompanyChanged);
    } else if(contractCustomResponse.code === 200){
      this.invalidDatesOnCompanyChanged = false;
      this.invalidDatesOnChangeCompany(this.invalidDatesOnCompanyChanged);
    }
  }
}
