import { Component, OnInit } from '@angular/core';
import { Skill } from '@data/schema/Skill';
import { Persona, PersonaSkills } from '@data/schema/Persona';
import { PersonaService } from '@data/service/persona.service';
import { SkillService } from '@data/service/skill.service';
import { MessageService,ConfirmationService, PrimeNGConfig, SortEvent } from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import { Technician } from '@data/schema/Technician';
import { Router } from '@angular/router';
import { SkillsOfPersonaDetailPersonaComponent } from '../skills-of-personas/detail-persona.component';
import { AssignSkillsToPersonasDetailComponent} from './assign-skills-to-personas-detail.component';
import { PersonasOfSkillDetailComponent} from './personas-of-skill-detail.component';
import {DataService} from '@shared/service/data.service';
import {CustomSortService} from "@shared/service/custom-sort.service";
import { OrganizationService } from '@data/service/organization.service';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { PermissionsList } from '@shared/enums/permissionsList';



@Component({
  selector: 'app-assign-skills-to-personas',
  templateUrl: './assign-skills-to-personas.component.html',
  styleUrls: ['./assign-skills-to-personas.component.css'],
  providers: [DialogService]
})
export class AssignSkillsToPersonasComponent implements OnInit {

  // LISTE DES SKILLS A AFFICHE
  listHabilitation:Skill[] = [];
  listSavoirFaire: Skill[] = [];
  listSavoirEtre: Skill[] = [];
  listSkill: Skill[] = [];
  listSkillWithCount: Skill[] = [];
  typeOfTag: string = "skill";

  // LISTE PERSONA A AFFICHER
  listPersonasWithCountSkills: Persona[] = [];
  tmp: Persona[] = [];

  // VARIABLE STOCK SELECTION LIGNE TABLEAU
  personaSelected: Persona;
  skillSelected: Skill;

  //
  selectedSkillToAssign: Skill[] = [];
  selectedPersona: Persona[] = [];


  id: number;

  showByType: string = "ALL";
  hab: boolean = false;
  sf: boolean = false;
  se: boolean = false;

  listAllIntervenant : Technician[];

  displayTechnician : boolean = false;
  virtual : any;
  currentDate : Date = new Date();

  userPools: number[] = [];

  arrayFiltered : any;
  constructor(
    private messageService: MessageService,
    public personaService: PersonaService,
    public skillService: SkillService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private organizationService: OrganizationService,
    private localStorageService : LocalStorageService,
    private customSortService: CustomSortService

  ) { }

  filterArrayByTag(newArrayFiltered: any) {
    if(this.selectedSkillToAssign.length > 0) {
      this.selectedSkillToAssign = [];
    }
    this.arrayFiltered = newArrayFiltered;
    if (this.showByType === 'HABILITATION') {
      this.listSkillWithCount = this.arrayFiltered.filter(x => x.type === 'HABILITATION');
    }
    else if (this.showByType === 'SAVOIR_FAIRE') {
      this.listSkillWithCount = this.arrayFiltered.filter(x => x.type === 'SAVOIR_FAIRE');
    }
    else if (this.showByType === 'SAVOIR_ETRE') {
      this.listSkillWithCount = this.arrayFiltered.filter(x => x.type === 'SAVOIR_ETRE');
    } else {
      this.listSkillWithCount = this.arrayFiltered;
    }

  }

  ngOnInit(): void {

    this.getPersonasSkillsByPools();

    


    this.personaService.getListTechnician().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listAllIntervenant = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {

      }
    );


  }

  private getSkillsWithCount() {
    this.personaService.getSkillWithCount(this.userPools.toString()).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listSkillWithCount = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {

        this.listSkill = this.listSkillWithCount;
        if (this.listSkillWithCount) {
          for (const skill of this.listSkillWithCount) {
            if (skill.type === "HABILITATION") {
              this.listHabilitation.push(skill);
            }
            if (skill.type === "SAVOIR_FAIRE") {
              this.listSavoirFaire.push(skill);
            }
            if (skill.type === "SAVOIR_ETRE") {
              this.listSavoirEtre.push(skill);
            }
          }
        }
      }
    );
  }

  private countPersonaBySkill() {
    this.personaService.countSkillsByPersona(this.userPools.toString()).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listPersonasWithCountSkills = res.body;
          this.tmp = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

  getPersonasSkillsByPools() {
    let hasPermissionToViewAll = this.localStorageService.hasPermission(PermissionsList.Kober_viewAll);
    if(hasPermissionToViewAll){
      this.countPersonaBySkill();
      this.getSkillsWithCount();
    }else{
      this.getUserPools();
    }
  }

  showPersona(persona:Persona) {
    const ref = this.dialogService.open(SkillsOfPersonaDetailPersonaComponent, {
      data: {
        persona: persona
      },
      header: 'Persona et compétences associées',
      width: '80%',
      styleClass: 'main-page',
      baseZIndex: 20000,
    });
  }

  showSkill(skill: Skill) {
    const ref = this.dialogService.open(PersonasOfSkillDetailComponent, {
      data: {
        selectedSkill: skill
      },
      header: 'Compétences et Personas associés',
      width: '60%',
      styleClass: 'main-page',
      baseZIndex: 20000,
    });
  }

  onRowSelectSkill(event:any){

    this.personaSelected = null;
  }


  onRowSelectPersona(event:any){

    this.skillSelected = null;

  }

  showListSkillbyType(event: any, type: string){
    if (event.checked){
      this.showByType = type
      if (type === "HABILITATION"){
        this.listSkillWithCount = this.arrayFiltered.filter(x => x.type === 'HABILITATION');
        this.se = false
        this.sf = false
      }
      if (type === "SAVOIR_ETRE"){
        this.listSkillWithCount = this.arrayFiltered.filter(x => x.type === 'SAVOIR_ETRE');
        this.hab = false
        this.sf = false
      }
      if (type === "SAVOIR_FAIRE"){
        this.listSkillWithCount = this.arrayFiltered.filter(x => x.type === 'SAVOIR_FAIRE');
        this.hab = false
        this.se = false
      }
    }
    else{
      this.showByType = "ALL";
      this.listSkillWithCount = this.arrayFiltered;
    }
  }

  drop(event:any,persona: Persona){

    let filteredSkill = []; // SKILL TYPE
    let selectedSkill = []; // PERSONA SKILL TYPE

    for(const skill of this.selectedSkillToAssign) {

      // CHECK DOUBLON
      if (this.hasSkill(persona, skill)){
        filteredSkill.push(skill)
      }
      else{
        // INITIALIZE PERSONA SKILL
        let now = new Date(Date.now());
        let personaSkill = {} as PersonaSkills;
        personaSkill.id = null;
        personaSkill.idPersona = persona.id;
        personaSkill.idSkill = skill.id;
        personaSkill.startDate = now;
        personaSkill.endDate = this.calculateEndDate(
          now,
          skill.day_duration,
          skill.month_duration,
          skill.year_duration
        ),
          personaSkill.skill = skill
        selectedSkill.push(personaSkill)
      }
    }

    if (selectedSkill.length > 0){
      this.assignSkilltoModal(persona, selectedSkill, filteredSkill);
    }
    else{
      this.assignRejectDialog();
    }

  }

  assignSkilltoModal(persona: Persona, selectedSkill: PersonaSkills[], filteredSkill: Skill[]){
    const ref = this.dialogService.open(AssignSkillsToPersonasDetailComponent, {
      data: {
        persona : persona,
        selectedSkill : selectedSkill,
        filteredSkill : filteredSkill
      },
      header: 'Configurer les dates de début et de fin de validité',
      width: '60%',
      styleClass: 'main-page',
      baseZIndex: 20000,
    });

    // on close of the dialog, do this
    ref.onClose.subscribe((assignedSkills: PersonaSkills[]) => {
      this.redirectTo('/phoenix/assign-skills-to-personas');
    });
  }

  assignRejectDialog(){
    this.confirmationService.confirm({
      header: 'Erreur',
      message:
        'Le persona dispose déjà des compétences sélectionnées.',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'null',
      acceptLabel: "J'ai compris",
      rejectVisible: false,
    });
  }

  selectTechnician(event : any){

    if (this.displayTechnician){
      this.listPersonasWithCountSkills = this.listPersonasWithCountSkills.filter((persona) =>{
        return (this.isIntervenant(persona))
      })

    }
    else {
      this.listPersonasWithCountSkills = this.tmp
    }


  }

  // Calculate end date based on parameters values
  calculateEndDate(date, days, months, years) {
    var d = new Date(date);

    d.setDate(d.getDate() + days);
    d.setFullYear(d.getFullYear() + years);
    d.setMonth(d.getMonth() + +months);

    return d;
  }

  checkValidDate(personaSkill: PersonaSkills){
    if (personaSkill.skill.type === 'HABILITATION' && personaSkill.skill.validity && new Date(personaSkill.endDate).getTime() < this.currentDate.getTime()){
      return false;
    }
    return true;
  }


  isIntervenant(persona: Persona){
    for (const intervenant of this.listAllIntervenant){
      if (intervenant.idPersona === persona.id){
        return true
      }
    }
    return false
  }

  hasSelectedSkill(persona : Persona){

    if (this.skillSelected){
      for (const skill of persona.listSkill){

        if (skill.idSkill === this.skillSelected.id){
          return true
        }
      }
    }

    return false
  }

  selectedPersonaHasSkill(skill : Skill){

    if (this.personaSelected){
      for (const listSkill of this.personaSelected.listSkill){
        if (listSkill.idSkill === skill.id && this.checkValidDate(listSkill)){
          return true
        }
      }
    }

    return false
  }

  hasSkill(persona: Persona, skill: Skill){
    for (const personaSkill of persona.listSkill){
      if (personaSkill.idSkill === skill.id){
        return true
      }
    }
    return false
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([uri]));
  }

  clear(){
    this.selectedSkillToAssign = []

  }

  naturalSort(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

  getUserPools(){
    let partyId =  JSON.parse(sessionStorage.getItem("persona")).idParty
    this.organizationService.getUserPools(partyId).subscribe(
     (res) =>{
      let pools = res;
      if(pools && pools.length > 0){
        this.userPools = pools.map(pool => pool.id);
        this.countPersonaBySkill();
      this.getSkillsWithCount();
      }else{
        this.userPools = [0];
      }
     }
   )
   }

}
