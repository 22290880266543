<div class="p-col-12">
 
    <div class="p-grid p-col-12">
      <div class="p-col-12">
        <h3 class="title">{{ workOrder.name }}</h3>
        <span *ngIf="workOrder.status === 'TODO'" class=" status-todo">
          A faire
        </span>

        <span *ngIf="workOrder.status === 'DOING'" class="status-doing">
          En cours
        </span>

        <span *ngIf="workOrder.status === 'DONE'" class="status-done">
        Terminé
        </span>
      </div>

    </div>

    <div class="p-col-12 reference">
      {{ workOrder.created | date: "dd MMMM YYYY" }}
    </div>
    <div class="p-col-12 description">
      {{ workOrder.description }}
    </div>
    <div class="p-grid">
      <div class="p-col-4">
        <div *ngIf="workOrder.project?.name" class="p-field p-col project">
          {{ workOrder.project?.name }}
        </div>
      </div>

      <div class="p-col-4">
        <div *ngIf="workOrder.attachedToCompany" class="p-field p-col company">
          {{ workOrder.company?.name }}
        </div>
      </div>

    </div>

</div>
