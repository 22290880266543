import {Component, OnInit, ViewChild} from '@angular/core';
import {Tag} from "@data/schema/Tag";
import {ActivatedRoute, Router} from "@angular/router";
import {TagService} from "@data/service/tag.service";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {DetailTagComponent} from "../../components/tag/detail-tag/detail-tag.component";

@Component({
  selector: 'app-show-tag',
  templateUrl: './show-tag.component.html',
  styleUrls: ['./show-tag.component.css']
})
export class ShowTagComponent implements OnInit {

  setting: string = "Modification de tag";
  editMode: boolean = false;
  tag: Tag = new Tag();
  attributTagValidator: boolean = false;
  tags: Tag[] = [];
  nameTagEdit : string;
  tagIsUsed: boolean = false;
  validatorName: boolean = false;

  sub: any;
  id: number;

  @ViewChild(DetailTagComponent)
  private detailTagComponent!: DetailTagComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tagService: TagService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) {
    this.route.queryParams.subscribe((params) => {
      if (params.editMode) {
        this.editMode = true;
      }
    });
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
    this.getTagAll();
    this.getTagById();
    this.tagUsed();
  }

  getTagById(){
    this.tagService.getTagById(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.tag = res.body;
          this.nameTagEdit = this.tag.label;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      });
  }

  changeAttributTag(tag:Tag){
    if(tag.label.trim()==="" && tag.description.trim()===""){
      this.attributTagValidator= true;
    }
  }

  validatorNameTag(errorNameTag:boolean){
    if(errorNameTag){
      this.validatorName = true;
    }else{
      this.validatorName = false;
    }
  }

  back() {
    if(this.attributTagValidator){
      this.gotoList();
    }else{
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez quitter la page, les données seront perdues.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        accept: () => {
          this.gotoList();
        },
      });
    }

  }

  gotoList() {
    this.redirectTo('/phoenix/tag');
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  submit(){
    let validForm = true;
    // test name tag not empty
    if (this.detailTagComponent.nameTagFieldValidator()) {
      validForm = false;
    }
    if (!validForm) {
      this.messageService.add({
        severity: 'error',
        summary: 'Échec de l’opération : veuillez remplir tous les champs',
      });
    } else {
      this.updateTagConfirmation();
    }
  }


  updateTagConfirmation() {
    //if tag used
    if(this.tagIsUsed){
      this.confirmationService.confirm({
        message: 'Les modifications seront appliquées à tous les éléments qui contiennent ce tag.' + '<br>' +
          'Voulez vous continuer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
        acceptButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.updateTag();
        },
        reject: (type) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              break;
            case ConfirmEventType.CANCEL:
              break;
          }
        }
      });
    }else{
      this.updateTag();
    }
  }

  updateTag(){
    this.tagService.updateTag(this.id,this.tag).subscribe(
      (res) => {
        if (res.status === 200) {
        }
      },
      (error) => {
        if (error) {
          if (error.status === 500) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
          if (error.status === 409) {
            this.messageService.add({
              severity: 'error',
              summary: 'Cet élément existe déjà',
            });
          }
        }
      },
      () => {
        this.editMode = false;
        this.redirectTo('/phoenix/tag');
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });
      }
    );
  }

  tagUsed(){
    this.tagService.tagUsed(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.tagIsUsed = res.body;
        }
      }
    )
  }

  getTagAll(){
    this.tagService.getAllTags().subscribe(
      (res) => {
        if (res.status === 200) {
          this.tags = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

}
