<p-sidebar
 [transitionOptions]="'0ms'"
  *ngIf="!reducedMenu"
  styleClass="left-menu-bar menu-bar-extended p-d-none p-d-lg-inline"
  [visible]="true"
  [blockScroll]="false"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="left"
  baseZIndex="2"
>
  <div>
    <div class="p-grid p-nogutter">
      <div class="p-col-10 p-d-flex p-jc-center logo-block">
            <img routerLink="/phoenix/home" src="assets/SVG/menu/Accueil.svg" alt="My Custom Icon" style="width: 48px; height: 48px; margin-left: 40px;">
            <!-- <img style="margin-left: 3%;" src="assets/icons/chevron-right.svg" alt="My arrow Icon"> -->
        <label *ngIf="module === 'kober-material'" style="color: #A0A0A0; margin-left: 15%; margin-top: 10px; font-size: 18px; padding-top: 2%;">{{ "univers_LOGISTIQUE" | translate }}</label>
        <label  *ngIf="module === 'kober-skill'" style="color: #A0A0A0;  margin-left: 15%; margin-top: 10px; font-size: 18px; padding-top: 2%;">{{ "univers_COMPETENCES" | translate }}</label>
        <label *ngIf="module === 'kober-services'" style="color: #A0A0A0; margin-left: 15%; font-size: 18px; margin-top: 10px; padding-top: 2%;">{{ "univers_INFRASTRUCTURES" | translate }}</label>


      </div>
      <div *ngIf="persona?.idKeycloak" class="p-col-12 user-info">
        <a routerLink="/../phoenix/profile/{{ origin }}">
          <div class="p-d-flex p-jc-center">
            <div class="p-col-12 p-md-4 p-d-flex p-jc-center p-mr-2">
              <div>
                <p-avatar
                  [style]="{ height: '56px', width: '56px' }"
                  class="p-m-5"
                  ngDefaultControl
                  shape="circle"
                  [(ngModel)]="persona.photo"
                  ><img
                    class="user-photo"
                    src="{{ persona?.photo }}"
                /></p-avatar>
              </div>
            </div>
            <div class="p-col-12 p-md-8 p-d-flex p-jc-start">
              <div class="p-grid p-ai-center vertical-container"></div>
              <label
                class="username"
                >{{ persona?.firstName }}

                <div><label style="color:#A0A0A0" class="persona">{{ role }}</label></div>
              </label
              >
            </div>
          </div>
        </a>
      </div>
      <div class="p-col-12 main-menu">
        <div class="p-d-flex p-jc-center">
          <p-panelMenu
           [transitionOptions]="'0ms'"
            [ngClass]="{
              'hover-pink': module === 'kober-skill',
              'hover-red': module === 'kober-material',
              'hover-blue': module === 'kober-services'
            }"
            styleClass="left-menu-bar extended"
            [model]="mainMenu"
            [multiple]="false"
          ></p-panelMenu>
        </div>
      </div>
      <div class="p-col-12 bottom-menu">
        <div class="p-d-flex p-jc-center" style="margin-left: 15%;">
          <p-menu
            styleClass="left-menu-bar extended bottom-menu"
            [model]="bottomMenu"
          ></p-menu>
        </div>
      </div>
    </div>
    <!--RETOUR A LA LISTE
    <p-button styleClass="p-button-outlined p-mr-2" label="Retour"></p-button>

    <p-button styleClass="p-mr-2" label="Valider"></p-button>-->
  </div>
</p-sidebar>

<div class="menu-bar-collapsed"
  [ngClass]="{'p-d-lg-none': reducedMenu === false}">
  <div class="p-grid p-nogutter">
    <div class="p-col-12 p-d-flex p-jc-center logo-block">
      <a routerLink="/phoenix/home">
        <img
          *ngIf="module === 'kober-material'"
          width="50px"
          height="50px"
          src="assets/SVG/menu/Accueil.svg"
        />
        <img
          *ngIf="module === 'kober-skill'"
          width="50px"
          height="50px"
          src="assets/SVG/menu/Accueil.svg"
        />
        <img
          *ngIf="module === 'kober-services'"
          width="50px"
          height="50px"
          src="assets/SVG/menu/Accueil.svg"
        />
      </a>
    </div>
    <div *ngIf="persona?.idKeycloak" class="p-col-12 p-d-flex p-jc-center">
      <a routerLink="{{ urlProfile }}">
        <div class="p-d-flex p-jc-center">
          <div class="p-col-12 p-md-4 p-d-flex p-jc-center">
            <div>
              <p-avatar
                [style]="{ height: '50px', width: '50px' }"
                ngDefaultControl
                shape="circle"
                [(ngModel)]="persona.photo"
                ><img
                  class="user-photo-collapsed"
                  src="{{ persona?.photo }}"
              /></p-avatar>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="p-col-12 main-menu">
      <div class="p-d-flex p-jc-center ">
        <p-tieredMenu
        [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
        [autoDisplay]="false"
          baseZIndex="1"
          [ngClass]="{
            'hover-pink': module === 'kober-skill',
            'hover-red': module === 'kober-material',
            'hover-blue': module === 'kober-services'
          }"
          [model]="mainMenu"
          styleClass="collapsed left-menu-bar p-d-lg-none"
        ></p-tieredMenu>
      </div>
      <div class="p-d-flex p-jc-center">
        <p-tieredMenu
        [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
          [autoDisplay]="true"
          baseZIndex="1"
          [ngClass]="{
            'hover-pink': module === 'kober-skill',
            'hover-red': module === 'kober-material',
            'hover-blue': module === 'kober-services'
          }"
          [model]="mainMenu"
          styleClass="collapsed left-menu-bar  p-d-none p-d-lg-inline-flex"
        ></p-tieredMenu>
      </div>
    </div>
    <div class="p-col-12 bottom-menu">
      <div class="p-d-flex p-jc-center">
        <p-tieredMenu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
          [model]="bottomMenu"
          styleClass="collapsed left-menu-bar"
        ></p-tieredMenu>
      </div>
    </div>
  </div>
  <!--RETOUR A LA LISTE
    <p-button styleClass="p-button-outlined p-mr-2" label="Retour"></p-button>

    <p-button styleClass="p-mr-2" label="Valider"></p-button>-->
</div>
