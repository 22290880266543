import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tag} from "@data/schema/Tag";
import {ConfirmationService, MessageService, SortEvent} from "primeng/api";
import {TagService} from "@data/service/tag.service";
import {CustomSortService} from "@shared/service/custom-sort.service";
import {NavigationExtras, Router} from "@angular/router";

@Component({
  selector: 'app-list-tag',
  templateUrl: './list-tag.component.html',
  styleUrls: ['./list-tag.component.css']
})
export class ListTagComponent implements OnInit {

  @Input() tags?: Tag[];
  @Output() isBookmarked = new EventEmitter();

  changeBookmarked(a:boolean) {
    this.isBookmarked.emit(a);
  }
  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  constructor(private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private tagService: TagService,
              private customSortService: CustomSortService,
              private router: Router) { }


  ngOnInit(): void {
  }

  bookmarked(id : number) {
    this.tagService.Bookmark(id).subscribe(res => {
        if (res.status === 200) {
          //appel pere
          this.changeBookmarked(true);
        }
      },
      error => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer'
          });
          this.changeBookmarked(false);
        }
      },
      () => {
      })

  }
  callBackValue(e){
  }
  naturalSortTag(event: SortEvent){
    this.customSortService.naturalSort(event);
  }

  update(idTag: number){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        editMode: true
      }
    }
    this.router.navigate(['phoenix/show-tag/', idTag], navigationExtras);
  }


  deleteTagconfirmation(tag: number) {

    this.confirmationService.confirm({
      message: 'Les données seront supprimées définitivement.' + '<br>' +
        'Voulez vous continuer ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Confirmer',
      rejectLabel: 'Annuler',
      acceptButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.deleteTag(tag);
      },
    });

  }
  deleteTagconfirmationUsed(tag: number) {
    this.confirmationService.confirm({
      message: 'Le tag sera supprimé de tous les éléments liés.' + '<br>' +
        'Voulez vous continuer ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Confirmer',
      rejectLabel: 'Annuler',
      acceptButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.deleteTag(tag);
      },
    });

  }

  tagUsed(id: number) {

    this.tagService.tagUsed(id).subscribe(res => {
        if (res.status === 200) {
          //appel pere
          if (!res.body) {
            this.deleteTagconfirmation(id);
          } else {
            this.deleteTagconfirmationUsed(id);
          }

        }
      },
      error => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer'
          });

        }
      },
      () => {
      })

  }


  deleteTag(id: number) {

    this.tagService.deleteTag(id).subscribe(res => {
        if (res.status === 200) {
          //appel pere
          this.router.navigate(['/phoenix/tag']);

        }
      },
      error => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer'
          });


        }
      },
      () => {

        this.redirectTo('/phoenix/tag');
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });
      }
    )
  }
}

