<div class="modal p-mb-5">
<div class="p-p-2" >
  <div class="p-grid">
    <div class="p-col-1"></div>
    <div class="p-col">{{ 'NOM_FAMILLE' | translate }}</div>
    <div class="p-col">{{ 'DEUXIEME_NOM'| translate }}</div>
    <div class="p-col">{{ 'NOM_PUBLIC' | translate }}</div>
    <div class="p-col">Poste</div>
  </div>
  <div class="p-grid p-d-flex p-ai-center">
    <div class="p-col-1"><p-avatar styleClass="p-mr-2" size="xlarge" shape="circle"><img style="object-fit: cover; " src="{{ persona?.photo }}"
      /></p-avatar></div>
    <div class="p-col">{{ persona.lastName }} {{ persona.firstName }}</div>
    <div class="p-col">{{ persona.middleName }}</div>
    <div class="p-col">{{ persona.publicName }}</div>
    <div class="p-col">    <ng-container
      *ngFor="let post of persona.listProfil; let i = index"
    >
      <p-chip
        *ngIf="!editPersonaMode"
        label="{{ post.name }}"
        class="postChips"
      ></p-chip>
    </ng-container>
    </div>
  </div>
</div>
<div *ngFor="let skill of filteredSkill">
  <p-message severity="warn" styleClass="p-mb-2" text="{{skill.name}} déjà existant.">
  </p-message>
</div>
<div>
  <h3>{{ 'HABILITATION' | translate }}</h3>
  <div *ngFor="let habilitation of listHabilitation">
    <div class="p-field p-grid p-ai-center vertical-container p-m-2">
      <span class="p-col-12 p-md-3"
        >{{ habilitation.skill.name }}
      </span>
      <span
        class="p-grid p-col-12 p-md-9"
      >
        <span class="p-col-12 p-md-6">
          <label for="start">Valide du </label>

          <p-calendar
            pTooltip="La modification de la date début de validité entrainera la modification automatique de la date de fin (calcul en fonction de la durée de validité de l'habilitation)"
            dateFormat="dd.mm.yy"
            [(ngModel)]="habilitation.startDate"
            [monthNavigator]="true"
            [yearNavigator]="true"
            yearRange="1970:2050"
            (onClose)="startDateOnChange(habilitation)"
          ></p-calendar>
        </span>
        <span class="p-col-12 p-md-6">
          <label for="end">au </label>
          <p-calendar
            dateFormat="dd.mm.yy"
            [(ngModel)]="habilitation.endDate"
            [monthNavigator]="true"
            [yearNavigator]="true"
            yearRange="1970:2050"
          ></p-calendar>
        </span>
      </span>
    </div>

  </div>
</div>
<div>
  <h3>Savoir Faire</h3>
  <div *ngFor="let sf of listSavoirFaire">
    {{ sf.skill.name }}
  </div>
</div>
<div>
  <h3>Savoir Etre</h3>
  <div *ngFor="let se of listSavoirEtre">
    {{ se.skill.name }}
  </div>
</div>
<p-sidebar
  class="p-text-right"
  [style]="{ height: '13vh' }"
  [visible]="true"
  [blockScroll]="true"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="bottom"
>

  <p-button (onClick)="save()" styleClass="p-mr-2" label="Valider"></p-button>
</p-sidebar>
<div>

