import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import { Tag } from '@data/schema/Tag';
import {ConfirmationService, MessageService} from "primeng/api";
import {TagService} from "@data/service/tag.service";
import {DetailTagComponent} from "../../components/tag/detail-tag/detail-tag.component";

@Component({
  selector: 'app-new-tag',
  templateUrl: './new-tag.component.html',
  styleUrls: ['./new-tag.component.css']
})
export class NewTagComponent implements OnInit {

  tag: Tag = new Tag();
  setting: string = "Création de tag";
  tags: Tag[] = [];
  attributTagValidator: boolean = false;

  @ViewChild(DetailTagComponent)
  private detailTagComponent!: DetailTagComponent;

  constructor(
    private router: Router,
    private tagService: TagService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.getTagAll();
  }
  back() {
    if(this.attributTagValidator || this.validatorAttributInitialTag()){
      this.gotoList();
    }else{
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez quitter la page, les données seront perdues.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        accept: () => {
          this.gotoList();
        },
      });
    }

  }

  gotoList() {
    this.redirectTo('/phoenix/tag');
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  submit() {
    let validForm = true;
    // check valid name field
    if (this.detailTagComponent.nameTagFieldValidator()) {
      validForm = false;
    }
    if (!validForm) {
      this.messageService.add({
        severity: 'error',
        summary: 'Échec de l’opération : veuillez remplir tous les champs',
      });
    } else {
      this.save();
    }
  }

  validatorAttributInitialTag(){
    if(this.tag.label.trim()==="" && this.tag.description.trim()==="") {
      return true;
    }
    return false;
  }

  changeAttributTag(tag:Tag){
    if(tag.label.trim()==="" && tag.description.trim()===""){
      this.attributTagValidator= true;
    }
  }

  save() {
    this.tagService.saveTag(this.tag).subscribe(
      (res) => {
        if (res.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        }
      },
      (error) => {
        if (error) {
          if (error.status === 500) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
          if (error.status === 409) {
            this.messageService.add({
              severity: 'error',
              summary: 'Cet élément existe déjà',
            });
          }
        }
      },
      () => {
        this.gotoList();
      }
    );
  }

  getTagAll(){
    this.tagService.getAllTags().subscribe(
      (res) => {
        if (res.status === 200) {
          this.tags = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

}
