import { Component, OnInit } from '@angular/core';
import { DialogService } from "primeng/dynamicdialog";
import { BrowserStorageService } from '@shared/service/browser-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@shared/service/local-storage.service';
import {EnvService} from "@core/service/env.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [DialogService]
})

export class AppComponent implements OnInit {

  private apiURL: string = this.environment?.readConfig()?.urlApiGateway + "authentication/";;

  /**
   * HANDLE LANGUAGE AT INIT OF THE APP
   *
   * @param browserDateService
   * @param translateService
   * @param localStorageService
   */
  constructor(
    public browserDateService: BrowserStorageService,
    public translateService: TranslateService,
    public localStorageService: LocalStorageService,
    public environment: EnvService) {
      console.log(environment.readConfig());
      console.log(this.apiURL);
      if (localStorageService.getLocalStorageItem("language") !== null){
        let languageConfig = JSON.parse(localStorageService.getLocalStorageItem("language"))
        if (languageConfig.tenant){
          // GO DB

          // SI DB
          // DEFAULT LANG = DB
          // IF languageConfig.locale
          // DEFAULt LANG = languageConfig.locale
          // ELSE
          // DEFAULT LANG = navigator.language
        }
        else{
          this.translateService.setDefaultLang(languageConfig.locale)
        }
      }
      else{
        let local = navigator.language
        console.log(local)
        this.translateService.setDefaultLang(local)
      }


  }

  ngOnInit() {
    this.translateService.use(this.translateService.getDefaultLang())
    if (localStorage) {
      this.browserDateService.retrieveLocalStorage();
    }
  }

}
