import { OnInit, Component } from '@angular/core';
import { SkillWork,MaterialWork,Work } from '@data/schema/Work';
import { Skill } from '@data/schema/Skill';
import { Materiel } from '@data/schema/Materiel';
import { RejectedWork } from '@data/schema/RejectedWork';
import { WorkService } from '@data/service/work.service';
import { SkillService } from '@data/service/skill.service';
import { MaterialService } from '@data/service/material.service';
import {ExportService} from '@shared/service/export.service'
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationService, MessageService, ConfirmEventType, MenuItem,SortEvent} from 'primeng/api';
import {FileUpload} from 'primeng/fileupload';
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver';
import {CustomSortService} from "@shared/service/custom-sort.service";

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css']
})
export class WorkComponent implements OnInit {

  works: Work[] = [];
  skills: Skill[] = [];
  materials: Materiel[] = [];
  listSkillDto: SkillWork[] = [];
  listMaterialDtos: MaterialWork[] = [];
  listSkill: Skill[] = [];
  listMaterialDto : Materiel[] = [];
  listMaterial : Materiel[] = [];

  workIdOnToggle: number;
  display: boolean = false;
  index_line : number;

  worksError: RejectedWork[] = [];
  worksImporter: Work[]=[];
  allowEmptyString: boolean;
  productDialog: any;
  displayImportFile: boolean = false;
  file: File = null;
  arrayBuffer: any;
  first_worksheet: any;
  second_worksheet: any;
  third_worksheet: any;
  displayErrorFile: boolean = false;
  fileReadyForUpload: boolean = false;
  workChoosen: any;
  fileReaded : any

  today: number = Date.now();
  workExport : Work;
  selectedWorks : Work[] = [];
  exportColumns: any[];

  dateDay : any = new Date();
  dateD : any = this.dateDay.getDay()+'/'+this.dateDay.getMonth()+'/'+this.dateDay.getFullYear()+'/'+this.dateDay.getHours()+':'+this.dateDay.getMinutes()+':'+this.dateDay.getSeconds();
  cols: any[];
  headers : string [] = [];
  headers_ : string;
  items: MenuItem[];

  workIsInUse: boolean = false;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private workService: WorkService,
    private skillService: SkillService,
    private materialService: MaterialService,
    private router: Router,
    private exportService : ExportService,
    private customSortService: CustomSortService,
  ) {}


  ngOnInit() {

    this.items = [
      {label:'Prestation'},
    ];

    this.workService.getListWork().subscribe(
      (res) => {
        if (res.status === 200) {
          this.works = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );

    this.skillService.getListSkill().subscribe(
      (res) => {
        if (res.status === 200) {
          this.skills = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );

    this.materialService.ListMateriel().subscribe(
      (res) => {
        if (res.status === 200) {
          this.materials = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );



    this.cols = [

      { field: 'name', header: 'Nom' },
      { field: 'description', header: 'Description' },
      { field: 'coste', header: 'Coût' },
      { field: 'currency', header: 'Devise' },
    ];

    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

  }

  getRowIndex(id: any) {
    this.workIdOnToggle = id;
  }

  redirectTo(uri:string){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([uri]));
   }
update() {
  let navigationExtras: NavigationExtras = {
    queryParams: {
        editMode: true
    }
  }
  this.router.navigate(['/phoenix/show-work/', this.workIdOnToggle], navigationExtras);
}

  confirmDeletion(message:string) {
    if(this.workIsInUse){
      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message:
        message,
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'J\'ai compris',
        rejectVisible: false,
        key: 'isInUseWhenDelete',
        accept: () => {
        },
      });
      this.workIsInUse = false;
    }else{
      console.log("before delete else")
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Les données seront supprimées définitivement.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        key: 'confirmDelete',
        accept: () => {
          // delete work function
          this.delete(this.workIdOnToggle);
          this.redirectTo('/phoenix/work')
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        },
      });
    }



  }

  confirmUpdate(message:string) {
    if(this.workIsInUse){
      this.confirmationService.confirm({
        header: 'Modification impossible',
        message:
          message,
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'J\'ai compris',
        rejectVisible: false,
        key: 'isInUseWhenUpdate',
        accept: () => {
        },
      });
      this.workIsInUse = false;
    }else{
      this.update();
    }



}

  delete(id: number) {

   this.workService.deleteWork(id).subscribe(
      (res) => {
        // Deleted
        if (res.status === 200) {
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );
  }

  showDialogImportFile() {
    this.displayImportFile = true;
  }

  fileRemoved() {
    this.fileReadyForUpload = false;

  }

  fileSelected() {
    this.fileReadyForUpload = true;
  }


  fileTemplateIsValidXlsOrCsv(worksheet: any) {

    if (worksheet['A1'] !== undefined && worksheet['B1'] !== undefined && worksheet['C1'] !== undefined && worksheet['D1'] !== undefined) {
      if (  (worksheet['A1'].w === 'NOM'  || worksheet['A1'].h === 'NOM')     &&
            (worksheet['B1'].w === 'DESCRIPTION' || worksheet['B1'].h === 'DESCRIPTION' ) &&
            (worksheet['C1'].w === 'COUT' || worksheet['C1'].h === 'COUT') &&
            (worksheet['D1'].w === 'DEVISE' || worksheet['D1'].h === 'DEVISE')  ){
            return true;
      }
    }

    return false;

  }


  cancelUpload(fileUpload: FileUpload) {
    if (this.fileReadyForUpload) {

      this.confirmationService.confirm({
        message: 'Vous allez quitter la page, les données seront perdues.' + '<br>' +
          'Voulez vous continuer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        acceptButtonStyleClass: 'p-button-outlined',
        accept: () => {
          fileUpload.clear();
          fileUpload.clearInputElement();
          this.getWorkList();
          this.messageService.add({severity: 'info', summary: 'Importation annulée', detail: ''});
          this.productDialog = false;
          this.displayImportFile = false;
        },
        reject: (type:any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              break;
            case ConfirmEventType.CANCEL:
              break;
          }
        }
      });
    } else {
      this.displayImportFile = false;


    }
  }

  getWorkList() {
    this.workService.getListWork().subscribe(res => {
        if (res.status === 200) {
          this.works = res.body;
        }
      },
      error => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer'
          });

        }
      },
      () => {
      })

  }


  private matchingCellXlsOrCsv(first_worksheet: any,second_worksheet: any,third_worksheet: any, work: Work) {

    this.worksImporter = [];
    let skillWork : SkillWork;
    let materialWork : MaterialWork;
    let material : Materiel;
    let firstWorksheetLine : any;
    let hasMaterial : boolean;
    let hasSkill : boolean;
    let skill_filter = [];
    let material_filter = [];

    for (let i = 0; i < first_worksheet.length; i++) {

      firstWorksheetLine = first_worksheet[i]

      material_filter = []
      skill_filter = []

      for (let key in second_worksheet){

          let element = second_worksheet[key]

          if (element.Nom_Prestation === firstWorksheetLine.NOM){


            this.listMaterial = this.materials.filter(c =>

              c.label === element.Nom_Materiel

            );

            for (let c  of this.listMaterial){



              material = {

                id : c.id,
                label : c.label,
                description : c.description,
                categorie : c.categorie,
                identify : c.identify,
                unit : c.unit,
                minQuantity: c.minQuantity,


              };

              materialWork = {

                id: null,
                work: null,
                idMateriel: null,
                materialDto: material,
                quantityMaterial: element.Quantite,

              };

              material_filter.push(materialWork);


            }


         }

      }


      for (let key in third_worksheet){

         let element = third_worksheet[key]

         if (element.Nom_Prestation == firstWorksheetLine.NOM){

            this.listSkill = this.skills.filter(

              (c) => c.name == element.Nom_Competence

            );


            for (let skill of this.listSkill){

                if (skill.name == element.Nom_Competence) {

                  skillWork = {

                    id: null,
                    work: null,
                    idSkill: null,
                    skillDto:  skill,

                  };

                  skill_filter.push(skillWork);

              }




            }


          }

      }

        if (firstWorksheetLine.COMPETENCE=== "Oui") {
           hasSkill = true;
        }

        if (firstWorksheetLine.COMPETENCE=== "Non") {
          hasSkill = false;
        }

        if (firstWorksheetLine.MATERIEL === "Oui") {
          hasMaterial = true;
        }

        if (firstWorksheetLine.MATERIEL === "Non") {
          hasMaterial = false;
        }


      const workFinal: Work = {

        idWork: null,
        index: i + 2,
        name: firstWorksheetLine.NOM,
        description: firstWorksheetLine.DESCRIPTION,
        cost: firstWorksheetLine.COUT,
        currency: firstWorksheetLine.DEVISE,
        listSkillDto : skill_filter,
        materialsList :  material_filter,
        hasMaterial: hasMaterial,
        hasSkill: hasSkill,
        isDefined: null


      };

      this.worksImporter.push(workFinal);

    }

    this.sendFileWithService();

  }


  sendFileWithService() {

    this.displayImportFile = false;

    this.workService.createWorks(this.worksImporter).subscribe(

      res => {
        if (res.status === 200) {

          this.messageService.add({severity: 'success', summary: 'Opération réussie', detail: res.message});

          this.getWorkList();
        }
      },

      error => {
        if (error.status === 417) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération',
            detail: "Veuillez modifier le fichier et réessayer"
          });
          this.worksImporter = [];
          this.worksError = [];
          this.worksError = JSON.parse(JSON.stringify(error.error)).listRejectedRecord;
          this.displayErrorFile = true;
        }
        else if (error.status === 500) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: "Erreur 500 : veuillez contacter votre administrateur",
            sticky: true
          });
          this.router.navigate(['/phoenix/work']);
        }
        else {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer',
            sticky: true
          });
        }
      }
    );

  }

  fileReader(fichier: File, work: Work) {

    let fileReader = new FileReader();
    this.worksImporter = [];

    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();

      for (let i = 0; i !== data.length; i++) {
        arr[i] = String.fromCharCode(data[i]);
      }

      const bstr = arr.join('');
      const workbook = xlsx.read(bstr, {type: 'binary', cellDates: true});
      const first_sheet_name = workbook.SheetNames[0];
      const second_sheet_name = workbook.SheetNames[1];
      const third_sheet_name = workbook.SheetNames[2];


      const first_worksheet = workbook.Sheets[first_sheet_name];
      const second_worksheet = workbook.Sheets[second_sheet_name];
      const third_worksheet = workbook.Sheets[third_sheet_name];

      this.first_worksheet = xlsx.utils.sheet_to_json(first_worksheet, {raw: true});
      this.second_worksheet = xlsx.utils.sheet_to_json(second_worksheet, {raw: true});
      this.third_worksheet = xlsx.utils.sheet_to_json(third_worksheet, {raw: true});

      if (this.fileTemplateIsValidXlsOrCsv(first_worksheet)) {

        this.matchingCellXlsOrCsv(this.first_worksheet,this.second_worksheet,this.third_worksheet, work);
        this.first_worksheet = null;

      }
      else {
        this.messageService.add({
          severity: 'error',
          summary: 'Échec de l’opération',
          detail: "Format du fichier non conforme"
        });
        return;
      }
      /**
       * Call matching function
       */


    };
    fileReader.readAsArrayBuffer(fichier);
    fichier = null;
  }

  getFile(event: any, fileUpload: FileUpload) {

    this.worksImporter = [];
    this.file = event.files[0];
    let work: Work = null;
    this.fileReader(this.file, work);
    fileUpload.clear();
    fileUpload.clearInputElement();

  }

    exportCsv(){

      const filename = 'Prestations';
      let fileName = filename + '_export_' + this.dateD;

      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez exporter les prestation au format csv' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Exporter',
        rejectLabel: 'Annuler',
        accept: () => {
          this.exportService.downloadFileCsv(this.works, fileName);
          this.router.navigate(['/phoenix/work']);
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });

        },
        reject: (type:any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              break;
            case ConfirmEventType.CANCEL:
              break;
          }
        },
      });

    }

    exportExcel(){

      const filename = 'Prestations';
      let fileName = filename + '_export_' + this.dateD;

      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez exporter les prestations au format Excel' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Exporter',
        rejectLabel: 'Annuler',
        accept: () => {
          this.exportService.downloadFileExcel(this.works, fileName);

        },
        reject: (type:any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              break;
            case ConfirmEventType.CANCEL:
              break;
          }
        },
      });

    }

    exportToExcel(){
       this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez exporter les prestations au format Excel' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Exporter',
        rejectLabel: 'Annuler',
        key: 'export',
        accept: () => {
          this.workService.getExcelData().subscribe(
            (responseMessage)=> {

              if (responseMessage){

                if (window.navigator) {
                  let file = new Blob([responseMessage],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;'});
                  let fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
                }

                else {

                  let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;";
                  let EXCEL_EXTENSION = '.xlsx';
                  const fileName = 'Prestations';
                  const blob = new Blob([responseMessage], {
                    type: EXCEL_TYPE
                  });
                FileSaver.saveAs(blob, fileName + '_export_' + this.dateD + EXCEL_EXTENSION);

                }

                this.router.navigate(['/phoenix/work']);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Opération réussie',
                });

              }
            },
            error => {
               if (error.status === 500) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Erreur',
                  detail: "Erreur 500 : veuillez contacter votre administrateur",
                  sticky: true
                });
                this.router.navigate(['/phoenix/work']);
              }
              else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Erreur',
                  detail: 'Échec de l’opération : veuillez réessayer',
                  sticky: true
                });
              }
            }

          )

        },
        reject: (type:any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              break;
            case ConfirmEventType.CANCEL:
              break;
          }
        },
      });


    }

    exportToCsv(){

      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez exporter les prestations au format csv' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Exporter',
        rejectLabel: 'Annuler',
        key: 'export',
        accept: () => {
          this.workService.getCsvData().subscribe(
            (responseMessage)=> {
              if (responseMessage){

                if (window.navigator) {
                  let file = new Blob([responseMessage],{type: 'text/csv;charset=utf-8;'});
                  let fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
                }
                else {// other browsers

                  let CSV_TYPE = "text/csv;charset=utf-8;";
                  let CSV_EXTENSION = '.csv';
                  const fileName = 'Prestations';
                  const blob = new Blob([responseMessage], {
                    type: CSV_TYPE
                  });
                FileSaver.saveAs(blob, fileName + '_export_' + this.dateD + CSV_EXTENSION);

                }
                this.router.navigate(['/phoenix/work']);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Opération réussie',
                });
              }
            },
            error => {
               if (error.status === 500) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Erreur',
                  detail: "Erreur 500 : veuillez contacter votre administrateur",
                  sticky: true
                });
                this.router.navigate(['/phoenix/work']);
              }
              else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Erreur',
                  detail: 'Échec de l’opération : veuillez réessayer',
                  sticky: true
                });
              }
            }

          )

        },
        reject: (type:any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              break;
            case ConfirmEventType.CANCEL:
              break;
          }
        },
      });

    }

    exportToPdf(){

      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez exporter les prestations au format pdf' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Exporter',
        rejectLabel: 'Annuler',
        key: 'export',
        accept: () => {
          this.workService.getPdfData().subscribe(
            (responseMessage)=> {
              if (responseMessage){

                if (window.navigator) {
                  let file = new Blob([responseMessage],{type: 'application/pdf;charset=utf-8;'});
                  let fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
                }

                else {

                  let PDF_TYPE = "application/pdf;charset=utf-8;";
                  let PDF_EXTENSION = '.pdf';
                  const fileName = 'Prestations';
                  const blob = new Blob([responseMessage], {
                    type: PDF_TYPE
                  });
                FileSaver.saveAs(blob, fileName + '_export_' + this.dateD + PDF_EXTENSION);

                }

                this.router.navigate(['/phoenix/work']);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Opération réussie',
                });

                }
            },
            error => {
               if (error.status === 500) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Erreur',
                  detail: "Erreur 500 : veuillez contacter votre administrateur",
                  sticky: true
                });
                this.router.navigate(['/phoenix/work']);
              }
              else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Erreur',
                  detail: 'Échec de l’opération : veuillez réessayer',
                  sticky: true
                });
              }
            }

          )

        },
        reject: (type:any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              break;
            case ConfirmEventType.CANCEL:
              break;
          }
        },
      });
    }

    isWorkInUseBeforeDelete(){
        this.workService.isWorkAlreadyInUse(this.workIdOnToggle).subscribe(
          res => {
            if(res.status === 200){
              this.workIsInUse = res.body.workUsed;
              this.confirmDeletion(res.body.message);
            }
          }
        );
      }

      isWorkInUseBeforeUpdate(){
          this.workService.isWorkAlreadyInUse(this.workIdOnToggle).subscribe(
            res => {
              if(res.status === 200){
                this.workIsInUse = res.body.workUsed;
                this.confirmUpdate(res.body.message);
              }
            }
          );
        }


  naturalSortWork(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

}
