import { Component, OnInit, HostListener } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TypeSkill, SelectTypeSkill, Skill } from '@data/schema/Skill';
import { Tag } from '@data/schema/Tag';
import { SkillService } from '@data/service/skill.service';
import { QrDataFormaterService } from '@shared/service/qr-data-formater.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserStorageService } from '@shared/service/browser-storage.service';
import { TagService } from '@data/service/tag.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';


@Component({
  selector: 'app-show-skills',
  templateUrl: './show-skills.component.html',
  styleUrls: ['./show-skills.component.css'],
})
export class ShowSkillsComponent implements OnInit, ComponentCanDeactivate {

  public submited: BehaviorSubject<boolean>;
  //@HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    return !this.changed || this.submited.value ? true : false;

  }

  changed: boolean = false;
  qrData: string = null;
  skill: Skill = new Skill();
  id: number;
  sub: any;
  updateGlobal: boolean = false;
  editMode: boolean;
  label: string = 'Modifier';
  hab: boolean;
  expi: boolean;
  errorNameRequired: boolean = false;
  errorTypeRequired: boolean = false;
  errorDateRequired: boolean = false;
  errorInvalidValue: boolean = false;
  yearDate: number = null;
  monthDate: number = null;
  dayDate: number = null;

  tags: any[] = [
    { label: 'Favoris', value: 'favoris', items: [] },
    { label: 'Tous', value: 'tous', items: [] },
  ];
  selectedTags: any[] = [];
  iconChange = 'pi custom-icon-tag';
  selectTypeOptions: SelectTypeSkill = new SelectTypeSkill();
  typeOptions: TypeSkill[] = this.selectTypeOptions.typeOptions;


  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private skillService: SkillService,
    private router: Router,
    private tagService: TagService,
    private qrDataFormaterService: QrDataFormaterService,
    private confirmationService: ConfirmationService,
    private _location: Location,
    public browserDataService: BrowserStorageService
  ) {

    this.route.queryParams.subscribe((params) => {
      if (params.editMode) {

        this.updateGlobal = true;
        this.label = 'Valider';


      }
    });
    this.submited = new BehaviorSubject(false);
  }

  // update skills
  updateSkills() {

    if (this.skill.name === '') {
      this.errorNameRequired = true;
    }
    if (this.updateGlobal === false) {

      this.label = 'Valider';
      this.updateGlobal = true;
    } else if (

      this.updateGlobal == true &&
      this.errorInvalidValue == false &&
      this.errorNameRequired == false
    ) {

      this.confirmUpdate();
      this.submited.next(true);
    }
  }

  checkHab(event: any) {
    if (this.skill.type == 'HABILITATION') {
      this.hab = true;
    } else {
      this.hab = false;
    }
  }

  checkExpi(event: any) {
    if (this.skill.validity == true) {
      this.expi = true;
    } else {
      this.expi = false;
    }
  }

  nameFieldValidator(event: any) {
    if (event.target.value === '') {
      this.errorNameRequired = true;
    } else {
      this.errorNameRequired = false;
    }
  }

  dateYearFieldValidator(event: any) {
    this.yearDate = event.target.value;

    if (
      (this.yearDate > 0 &&
        (this.monthDate >= 0 || this.monthDate == null) &&
        (this.dayDate >= 0 || this.dayDate == null)) ||
      (this.yearDate == 0 &&
        (this.monthDate >= 0 || this.monthDate == null) &&
        this.dayDate > 0) ||
      (this.yearDate == 0 &&
        this.monthDate > 0 &&
        (this.dayDate >= 0 || this.dayDate == null))
    ) {
      this.errorInvalidValue = false;
    } else {
      this.errorInvalidValue = true;
    }

    if (
      this.yearDate !== null ||
      this.monthDate !== null ||
      this.dayDate !== null
    ) {
      this.errorDateRequired = false;
    }
  }

  dateMonthFieldValidator(event: any) {
    this.monthDate = event.target.value;

    if (
      (this.monthDate > 0 &&
        (this.dayDate >= 0 || this.dayDate == null) &&
        (this.yearDate >= 0 || this.yearDate == null)) ||
      (this.monthDate == 0 &&
        (this.dayDate >= 0 || this.dayDate == null) &&
        this.yearDate > 0) ||
      (this.monthDate == 0 &&
        this.dayDate > 0 &&
        (this.yearDate >= 0 || this.yearDate == null))
    ) {
      this.errorInvalidValue = false;
    } else {
      this.errorInvalidValue = true;
    }

    if (
      this.yearDate !== null ||
      this.monthDate !== null ||
      this.dayDate !== null
    ) {
      this.errorDateRequired = false;
    }
  }

  dateDayFieldValidator(event: any) {
    this.dayDate = event.target.value;

    if (
      (this.dayDate > 0 &&
        (this.monthDate >= 0 || this.monthDate == null) &&
        (this.yearDate >= 0 || this.yearDate == null)) ||
      (this.dayDate == 0 &&
        (this.monthDate >= 0 || this.monthDate == null) &&
        this.yearDate > 0) ||
      (this.dayDate == 0 &&
        this.monthDate > 0 &&
        (this.yearDate >= 0 || this.yearDate == null))
    ) {
      this.errorInvalidValue = false;
    } else {
      this.errorInvalidValue = true;
    }

    if (
      this.yearDate !== null ||
      this.monthDate !== null ||
      this.dayDate !== null
    ) {
      this.errorDateRequired = false;
    }
  }

  confirme() {
    this.browserDataService.copySessionStorage();

    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Les données seront supprimées définitivement.' +
        '<br>' +
        'Si cette compétence est affectée à un ou plusieurs personas, sa suppression effacera toutes les associations avec les personas (vérifier les associations <a href="/phoenix/personas-of-skills" target="_blank">ici</a>).' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Supprimer',
      rejectLabel: 'Annuler',
      accept: () => {
        // delete skill function
        this.delete(this.id);
        this.submited.next(true);
      },
      reject: () => {
      },
    });
  }

  delete(id: number) {

    console.log('id', id);
    this.submited.next(true)
    // Call API in the service to soft delete Persona
    this.skillService.deleteSkill(id).subscribe(
      (res) => {
        // Deleted
        if (res.status === 200) {
          console.log(res.body);
          this.redirectTo('/phoenix/skill');
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );
  }

  switchEditSkillsMode(value: boolean) {
    this.updateGlobal = value;
  }

  alphaNumericSortTag = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.label.match(/\d+|\D+/g);
    const bPart = b.label.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };


  getTagAll() {

    this.tagService.getAllTags().subscribe(
      (res) => {
        if (res.status === 200) {
          let tags = res.body;
          this.alphaNumericSortTag(tags)
          let tagsFilter: Tag[] = [];
          for (let i = 0; i < tags.length; i++) {
            let isPresent = false;
            for (let j = 0; j < this.skill.tags.length; j++) {

              if(tags[i].label === this.skill.tags[j].label){
                isPresent = true;
              }
            }
            if (!isPresent){

              tagsFilter.push(tags[i])
            }
          }
          for (let i = 0; i < tagsFilter.length; i++) {
            if (tagsFilter[i].bookmarked) {
                this.tags[0].items.push(tagsFilter[i]);
            } else {
                this.tags[1].items.push(tagsFilter[i]);
            }
          }
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );
  }

  panelOpen() {

    setTimeout(function () {
      let multiSelection = document.getElementById('multiselectTag');
      let heightOfMultiSelection: any =
        multiSelection.getElementsByClassName('p-multiselect')[0];
      let offsetHeightMultiselect: any = heightOfMultiSelection.offsetHeight;
      let test: any = document.getElementsByClassName(
        'ng-trigger ng-trigger-overlayAnimation p-multiselect-panel p-component ng-star-inserted'
      )[0];
      test.style.removeProperty('left');
      test.style.cssText =
        'min-width: 33%; right:0px; z-index: 1005; transform-origin: center top;';
      test.style['top'] = offsetHeightMultiselect + 'px';
    }, 10);
    setTimeout(function () {
      let group1 = document.getElementsByClassName(
        'p-multiselect-item-group ng-star-inserted'
      )[0];
      let group2 = document.getElementsByClassName(
        'p-multiselect-item-group ng-star-inserted'
      )[1];
      if (group1) {
        let div1 = document.createElement('div');
        div1.style.cssText =
          'height:1px;display:inline-block;width:70%;max-width:200px;margin-left:10px;margin-bottom:3px;background: #818181;';
        group1.appendChild(div1);
      }
      if (group2) {
        let div2 = document.createElement('div');
        div2.style.cssText =
          'height:1px;display:inline-block;width:70%;max-width:200px;margin-left:26.5px;margin-bottom:3px;background: #818181;';
        group2.appendChild(div2);
      }
    }, 50);

  }

  changeTag(event: any) {

    setTimeout(function () {
      let multiSelection = document.getElementById('multiselectTag');
      let heightOfMultiSelection: any =
        multiSelection.getElementsByClassName('p-multiselect')[0];
      let offsetHeightMultiselect: any = heightOfMultiSelection.offsetHeight;
      let test: any = document.getElementsByClassName(
        'ng-trigger ng-trigger-overlayAnimation p-multiselect-panel p-component ng-star-inserted'
      )[0];
      test.style['top'] = offsetHeightMultiselect + 'px';
    }, 50);
    if (this.selectedTags.length > 0) {
      this.iconChange = 'pi custom-icon-close';
    } else {
      this.iconChange = 'pi custom-icon-tag';
    }

  }

  onClickElement(event: any) {

    let span = event.path[0];
    let listOfClassSpan = span.classList;
    if (listOfClassSpan.contains('custom-icon-close')) {
      this.selectedTags = [];
      this.iconChange = 'pi custom-icon-tag';
    }
  }

  chipRemove(event: any) {
    this.selectedTags = this.selectedTags.filter((x) => x !== event);
    if (this.selectedTags.length === 0) {
      this.iconChange = 'pi custom-icon-tag';
    }
  }

  // Show skills
  ngOnInit(): void {

    this.sub = this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    let content = {
      id: this.id,
    };
    this.qrData = this.qrDataFormaterService.generateQrData('skill', content);

    this.skillService.getSkillWithTags(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.skill = res.body;
          this.alphaNumericSortTag(this.skill.tags)

          if (this.skill.type == 'HABILITATION') {
            this.hab = true;
          }

          if (this.skill.validity == true) {
            this.expi = true;
          }

          this.yearDate = this.skill.year_duration;
          this.monthDate = this.skill.month_duration;
          this.dayDate = this.skill.day_duration;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      }
    );

    this.getTagAll();
    this.changed = false;
  }

   // Detect input changes
   onChange(UpdatedSkill: Skill): void {

      if (this.skill != UpdatedSkill) {
        this.changed = true;
      }

    }


  confirm() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez quitter la page, les données seront perdues.' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.updateGlobal = false;
        this.goBack();
      },
    });
  }

  goBack() {
    this._location.back();
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  removeTag(event: any, tag: Tag) {

    this.skill.tags = this.skill.tags.filter((x) => x.tagId !== tag.tagId);

  }

  confirmUpdate() {

    for (let element of this.selectedTags) {

      this.skill.tags.push(element);
    }
    this.skillService.updateSkill(this.skill.id, this.skill).subscribe(
      (res) => {
        if (res.status === 200) {
          console.log(res.body);
          this.updateGlobal = false;
          this.label = 'Modifier';
          this.redirectTo('/phoenix/skill');
          this.messageService.add({
            severity: 'success',
            summary: 'Opération Réussie',
          });
        }
      },
      (error) => {
        if (error) {
          if (error.status === 500) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
          if (error.status === 409) {
            this.messageService.add({
              severity: 'error',
              summary: 'Cet élément existe déjà',
            });
          }
        }
      },

      () => {}
    );
  }
}
