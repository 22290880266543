import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appCheckStoreHours]'
})
export class CheckStoreHoursDirective {

  constructor() { }


}
export function forbiddenHoursValidator(hours): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    console.log(hours.value);
    // let forbiddenArr = hours.filter(x => x. === control.value.toLowerCase().trim());
    this.checkHoursLenght(hours,control.value.id);
    let forbidden = false;
    // if(forbiddenArr.length > 0) {
    //   forbidden = true;
    // }
    return forbidden ? {forbiddenName: {value: control.value}} : null;
  };
}

export function checkHoursLenght(hours,id){
  let arrayOfId = hours.map(x => x.day.id);
  console.log(arrayOfId);
  console.log(hours);
  console.log(id);

}
