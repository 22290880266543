<h3>{{'OUTILLAGE_REQUIS'|translate}}</h3>
<div class="p-grid p-mb-2">
  <div class="p-col-4">
    {{'NOM'|translate}}
  </div>
  <div class="p-col-4">
    {{'UNITE'|translate}}
  </div>
  <div class="p-col-2 p-text-right">
    {{'QUANTITE'|translate}}
  </div>
</div>
<div *ngFor="let workMateriel of listToolsSelected" class="p-grid">
  <div class="p-col-4">
    {{workMateriel.materialDto.label}}
  </div>
  <div class="p-col-4">
    {{workMateriel.materialDto.unit.name}}
  </div>
  <div class="p-col-2 p-text-right">
    {{workMateriel.quantityMaterial}}
  </div>
</div>