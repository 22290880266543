import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Project, ProjectWorkOrder } from '@data/schema/Project';
import { Work } from '@data/schema/Work';
import { WorkService } from '@data/service/work.service';
import {CurrencyService} from "@data/service/currency.service";
import {Currency} from "@data/schema/Currency";
import { LocalStorageService } from '@shared/service/local-storage.service';

@Component({
  selector: 'app-project-form-detail',
  templateUrl: './project-form-detail.component.html',
  styleUrls: ['./project-form-detail.component.css'],
})
export class ProjectFormDetailComponent implements OnInit {
  @Input() project: Project;
  @Input() list: ProjectWorkOrder[];
  @Output() projectChange = new EventEmitter();
  change() {
    this.projectChange.emit(this.project);
  }
  @Input() editMode: boolean;

  listWork: Work[];
  currency: Currency;

  constructor(
    private workService: WorkService,
    private messageService: MessageService,
    private currencyService: CurrencyService,
    private localStorage: LocalStorageService
  ) {
    this.currency = JSON.parse(this.localStorage.getLocalStorageItem("currency"))
  }

  ngOnInit(): void {
    this.getListWork();
  }

  getListWork(){
    this.workService.getListWork().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listWork = res.body;
       
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );
  }

 
}
