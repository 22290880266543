import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Project, ProjectWork } from '@data/schema/Project';
import { Work } from '@data/schema/Work';
import { ConfirmationService, MessageService,SortEvent } from 'primeng/api';
import {CustomSortService} from "@shared/service/custom-sort.service";


@Component({
  selector: 'app-work-form-list-works',
  templateUrl: './work-form-list-works.component.html',
  styleUrls: ['./work-form-list-works.component.css'],
})
export class WorkFormListWorksComponent implements OnInit {
  //recupérer l'objet parent
  @Input() project: Project;
  //revoyer des infos au parent (en cas de modification)
  @Output() projectChange = new EventEmitter();
  change() {
    this.projectChange.emit(this.project);
  }

  //liste des prestation à sélectionnées
  @Input() works: Work[];

  //initialiser la prestation à ajouter dans le projet
  addedWork: ProjectWork = new ProjectWork();

  //varibale qui me permet d'afficher le modal pour  valider l'ajouter de la prestation
  displayModal: boolean = false;

  @ViewChild("input") elm;

  constructor(
      private confirmationService: ConfirmationService,
      private messageService : MessageService,
      private customSortService: CustomSortService
  ) {}

  ngOnInit(): void {}

  //methode pour ouvrir le modal
  addWork(work: Work) {
    if(this.project.status==="DOING"){
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'La modification des prestations impactera le budget du projet.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.displayModal = true;
          this.addedWork = new ProjectWork();
          this.addedWork.work = work;
          this.addedWork.project = new Project();
          this.addedWork.project.id = this.project.id;
          this.addedWork.quantityTotal = undefined;
        },
      });
  }else{
          this.displayModal = true;
          this.addedWork = new ProjectWork();
          this.addedWork.work = work;
          this.addedWork.project = new Project();
          this.addedWork.project.id = this.project.id;
          this.addedWork.quantityTotal = undefined;
  }

  }


  //valider l'ajout de la prestation dans l'objet projet
  //ferme la modal
  validWork() {
    
    if (this.addedWork.quantityTotal > 0){
      this.addedWork.quantityFree = this.addedWork.quantityTotal;
      this.project.projectWorks.push(this.addedWork);
      this.displayModal = false;
    }
    
  }

  cancel() {
    this.displayModal = false;
  }

  setFocus() {
    this.elm.input.nativeElement.focus();
  }

  naturalSortWork(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

}
