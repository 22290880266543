import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-setting-currency',
  templateUrl: './setting-currency.component.html',
  styleUrls: ['./setting-currency.component.css']
})
export class SettingCurrencyComponent implements OnInit {

  @Input() setting: string;
  constructor() { }

  ngOnInit(): void {
  }

}
