import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}


@Injectable({
  providedIn: 'root'
})
export class ComponentCanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private confirmationService: ConfirmationService, private translateService: TranslateService) {
  }

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> | boolean {
    return component.canDeactivate() ?
      true :
      new Observable((observer: Observer<boolean>) => {
        this.confirmationService.confirm({
            header: 'Confirmation',
            message:
              this.translateService.instant('texte_message_exception_CONFIRMATION') +
              '<br>' +
              this.translateService.instant('texte_message_exception_QUITTER_MODIFICATION'),
            icon: 'pi pi-exclamation-triangle',
            acceptButtonStyleClass: 'p-button-outlined',
            acceptIcon: 'null',
            rejectIcon: 'null',
            acceptLabel: this.translateService.instant('bouton_message_exception_QUITTER'),
            rejectLabel: this.translateService.instant('ANNULER'),
            accept: () => {
                observer.next(true);
                observer.complete();
            },
            reject: () => {
                observer.next(false);
                observer.complete();
            }
        });
    });

  }

}
