import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Injectable } from '@angular/core';
import { QrContent, QrData } from '@data/schema/QrData';

@Injectable({
  providedIn: 'root'
})
export class QrDataFormaterService {

  constructor() { }

  public generateQrData(usage: string, content: QrContent ): string {
    let qrData = {} as QrData
    qrData.usage = usage
    qrData.content = content
    return JSON.stringify(qrData)
  }

}
