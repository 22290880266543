<div class="panel">
  <div class="p-grid p-d-flex p-ai-center">
    <div class="p-col-6">
      <span *ngIf="mode === 'advancement'"
        ><strong>Historique des avancements</strong></span
      >
      <span *ngIf="mode === 'voucher'"
        ><strong>Historique des bons de sortie</strong></span
      >
    </div>
    <div class="p-col-6 p-text-right">
      <p-selectButton
        [options]="displayOptions"
        [(ngModel)]="mode"
        optionValue="mode"
        optionLabel="name"
      >
        <ng-template let-item>
            <div class="p-10">
                {{ item.name }} <i class="p-pl-1 pi pi-list"></i>
            </div>

        </ng-template>
      </p-selectButton>
    </div>
  </div>

  <p-accordion *ngIf="mode === 'voucher'">
    <div *ngFor="let voucher of listVoucherforot">
      <p-accordionTab>
        <p-header class="customHeaderAccordian">
          <div class="p-grid">
            <div class="p-col-2">
              <img
                width="32px"
                height="32px"
                class="imageCheck"
                src="../../../../../assets/img/check-circle.svg"
              />
            </div>
            <div class="p-col-10">
              <div class="p-grid">
                <div class="p-col-12">
                  <div *ngIf="voucher?.voucherRef?.length" class="bonsdesortie">
                    <label>Bon de sortie ({{ voucher?.voucherRef }})</label
                    ><br />
                  </div>
                  <div
                    *ngIf="!voucher?.voucherRef?.length"
                    class="bonsdesortie"
                  >
                    <label>Bon de sortie </label><br />
                  </div>
                  <label class="dateBonsdesortie"
                    >{{ voucher?.voucherDate | date: "d MMMM y" }} à
                    {{ voucher?.voucherDate | date: "HH:mm" }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </p-header>
        <h4>Consommables</h4>
        <p-table
          #dt1
          (sortFunction)="naturalSort($event)"
          [customSort]="true"
          sortField="materialWork.materialDto.label"
          [sortOrder]='1'
          [value]="voucher?.voucherMaterialWorks"
          styleClass="p-datatable-striped"
          [globalFilterFields]="[
            'materialWork.materialDto.label',
            'materialWork.quantityMaterial',
            'quantity'
          ]"
        >


        <ng-template pTemplate="caption">
          <div class="p-d-flex p-jc-between p-m-3">
            <div></div>
            <div>
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  placeholder="Rechercher"
                />
              </span>
            </div>
          </div>
        </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="materialWork.materialDto.label">
                Nom
                <p-sortIcon field="materialWork.materialDto.label"></p-sortIcon>
              </th>
              <th
                class="table-40 p-text-right"
                pSortableColumn="materialWork.quantityMaterial"
              >
                Quantité totale
                <p-sortIcon field="materialWork.quantityMaterial"></p-sortIcon>
              </th>
              <th class="table-40 p-text-right" pSortableColumn="quantity">
                Quantité sortie
                <p-sortIcon field="quantity"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-workMaterialConsumable>
            <tr>
              <td>
                {{ workMaterialConsumable?.materialWork.materialDto.label }}
              </td>
              <td class="p-text-right">
                {{ workMaterialConsumable?.quantityTotal }}
              </td>
              <td class="p-text-right">
                {{ workMaterialConsumable?.quantity }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </div>
    <!--<p-accordionTab header="Avancement">
    </p-accordionTab>-->
  </p-accordion>

  <p-accordion *ngIf="mode === 'advancement'">
    <div *ngFor="let history of listHistoryProgress">
      <p-accordionTab>
        <p-header class="customHeaderAccordian">
          <div class="p-grid">
            <div class="p-col-2">
              <img
                width="32px"
                height="32px"
                class="imageCheck"
                src="../../../../../assets/img/check-circle.svg"
              />
            </div>
            <div class="p-col-10">
              <div class="p-grid">
                <div class="p-col-12">
                  <div *ngIf="history?.reference?.length" class="bonsdesortie">
                    <label>Avancement ({{ history.reference }})</label><br />
                  </div>
                  <div *ngIf="!history?.reference?.length" class="bonsdesortie">
                    <label>Avancement </label><br />
                  </div>
                  <label class="dateBonsdesortie"
                    >{{ history.created | date: "d MMMM y" }} à
                    {{ history.created | date: "HH:mm" }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </p-header>
        <p-table
          #dt2
          (sortFunction)="naturalSort($event)"
          [customSort]="true"
          sortField="workOrderProjectWork.projectWork.work.name"
          [sortOrder]='1'
          [value]="history.listWorkOrderHistoryProgress"
          styleClass="p-datatable-striped"
          [globalFilterFields]="[
            'workOrderProjectWork.projectWork.work.name',
            'quantityTotal',
            'quantityDone',
            'quantityAvailable'
          ]"
        >
        <ng-template pTemplate="caption">
          <div class="p-d-flex p-jc-between p-m-3">
            <div></div>
            <div>
              <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-left':'-20px'}">
                <i class="pi pi-search" [style]="{'margin-left':'10%','height': '45px','margin-top':'5px'}"></i>
                <input
                [style]="{'margin-left':'10%','height': '45px'}"
                  pInputText
                  type="text"
                  (input)="dt2.filterGlobal($event.target.value, 'contains')"
                  placeholder="Rechercher"
                />
            </span>
            </div>
          </div>
        </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="workOrderProjectWork.projectWork.work.name">
                Prestations
                <p-sortIcon
                  field="workOrderProjectWork.projectWork.work.name"
                ></p-sortIcon>
              </th>
              <th class="table-20 p-text-right" pSortableColumn="quantityTotal">
                A réaliser
                <p-sortIcon field="quantityTotal"></p-sortIcon>
              </th>
              <th class="table-20 p-text-right" pSortableColumn="quantityDone">
                Réalisées
                <p-sortIcon field="quantityDone"></p-sortIcon>
              </th>
              <th
                class="table-20 p-text-right"
                pSortableColumn="quantityAvailable"
              >
                Restantes
                <p-sortIcon field="quantityAvailable"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-wopw>
            <tr>
              <td>{{ wopw.workOrderProjectWork.projectWork.work.name }}</td>
              <td class="p-text-right">{{ wopw.quantityTotal }}</td>
              <td class="p-text-right">{{ wopw.quantityDone }}</td>
              <td class="p-text-right">{{ wopw.quantityAvailable }}</td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </div>
    <!--<p-accordionTab header="Avancement">
    </p-accordionTab>-->
  </p-accordion>
</div>
