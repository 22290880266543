import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class ExportDataService {

  constructor() { }


    
  public exportAsExcelFile(json: any[], excelFileName: string, title?: string): void {
    // Créer une nouvelle feuille de calcul
    let worksheet: XLSX.WorkSheet;
    // Si un titre est fourni, ajouter le titre en ligne 1
    if (title) {
      const header = Object.keys(json[0]);
      worksheet = XLSX.utils.json_to_sheet([{ [title]: null }], { header: [title] });
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: 0 });
      // Appliquer le style à la cellule A1
      worksheet[cellRef].s = {
        font: {
          bold: true,
        },
        alignment: {
          horizontal: 'center',
        },
      };
  
      // Fusionner les cellules en fonction du nombre de colonnes dans l'en-tête
      const mergeRange = {
        s: { r: 0, c: 0 },
        e: { r: 0, c: header.length - 1 },
      };
      worksheet['!merges'] = [mergeRange];
    } else {
      // Si pas de titre, créer une feuille de calcul sans données
      worksheet = XLSX.utils.book_new().Sheets['Sheet1'];
    }
  
    // Ajouter les données à partir de la ligne suivante
    const startRow = title ? 2 : 1;
    const defaultCellValue = '';  // Valeur par défaut pour les cellules vides
  
    for (const rowData of json) {
      for (const key of Object.keys(rowData)) {
        if (rowData[key] === undefined || rowData[key] === null) {
          rowData[key] = defaultCellValue;
        }
      }
    }
  
    worksheet = XLSX.utils.sheet_add_json(worksheet, json, { header: [], skipHeader: false, origin: `A${startRow}` });
    
      const header = Object.keys(json[0]);
      let rangeHeader;
      if(startRow === 2){
        rangeHeader = {
          s: { r: 1, c: 0 },
          e: { r: 1, c: header.length - 1 },
        };
      } else {
        rangeHeader = {
          s: { r: 0, c: 0 },
          e: { r: 0, c: header.length - 1 },
        };
      }
     
      for (let C = rangeHeader.s.c; C <= rangeHeader.e.c; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: rangeHeader.s.r, c: C });
        if (worksheet[cellRef]) {
          if (!worksheet[cellRef].s) {
            worksheet[cellRef].s = {};
          }
          worksheet[cellRef].s.fill = {
            patternType: 'solid',
            fgColor: { rgb: 'b2b2b2' },
            bgColor: { rgb: 'b2b2b2' },
          };
        }
      } 
    // Appliquer le style avec des bordures à toutes les cellules
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (worksheet[cellRef]) {
          if (!worksheet[cellRef].s) {
            worksheet[cellRef].s = {};
          }
          worksheet[cellRef].s.border = {
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
          };
        }
      }
    }
    // Convertir le classeur en tableau Excel (xlsx)
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // Enregistrer le fichier Excel
    this.saveAsExcelFile(excelBuffer, (title) ? title : excelFileName);
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; 
    const year = currentDate.getFullYear();
    const formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
    const fileNameWithFormatted = fileName.replace(/\s+/g, '-');
    FileSaver.saveAs(data, fileNameWithFormatted + '_export_' + formattedDate + EXCEL_EXTENSION);
   }
}
