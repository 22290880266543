<div *ngIf="mode === 'consultation'">


  <div class="p-field p-grid">
    <h2 class="p-col-12 p-mb-2 p-md-2 p-mb-md-0 title">Contrat</h2>

    <div *ngIf="contracts?.status?.code === statusContract.INACTIF" class="p-col-12 p-md-10 p-pl-5 p-pr-5 ">
      <span class="statut-Inactif"> {{contracts?.status?.label}} </span>
    </div>

    <div *ngIf="contracts?.status?.code === statusContract.EN_VIGUEUR" class="p-col-12 p-md-10 p-pl-5 p-pr-5 ">
      <span class="statut-en_vigueur"> {{contracts?.status?.label}} </span>
    </div>

    <div *ngIf="contracts?.status?.code === statusContract.TERMINE" class="p-col-12 p-md-10 p-pl-5 p-pr-5 ">
      <span class="status-termine"> {{contracts?.status?.label}} </span>
    </div>
  </div>


  <div class="p-field p-grid">
    <label for="nom" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
      Nom
    </label>
    <div class="p-col-12 p-md-10 p-pl-5 p-pr-5 breakWord">
      <p>{{contracts?.contractName}}</p>
    </div>
  </div>
  <div class="p-field p-grid">
    <label for="description" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
      Description
    </label>
    <div class="p-col-12 p-md-10 p-pl-5 p-pr-5 breakWord">
      <p>{{contracts?.description}}</p>
    </div>
  </div>
  <div class="p-field p-grid">
    <div class="p-col-6">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label for="date" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
            {{'ENTREE_VIGUEUR'|translate}}
          </label>
          <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
            <p>{{contracts?.effectiveDate | date: "dd/MM/yyyy"}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-6">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label for="date" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
            {{'FIN_VALIDITE'|translate}}
          </label>
          <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
            <p>{{contracts?.expiryDate | date: "dd/MM/yyyy"}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------------------------------------------------------>
<div *ngIf="mode !== 'consultation' && mode !== 'update' ">


  <div [formGroup]="contractForm" class="p-grid">
    <div class="p-col-12">
      <h2>Contrat</h2>
    </div>


    <div class="p-grid p-col-12 p-d-flex">
      <div class="p-col-12">
        <div class="p-fluid">
          <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12">
              <label>Nom*</label>
              <p-autoComplete formControlName="contractName" [suggestions]="labelsContractOutputs"
                              (completeMethod)="search($event)" (onBlur)="sendErr()"
                              [inputStyle]="{'width':'100%'}"
                              class="contratClassForm"></p-autoComplete>
              <p-message *ngIf="contractForm?.controls?.contractName?.invalid && isSubmit === true"
                         text="Champ obligatoire"></p-message>
              <div
                *ngIf="contractForm?.controls?.contractName?.invalid && (contractForm?.controls?.contractName?.dirty || contractForm?.controls?.contractName?.touched)"
                class="alert">
                <p-message *ngIf="contractForm?.controls?.contractName?.errors?.required" severity="error"
                           class="contratNameError"
                           text="Champ obligatoire"></p-message>
                <p-message *ngIf="contractForm?.controls?.contractName?.errors?.forbiddenName" severity="error"
                           class="contratNameError"
                           text="Nom déja existant"></p-message>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="p-grid p-col-12 p-d-flex">
      <div class="p-col-12">
        <div class="p-fluid">
          <div class="p-grid">
            <label class="descriptionLabel">Description</label>
            <div class="p-col-12 p-md-12 p-lg-12">

              <textarea [rows]="4" class="textAreaDescription" formControlName="description" pInputTextarea></textarea>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="p-grid p-col-12 p-d-flex">
      <div class="p-col-6">
        <div class="p-fluid">
          <div class="p-grid">
            <div class="p-col-12">
              <label>{{'ENTREE_VIGUEUR'|translate}}</label><br>
              <div class="p-inputgroup custom-group-calendar">
                <span class="p-inputgroup-addon custom-group"><i class="pi pi-calendar p-mr-2"></i> Date</span>
                <p-calendar formControlName="effectiveDate" [minDate]="startDate"
                            (onSelect)="isContractDataValidByDateForSaveTZ($event)"
                            (onClickOutside)="isEffectiveDateValidForSave()"
                            dateFormat="dd/mm/yy" appendTo="body" [showTime]="false" inputId="time"></p-calendar>
              </div>
              <div>
                <p-message *ngIf="startdateInvalid" severity="error"
                           class="dateInvalid"
                           text="Date non valide"></p-message>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-fluid">
          <div class="p-grid">
            <div class="p-col-12">
              <label>{{'FIN_VALIDITE'|translate}}</label><br>
              <div class="p-inputgroup custom-group-calendar">
                <span class="p-inputgroup-addon custom-group"><i class="pi pi-calendar p-mr-2"></i> Date</span>
                <!--<p-calendar formControlName="expiryDate" [minDate]="contractForm.controls.effectiveDate.value === null ? controlStartDate() : controleEndDate()"  (onSelect)="isContractDataValidByDateForSave(event)"-->
                <p-calendar formControlName="expiryDate" [minDate]="endDate"
                            (onSelect)="isContractDataValidByDateForSaveTZ($event)" 
                            (onClickOutside)="isEndDateValidForSave()"
                            dateFormat="dd/mm/yy" class="custom-calendar" appendTo="body" [showTime]="false"
                            inputId="time"></p-calendar>
              </div>
              <div>
                <p-message *ngIf="enddateInvalid" severity="error"
                           class="dateInvalid"
                           text="Date non valide"></p-message>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!------------------------------------------------------------------------------------------------->
<div *ngIf="contractForm && mode === 'update'">

  <div [formGroup]="contractForm">


      <div class="p-field p-grid">
        <h2 class="p-col-12 p-mb-2 p-md-2 p-mb-md-0 title">Contrat</h2>

        <div *ngIf="contracts?.status?.code === statusContract.INACTIF" class="p-col-12 p-md-10 p-pl-5 p-pr-5 ">
          <span class="statut-Inactif"> {{contracts?.status?.label}} </span>
        </div>

        <div *ngIf="contracts?.status?.code === statusContract.EN_VIGUEUR" class="p-col-12 p-md-10 p-pl-5 p-pr-5 ">
          <span class="statut-en_vigueur"> {{contracts?.status?.label}} </span>
        </div>

        <div *ngIf="contracts?.status?.code === statusContract.TERMINE" class="p-col-12 p-md-10 p-pl-5 p-pr-5 ">
          <span class="status-termine"> {{contracts?.status?.label}} </span>
        </div>
      </div>

    <div class="p-grid">
      <div class="p-grid p-col-12 p-d-flex">
        <div class="p-col-12">
          <div class="p-fluid">
            <div class="p-grid">
              <div class="p-col-12 p-md-12 p-lg-12">
                <label for="nom">Nom*</label>
                <p-autoComplete formControlName="contractName" (onSelect)="filterContractsNamesForUpdate()"
                                (onKeyUp)="filterContractsNamesForUpdate()" [suggestions]="labelsContractOutputs"
                                (completeMethod)="search($event)" class="contratClassForm"
                                (onBlur)="filterContractsNamesForUpdate()"></p-autoComplete>
                <p-message *ngIf="contractForm?.controls?.contractName?.invalid && isSubmit === true" class="contratNameError"
                           text="Champ obligatoire"></p-message>
                <div
                  *ngIf="contractForm?.controls?.contractName?.invalid && (contractForm?.controls?.contractName?.dirty || contractForm?.controls?.contractName?.touched)"
                  class="alert">
                  <p-message *ngIf="contractForm?.controls?.contractName?.errors?.required" severity="error"
                             class="contratNameError"
                             text="Champ obligatoire"></p-message>
                  <p-message *ngIf="contractForm?.controls?.contractName?.errors?.forbiddenName" severity="error"
                             class="contratNameError"
                             text="Nom déja existant"></p-message>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="p-grid p-col-12 p-d-flex">
        <div class="p-col-12">
          <div class="p-fluid">
            <div class="p-grid">
              <label class="descriptionLabel">Description</label>
              <div class="p-col-12 p-md-12 p-lg-12">

                <textarea [rows]="4" class="textAreaDescription" formControlName="description" pInputTextarea></textarea>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="p-grid p-col-12 p-d-flex">
        <div class="p-col-6">
          <div class="p-fluid">
            <div class="p-grid">
              <div class="p-col-12">
                <label>{{'ENTREE_VIGUEUR'|translate}}</label><br>
                <div *ngIf="contractForm?.value.status?.code === statusContract.EN_VIGUEUR"
                     class="p-inputgroup custom-group-calendar">
                  <span class="p-inputgroup-addon custom-group"><i class="pi pi-calendar p-mr-2"></i> Date</span>
                  <p-calendar dateFormat="dd/mm/yy" formControlName="effectiveDate" appendTo="body" [showTime]="false"
                              inputId="time" [disabled]="true"></p-calendar>

                </div>
                <div *ngIf="contractForm?.value.status?.code === statusContract.INACTIF"
                     class="p-inputgroup custom-group-calendar">
                  <span class="p-inputgroup-addon custom-group"><i class="pi pi-calendar p-mr-2"></i> Date</span>
                  <p-calendar dateFormat="dd/mm/yy" formControlName="effectiveDate" appendTo="body" [showTime]="false"
                              inputId="time" (onSelect)="isContractDataValidByDateForUpdateTZ($event)"
                              (onClickOutside)="isEffectiveDateValidForUpdate()"
                              (onClose)="isEffectiveDateValidForUpdate()"
                  ></p-calendar>

                </div>
                <div>
                  <p-message *ngIf="startdateInvalid" severity="error"
                             class="dateInvalid"
                             text="Date non valide"></p-message>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="p-col-6">
          <div class="p-fluid">
            <div class="p-grid">
              <div class="p-col-12">
                <label>{{'FIN_VALIDITE'|translate}}</label><br>
                <div *ngIf="contractForm?.value.status?.code === statusContract.INACTIF"
                     class="p-inputgroup custom-group-calendar">
                  <span class="p-inputgroup-addon custom-group"><i class="pi pi-calendar p-mr-2"></i> Date</span>
                  <p-calendar dateFormat="dd/mm/yy" formControlName="expiryDate" [minDate]="endDate"
                              (onSelect)="isContractDataValidByDateForUpdateTZ($event)"
                              (onClickOutside)="isEndDateValidForUpdate()" (onClose)="isEndDateValidForUpdate()"
                              appendTo="body"
                              [showTime]="false" inputId="time"></p-calendar>
                </div>
                <div *ngIf="contractForm?.value.status?.code === statusContract.EN_VIGUEUR"
                     class="p-inputgroup custom-group-calendar">
                  <span class="p-inputgroup-addon custom-group"><i class="pi pi-calendar p-mr-2"></i> Date</span>
                  <p-calendar dateFormat="dd/mm/yy" formControlName="expiryDate" [minDate]="endDateForActiveCOntract"
                              (onSelect)="isContractDataValidByDateForUpdateTZ($event)" appendTo="body" [showTime]="false"
                              inputId="time"></p-calendar>
                </div>
                <div>
                  <p-message *ngIf="enddateInvalid" severity="error"
                             class="dateInvalid"
                             text="Date non valide"></p-message>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
