import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormGroup, Validators} from "@angular/forms";
import {Materiel} from "@data/schema/Materiel";
import {MaterialWork} from '@data/schema/Work';
import {MaterialService} from "@data/service/material.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {WorkService} from "@data/service/work.service";
import {WorkOrderService} from '@data/service/work-order.service';

@Component({
  selector: 'app-work-new-voucher-add-materials',
  templateUrl: './work-new-voucher-add-materials.component.html',
  styleUrls: ['./work-new-voucher-add-materials.component.css']
})
export class WorkNewVoucherAddMaterialsComponent implements OnInit {

  @Input() formG: FormGroup;
  @Input() selectedMateriel: any;
  @Input() listWorkMaterialConsumable: MaterialWork[] = []
  @Input() editItem: any;
  @Input() edit: boolean = false;
  @Input() maxInput: number;
  @Output() sendMaterial = new EventEmitter<any>();
  @Input() articleSelectedForEdit;

  errorDuplication: boolean = false;
  displayError: boolean = false;
  listConsumable: Materiel[];
  listConsumableLabel: string[];
  unitLabels: MaterialWork[];

  listTools: Materiel[];
  listMaterial: Materiel[];

  constructor(public materialService: MaterialService,
              public messageService: MessageService,
              public workService: WorkService,
              private workOrderService: WorkOrderService,
              private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {

  }

  ngOnChanges() {
    if (Object.keys(this.editItem).length !== 0) {
      if (this.editItem.delete) {
        this.listWorkMaterialConsumable.push(this.editItem.cons)
      }
      /*
      if (this.editItem.maxInput) {
        this.maxInput = this.editItem.maxInput;
        this.voucherControls.at(0).get('quantity').setValidators([Validators.required,Validators.max(this.maxInput)]);
        this.voucherControls.at(0).get('quantity').updateValueAndValidity();
        console.log(this.voucherControls.at(0).get('quantity').validator)
      }
       */
    }
  }

  change(event: any) {
    if (event.value === null) {
      this.voucherControls.at(0).get('materialWork').patchValue({id: null})
      this.voucherControls.at(0).patchValue({quantity: 1})
      //this.maxInput = 1;

    } else {
      this.voucherControls.at(0).get('materialWork').patchValue({id: event.value.id})
      /*this.maxInput = event.value.quantityMaterial;
      this.voucherControls.at(0).get('quantity').setValidators([Validators.required,Validators.max(this.maxInput)]);
       */
      this.voucherControls.at(0).get('quantity').updateValueAndValidity();
      this.voucherControls.at(0).patchValue({quantityTotal: event.value.quantityMaterial});
      this.voucherControls.at(0).get('quantityTotal').updateValueAndValidity();
    }
    this.selectedMateriel = event.value;
  }

  get voucherFormControls() {
    return this.formG.controls;
  }

  get voucherControls() {
    return this.voucherFormControls.voucherMaterialWorks as FormArray;
  }

  addVoucher() {
    if (this.formG.invalid) {
      this.displayError = true;
      return;
    } else {
      this.displayError = false;
      this.listWorkMaterialConsumable = this.listWorkMaterialConsumable.filter(x => x !== this.selectedMateriel);
      this.sendMaterial.emit({
        cons: this.selectedMateriel,
        formG: this.formG.value.voucherMaterialWorks,
        edit: this.edit,
        editMode: false
      });
      this.voucherControls.reset();
      this.voucherControls.at(0).patchValue({quantity: 1})
      this.formG.patchValue({conso: null});
      this.messageService.add({
        severity: 'info',
        summary: 'Vous avez ajouté un matériel dans votre liste',
        detail: "N'oubliez pas de confirmer votre liste"
      });
      this.formG.get('conso').clearValidators();
      this.formG.get('conso').setValidators([]);
      this.formG.get('conso').updateValueAndValidity();
    }
  }

  editVoucher() {
    if (this.formG.invalid) {
      this.displayError = true;
      return;
    } else {
      this.sendMaterial.emit({
        editMode: true,
        cons: this.formG.value.conso,
        formG: this.formG.value.voucherMaterialWorks,
        edit: true
      });
      this.voucherControls.reset();
      this.voucherControls.at(0).patchValue({'quantity': 1});
      this.voucherControls.at(0).patchValue({'quantityTotal': 1});
      this.editItem = {};
      this.formG.patchValue({conso: null});
      this.messageService.add({
        severity: 'info',
        summary: 'Vous avez ajouté un matériel dans votre liste',
        detail: "N'oubliez pas de confirmer votre liste"
      });
    }
  }
}
