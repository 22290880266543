import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, InputNumber, InputText, Select, SelectWithQuantity, Photo } from '@data/schema/intervention/Form';

@Pipe({
  name: 'isPhoto'
})
export class IsPhotoPipe implements PipeTransform {

  transform(controlsType : Select | SelectWithQuantity | InputNumber | InputText | DateTime |Photo): controlsType is Photo {
    return controlsType?.code === "photo";
  }

}
