import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from "@core/service/login.service";
import {MessageService} from "primeng/api";
import {TokenStorageService} from "@core/service/token-storage.service";

@Injectable({
  providedIn: 'root'
})

export class GuardianGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  private roles: string;

  constructor(private loginService: LoginService, private tokenStorageService: TokenStorageService, private router: Router, private messageService: MessageService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.tokenStorageService.isTokenExpired()) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Session Expirée',
        detail: 'Veuillez vous reconnecter s\'il vous plait'
      });
      this.router.navigate(['']);
      return false;
    }
    if (this.loginService.isLogin()) {
      return true;
    } else {
      this.messageService.add({severity: 'info', summary: 'Connectez-vous', detail: ''});
      this.router.navigate(['']);
      return false;
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.loginService.isLogin()) {
      return true;
    } else {
      this.router.navigate(['']);
    }
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRole = {role: this.tokenStorageService.getRole()}
    console.log(route.data);
    return true;
  }
}
