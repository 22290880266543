import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Contracts} from "@data/schema/Contracts";
import {ContractService} from "@data/service/contract.service";
import {forbiddenDuplicationNameValidator} from "../../../../directives/duplication.directive";
import {ConfirmationService, MessageService} from "primeng/api";
import {ContractCustomResponse} from "@data/schema/ContractCustomResponse";
import {StatusEnumContract} from "@data/schema/StatusEnumContract";

@Component({
  selector: 'app-contract-form-general',
  templateUrl: './contract-form-general.component.html',
  styleUrls: ['./contract-form-general.component.css']
})
export class ContractFormGeneralComponent implements OnInit, OnChanges {

  @Input() contractForm: FormGroup;
  @Input() mode: String;
  @Input() contracts: Contracts;
  @Input() isSubmit: boolean;
  @Input() prestationsFrom;
  @Input() invalidDatesOnCompanyChanged: boolean;
  results: Contracts[];
  labelsContract: string[] = [];
  labelsContractOutputs: string[] = [];
  errorNameRequired: boolean;
  contractCustomResponse: ContractCustomResponse;
  startdateInvalid: boolean = false;
  enddateInvalid: boolean = false;
  startDate: Date = new Date();
  endDate: Date = new Date();
  statusContract = StatusEnumContract;
  contractToUpdate: FormGroup;
  endDateForActiveCOntract: Date = new Date();


  goodValue: string;
  @Input() contractIsAttachedToWorkOrder: boolean;

  constructor(private contractService: ContractService,
              private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.contractService.ListAllContract().subscribe(
      res => {
        if (res.status === 200) {
          for (let c of res.body) {
            this.labelsContract.push(c.contractName);
          }
          if (this.mode !== "consultation" && this.mode !== "update") {
            this.contractForm.controls["contractName"].addValidators(forbiddenDuplicationNameValidator(this.labelsContract))
            this.contractForm.controls["contractName"].addValidators(this.forbiddenEmptyField())
          }


        }
      }, error => {

      }, () => {

      }
    )

    //start date
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() + 1);

    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate() + 2);
  }



  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const changedProp = changes[propName];
      if (propName === "contracts" && changedProp.currentValue) {
        this.goodValue = changedProp.currentValue.contractName
      }
      if(propName === 'invalidDatesOnCompanyChanged'  ){
        this.enddateInvalid = this.invalidDatesOnCompanyChanged;
        this.startdateInvalid = this.invalidDatesOnCompanyChanged;
        this.contractForm.controls["expiryDate"].addValidators(this.dateInvalid());
        this.contractForm.controls["effectiveDate"].addValidators(this.dateInvalid());

      }

    }
  }

  search(event) {
    this.labelsContractOutputs = this.labelsContract.filter(c => c.toLowerCase().startsWith(event.query.toLowerCase()));
  }

  sendErr() {

  }

  filterContractsNamesForUpdate() {
    let filteredForbiddenContracts = this.labelsContract.filter(x => x !== this.goodValue);
    this.contractForm.controls["contractName"].addValidators(forbiddenDuplicationNameValidator(filteredForbiddenContracts));
    this.contractForm.controls["contractName"].addValidators(this.forbiddenEmptyField());

  }

  forbiddenEmptyField(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let forbidden = false;
      if (control.value.trim() === "") {
        forbidden = true;
      }
      return forbidden ? {required: {value: control.value}} : null;
    };
  }

  dateInvalid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      return this.invalidDatesOnCompanyChanged ? {required: {value: control.value}} : null;
    };
  }



  isContractDataValidByDateForSaveTZ(event) {
    this.addTimeZoneOffsetToCalendarTime(event);
    this.isContractDataValidByDateForSave();
    
  }
  isContractDataValidByDateForSave(){
    if (this.contractForm.controls.effectiveDate.value === null || this.contractForm.controls.expiryDate.value === null) {
      return;
    }
    

    this.contractService.isContractDataValidByDateForSave(this.contractForm).subscribe(
      res => {
        if (res.status === 200)
        this.contractCustomResponse = res.body;
        this.getMessageError(this.contractCustomResponse);
      }, error => {
      }
    )
  }

  isContractDataValidByDateForUpdateTZ(event) {
    this.addTimeZoneOffsetToCalendarTime(event);
    this.isContractDataValidByDateForUpdate();
    
  }

  isContractDataValidByDateForUpdate(){
    this.combinePrestationBeforeAndAfterUpdate();
    this.contractService.isContractDataValidByDateForUpdate(this.contractToUpdate).subscribe(
      res => {
        if (res.status === 200)
        this.contractCustomResponse = res.body;
        this.getMessageError(this.contractCustomResponse);
      }, error => {
      }
    )
  }


  getMessageError(contractCustomResponse: ContractCustomResponse) {
    this.startdateInvalid = false;
    this.enddateInvalid = false;

    if (contractCustomResponse.code === 605) {
      this.confirmationService.confirm({
        header: 'Action impossible',
        message:
          'Un autre contrat contenant une ou plusieurs des prestations sélectionnées sera en vigueur aux mêmes dates : ' + contractCustomResponse.contract.contractName.link('phoenix/show-contract/'
          + contractCustomResponse.contract.contractId) + '.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
      });
      this.startdateInvalid = true;
      this.enddateInvalid = true;
    } else if (contractCustomResponse.code === 606) {
      this.confirmationService.confirm({
        header: 'Action impossible',
        message:
          'Un contrat en vigueur aux mêmes dates contient déjà une ou plusieurs des prestations sélectionnées : ' + contractCustomResponse.contract.contractName.link('phoenix/show-contract/'
          + contractCustomResponse.contract.contractId) + '.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
      });
      this.startdateInvalid = true;
      this.enddateInvalid = true;
    } else if (contractCustomResponse.code === 608) {
      this.startdateInvalid = true;
    } else if (contractCustomResponse.code === 609) {
      this.enddateInvalid = true;
    }
  }

  get contractFormControls() {
    return this.contractForm.controls;
  }

  get prestationControls() {
    return this.contractFormControls.contractWorks as FormArray;
  }

  get prestationFormControls() {
    return this.prestationsFrom.controls;
  }

  get prestationsBeforeUpdate() {
    return this.prestationFormControls.contractWorks as FormArray;
  }

  get contractToUpdateContractWorks() {
    return this.contractToUpdate.controls.contractWorks as FormArray;
  }

  combinePrestationBeforeAndAfterUpdate() {

    this.contractToUpdate = new FormGroup({
      contractId: new FormControl(this.contracts.contractId),
      contractName: new FormControl(this.contractForm.value.contractName, Validators.required),
      description: new FormControl(this.contractForm.value.description),
      creationDate: new FormControl(this.contractForm.value.creationDate),
      effectiveDate: new FormControl(this.contractForm.value.effectiveDate),
      expiryDate: new FormControl(this.contractForm.value.expiryDate),
      status: new FormControl(this.contractForm.value.status),
      currency: new FormControl(this.contractForm.value.currency, Validators.required),
      company: new FormControl(this.contracts.company, Validators.required),
      contractWorks: new FormArray([])

    })

    for (let prestaBeforeUpdate of this.prestationsBeforeUpdate.controls) {
      this.contractToUpdateContractWorks.push(prestaBeforeUpdate);
    }
    for (let prestaAfterUpdate of this.prestationControls.controls) {
      this.contractToUpdateContractWorks.push(prestaAfterUpdate);
    }



  }

  addTimeZoneOffsetToCalendarTime(event) {
    /*To be removed when primeng fixes the bug related to p calendar or when migrating to another library*/
    event.setTime(event.getTime() - (new Date().getTimezoneOffset() * 60 * 1000));
    /**/
  }


  isEffectiveDateValidForSave() {
    this.isContractDataValidByDateForSaveTZ(this.contractForm.controls.effectiveDate.value)
  }
  isEffectiveDateValidForUpdate() {
    this.isContractDataValidByDateForUpdateTZ(this.contractForm.controls.effectiveDate.value)
  }

  isEndDateValidForSave(){
    
    this.isContractDataValidByDateForSaveTZ(this.contractForm.controls.expiryDate.value)
  }
  isEndDateValidForUpdate(){
    this.isContractDataValidByDateForUpdateTZ(this.contractForm.controls.expiryDate.value)
  }
}
