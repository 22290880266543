import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Work } from '@data/schema/Work';
import { CurrencyService } from '@data/service/currency.service';
@Component({
  selector: 'app-work-show-cost',
  templateUrl: './work-show-cost.component.html',
  styleUrls: ['./work-show-cost.component.css']
})
export class WorkShowCostComponent implements OnInit {
  @Input() work: Work;
  @Output() workChange = new EventEmitter();
  change() {
    this.workChange.emit(this.work);
  }
  constructor() { }

  ngOnInit(): void {
  }

}
