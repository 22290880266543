<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-ai-center vertical-container p-jc-center">

    <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">

        <div class="p-grid p-jc-between p-p-3">
        <h2> {{ 'INTERVENANTS'| translate }} </h2>
        <!-- <button *ifRoles='["administrateur", "admin_metier","admin_responsable_DPO", "manager_CA","manager_operationnel"]' class="p-mr-5 p-mt-5" pButton routerLink="/phoenix/new-technician" type="button" label="Créer"></button> -->
        </div>
        <p-table #dt1 [value]="personaIntervenant"
                  (sortFunction)="naturalSortTechnician($event)"
                  [customSort]="true"
                  dataKey="lastName"
                 sortField="lastName"
                 [sortOrder]="1"
                 [responsive]="true"
                 class="table"
                 [scrollable]="true"
                 scrollHeight="40vh"
                 [paginator]="true"
                 [rows]="10"
                 styleClass="p-datatable-sm p-datatable-striped"
                 [showCurrentPageReport]="true"
                 currentPageReportTemplate="{{'PAGINATION'|translate}}"
                 [rowsPerPageOptions]="[10, 25, 50]" [globalFilterFields]="[
                  'firstName',
                  'lastName',
                  'personalNumber',
                   'mail',
                  'publicName'
                ]">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                     placeholder="{{ 'RECHERCHER' | translate }}"/>
            </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th id="lastName" pSortableColumn="lastName" >
             {{ 'NOM_FAMILLE' | translate }}
                <p-sortIcon field="lastName"></p-sortIcon>
              </th>
              <th id="firstName" pSortableColumn="firstName">
                {{ "PRENOM" | translate }}
                <p-sortIcon field="firstName"></p-sortIcon>
              </th>
              <th id="publicName" pSortableColumn="publicName">
                {{ 'NOM_PUBLIC' | translate }}
                <p-sortIcon field="publicName"></p-sortIcon>
              </th>
              <th id="mail" pSortableColumn="mail">
                Email
                <p-sortIcon field="mail"></p-sortIcon>
              </th>
              <th id="personalNumber" pSortableColumn="personalNumber">
                {{ 'NUM_PERSONEL'| translate }}
                <p-sortIcon field="personalNumber"></p-sortIcon>
              </th>
              <th id="actions" colspan="2" class="p-text-center table-10"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-personaIntervenant let-ri="rowIndex" let-columns>
            <tr>
              <td>{{ personaIntervenant?.lastName }}</td>
              <td>{{ personaIntervenant?.firstName}}</td>
              <td>{{ personaIntervenant?.publicName}}</td>
              <td>{{ personaIntervenant?.mail }}</td>
              <td>{{ personaIntervenant?.personalNumber }}</td>
              <td class="p-text-center table-5">
                <button
                  routerLink="/phoenix/show-technician/{{personaIntervenant.idPersona }}"
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-search"
                  class="p-button-rounded p-button-text p-button-plain"
                ></button>
              </td>
              <td class="p-text-center table-5">
                <button *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_CA"]' pButton pRipple type="button" icon="pi pi-ellipsis-h"
                        (click)="getRowIndex(personaIntervenant.idPersona, personaIntervenant.id, personaIntervenant.idParty);op.toggle($event)"
                        class="p-button-rounded p-button-text p-button-plain"></button>
              </td><!-- + d'actions -->
            </tr>
          </ng-template>
        </p-table>
        <p-overlayPanel #op appendTo="body" baseZIndex="2">
          <ng-template pTemplate>
            <div #actualTarget>
              <button *ifRoles='["administrateur", "admin_metier","admin_responsable_DPO" , "manager_operationnel" , "manager_CA"]' pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
                      [style]="{'margin-right': '5px'}" label="Modifier" (click)="update()"></button>
              <br>
              <button *ifRoles='["administrateur", "admin_metier","admin_responsable_DPO"]' pButton pRipple type="button" icon="pi pi-trash" (click)="technicianHasOtherProfiles()"
                      class="p-button-rounded p-button-text" label="Supprimer"></button>
            </div>
          </ng-template>
        </p-overlayPanel>
        <p-confirmDialog baseZIndex=5 styleClass="main-page" key="noStock" appendTo="body" [style]="{width: '60%'}"></p-confirmDialog>
        <p-confirmDialog baseZIndex=5 styleClass="main-page" key="deleteTech" appendTo="body" [style]="{width: '60%'}"></p-confirmDialog>
        <p-confirmDialog baseZIndex=5 styleClass="main-page" key="partOfTeam" appendTo="body" [style]="{width: '60%'}"></p-confirmDialog>

      </div>
    </div>
  </div>

