<div class="p-col-12">
  <!--  SELECT THIS DIV IF ONE COLUMN FORM AND DELETE THE ONE ABOVE
  <div class="p-col-12 p-d-flex p-jc-center">-->
  <h3>{{'menu_PRESTATIONS'|translate}}</h3>
</div>
<div class="p-grid p-col-12 p-d-flex">
  <div class="p-col-6">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="name" class="p-mb-2 p-md-2 p-mb-md-0">
            {{'NOM'|translate}}
          </label>
        </div>
        <div class="p-col-10">
          <p-autoComplete
              id="name"
              [(ngModel)]="work.name"
              [suggestions]="outputName"
              (completeMethod)="filterName($event)"
              (onKeyUp)="nameFieldValidator()"
              (ngModelChange)="change()"
              [minLength]="1"
            >
            </p-autoComplete>
          <p-message
            *ngIf="errorNameRequired"
            severity="error"
            text="Champ obligatoire."
          ></p-message>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-10">
          <label for="description" class="p-mb-2 p-md-2 p-mb-md-0">
            {{'DESCRIPTION'|translate}}
          </label>
        </div>
        <div class="p-col-10">
          <textarea [(ngModel)]="work.description" (ngModelChange)="change()" [rows]="4" pInputTextarea> </textarea>
        </div>
      </div>
    </div>
  </div>
  <!-- DELETE THIS DIV TO CENTER THE FIRST COLUMN--->
  <!-- <div class="p-col-6">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="type" class="p-mb-2 p-md-2 p-mb-md-0">
            Type de prestation
          </label>
        </div>
        <div class="p-col-10">
          <p-dropdown
            id="type"
            placeholder="Selection"
            optionLabel="name"
            optionValue="value"
          ></p-dropdown>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-10">
          <label for="zone" class="p-mb-2 p-md-2 p-mb-md-0"> Zone </label>
        </div>
        <div class="p-col-10">
          <p-dropdown
            id="zone"
            placeholder="Selection"
            optionLabel="name"
            optionValue="value"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div> -->
</div>
