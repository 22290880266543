import { Pipe, PipeTransform } from '@angular/core';
import { ControlsType, InputLongText } from '@data/schema/intervention/Form';

@Pipe({
  name: 'isInputLongText'
})
export class IsInputLongTextPipe implements PipeTransform {

  transform(controlsType : ControlsType): controlsType is InputLongText {
    return controlsType?.code === "inputLongText";
  }

}
