export class Company {

  companyId: number;
  partyId: number;
  enabled: boolean;
  name: string;
  type: string;
  siret: string;
  email: string;
  phone: string;
  address:string;
  manager:string;
  logo:string;


  constructor(company?: Partial<Company>) {

    if (company){

      Object.assign(this, company)


    }

    else{
        this.enabled = false
        this.name = "";
        this.type = "";
        this.siret = "";
        this.email = "";
        this.phone = "";
        this.address = "";
        this.manager = "";
        this.logo = "";
    }
  }


  public isDefined(){

    return (
      this.enabled ||
      this.name ||
      this.type ||
      this.siret ||
      this.email ||
      this.phone ||
      this.address ||
      this.manager ||
      this.logo

    )

  }

}
