<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-4">
    <div class="p-col-12">
      <!--  SELECT THIS DIV IF ONE COLUMN FORM AND DELETE THE ONE ABOVE
        <div class="p-col-12 p-d-flex p-jc-center">-->
      <h2>
        {{ 'FICHE_DE' | translate }}   {{ persona?.firstName }} {{ persona?.lastName }} /
        <span *ngIf="persona?.firstName"
          >{{ persona?.personalNumber }}
          <p-tag
            *ngIf="persona.enable"
            styleClass="p-mr-2"
            severity="success"
            value="{{ 'ACTIF' | translate }}"
          ></p-tag
          ><p-tag
            *ngIf="persona && !persona.enable"
            styleClass="p-mr-2"
            severity="danger"
            value="{{ 'INACTIF' | translate }}"
          ></p-tag
        ></span>
      </h2>
    </div>
    <div class="p-grid p-col-12">
      <div class="p-col-12 p-d-flex p-jc-between">
        <!--  SELECT THIS DIV IF ONE COLUMN FORM AND DELETE THE ONE ABOVE
          <div class="p-col-12 p-d-flex p-jc-center">-->

        <div class="profilepic">
          <img
            *ngIf="persona?.photo"
            class="profilepic__image"
            src="{{ persona?.photo }}"
            width="150"
            height="150"
            alt="Profibild"
          />
          <img
            *ngIf="!persona?.photo"
            class="profilepic__image"
            width="150"
            height="150"
            src="assets/img/default-avatar.png"
            alt="Profibild"
          />
          <div class="profilepic__content">
            <!-- <span class="profilepic__text">Modifier la photo</span><br> -->
            <p-fileUpload
              [hidden]="!editPersonaMode"
              mode="basic"
              (onSelect)="onSelection($event, ftl)"
              #ftl
              multiple="false"
              fileLimit="1"
              accept="image/*"
              invalidFileSizeMessageSummary="Impossible de charger cette photo (taille max autorisée 1MB)."
              invalidFileSizeMessageDetail="L'image ne sera pas enregistrée."
              [showUploadButton]="false"
              cancelLabel="Annuler"
              class="p-pb-1"
              title="Modifier la photo"
            >
            </p-fileUpload>
            <div
              *ngIf="checkExistPhoto() && editPersonaMode"
              class="suppr"
              (click)="deletePhoto()"
              title="Supprimer la photo"
            >
              <i class="pi pi-times p-mt-1"></i>
            </div>
          </div>
        </div>

        <div class="p-col12">
          <!-- <ngx-qrcode class="qrCode"
                        elementType="url"
                        value="
                  Nom:{{ persona.lastName }},
                  Prenom: {{persona?.firstName}},
                  Email:  {{persona.mail}},
                  Télephone:  {{persona.phone}},
                  Numéro Personnel:  {{persona.personalNumber}},
                  Id: {{persona.id}}  "
                        cssClass="aclass"
                        errorCorrectionLevel="L"
                        width="150px"
          Height="150px">
          </ngx-qrcode> -->
          <div *ngIf="persona.rgpd">
            <qrcode
              *ngIf="qrData"
              [qrdata]="qrData"
              [width]="150"
              [errorCorrectionLevel]="'M'"
            ></qrcode>
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid p-col-12 p-d-flex p-jc-center">
      <div class="p-col-6">
        <div class="p-fluid">
          <div class="p-field p-grid">
            <label for="lastname" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'NOM' | translate }} *
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.lastName }}</span>
              <input
                *ngIf="editPersonaMode"
                id="lastname"
                name="lastname"
                type="text"
                pInputText
                [ngClass]="{ 'ng-invalid ng-dirty': errorLastNameRequired }"
                [(ngModel)]="persona.lastName"
                (ngModelChange)="onChange($event)"
                required="true"
                (blur)="lastNametFieldValidator($event)"
                (input)="lastNametFieldValidator($event)"
              />
              <p-message
                *ngIf="errorLastNameRequired"
                severity="error"
                text="{{ 'form_message_CHAMP_OBLIGATOIRE' | translate }}."
              ></p-message>
            </div>
          </div>

          <div class="p-field p-grid">
            <label for="firstname" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'PRENOM' | translate }} *
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.firstName }}</span>
              <input
                *ngIf="editPersonaMode"
                id="firstname"
                [ngClass]="{ 'ng-invalid ng-dirty': errorFirstNameRequired }"
                type="text"
                value="{{ persona.firstName }}"
                pInputText
                [(ngModel)]="persona.firstName"
                (ngModelChange)="onChange($event)"
                name="firstName"
                required="true"
                (blur)="firsNametFieldValidator($event)"
                (input)="firsNametFieldValidator($event)"
              />
              <p-message
                *ngIf="errorFirstNameRequired"
                severity="error"
                text="{{ 'form_message_CHAMP_OBLIGATOIRE' | translate }}."
              ></p-message>
            </div>
          </div>

          <div class="p-field p-grid">
            <label for="middlename" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
               {{ ' DEUXIEME_NOM' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.middleName }}</span>
              <input
                *ngIf="editPersonaMode"
                id="middlename"
                type="text"
                pInputText
                [(ngModel)]="persona.middleName"
                (ngModelChange)="onChange($event)"
              />
            </div>
          </div>

          <div class="p-field p-grid">
            <label for="publicName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'NOM_PUBLIC' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.publicName }}</span>
              <input
                *ngIf="editPersonaMode"
                [(ngModel)]="persona.publicName"
                (ngModelChange)="onChange($event)"
                id="publicName"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div class="p-field p-grid">
            <label for="nationality" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
               {{ 'NATIONALITE' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.nationality }}</span>
              <input
                *ngIf="editPersonaMode"
                [(ngModel)]="persona.nationality"
                (ngModelChange)="onChange($event)"
                id="nationality"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div class="p-field p-grid">
            <label for="mail" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ ' EMAIL' | translate }} *
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.mail }}</span>
              <input
                *ngIf="editPersonaMode"
                [(ngModel)]="persona.mail"
                (ngModelChange)="onChange($event)"
                id="mail"
                type="text"
                pInputText
                [ngClass]="{
                  'ng-invalid ng-dirty': errorMailRequired || errorMailFormat
                }"
                required="true"
                name="mail"
                (blur)="mailFieldValidator($event)"
                (input)="mailFieldValidator($event)"
              />
              <p-message
                *ngIf="errorMailRequired"
                severity="error"
                text="{{ 'form_message_CHAMP_OBLIGATOIRE' | translate }}."
              ></p-message>
              <p-message
                *ngIf="errorMailFormat"
                severity="error"
                text="{{ 'form_message_EMAIL_INVALIDE' | translate }}."
              ></p-message>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="phone" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'TELEPHONE' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.phone }}</span>
              <input
                *ngIf="editPersonaMode"
                (ngModelChange)="onChange($event)"
                [(ngModel)]="persona.phone"
                id="phone"
                type="text"
                pInputText
                (blur)="validPhoneFormat($event)"
                (input)="validPhoneFormat($event)"
              />
              <p-message
                *ngIf="errorPhone"
                severity="error"
                text="{{ 'form_message_FORMAT_NUMERIQUE_UNIQUEMENT' | translate }}."
              ></p-message>
            </div>
          </div>

          <div class="p-field p-grid">
            <label for="adress" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'ADRESSE' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.address }}</span>
              <textarea
                *ngIf="editPersonaMode"
                id="adress"
                [rows]="4"
                pInputTextarea
                [(ngModel)]="persona.address"
                (ngModelChange)="onChange($event)"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <!-- DELETE THIS DIV TO CENTER THE FIRST COLUMN--->
      <div class="p-col-6">
        <div class="p-fluid">
          <div class="p-field p-grid">
            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'DATE_DE_NAISSANCE' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{
                persona.birthDate !== null
                  ? (persona.birthDate | date: "dd MMMM YYYY")
                  : ""
              }}</span>
              <p-calendar
                *ngIf="editPersonaMode"
                id="birthdate"
                dateFormat="dd/mm/yy"
                [(ngModel)]="persona.birthDate"
                (ngModelChange)="onChange($event)"
                [monthNavigator]="true"
                [yearNavigator]="true"
                yearRange="1960:2050"
                inputId="navigators"
              >
              </p-calendar>
            </div>
          </div>

          <div class="p-field p-grid">
            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">  {{ 'GENRE' | translate }} * </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.gender }}</span>
              <p-dropdown
                *ngIf="editPersonaMode"
                id="gender"
                [options]="gender"
                placeholder="Genre"
                optionLabel="name"
                optionValue="value"
                name="gender"
                [(ngModel)]="persona.gender"
                (ngModelChange)="onChange($event)"
                [ngClass]="{ 'ng-invalid ng-dirty': errorGenderRequired }"
                (onBlur)="genderFieldValidator($event)"
                (onChange)="genderFieldValidator($event)"
              ></p-dropdown>
              <p-message
                *ngIf="errorGenderRequired"
                severity="error"
                text="{{ 'form_message_CHAMP_OBLIGATOIRE' | translate }}."
              ></p-message>
            </div>
          </div>
          <!--  -->
          <div class="p-field p-grid"  *ngIf="persona.postPersona?.id != null">
            <label for="manager" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'ENTREPRISE' | translate }}   *
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span>{{ persona.company?.name }}</span>
            </div>
          </div>
          <!--  -->
          <div class="p-field p-grid" *ngIf="persona.postPersona?.id == null">
            <label for="manager" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'ENTREPRISE' | translate }}   *
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.company?.name }}</span>
              <p-autoComplete
                *ngIf="editPersonaMode"
                (ngModelChange)="onChange($event)"
                [(ngModel)]="persona.company"
                field="name"
                [suggestions]="filteredCompanies"
                (completeMethod)="filterCompany($event)"
                [dropdown]="true"
                id="company"
                name="company"
                [ngClass]="{ 'ng-invalid ng-dirty': errorCompanyRequired }"
                required="true"
                (onKeyUp)="companyFieldValidator($event)"
                (onSelect)="companyFieldValidator($event)"
              >
              </p-autoComplete>
              <p-message
                *ngIf="errorCompanyRequired"
                severity="error"
                text="{{ 'form_message_CHAMP_OBLIGATOIRE' | translate }}."
              ></p-message>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="manager" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                {{ 'RESPONSABLE' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.manager }}</span>
              <input
                *ngIf="editPersonaMode"
                id="manager"
                type="text"
                pInputText
                [(ngModel)]="persona.manager"
                (ngModelChange)="onChange($event)"
              />
            </div>
          </div>
          <!--  -->
          <div class="p-field p-grid"  *ngIf="persona.postPersona?.id != null">
            <label for="manager" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
               {{'POSTE' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span >{{ persona.postPersona?.nameFR }}  ({{ persona.postPersona?.nameOrgaUnit }}) </span>

            </div>
          </div>
          <!--  -->
          <div class="p-field p-grid">
            <label for="position" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
               {{ 'PROFILS' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <ng-container
                *ngFor="let post of persona.listProfil; let i = index"
              >
                <p-chip
                  *ngIf="!editPersonaMode"
                  label="{{ post.nameFR }}"
                  class="postChips"
                ></p-chip>
              </ng-container>
              <div *ngIf="editPersonaMode">
                <p-multiSelect
                  #select="ngModel"
                  [options]="listPosts"
                  defaultLabel="Séléctionner un poste"
                  optionLabel="name"
                  [(ngModel)]="selectedPosts"
                  [ngModelOptions]="{ standalone: true }"
                  (onChange)="changePost($event)"
                  (ngModelChange)="onChange($event)"
                  [ngClass]="{ 'ng-invalid ng-dirty': errorPostRequired }"
                  required="true"
                  (onPanelHide)="panelClosed = true"
                  display="chip"
                  (onBlur)="postFieldValidator($event)"
                  (onSelect)="postFieldValidator($event)"
                ></p-multiSelect>
                <!-- validation error message -->
                <div *ngIf="errorPostRequired">
                  <p-message
                    severity="error"
                    text="{{ 'form_message_CHAMP_OBLIGATOIRE' | translate }}."
                  ></p-message>
                </div>
              </div>
            </div>
          </div>

          <div class="p-field p-grid">
            <label for="subscription" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                {{ 'ABONNEMENT' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <span *ngIf="!editPersonaMode">{{ persona.subscription }}</span>
              <input
                *ngIf="editPersonaMode"
                id="subscription"
                type="text"
                pInputText
                [(ngModel)]="persona.subscription"
                (ngModelChange)="onChange($event)"
              />
            </div>
          </div>

          <div class="p-field p-grid">
            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              {{ 'NOTE_GLOBALE' | translate }}
            </label>
            <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
              <p-rating
                *ngIf="!editPersonaMode"
                [(ngModel)]="persona.globalRating"
                readonly="true"
                [cancel]="false"
                disabled="true"
              ></p-rating>
              <p-rating
                *ngIf="editPersonaMode"
                (ngModelChange)="onChange($event)"
                [(ngModel)]="persona.globalRating"
                [cancel]="false"
                (rate)="onRate($event)"
              ></p-rating>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!editPersonaMode" class="p-col-12 p-d-flex p-jc-end">
      <!--Valider les modification-->
      <ng-container
        *ifRoles="['administrateur', 'admin_metier', 'admin_responsable_DPO']"
      >
        <p-button
          class="p-mr-2 p-mb-2"
          *ngIf="!editPersonaMode"
          (click)="switchEditPersonaMode(true)"
          label="{{ 'MODIFIER' | translate }}"
        ></p-button>
      </ng-container>
    </div>

    <div class="p-col-12 p-d-flex p-jc-end" *ngIf="editPersonaMode">
      <div>
        <p-button
          *ngIf="editPersonaMode"
          (click)="confirmReturn()"
          styleClass="p-button-outlined p-mr-2"
          label="{{ 'formulaire_RETOUR' | translate }}"
        ></p-button>

        <p-button
          class="p-mr-2 p-mb-2"
          *ngIf="editPersonaMode"
          (click)="valideUpdate()"
          label="{{ 'formulaire_VALIDER' | translate }}"
          styleClass="p-mr-2"
        ></p-button>
      </div>
    </div>
  </div>
  <div
    class="p-grid p-fluid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-4"
  >
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="p-grid p-fluid">
        <h3 class="p-col-9">
          {{ 'COMPETENCES_DE' | translate }}  {{ persona?.firstName }} {{ persona?.lastName }}
        </h3>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6">
      <app-filtre-tag
        (newArrayFilter)="filterArrayByTag($event)"
        [typeOfTag]="typeOfTag"
      ></app-filtre-tag>
    </div>
    <div class="p-col-12 p-md-12 p-lg-12">
      <div class="p-fluid">
        <p-accordion styleClass="list-skill">
          <div *ngFor="let personaSkill of persona?.listSkill; let i = index">
            <p-accordionTab [selected]="activeState[i]">
              <ng-template (click)="toggle(i)" pTemplate="header">
                <div
                  class="p-col-12 p-grid p-nogutter p-d-flex p-ai-center p-jc-between"
                >
                  <div class="p-col-2">
                    {{ personaSkill.skill.name }}
                  </div>

                  <div class="p-col-9">
                    <ng-container
                      id="ng-container"
                      class="p-grid"
                      *ngFor="let item of personaSkill.skill.tags"
                    >
                      <p-chip
                        [label]="item.label"
                        [pTooltip]="item?.description"
                        tooltipPosition="top"
                        [style]="{
                          color: '#ffffff',
                          background: item.colorCode,
                          'border-radius': '6px',
                          height: '35px',
                          'margin-right': '8px',
                          'margin-bottom': '8px'
                        }"
                      ></p-chip>
                    </ng-container>
                  </div>

                  <div class="p-d-flex p-ai-center">
                    <p-tag
                      severity="danger"
                      value="{{ 'DATE_ECHU' | translate }}"
                      icon="pi pi-exclamation-triangle"
                      *ngIf="!checkValidDate(personaSkill)"
                    ></p-tag>
                    <button
                      *ngIf="editSkillMode"
                      (click)="deleteSkill(personaSkill.skill)"
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-times"
                      class="p-button-rounded p-button-danger p-button-sm p-ml-1"
                    ></button>
                  </div>
                </div>
              </ng-template>

              <div class="p-col-12">
                {{ 'TYPE' | translate }} :

                <span *ngIf="personaSkill.skill.type === 'HABILITATION'"
                  >{{ 'HABILITATION' | translate }} </span
                >
                <span *ngIf="personaSkill.skill.type === 'SAVOIR_ETRE'"
                  >{{ 'SAVOIR_ETRE' | translate }}  </span
                >
                <span *ngIf="personaSkill.skill.type === 'SAVOIR_FAIRE'"
                  >{{ 'SAVOIR_FAIRE' | translate }} </span
                >
              </div>
              <div class="p-col-12">
                {{ 'DESCRIPTION ' | translate }} : {{ personaSkill.skill.description }}
              </div>
              <div
                *ngIf="
                  personaSkill.skill.type === 'HABILITATION' &&
                  personaSkill.skill.validity
                "
                class="p-col-12"
              >
              {{ 'VALIDITE' | translate }}  :
                {{
                  getValidity(
                    personaSkill.skill.year_duration,
                    personaSkill.skill.month_duration,
                    personaSkill.skill.day_duration
                  )
                }}
              </div>
              <div
                class="p-grid p-col-12"
                *ngIf="
                  personaSkill.skill?.type === 'HABILITATION' &&
                  personaSkill.skill?.validity &&
                  !editSkillMode
                "
              >
                <span class="p-col-12">
                  {{ 'VALIDE_DU' | translate }}
                  {{ persona?.listSkill[i].startDate | date: "fullDate":"fr" }}
                  {{ 'AU' | translate }}
                  {{ persona?.listSkill[i].endDate | date: "fullDate":"fr" }}
                </span>
              </div>

              <span
                class="p-grid p-col-12 p-md-12"
                *ngIf="
                  personaSkill.skill.type === 'HABILITATION' &&
                  personaSkill.skill.validity &&
                  editSkillMode
                "
              >
                <span class="p-col-12 p-md-6">
                  {{ 'VALIDE_DU' | translate }}
                  <i
                    pTooltip="La modification de la date début de validité entrainera la modification automatique de la date de fin (calcul en fonction de la durée de validité de l'habilitation)"
                    class="pi pi-question-circle"
                  ></i>
                  <p-calendar
                    dateFormat="dd.mm.yy"
                    (ngModelChange)="onChange($event)"
                    [(ngModel)]="persona?.listSkill[i].startDate"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="1970:2050"
                    (onClose)="startDateOnChange(i)"
                  ></p-calendar>
                </span>
                <span class="p-col-12 p-md-6">
                  {{ 'AU' | translate }}
                  <p-calendar
                    dateFormat="dd.mm.yy"
                    (ngModelChange)="onChange($event)"
                    [(ngModel)]="persona?.listSkill[i].endDate"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="1970:2050"
                  ></p-calendar>
                </span>
              </span>
            </p-accordionTab>
          </div>
          <div *ngFor="let skill of newSkillsOfPersona; let j = index">
            <p-accordionTab [selected]="true">
              <ng-template (click)="toggle(i)" pTemplate="header">
                <div
                  class="p-col-12 p-grid p-nogutter p-d-flex p-ai-center p-jc-between"
                >
                  <div>
                    {{ skill?.name }}
                  </div>
                  <div class="p-d-flex p-ai-center">
                    <p-tag
                      severity="danger"
                      value="Date échue"
                      icon="pi pi-exclamation-triangle"
                      *ngIf="
                        skill.type === 'HABILITATION' &&
                        skill.validity &&
                        listAddSkills[j]?.endDate?.getTime() <
                          this.currentDate.getTime()
                      "
                    ></p-tag>
                    <button
                      *ngIf="editSkillMode"
                      (click)="deleteAddedSkill(skill)"
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-times"
                      class="p-button-rounded p-button-danger p-button-sm p-ml-1"
                    ></button>
                  </div>
                </div>
              </ng-template>
              <div class="p-col-12">
                {{ 'TYPE' | translate }}  :
                <span *ngIf="skill.type === 'HABILITATION'">{{ 'HABILITATION' | translate }}</span>
                <span *ngIf="skill.type === 'SAVOIR_ETRE'">{{ 'SAVOIR_ETRE' | translate }} </span>
                <span *ngIf="skill.type === 'SAVOIR_FAIRE'">{{ 'SAVOIR_FAIRE' | translate }}</span>
              </div>
              <div class="p-col-12">Description : {{ skill.description }}</div>
              <div
                *ngIf="skill.type === 'HABILITATION' && skill.validity"
                class="p-col-12"
              >
                Validité :
                {{
                  getValidity(
                    skill.year_duration,
                    skill.month_duration,
                    skill.day_duration
                  )
                }}
              </div>
              <div
                class="p-grid p-col-12"
                *ngIf="
                  skill?.type === 'HABILITATION' &&
                  skill?.validity &&
                  editSkillMode
                "
              >
                <span class="p-col-12 p-md-6">
                  {{ 'VALIDE_DU' | translate }}
                  <i
                    pTooltip="La modification de la date début de validité entrainera la modification automatique de la date de fin (calcul en fonction de la durée de validité de l'habilitation)"
                    class="pi pi-question-circle"
                  ></i>
                  <p-calendar
                    dateFormat="dd.mm.yy"
                    *ngIf="listAddSkills[j]"
                    (ngModelChange)="onChange($event)"
                    [(ngModel)]="listAddSkills[j].startDate"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="1970:2050"
                    (onClose)="addedStartDateOnChange(j)"
                  ></p-calendar>
                </span>
                <span class="p-col-12 p-md-6">
                  {{ 'AU' | translate }}
                  <p-calendar
                    dateFormat="dd.mm.yy"
                    *ngIf="listAddSkills[j]"
                    (ngModelChange)="onChange($event)"
                    [(ngModel)]="listAddSkills[j].endDate"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="1970:2050"
                  ></p-calendar>
                </span>
              </div>
            </p-accordionTab>
          </div>
        </p-accordion>
      </div>
    </div>

    <br />
    <div class="p-col-12 p-d-flex p-jc-end">
      <ng-container
        *ifRoles="[
          'administrateur',
          'admin_metier',
          'admin_responsable_DPO',
          'manager_operationnel'
        ]"
      >
        <p-button
          *ngIf="!editSkillMode"
          (click)="switchEditSkillsMode(true)"
          label="{{ 'MODIFIER' | translate }}"
        ></p-button>
      </ng-container>
      <p-button
        class="p-mr-2 p-mb-2"
        styleClass="p-button-outlined p-mr-2"
        *ngIf="editSkillMode"
        (click)="confirmReset()"
        label="{{ 'formulaire_ANNULER' | translate }}"
      ></p-button>
      <p-button
        class="p-mr-2 p-mb-2"
        *ngIf="editSkillMode"
        (click)="addNewSkills()"
        label="{{ 'formulaire_AJOUTER' | translate }}"
      ></p-button>
      <p-button
        class="p-mr-2 p-mb-2"
        *ngIf="editSkillMode"
        (click)="updateSkills()"
        label="{{ 'formulaire_VALIDER' | translate }}"
      ></p-button>
    </div>
  </div>
  <p-dialog
  [style]="{ width: '32vw' }"
  header="Intervenant"
  [draggable]="false"
  [closable]="true"
  [(visible)]="displayTech"
>
  <div class="p-grid p-field">
    <div class="p-col-4">
      <h3 class="p-d-inline">{{ 'Adresse_DEPART' | translate }} :</h3>
    </div>
    <div class="p-col-2"></div>
    <div class="p-col-4">
      <input
        type="text"
        name="address"
        [(ngModel)]="technicianAddress"
        class="form-control"
        pInputText
        id="address"
        name="address"
      />
    </div>
  </div>

  <!-- spacing pour button -->
  <div class="p-text-right">
    <button
      pButton
      type="button"
      (click)="displayTech = false"
      label="Annuler"
      class="p-button-outlined p-mr-2"
    ></button>
    <p-button
      (onClick)="createTechnicien()"
      styleClass="p-mr-2"
      label="Valider"
    ></p-button>
  </div>
</p-dialog>
</div>
<p-confirmDialog
  styleClass="main-page"
  [style]="{ width: '60%' }"
></p-confirmDialog>
<p-dialog [(visible)]="display" [draggable]="false" styleClass="main-page">
  <image-cropper
    [imageBase64]="imageBase64"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="containWithinAspectRatio"
    [aspectRatio]="1 / 1"
    [resizeToWidth]="200"
    [cropperMinWidth]="128"
    [onlyScaleDown]="true"
    [roundCropper]="true"
    [canvasRotation]="canvasRotation"
    [transform]="transform"
    [alignImage]="'center'"
    [style.display]="showCropper ? null : 'none'"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
  <ng-template pTemplate="footer">
    <p-button (click)="onUpload(ftl)">Valider</p-button>
  </ng-template>
  
</p-dialog>
<div [style]="{ height: '13vh' }"></div>
<p-sidebar
  class="p-text-right"
  [style]="{ height: '13vh' }"
  [visible]="true"
  [blockScroll]="true"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="bottom"
>
  <div>
    <!--RETOUR A LA LISTE-->
    <p-button
      styleClass="p-button-outlined p-mr-2"
      label="{{ 'formulaire_RETOUR' | translate }}"
      (click)="confirmReturnBottom()"
    ></p-button>
    <ng-container
      *ifRoles="['administrateur', 'admin_metier', 'admin_responsable_DPO']"
    >
      <p-button
        *ngIf="this.persona.enable === true"
        class="p-mr-2 p-mb-2"
        (click)="confirmDelete()"
        label="{{ 'SUPPRIMER' | translate }}"
      ></p-button>
    </ng-container>
    <ng-container
      *ifRoles="['administrateur', 'admin_metier', 'admin_responsable_DPO']"
    >
      <p-button
        *ngIf="this.persona.enable === false"
        class="p-mr-2 p-mb-2"
        (click)="confirmActivate()"
        label="Activer"
      ></p-button>
    </ng-container>
  </div>
</p-sidebar>
