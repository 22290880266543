import { Injectable } from '@angular/core';
import { Persona } from '@data/schema/Persona';
import { Skill } from '@data/schema/Skill';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Technician } from '@data/schema/Technician';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root',
})
export class PersonaService {
  private apiURL = this.environment?.readConfig()?.urlApiGateway + 'persona/';

  constructor(private http: HttpClient,private environment: EnvService) { }

  // STORE DATA BETWEEN PAGES
  personaInformation = {
    persona: new Persona(),
  };

  listSelectableSkills: Skill[] = []; //store selectable skills (all skills -skills of persona and skills added to the persona)

  public erasePersonaInformation() {
    this.personaInformation.persona = new Persona();
  }

  // GET LIST OF ALL PERSONA
  // public getListPersona(): Observable<any> {
  //   return this.http
  //     .get(this.apiURL + 'list', { observe: 'response' as 'body' })
  //     .pipe(
  //       map((res) => {
  //         return res;
  //       })
  //     );
  // }

  public countSkillsByPersona(pools: string): Observable<any> {
    const params = new HttpParams().set('pools', pools);
    return this.http
      .get(this.apiURL + `countSkillsByPersona`, {
        params: params,
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //Changer l'url pour avoir la bonne route
  public getListSkillWithPersonaCount(): Observable<any>{
    return this.http
    .get(this.apiURL + 'getSkillWithPersonaCount', {observe: 'response' as 'body'})
    .pipe(
      map((res) => {
        return res;
      })
    );
  }

  public addSkillsToPersona(persona: Persona): Observable<any> {
    return this.http
      .get(this.apiURL + 'addSkillsToPersona/' + persona, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getSkillWithCount(pools: string): Observable<any> {
    const params = new HttpParams().set('pools', pools);
    return this.http
      .get(this.apiURL + `getSkillWithCount`, { params: params, observe: 'response' as 'body' })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getListPersonaByEnabled(bool: boolean, pools: string, lazyLoadEvent: any): Observable<any> {
    const params = new HttpParams().set('pools', pools)
      .set('offset', lazyLoadEvent.first)
      .set('rows', lazyLoadEvent.rows)
      .set('sortField', lazyLoadEvent.sortField)
      .set('sortOrder', lazyLoadEvent.sortOrder)
      .set('globalFilter', lazyLoadEvent.globalFilter)
      .set('page', lazyLoadEvent.page)
      .set('skillEndDate', lazyLoadEvent.skillEndDate)
      ;
    return this.http
      .get(this.apiURL + 'all/enable/' + bool, {
        params: params,
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

    //GET A SKILL WITH PERSONA LIST BY ID
    public getSkillByIdWithPersona(id: number): Observable<any> {
      return this.http
        .get(this.apiURL + `getPersonasWithIdSkill/` + id, {
          observe: 'response' as 'body',
        })
        .pipe(
          map((res) => {
            return res
          })
        );
    }

    //GET A SKILL WITH PERSONA LIST BY ID
    public getPersonaWithSkill(id: number): Observable<any> {
      return this.http
        .get(this.apiURL + `/getPersona/` + id, {
          observe: 'response' as 'body',
        })
        .pipe(
          map((res) => {
            return res
          })
        );
    }


    // GET PERSONA FROM PARTYID
  public getTechnicianByPartyId(partyId: string): Observable<any> {
    return this.http.get(this.apiURL + "tech/party/" + partyId, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )

  }

  //get personas with expired skills
  public getPersonaListWithExpiredSkills(): Observable<any> {
    const currentDate = new Date();
    const isoDate = currentDate.toISOString().split('.')[0]+"Z" ;
    console.log(this.apiURL + 'getListPersonaWithSkills?skillEndDate=' + isoDate);
    return this.http
      .get(this.apiURL + 'getListPersonaWithSkills?skillEndDate=' + isoDate + "&skillType=HABILITATION", {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );

  }

  public getMinifiedPersonaList(pools: string): Observable<any> {
    const params = new HttpParams().set('pools', pools)
      ;
    return this.http
      .get(this.apiURL + 'list', {
        params: params,
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }


  public getMinifiedPersonaPageable(pools: string, page : string, size : string): Observable<any> {
    const params = new HttpParams()
      .set('pools', pools)
      .set('page', page.toString())
      .set('size', size.toString());

    return this.http
      .get(this.apiURL + 'v2/pageable', {
        params: params,
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }


  public getAreas(): Observable<any> {

    const params = new HttpParams()
      .set('name', "");

    return this.http.get(`${this.apiURL}v2/area`, { params }).pipe(
      map((res) => {
        console.log(res);
          return res;
        })
      );
  }

  public getAllAreasSearched(areaName): Observable<any> {
    const params = new HttpParams()
      .set('name', areaName);

    return this.http.get(`${this.apiURL}v2/area`, { params }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getPageableAreasSearched(areaName: string, size: string, page: string, sortBy: string = 'label', sortOrder: string = 'asc'): Observable<any> {
    const params = new HttpParams()
      .set('name', areaName)
      .set('size', size)
      .set('page', page)
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);

    return this.http.get(`${this.apiURL}v2/area/pageable`, { params }).pipe(
      map(res => res)
    );
  }

  public getAreasPageable(size: string, page: string, sortBy: string = 'label', sortOrder: string = 'asc'): Observable<any> {

    const params = new HttpParams()
      .set('size', size)
      .set('page', page)
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);

    return this.http.get(`${this.apiURL}v2/area/pageable`, { params }).pipe(
      map(res => res)
    );
  }


  // GET LIST OF ALL ENABLED PERSONA
  //replaced by get enable/all
  // public getListEnabledPersona(): Observable<any> {
  //   return this.http
  //     .get(this.apiURL + 'getPersonasByEnable/1', {
  //       observe: 'response' as 'body',
  //     })
  //     .pipe(
  //       map((res) => {
  //         return res;
  //       })
  //     );
  // }

  // GET LIST OF ALL DISABLED PERSONA
  // public getListDisabledPersona(): Observable<any> {
  //   return this.http
  //     .get(this.apiURL + 'getPersonasByEnable/0', {
  //       observe: 'response' as 'body',
  //     })
  //     .pipe(
  //       map((res) => {
  //         return res;
  //       })
  //     );
  // }

  // GET A PERSONA By ID KEYCLOAK
  public getPersonaByKeycloak(id: string): Observable<any> {
    return this.http
      .get(this.apiURL + `getPersonaByIdKeycloak/` + id, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // GET A PERSONA By ID whith skills ans company
  public getPersona(id: any): Observable<any> {
    return this.http
      .get(this.apiURL + `getPersona/` + id, { observe: 'response' as 'body' })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //get Num Persona
  public getNumPersona(): Observable<any> {
    return this.http
      .get(this.apiURL + `getNumPersona`, {
        observe: 'response' as 'body',
        responseType: 'text',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //getProfile
  public getProfile(): Observable<any> {
    return this.http
      .get(this.apiURL + `getProfile`, {
        observe: 'response' as 'body',
        responseType: 'text',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // GET firstname and lastName persona
  public getFirstLastPersona(id: any): Observable<any> {
    return this.http
      .get(this.apiURL + 'tech/' + id + '/name', {
        observe: 'response' as 'body',
        responseType: 'text' as 'json',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // ACTIVE RGPD By IdKEYCLOACK
  public activeRgpd(idKeycloak: string): Observable<any> {
    return this.http
      .put(this.apiURL + `activateRgpd/` + idKeycloak, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  // DESACTIVE RGPD By IdKEYCLOACK
  public desactiveRgpd(idKeycloak: string): Observable<any> {
    return this.http
      .put(this.apiURL + `desableRgpd/` + idKeycloak, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // UPDATE A PERSONA WITH NEW PHOTO
  public updatePhoto(id: number, photo: string): Observable<any> {
    return this.http.put(this.apiURL + `updatePhoto/` + id, photo, {
      observe: 'response' as 'body',
    });
  }

  // UPDATE A PERSONA WITH NEW PHOTO
  public deletePhoto(id: number): Observable<any> {
    return this.http.put(this.apiURL + `deletePhoto/` + id, {
      observe: 'response' as 'body',
    });
  }

  // CREATE A PERSONA
  public createPersona(persona: Persona): Observable<any> {
    return this.http.post(this.apiURL + `addPersonaWithSkills`, persona, {
      observe: 'response' as 'body',
    });
  }

  // UPDATE A PERSONA WITH NEW VALUES
  public updatePersona(id: number, persona: Persona): Observable<any> {
    return this.http.put(this.apiURL + `updatePersona/` + id, persona, {
      observe: 'response' as 'body',
    });
  }

  // DELETE A PERSONA
  public deletePersona(id: number, persona: Persona): Observable<any> {
    return this.http.put(this.apiURL + `deletePersona/` + id, persona, {
      observe: 'response' as 'body',
    });
  }

  // ACTIVATE A PERSONA
  public activatePersona(id: number, persona: Persona): Observable<any> {
    return this.http.put(this.apiURL + `activatePersona/` + id, persona, {
      observe: 'response' as 'body',
    });
  }

  //MEGA UPDATE
  public updateAllSkills(listModifsSkills: any): Observable<any> {
    return this.http.post(this.apiURL + `manageSkills`, listModifsSkills, {
      observe: 'response' as 'body',
    });
  }

  // APPELS LIE AUX INTERVENANTS

  // GET LIST OF ALL INTERVENANTS
  public getListTechnician(pools?: any): Observable<any> {
    let userPools =pools || [];
    const params = new HttpParams().set('pools', userPools)
    return this.http
      .get(this.apiURL + 'getAllTechnicians',
        {
          params: params,
          observe: 'response' as 'body'
        })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }



  public getNewListPersonaByEnabled(enable? : boolean, pools?: string, page? : string, size? : string, sortBy? : string, sortOrder? : string, searchText? : string): Observable<any> {
    const params = new HttpParams()
      .set('enable', enable)
      .set('pools', pools)
      .set('page', page)
      .set('size', size)
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
      .set('searchText', searchText);
    return this.http
      .get(this.apiURL + 'v2/pageable', {
        params: params,
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // GET LIST OF ALL INTERVENANTS PAGEABLE
  public getListTechnicianPageable(pools?: string, page? : string, size? : string, sortBy? : string, sortOrder? : string, searchText? : string): Observable<any> {
    const params = new HttpParams()
      .set('pools', pools)
      .set('page', page)
      .set('size', size)
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
      .set('searchText', searchText);

      return this.http
        .get(this.apiURL + 'v2/technician/pageable',
        {
          params: params,
          observe: 'response' as 'body'
        })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // GET LIST OF ALL INTERVENANTS New API CALL
  public getPostTechnician(post: string): Observable<any> {
    return this.http
      .get(this.apiURL + 'listByPost/' + post, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // CREATION D'UN INTERVENANT
  public createTechnician(technician: Technician): Observable<any> {
    return this.http.post(this.apiURL + `addTechnician`, technician, {
      observe: 'response' as 'body',
    });
  }

  //UPDATE D'UN INTERVENANT
  public updateTechnician(technician: Technician): Observable<any> {
    return this.http.put(this.apiURL + `updateTechnician`, technician, {
      observe: 'response' as 'body',
    });
  }

  // // DELETE A TECHNICIAN
  public deleteTechnician(technician: Technician): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: technician,
    };
    return this.http.delete(this.apiURL + `deleteTechnician`, options);
  }

  // List des personas avec tout leurs skills
  public getPersonaWithSkills(pools: string): Observable<any> {
    const params = new HttpParams().set('pools', pools)
    return this.http
      .get(this.apiURL + `getListPersonaWithSkills`, {
        params: params,
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //get technician by persona id
  public getContributorByPersonaId(id: number): Observable<any> {
    return this.http
      .get(this.apiURL + 'getTechnician/' + id, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }



  //get list id of company linked to persona
  public getListCompanyIdOfPersona(): Observable<any> {
    return this.http
      .get(this.apiURL + 'getListCompanyIdOfPersona', {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //get list id of company linked to persona
  public getPersonaBySkillId(id: number): Observable<any> {
    return this.http
      .get(this.apiURL + 'getPersonasBySkillId/' + id, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  /**
   *
   Get persona card information
   */

  public getPersonaCardInformation(id: number): Observable<any> {
    return this.http
      .get(this.apiURL + id + '/card', { observe: 'response' as 'body' })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //check if persona has one party role who is tech
  public technicianHasOtherProfiles(id: number): Observable<any> {
    return this.http.get(this.apiURL + "tech-has-other-profiles/" + id, { observe: 'response' as 'body' }).pipe(
      map(res => {
        return res;
      })
    )
  }

  public userSync(): Observable<any> {
    return this.http.get(this.apiURL + "sync-users", { observe: 'response' as 'body' });
  }

}
