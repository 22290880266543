import { Pipe, PipeTransform } from '@angular/core';
import { ControlsType, InputNumber } from '@data/schema/intervention/Form';

@Pipe({
  name: 'isInputNumber'
})
export class IsInputNumberPipe implements PipeTransform {

  transform(controlsType : ControlsType): controlsType is InputNumber {
    return controlsType?.code === "inputNumber";
  }

}
