import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageService } from '@shared/service/local-storage.service';

@Pipe({ name: 'IsInPossibleWorkOrderActions' })
export class IsInPossibleWorkOrderActions implements PipeTransform {
  userRole: string;

  constructor(private localStorageService: LocalStorageService) {
    this.userRole = this.localStorageService.getRole();
  }

  transform(workOrderPossibleActions: any[], action: string) {
    let exists = false;
    let foundAction = workOrderPossibleActions?.find(pa => pa.action == action);
    if (foundAction) {
      exists = foundAction.actorList?.includes(this.userRole);
    }
    return exists;
  }
}
