
    <div class="p-grid p-ai-center vertical-container p-jc-center">
        <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
          <div class="header-container">
            <h2 style="width: 50%;">{{'popUp_PERSONAS_COMPETENCES_EXPIREES'|translate}}</h2>
            <!-- <mat-dialog-actions class="returnBtnDiv">
              <button class="returnBtn" mat-button mat-dialog-close>{{'formulaire_RETOUR'|translate}}</button>
            </mat-dialog-actions> -->
            <button mat-icon-button aria-label="close dialog" mat-dialog-close>
              <mat-icon>close</mat-icon>
            </button>

          </div>
            <div class="header">
                <div><h2>{{currentSkill.name}}</h2></div> <div style="width: 60%;">{{currentSkill.description}}</div> 
                <div class="validite">
                  <div class="validiteTitle">{{'VALIDITE'|translate}}</div>
                  <div>{{currentSkill.year_duration}} {{'ANNEE_S'|translate}}  {{currentSkill.month_duration}} {{'MOIS_S'|translate}}  {{currentSkill.day_duration}} {{'JOUR_S'|translate}}</div>
                </div>
            </div>
            <p-table
                   #dt1
                   #dt2
                   [value]="personaList"
                   class="table"
                   [scrollable]="true"
                   scrollHeight="40vh"
                   [paginator]="true"
                   [rows]="10"
                   (sortFunction)="naturalSortPersona($event)"
                   [customSort]="true"
                   sortField="persona.lastName"
                   [sortOrder]='1'
                   dataKey="persona.lastName"
                   styleClass="p-datatable-sm p-datatable-striped"
                   [showCurrentPageReport]="true"
                    currentPageReportTemplate="{{'PAGINATION'|translate}}"
                    [rowsPerPageOptions]="[10, 25, 50]"
                   [globalFilterFields]="[
                  'firstName',
                  'lastName',
                  'publicName',
                ]"
        >
                <ng-template pTemplate="caption">
                  <div class="p-d-flex">
                    <span>
                      {{'PERSONAS'|translate}} <i class="bi bi-question-circle" pTooltip="{{'helper_PERSONAS_COMPETENCES_EXPIREES'|translate}}"></i>
                    </span>
                    <span class="p-ml-auto radioBtnSpan">
                      <p-radioButton class="radioBtn" name="filter" value="all" [(ngModel)]="selectedValue" inputId="all"></p-radioButton>
                      <label class="radioBtnSpan-label" for="all">{{'TOUS'|translate}}</label>
                      <p-radioButton class="radioBtn" name="filter" value="willExpire" [(ngModel)]="selectedValue" inputId="willExpire"></p-radioButton>
                      <label class="radioBtnSpan-label" for="willExpire">{{'filtre_EXPIRENT_BIENTOT'|translate}}</label>
                      <p-radioButton class="radioBtn" name="filter" value="expired" [(ngModel)]="selectedValue" inputId="expired"></p-radioButton>
                      <label class="radioBtnSpan-label" for="expired">{{'filtre_EXPIREES'|translate}}</label>
                    </span>
                    <span class="p-input-icon-left p-ml-5">
                      <i class="pi pi-search"></i>
                      <input
                        p
                        pInputText
                        type="text"
                        (input)="dt1.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{ 'recherche' | translate }}"
                      />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th id="photo"></th>
                    <th id="persona.lastName" pSortableColumn="persona.lastName">
                      {{ "NOM" | translate }}
                      <p-sortIcon field="persona.lastName"></p-sortIcon>
                    </th>
                    <th id="persona.firstName" pSortableColumn="persona.firstName">
                      {{ "PRENOM" | translate }}
                      <p-sortIcon field="firstName"></p-sortIcon>
                    </th>
                    <th id="persona.publicName" pSortableColumn="persona.publicName">
                      {{ "DEUXIEME_NOM" | translate }}
                      <p-sortIcon field="publicName"></p-sortIcon>
                    </th>
                    <th id="persona.company.name" pSortableColumn="persona.company.name">
                      {{ "ENTREPRISE" | translate }}
                      <p-sortIcon></p-sortIcon>
                    </th>
                    <th id="ExpDate">
                        {{ "DATE_EXPIRATION" | translate }}
                    </th>
                    <th id="TimeLeft">
                        {{ "DELAI_RESTANT_JOURS" | translate }}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="colgroup">
                  <colgroup>
                    <col style="width: 4%" />
                    <col style="width: 9%" />
                    <col style="width: 12%" />
                    <col style="width: 12%" />
                    <col style="width: 15%" />
                    <col style="width: 12%" />
                    <col style="width: 10%" />
                  </colgroup>
                </ng-template>
                <ng-template pTemplate="body" let-persona let-ri="rowIndex" let-columns>
                  <tr *ngIf="persona.daysRemaining <= 0" [hidden]="selectedValue!='expired'">
                    <td>
                      <img
                        *ngIf="persona?.persona.photo"
                        src="{{ persona?.persona.photo }}"
                        class="header-image"
                      />
                      <img
                        *ngIf="!persona?.persona.photo"
                        class="header-image"
                        src="assets/img/default-avatar.png"
                        alt="Profibild"
                      />
                    </td>
                    <td>{{ persona.persona.lastName }}</td>
                    <td>{{ persona.persona.firstName }}</td>
                    <td>{{ persona.persona.publicName }}</td>
                    <td>{{ persona.persona.company.name }}</td>
                    <td >{{ persona.endDate | date:'d/M/yyyy' }}</td>
                    <td *ngIf="persona.daysRemaining > 0" >{{ persona.daysRemaining }}</td>
                    <td *ngIf="persona.daysRemaining <= 0" ><div class="expired">EXPIRÉE</div></td>
                  </tr>

                  <tr *ngIf="persona.daysRemaining > 0" [hidden]="selectedValue!='willExpire'">
                    <td>
                      <img
                        *ngIf="persona?.persona.photo"
                        src="{{ persona?.persona.photo }}"
                        class="header-image"
                      />
                      <img
                        *ngIf="!persona?.persona.photo"
                        class="header-image"
                        src="assets/img/default-avatar.png"
                        alt="Profibild"
                      />
                    </td>
                    <td>{{ persona.persona.lastName }}</td>
                    <td>{{ persona.persona.firstName }}</td>
                    <td>{{ persona.persona.publicName }}</td>
                    <td>{{ persona.persona.company.name }}</td>
                    <td >{{ persona.endDate | date:'d/M/yyyy' }}</td>
                    <td *ngIf="persona.daysRemaining > 0" >{{ persona.daysRemaining }}</td>
                    <td *ngIf="persona.daysRemaining <= 0" ><div class="expired">EXPIRÉE</div></td>
                  </tr>

                  <tr [hidden]="selectedValue!='all'">
                    <td>
                      <img
                        *ngIf="persona?.persona.photo"
                        src="{{ persona?.persona.photo }}"
                        class="header-image"
                      />
                      <img
                        *ngIf="!persona?.persona.photo"
                        class="header-image"
                        src="assets/img/default-avatar.png"
                        alt="Profibild"
                      />
                    </td>
                    <td>{{ persona.persona.lastName }}</td>
                    <td>{{ persona.persona.firstName }}</td>
                    <td>{{ persona.persona.publicName }}</td>
                    <td>{{ persona.persona.company.name }}</td>
                    <td >{{ persona.endDate | date:'d/M/yyyy' }}</td>
                    <td *ngIf="persona.daysRemaining > 0" >{{ persona.daysRemaining }}</td>
                    <td *ngIf="persona.daysRemaining <= 0" ><div class="expired">EXPIRÉE</div></td>
                  </tr>
                </ng-template>
              </p-table>
              <mat-dialog-actions class="returnBtnDiv">
                <button class="returnBtn" mat-button mat-dialog-close>{{'formulaire_RETOUR'|translate}}</button>
              </mat-dialog-actions>
        </div>
       
    </div>