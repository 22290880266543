import { Pipe, PipeTransform } from '@angular/core';
import { ControlsType, SelectWithBoxAndQuantity } from '@data/schema/intervention/Form';

@Pipe({
  name: 'isSelectWithBoxAndQuantity',
})
export class IsSelectWithBoxAndQuantityPipe implements PipeTransform {

  transform(controlsType : ControlsType): controlsType is SelectWithBoxAndQuantity {
    return controlsType?.code === "selectWithBoxAndQuantity";
  }

}
