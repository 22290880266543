<h3 [style]="{'font-weight': '600', 'font-size': '1.25rem'}">Confirmer la sortie des consommables</h3>
<p-table 
    #dt2
    (sortFunction)="naturalSort($event)"
    [customSort]="true"
    sortField="value.selectedMateriel.label" 
    [sortOrder]='1'
    [globalFilterFields]="[
    'value.selectedMateriel.label',
    'value.selectedMateriel.quantity',
    'cons.materialDto.label',
    'cons.quantityMaterial',
    'quantiteSortie'
    ]" 
    [value]="listItem"
    styleClass="p-datatable-sm p-datatable-striped"
>


<ng-template pTemplate="caption">
  <div class="p-d-flex p-jc-between p-m-3">
    <div></div>
    <div>
      <span class="p-input-icon-left p-ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="dt2.filterGlobal($event.target.value, 'contains')"
          placeholder="Rechercher"
        />
      </span>
    </div>
  </div>
  </ng-template>
  
<ng-template pTemplate="colgroup">
  <colgroup>
      <col style="width:20%">
      <col style="width:30%">
      <col style="width:30%">
      <col style="width:20%">

  </colgroup>
</ng-template>


  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="cons.materialDto.label">Nom
        <p-sortIcon field="cons.materialDto.label"></p-sortIcon>
      </th>
      <th style="text-align: right" pSortableColumn="cons.quantityMaterial">Quantité totale
        <p-sortIcon field="cons.quantityMaterial"></p-sortIcon>
      </th>
      <th style="text-align: right" pSortableColumn="quantiteSortie">Quantité sortie
        <p-sortIcon field="quantiteSortie"></p-sortIcon>
      </th>
      <!--<th style="text-align: right" pSortableColumn="quantiteRestante">Quantité restante
        <p-sortIcon field="quantiteRestante"></p-sortIcon>
      </th>-->
      <th> </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-voucher let-ri="rowIndex">
    <tr class="p-selectable-row">
      <td>{{voucher?.cons?.materialDto?.label}}</td>
      <td style="text-align: right">{{voucher?.cons?.quantityMaterial}}</td>
      <td style="text-align: right">{{voucher?.quantiteSortie}}</td>
      <!--<td style="text-align: right">{{voucher?.quantiteRestante}}</td>-->
      <td>
        <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
          (click)="editArticle(voucher)" [style]="{'margin-right': '5px'}">
        </button>
        <div [style]="{'display': 'inline-block'}">
          <button pButton pRipple type="button" icon="pi pi-trash"
          class="p-button-rounded p-button-text" (click)="deleteProduct(voucher)"
            ></button>
        </div>
      </td>
    </tr>
    <br>
  </ng-template>
</p-table>

