import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-persona-create-steps',
  templateUrl: './new-persona-steps.component.html',
})
export class NewPersonaStepsComponent implements OnInit {
  
  items: MenuItem[];
  subscription: Subscription;

  constructor() {

  }

  ngOnInit(): void {
    this.items = [
      {
        label: 'Persona',
        routerLink: 'persona',
      },
      {
        label: 'Compétences',
        routerLink: 'assign-skill',
      }
      
    ];
  }
}
