<app-kober-menu module="kober-services"></app-kober-menu>
<div class="p-grid p-d-flex p-ml-2 p-pl-2 main-page">
  <div class="p-mt-3 p-pt-3 p-ml-3 p-pl-3">
    <p-breadcrumb [model]="items"></p-breadcrumb>
  </div>
</div>
<form [formGroup]="contractForm" (ngSubmit)="sendContract()">
<div class="p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-jc-center overFlow">

    <div class="p-col-12 p-md-12 p-lg-5 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <app-contract-form-general [contractForm]="contractForm" [mode]="mode"
                                 [isSubmit]="isSubmit" [invalidDatesOnCompanyChanged]="invalidDatesOnCompanyChanged"></app-contract-form-general>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <app-contract-form-company [formC]="contractForm" [mode]="mode" [isSubmit]="isSubmit" (invalidDatesOnChangeCompanyEvent)="invalidDatesOnChangeCompany($event)"></app-contract-form-company>
    </div>
    <div class="p-col-12 p-md-12 p-lg-11-custom p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <app-contract-form-select-prestation [contractForm]="contractForm" [mode]="mode" [isSubmit]="isSubmit"></app-contract-form-select-prestation>
    </div>
  </div>
</div>
</form>

<p-sidebar
  class="p-text-right"
  [style]="{ height: '13vh' }"
  [visible]="true"
  [blockScroll]="true"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="bottom"
>
  <div>
    <!--RETOUR A LA LISTE-->
    <p-button styleClass="p-button-outlined p-mr-2" (click)="back()" label="Annuler"></p-button>

    <p-button styleClass="p-mr-2" [disabled]="contractForm.invalid || invalidDatesOnCompanyChanged" (click)="sendContract()" label="Valider"></p-button>
  </div>
</p-sidebar>
<p-confirmDialog baseZIndex=5 styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>


