import {Injectable} from '@angular/core';
import {Skill} from '@data/schema/Skill';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Tag} from "@data/schema/Tag";
import {EnvService} from "@core/service/env.service";
import { EnumInfoDto } from '@data/schema/EnumInfoDto';

@Injectable({
  providedIn: 'root',
})
export class SkillService {
  private apiURL = this.environment?.readConfig()?.urlApiGateway + 'skill/';

  constructor(private http: HttpClient, private environment: EnvService) {
  }


  public assignTagToSkills(Skills : Skill[]): Observable<any> {
    return this.http.post(this.apiURL + "assignTagToSkills", Skills , {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }


  public updateSkillsWithTags(Skills : Skill[]): Observable<any> {
    return this.http.put(this.apiURL + "update-with-tags", Skills , {
      observe: 'response' as 'body',
     });


  }

  // GET LIST OF ALL SKILLS
  public getListSkill(): Observable<any> {
    return this.http
      .get(this.apiURL + 'getAllSkill', {observe: 'response' as 'body'})
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getListSkillWithTags(): Observable<any> {
    return this.http
      .get(this.apiURL + 'getListSkill', {observe: 'response' as 'body'})
      .pipe(
        map((res) => {
          return res;
        })
      );
  }




  // GET LIST OF ALL SKILLS BY TAGS
  public getSkillListByTag(listOfTags: Tag[]): Observable<any> {
    return this.http.post(this.apiURL + "listSkillByTag", listOfTags, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  public getListSkillByType(label: string): Observable<any> {
    return this.http
      .get(this.apiURL + 'getAllByType/' + label, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // GET A SKILL By ID
  public getSkills(id: number): Observable<any> {
    return this.http
      .get(this.apiURL + `getSkillById/` + id, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // GET A SKILL WITH TAGS
  public getSkillWithTags(id: number): Observable<any> {
    return this.http
      .get(this.apiURL + `getSkill/` + id, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getExcelData() {
    return this.http.get<any>(`${this.apiURL}exportExcel`, {responseType: 'arraybuffer' as 'json'});
  }

  public getPdfData() {
    return this.http.get<any>(`${this.apiURL}exportPdf`, {responseType: 'arraybuffer' as 'json'});
  }

  public getCsvData() {
    return this.http.get<any>(`${this.apiURL}exportCsv`, {responseType: 'arraybuffer' as 'json'});
  }


  // Create Skill
  public createSkill(skill: Skill): Observable<any> {
    return this.http.post(this.apiURL + `addSkillWithTag`, skill, {
      observe: 'response' as 'body',
    });
  }

  public createSkills(skills: Skill[]): Observable<any> {
    return this.http.post(this.apiURL + `addSkills`, skills, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }

  // UPDATE A SKILL WITH NEW VALUES
  public updateSkill(id: number, skill: Skill): Observable<any> {
    return this.http.put(this.apiURL + `updateSkill/` + id, skill, {
      observe: 'response' as 'body',
    });
  }

  // DELETE A SKILL
  public deleteSkill(id: number): Observable<any> {
    return this.http.delete(this.apiURL + `deleteSkill/` + id, {
      observe: 'response' as 'body',
    });
  }

  getTagSkill(): Observable<any> {
    return this.http.get(this.apiURL + "tags", {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

  async getTemplateXLSX(): Promise<Blob> {
    try {
      let base_url = this.apiURL;
      let url = base_url + "import/download_xlsx_template";
      
      const response = await this.http.get(url, { responseType: 'blob' }).toPromise();
      return response as Blob;
    } catch (error) {
      console.error('Error fetching XLSX file:', error);
      throw error;
    }
  }

  getLegendEnumNames( language): Observable<EnumInfoDto[]> {
    let base_url = this.apiURL;
    return this.http.get<EnumInfoDto[]>(base_url + '/import/legend/' + language);
  }

}
