import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";

import {BehaviorSubject, Observable, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {LoginService} from "@core/service/login.service";
import {MessageService} from "primeng/api";
import {TokenStorageService} from "@core/service/token-storage.service";
import {catchError, filter, switchMap, take} from "rxjs/operators";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router, private login: LoginService, private tokenStorageService: TokenStorageService, private messageService: MessageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.tokenStorageService.getToken()) {
      request = this.addToken(request, this.tokenStorageService.getToken());
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (request.url.includes("authentication/login")) {
          return throwError(error);
        }
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (this.tokenStorageService.isTokenExpired() && !request.url.includes("authentication/login")) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Session Expirée',
        detail: 'Veuillez vous reconnecter s\'il vous plait'
      });
      this.login.logout();
      this.router.navigate(['']);
    } else {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this.tokenStorageService.refreshToken(this.tokenStorageService.getRefreshToken()).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token);
            return next.handle(this.addToken(request, token.body.access_token));
          }));

      } else {
        return this.refreshTokenSubject.pipe(
          filter(token => token != null),
          take(1),
          switchMap(token => {
            return next.handle(this.addToken(request, token.body.access_token));
          }));
      }
    }
  }

  private addToken(request: HttpRequest<any>, token?: string) {
    if (token) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });
    }
  }
}
