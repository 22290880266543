<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-ai-center vertical-container p-jc-center">
      <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
        <div class="p-grid p-jc-between p-p-3"> 
          <h2>{{ 'etape_PERSONAS' | translate }}</h2>
          <div>       
            <container *ifRoles="['administrateur', 'admin_metier', 'admin_responsable_DPO']">
              <button *ngIf="!isLoading" 
                      class="p-mr-5 p-mt-5" 
                      pButton 
                      (click)="userSync()" 
                      pTooltip="Synchroniser les personas">
                <span style="font-size: 1.2rem" class="pi pi-replay"></span>
              </button>
            
              <img *ngIf="isLoading" 
                   src="assets/GIF/Loader.gif" 
                   width="50px" 
                   height="50px" 
                   pTooltip="Synchronisation des personas en cours" />
            </container>     
            <button *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO"]' class="p-mr-5 p-mt-5" pButton routerLink="/phoenix/new-persona" type="button" label="{{ 'CREER' | translate }}"></button>
          </div>
        </div>

      <p-table
        #dt1
        [value]="persona"
        [lazy]="true"
        (onLazyLoad)="loadPersona($event)"
        [loading]="loading"
        class="table"
        [scrollable]="true"
        scrollHeight="40vh"
        [paginator]="true"
        [rows]="10"
        (sortFunction)="naturalSortPersona($event)"
        [customSort]="true"
        sortField="lastName"
        [sortOrder]="1"
        dataKey="lastName"
        [totalRecords]="totalRecords"
        styleClass="p-datatable-sm p-datatable-striped"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'PAGINATION'|translate}}"
        [rowsPerPageOptions]="[10, 25, 50]"
        (onPage)="onPageChangeReloadData()"
        (onSort)="onPageChangeReloadData()"
        [globalFilterFields]="[
                'firstName',
                'lastName',
                'publicName',
                'listProfil?.name',
                'company',
                'mail',
                'personalNumber'
              ]"
      >
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <p-button (click)="onPress()">{{ display }}</p-button>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input
                p
                pInputText
                type="text"
                (input)="onSearchChangeReloadData($event.target.value)"
                placeholder="{{ 'recherche' | translate }}"
              />
            </span>
            <button pButton pRipple style="width: auto; margin-left: 20px;" (click)="exportToExcel()"  matTooltip="{{ 'tooltip_EXPORTER_EXCEL' | translate }}"
            matTooltipClass="tooltip" id="export">
            <mat-icon class="pi pi-upload" style="margin: auto;"></mat-icon>
            </button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th id="photo"></th>
            <th id="lastName" pSortableColumn="lastName">
              {{ "NOM" | translate }}
              <p-sortIcon field="lastName"></p-sortIcon>
            </th>
            <th id="firstName" pSortableColumn="firstName">
              {{ "PRENOM" | translate }}
              <p-sortIcon field="firstName"></p-sortIcon>
            </th>
            <th id="publicName" pSortableColumn="publicName">
              {{ "NOM_PUBLIC" | translate }}
              <p-sortIcon field="publicName"></p-sortIcon>
            </th>
            <th id="listProfil" pResizableColumn >
              {{ "PROFILS" | translate }}
            </th>
            <th id="company" pResizableColumn pSortableColumn="company">
              {{ "ENTREPRISE" | translate }}
              <p-sortIcon field="company"></p-sortIcon>
            </th>
            <th id="mail" pSortableColumn="mail">
              {{ " EMAIL" | translate }}
              <p-sortIcon field="mail"></p-sortIcon>
            </th>
            <th id="personalNumber" pSortableColumn="personalNumber">
              {{ "NUM_PERSONEL" | translate }}
              <p-sortIcon field="personalNumber"></p-sortIcon>
            </th>
            <th id="actions" colspan="2" class="p-text-center table-10"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="colgroup">
          <colgroup>
            <col style="width: 8%" />
            <col style="width: 9%" />
            <col style="width: 12%" />
            <col style="width: 12%" />
            <col style="width: 15%" />
            <col style="width: 12%" />
            <col style="width: 10%" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="body" let-persona let-ri="rowIndex" let-columns>
          <tr>
            <td>
              <img
                *ngIf="persona?.photo"
                src="{{ persona?.photo }}"
                class="header-image"
              />
              <img
                *ngIf="!persona?.photo"
                class="header-image"
                src="assets/img/default-avatar.png"
                alt="Profibild"
              />
            </td>
            <td>{{ persona.lastName }}</td>
            <td>{{ persona.firstName }}</td>
            <td>{{ persona.publicName }}</td>
            <td>
              <div class="p-grid p-d-flex">
                <ng-container *ngFor="let profile of persona.listProfil">
                  <p-chip
                    label="{{ profile.nameFR }}"
                    class="profileChip"
                  ></p-chip>
                </ng-container>
              </div>
            </td>
            <td>{{ persona?.company.name }}</td>
            <td>{{ persona.mail }}</td>
            <td>{{ persona.personalNumber }}</td>
            <td class="p-text-center table-5">
              <button
                routerLink="/phoenix/show-persona/{{ persona.id }}"
                pButton
                pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-rounded p-button-text p-button-plain"
              ></button>
            </td>
            <td class="p-text-center table-5">
              <button
                *ifRoles="[
                  'administrateur',
                  'admin_metier',
                  'admin_responsable_DPO',
                  'manager_operationnel'
                ]"
                pButton
                pRipple
                type="button"
                icon="pi pi-ellipsis-h"
                (click)="
                  getRowPersona(persona);
                  op.toggle($event);
                  isTechPartOfTeam(persona.idParty)
                "
                class="p-button-rounded p-button-text p-button-plain"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <p-overlayPanel #op appendTo="body" baseZIndex="2">
        <ng-template pTemplate>
          <div #actualTarget>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-pencil"
              class="p-button-rounded p-button-text"
              [style]="{ 'margin-right': '5px' }"
              label="Modifier"
              (click)="update()"
            ></button
            ><br />
            <ng-contaire
              *ifRoles="[
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO'
              ]"
            >
              <button
                *ngIf="enabled"
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                (click)="changePersonaStatus()"
                class="p-button-rounded p-button-text"
                label="Supprimer"
              ></button>
            </ng-contaire>
            <ng-contaire
              *ifRoles="[
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO'
              ]"
            >
              <button
                *ngIf="!enabled"
                pButton
                pRipple
                type="button"
                icon="pi pi-undo"
                (click)="changePersonaStatus()"
                class="p-button-rounded p-button-text"
                label="Activer"
              ></button>
            </ng-contaire>
          </div>
        </ng-template>
      </p-overlayPanel>
      <p-confirmDialog
        baseZIndex="5"
        styleClass="main-page"
        [style]="{ width: '60%' }"
      ></p-confirmDialog>
    </div>
  </div>
</div>
