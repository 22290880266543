<div class="p-col-12">
  <!--  SELECT THIS DIV IF ONE COLUMN FORM AND DELETE THE ONE ABOVE
    <div class="p-col-12 p-d-flex p-jc-center">-->
  <div class="p-grid p-fluid">
    <div class="p-col-6">
      <h3>{{'COMPETENCES_REQUISES'|translate}}</h3>
    </div>
    <div class="p-col-6">
      <app-filtre-tag (newArrayFilter)="filterArrayByTag($event)" [typeOfTag]="typeOfTag"></app-filtre-tag>
    </div>
  </div>
</div>
<div
  *ngFor="let skill of work.listSkillDto; let index = index"
  class="p-grid p-col-12 p-d-flex"
>
  <div class="p-col-8">
    <div class="p-fluid">
      <div class="p-grid">
        <div *ngIf="index === 0" class="p-col-10">
          <label for="skill" class="p-mb-2 p-md-2 p-mb-md-0">
            {{'COMPETENCE'|translate}}
          </label>
        </div>
        <div class="p-col-10">
          <p-dropdown
            id="skill"
            [(ngModel)]="work.listSkillDto[index].skillDto"
            [options]="listSkill[index]"
            placeholder="Selection"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            (onHide)="selectSkill()"
          ></p-dropdown>
          <p-message
            *ngIf="checkIsDuplicate(work.listSkillDto[index].skillDto)"
            severity="error"
            text="Cette compétence existe déjà."
          ></p-message>
          <p-message
            *ngIf="(work.listSkillDto.length-1) === index && errorNotFilled"
            severity="error"
            text="Veuillez sélectionner une compétence avant d'en ajouter une autre."
          ></p-message>
        </div>
        <div class="p-col-2 p-d-flex p-ai-center">
          <i *ngIf="!work.listSkillDto[index].skillDto.name" tooltipPosition="bottom" pTooltip="Sélectionner un consommable dans la liste" class="pi pi-question-circle"></i>
          <i *ngIf="work.listSkillDto[index].skillDto.name" tooltipPosition="bottom" [pTooltip]="work.listSkillDto[index].skillDto.description" class="pi pi-question-circle"></i>
          <button (click)="deleteSkill(index)" pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-sm p-ml-1"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="p-col-12 p-d-flex p-ai-center">
  <button
    (click)="addSkill()"
    pButton
    pRipple
    type="button"
    icon="pi pi-plus"
    class="p-button-rounded p-button-text"
  ></button>
  <span (click)="addSkill()">Ajouter une compétence</span>
</div>
