<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
  <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
    <h2>
      Fiche {{ company.name }}
      <p-tag
        *ngIf="company.enabled"
        styleClass="p-mr-2"
        severity="success"
        value="Active"
      ></p-tag
      ><p-tag
        *ngIf="company && !company.enabled"
        styleClass="p-mr-2"
        severity="danger"
        value="Inactive"
      ></p-tag>
    </h2>
    <div class="p-col-12">
      <!--(onSelect) to trigger function-->
      <div class="p-ml-4 profilepic">
        <img
          *ngIf="company?.logo"
          class="profilepic__image"
          src="{{ company?.logo }}"
          alt="Profibild"
        />
        <img
          *ngIf="!company?.logo"
          class="profilepic__image"
          width="150"
          height="150"
          src="assets/img/empty-image.png"
          alt="Profibild"
        />
        <div class="profilepic__content">
          <!-- <span class="profilepic__text">Modifier la photo</span><br> -->
          <p-fileUpload
            [hidden]="!updateGlobal"
            mode="basic"
            (onSelect)="onSelection($event, ftl)"
            #ftl
            multiple="false"
            fileLimit="1"
            accept="image/*"
            invalidFileSizeMessageSummary="Impossible de charger cette photo (taille max autorisée 1MB)."
            invalidFileSizeMessageDetail="L'image ne sera pas enregistrée."
            [showUploadButton]="false"
            cancelLabel="Annuler"
            class="p-pb-1"
            title="Modifier la photo"
          >
          </p-fileUpload>
          <div
            *ngIf="updateGlobal && this.company.logo"
            class="suppr"
            (click)="deletePhoto()"
            title="Supprimer la photo"
          >
            <i class="pi pi-times p-mt-1"></i>
          </div>
        </div>
      </div>
      <div class="p-grid p-col-12 p-d-flex p-jc-center">
        <div class="p-col-6">
          <div class="p-fluid">
            <div class="p-field p-grid">
              <label for="nom" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                {{ 'NOM' | translate }} *
              </label>

              <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                <span *ngIf="!updateGlobal">{{ company.name }}</span>
                <input
                  type="text"
                  value="{{ company.name }}"
                  *ngIf="updateGlobal"
                  pInputText
                  id="name"
                  [ngClass]="{ 'ng-invalid ng-dirty': errorNameRequired }"
                  type="text"
                  pInputText
                  [(ngModel)]="company.name"
                  (ngModelChange)="onChange($event)"
                  required
                  name="name"
                />
                <p-message
                  *ngIf="errorNameRequired"
                  severity="error"
                  text="Champ obligatoire."
                ></p-message>
              </div>
            </div>

            <div class="p-field p-grid">
              <label for="mail" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                Email
              </label>
              <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                <span *ngIf="!updateGlobal">{{ company.email }}</span>
                <input
                  *ngIf="updateGlobal"
                  [(ngModel)]="company.enabled"
                  (ngModelChange)="onChange($event)"
                  id="mail"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <!-- <div class="p-field p-grid">
              <label for="type" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                Type
              </label>
              <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                <span *ngIf="!updateGlobal">{{ company.type }}</span>
                <p-autoComplete
                  *ngIf="updateGlobal"
                  required
                  type
                  [(ngModel)]="company.type"
                  (ngModelChange)="onChange($event)"
                  [suggestions]="filterCompany"
                  (completeMethod)="companyType($event)"
                  [dropdown]="true"
                  id="type"
                  name="type"
                  [ngClass]="{ 'ng-invalid ng-dirty': errorTypeRequired }"
                  (blur)="typeFieldValidator($event)"
                  (input)="typeFieldValidator($event)"
                >
                </p-autoComplete>
                <p-message
                  *ngIf="errorTypeRequired"
                  severity="error"
                  text="Champ obligatoire."
                ></p-message>
              </div>
            </div> -->
          </div>
        </div>

        <div class="p-col-6">
          <div class="p-fluid">
            <div class="p-field p-grid">
              <label for="manager" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                Dirigeant
              </label>
              <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                <span *ngIf="!updateGlobal">{{ company.manager }}</span>
                <input
                  *ngIf="updateGlobal"
                  (ngModelChange)="onChange($event)"
                  [(ngModel)]="company.manager"
                  id="manager"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <label for="phone" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                Télephone
              </label>
              <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                <span *ngIf="!updateGlobal">{{ company.phone }}</span>
                <input
                  *ngIf="updateGlobal"
                  [(ngModel)]="company.phone"
                  id="phone"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <label for="adress" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                Adresse
              </label>
              <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                <span *ngIf="!updateGlobal">{{ company.address }}</span>
                <input
                  *ngIf="updateGlobal"
                  [(ngModel)]="company.address"
                  id="adress"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <label for="siret" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                Siret
              </label>
              <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                <span *ngIf="!updateGlobal">{{ company.siret }}</span>
                <input
                  *ngIf="updateGlobal"
                  [(ngModel)]="company.siret"
                  id="siret"
                  type="text"
                  pInputText
                />
              </div>
            </div>
          </div>
        </div>
        <p-confirmDialog
          baseZIndex="5"
          styleClass="main-page"
          [style]="{ width: '60%' }"
        ></p-confirmDialog>

        <!-- update skill router link -->
        <p-sidebar
          class="p-text-right"
          [style]="{ height: '13vh' }"
          [visible]="true"
          [blockScroll]="true"
          [dismissible]="false"
          [showCloseIcon]="false"
          [modal]="false"
          position="bottom"
        >
          <p-button
            styleClass="p-button-outlined p-mr-2"
            *ngIf="!updateGlobal"
            label="Retour"
            routerLink="/phoenix/company"
          ></p-button>
          <p-button
            *ngIf="updateGlobal"
            styleClass="p-button-outlined p-mr-2"
            label="Retour"
            (click)="confirmReturnBottom()"
          ></p-button>
          <!-- <ng-container *ngIf="!deletable">
            <p-button
              *ifRoles="[
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO'
              ]"
              class="p-mr-2 p-mb-2"
              (click)="confirme()"
              label="Supprimer"
            ></p-button>
          </ng-container> -->
          <!-- <ng-container *ngIf="deletable">
            <p-button
              *ifRoles="[
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO'
              ]"
              class="p-mr-2 p-mb-2"
              (click)="confirmActiveCompany()"
              label="Activer"
            ></p-button>
          </ng-container> -->
          <!-- <p-button
            *ifRoles="[
              'administrateur',
              'admin_metier',
              'admin_responsable_DPO'
            ]"
            class="p-mr-2 p-mb-2"
            (click)="confirmUpdateCompany()"
            label="{{ label }}"
          ></p-button> -->
        </p-sidebar>
      </div>
    </div>
  </div>
</div>
<p-dialog
  [(visible)]="display"
  [draggable]="false"
  (onHide)="clearUpload(ftl)"
  styleClass="main-page"

>
  <image-cropper
    [imageBase64]="imageBase64"
    [maintainAspectRatio]="false"
    [containWithinAspectRatio]="containWithinAspectRatio"
    [resizeToWidth]="200"
    [resizeToHeight]="200"
    [cropperMinWidth]="128"
    [onlyScaleDown]="true"
    [canvasRotation]="canvasRotation"
    [transform]="transform"
    [alignImage]="'center'"
    [style.display]="showCropper ? null : 'none'"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"


    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
  <ng-template pTemplate="footer">
    <p-button (click)="onUpload(ftl)">Valider</p-button>
  </ng-template>
</p-dialog>
