import { Injectable } from '@angular/core';
import {Company} from "@data/schema/Company";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {



  private apiURL = this.environment?.readConfig()?.urlApiGateway + "company/";

  constructor(private http: HttpClient, private environment: EnvService) { }

// GET LIST OF ALL Company
public getListCompany(): Observable<any> {
  return this.http.get(this.apiURL + "getAllCompany", {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}

  public getListCompanyActive(): Observable<any> {
    return this.http.get(this.apiURL + "getAllActiveCompany", {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    )
  }

public getListAllEnableCompany(): Observable<any> {
  return this.http.get(this.apiURL + "getAllActiveCompany"  , {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}

public getAllDisableCompany(): Observable<any> {
  return this.http.get(this.apiURL + "getAllDisableCompany"  , {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}


 // GET A Company By ID
 public getCompanyById(id: number): Observable<any> {
  return this.http.get(this.apiURL + `getCompanyById/` + id, {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}

 // GET A Company By Name
 public getCompanyByName(name: string): Observable<any> {
  return this.http.get(this.apiURL + `getCompanyByName/` + name, {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}

// Create Company
public createCompany(company: Company): Observable<any> {
  return this.http.post(this.apiURL + `addCompany`, company, {observe: 'response' as 'body'});
}


// UPDATE A COMPANY WITH NEW VALUES
public updateCompany(company: Company): Observable<any> {
  return this.http.put(this.apiURL + `updateCompany` ,  company, {observe: 'response' as 'body'});
}

// DELETE A COMPANY
// public deleteCompany(id: number): Observable<any> {
//   return this.http.delete(this.apiURL + `deleteCompanyById/` + id, {observe: 'response' as 'body'});
// }

// ACTIVATE A COMPANY
public activateCompanyById(id: number): Observable<any> {
  return this.http.put(this.apiURL + `activateCompanyById/` + id, {observe: 'response' as 'body'});
}

// DISABLE A COMPANY
public disableCompany(id: number): Observable<any> {
  return this.http.put(this.apiURL + `disableCompanyById/` + id, {observe: 'response' as 'body', responseType: 'text'});
}
// GET ASSOCIATIONS
public getAssociations(): Observable<any> {
  return this.http.get(this.apiURL + `getAssociationType` , {observe: 'response' as 'body'});
}

// isExists By ID
 public isExistById(id: number): Observable<any> {
  return this.http.get(this.apiURL + `isExistById/` + id, {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}

// isExists By Name
 public isExistByName(name: String): Observable<any> {
  return this.http.get(this.apiURL + `isExistByName/` + name, {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}


// isExists By Siret
 public isExistBySiret(siret: String): Observable<any> {
  return this.http.get(this.apiURL + `isExistBySiret/` + siret, {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}

public isCompanyHasOrganization(companyID: number): Observable<any> {
  return this.http.get(this.apiURL + `companyHasOrganisation/` + companyID, {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}

public isRelatedPartiesCompanyNotNull(companyID: number): Observable<any> {
  return this.http.get(this.apiURL + `relatedPartiesCompanyNotNull/` + companyID, {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}


//TODO change name function
public isCompanyHasPersona(companyID: number): Observable<any>{
  return this.http.get(this.apiURL + `companyHasPersona/` + companyID, {observe: 'response' as 'body'}).pipe(
    map(res => {
      return res;
    })
  )
}

}
