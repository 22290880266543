<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
  <div class="p-grid p-col-12 p-md-10 p-m-3 p-p-3 main-content p-shadow-4">
    <div class="p-col-12">
      <h2>Matériels</h2>
      <p-table
        class="table"
        #dt1
        styleClass="p-datatable-gridlines p-mb-4"
        [style]="{'font-family': 'calibri'}"
        [value]="materiels"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'PAGINATION'|translate}}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="[
    'label',
    'description',
    'categorie.label',
    'unit.name'
  ]"
      >
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
      <span class="p-input-icon-left p-ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="dt1.filterGlobal($event.target.value, 'contains')"
          placeholder="Rechercher"
        />
      </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="label">
              Nom
              <p-sortIcon field="label"></p-sortIcon>
            </th>
            <th pSortableColumn="description">
              {{ 'DESCRIPTION' | translate }}
              <p-sortIcon field="description"></p-sortIcon>
            </th>
            <th pSortableColumn="categorie.label">
              Catégorie
              <p-sortIcon field="categorie.label"></p-sortIcon>
            </th>
            <th pSortableColumn="unit.name">
              Unité
              <p-sortIcon field="unit.name"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-materiels let-ri="rowIndex">
          <tr>
            <td>{{materiels.label}}</td>
            <td>{{materiels.description}}</td>
            <td>{{materiels.categorie.label}}</td>
            <td>{{ materiels.unit.name }}</td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
</div>

