import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { WorkOrderAnnouncer } from '@data/schema/intervention/WorkOrderAnnouncer';
import { Team } from '@data/schema/intervention/teamSchema';
import { CommandApiService } from '@data/service/intervention/command-api.service';
import { InterventionApiService } from '@data/service/intervention/intervention-api.service';
import { PartyApiService } from '@data/service/intervention/party-api.service';
import { WorkOrderAnnouncerService } from '@data/service/intervention/work-order-announcer.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkOrderCommands } from '@shared/enums/commands';
import { DataService } from '@shared/service/data.service';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, retryWhen } from 'rxjs/operators';
import {SellerbotService} from "@data/service/sellerbot.service";
import { LocalStorageService } from '@shared/service/local-storage.service';

@Component({
  selector: 'app-teams-list-dialog',
  templateUrl: './teams-list-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./teams-list-dialog.component.scss'],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: 'mat-dialog-override' } }
  ]
})
export class TeamsListDialogComponent implements OnInit {
  teams: Team[] = [];
  loading: boolean = true;
  assignLoading: boolean = false;
  allExpandState: boolean = false;
  searchMode: boolean = true;
  expanded: boolean = false;
  inputSearch: any = '';
  arrayOfIndex: boolean[] = [];
  teamObject = {} as any;
  searchCriteria: any = {};
  token: any;
  selected = false;
  indexOfTeam: number; // determine which team to select
  indexOfWorkOrder: number; // determine which Work Order to select.
  workOrderPoolIds: number[] = [];
  currentIndex: number;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  selection = false;
  // actorRole: any;
  actor: any;

  constructor(private partyService: PartyApiService, @Inject(MAT_DIALOG_DATA) public indata: any,
    private _snackBar: MatSnackBar,
    private commandApiService: CommandApiService,
    private translate: TranslateService,
    private dataService: DataService,
    public router: Router,
    private interventionService: InterventionApiService,
    private workOrderAnnouncerService : WorkOrderAnnouncerService,
    private sellerbotService: SellerbotService,
    public dialogRef: MatDialogRef<TeamsListDialogComponent>,
    private localStorageService : LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.actor = JSON.parse(sessionStorage.getItem("persona"));
    // this.actorRole = sessionStorage.getItem("auth-role");
    if (this.localStorageService.hasPermission("teams:viewbydefault")) {
      this.selection = true;
      if (this.localStorageService.hasPermission("teams:viewall")) {
        this.getDataTeams();
      }
      else {
        this.getUsersTeams();
      }
    } else {
      this.getDataTeamsByPools();
    }
    
  }
  getDataTeams() {
    this.partyService.getAllTeams().subscribe(
      (res) => {
        if (res) {
          this.teams = res;
          this.loading = false;
        }
      }
    );
  }
  getUsersTeams() {
      this.partyService.getUserTeams(this.indata.userPools).subscribe(
        (res) => {
          if (res) {
            this.teams = res;
            this.loading = false;
          }
        }
      )
  }
  getDataTeamsByPools() {
    if (this.indata.workOrderPools) {
      this.workOrderPoolIds = this.indata.workOrderPools;
      let filteredWOPools = this.workOrderPoolIds;
      if (this.indata.userPools) {
        filteredWOPools = this.workOrderPoolIds.filter(poolId => this.indata.userPools.includes(poolId));
      }
      this.partyService.getUserTeams(filteredWOPools).subscribe(
        (res) => {
          if (res) {
            this.teams = res;
            this.loading = false;
          }
        }
      )
    }
  }

  getTeams() {
    this.selection = !this.selection;
    if (this.selection) {
      this.getDataTeams();
    } else {
      this.getDataTeamsByPools();
    }
  }
  getMyTeams() {
    this.selection = !this.selection;
    if (this.selection) {
      this.getUsersTeams();
    } else {
      this.getDataTeamsByPools();
    }
  }


  /**
   * Function that is called when the user select a team while assigning workOrders.
   */
  selectTeam(index) {
    // let techResponsible = this.teams[index].technicians.filter(data => data.responsible === true)[0];
    this.teamObject = {
      actionWO: WorkOrderCommands.ATTRIBUER,
      actorId: this.actor.idParty,
      // actorName: this.actor.firstName + " " + this.actor.lastName,
      // actorRole: this.actorRole,
      targetId: this.teams[index].technicians.filter(data => data.responsible === true)[0].idParty,
      // targetName: techResponsible.firstName + " " + techResponsible.lastName,
      // targetRole: "intervenant",
      workForcePool: {
        name: this.teams[index].name,
        id: this.teams[index].id,
        poolName: this.teams[index].pool.name,
        poolId: this.teams[index].pool.id
      }

    }
    this.indexOfTeam = index;
    this.selected = true;
  }

  /**
   * Function that open & close the panel
   */
  togglePanel(index) {
    this.currentIndex = index;
    this.expanded = !this.expanded;
    this.arrayOfIndex = new Array(this.teams.length).fill(this.expanded)
    this.arrayOfIndex[index] = !this.arrayOfIndex[index];
  }
  /**
   * Function that expand and collapse all the teams.
   */
  toggleAll() {
    this.allExpandState = !this.allExpandState;
    this.arrayOfIndex = new Array(this.teams.length).fill(this.allExpandState);
  }

  validateWorkOrderAssignement() {
    //let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    this.dialogRef.close( this.teamObject);

    if(this.indata.origin == 'list-multiple')
    {

    } else {

      let assignmentDate = (new Date(Date.now())).toISOString();
      this.indata.workOrder.assignmentDate = assignmentDate;
      this.indata.workOrder.targetId = this.teamObject.targetId;
      // this.indata.workOrder.targetName = this.teamObject.targetName;
      this.indata.workOrder.targetRole = this.teamObject.targetRole;
      let assignementObject = {
        woDTO: this.indata.workOrder,
        ...this.teamObject
      }
      this.assignLoading = true;
      this.dataService.changeMessage(this.assignLoading);

      this.triggerWOActionAndRetry(assignementObject);
    }
  }

  /**
   * Function that switch between search mode technicians and team names.
   */
  switchSearchMode(mode: string) {
    this.searchMode = !this.searchMode;
    if (mode === "team") {
      this.searchByTeamName();
    } else if (mode = "tech") {
      this.searchByTechFullame();
    }
  }
  /**
 * Function that search by team name.
 */
  searchByTeamName() {
    let partyId =  JSON.parse(sessionStorage.getItem("persona")).idParty
    this.searchCriteria['team'] = this.inputSearch;
    this.partyService.getTeamsByNames(this.searchCriteria, partyId, this.selection)
      .subscribe((res) => {
        this.teams = res;
      });
  }

  /**
   * Function that search by technicians fullName and return list of teams.
   */
  searchByTechFullame() {
    setTimeout(() => {
      this.searchCriteria['fullName'] = this.inputSearch;
      this.partyService
        .getTeamsWithSearchedTechnician(this.searchCriteria)
        .subscribe((res) => {
          this.teams = res;
        });
    }, 1000);
  }



  private triggerWOActionAndRetry(workOrderCommand: any) {
    var codeError;
    of(1)
      .pipe(
        concatMap(() => {
          return this.commandApiService.commandTrigger([workOrderCommand]);
        }),
        catchError((error) => {
          if (error.status === 417) {
            //retry
            codeError = error.status;
            return throwError(error.status);
          } else if (error.status === 424) {
            //rule not found
            //retry
            codeError = error.status;
            return throwError(error.status);
          } else if (error.status === 404) {
            //aggregate not found
            //retry
            codeError = error.status;
            return throwError(error.status);
          }
          this.openErrorToast();
          this.dataService.changeMessage(false);
          return of({ error: error });
        }),
        retryWhen((errors) => {
          this.getLatestCurrentWorkOrder(workOrderCommand);
          return errors
            .delay(500)
            .take(2)
            .concat(throwError({ code: codeError }));
        })
      ).subscribe(
        (result) => {
          if (result.status === 200) {
            this.openSuccessToast();
            let expectedVersionEvolution = 2;
            if(this.indata.origin === 'list'){
              this.workOrderAnnouncerService.announceWorkOrderListUpdate(new WorkOrderAnnouncer(expectedVersionEvolution))
            }else{
              this.workOrderAnnouncerService.announceWorkOrderDetailsUpdate(new WorkOrderAnnouncer(expectedVersionEvolution))
            }
            this.dataService.changeMessage(false);
          }
        },
        (error) => {
          console.log(error)
          if (error.code === 424) {
            this.triggerErrorToast('rule_not_found');
          } else if (error.code === 417) {
            this.triggerErrorToast('wrong_version');
          } else if (error.code === 404) {
            this.triggerErrorToast('aggregate_not_found');
          } else if (error.code === 500) {
            this.triggerErrorToast('server_error');
          } else {
            this.openErrorToast();
          }
          this.dataService.changeMessage(false);
        },
      );
    return codeError;
  }


  private getLatestCurrentWorkOrder(currentWO) {
    this.interventionService.getWorkOrderFromList(currentWO.woDTO.internalNumber)
      .subscribe((res) => {
        currentWO.woDTO = res.body
      })
  }

  private openErrorToast() {
    this._snackBar.open(
      this.translate.instant('toast_OPERATION_ERREUR'),
      'Exit',
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 3000,
        panelClass: ['error-snackbar'],
      }
    );
  }
  private triggerErrorToast(message: string) {
    this._snackBar.open(
      this.translate.instant(message),
      'Exit',
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 3000,
        panelClass: ['error-snackbar'],
      }
    );
  }
  private openSuccessToast() {
    this._snackBar.open(
      this.translate.instant('toast_OPERATION_REUSSI'),
      'Exit',
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 3000,
        panelClass: ['success-snackbar'],
      }
    );
  }
}
