import { Injectable } from '@angular/core';
import {LazyLoadEvent, SortEvent} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class CustomSortService {

  constructor() { }

  public naturalSort(event: SortEvent){
    console.log("event en dessous");
    console.log(event);
    event.data.sort((a, b) => {

      var ax = [], bx = [];

      const nestedField = (obj, path) => {
        return (path.split('.').reduce((value,el) => value[el], obj))
    };
    
    if(event.field === 'orderRef'){
      if(a.orderRef !== null && b.orderRef !== null){
        nestedField(a, event.field).toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
        nestedField(b, event.field).toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
      }
    }else if(event.field === 'recipient'){
      if(a.recipient !== null && b.recipient !== null){
        nestedField(a, event.field).toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
        nestedField(b, event.field).toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
      }
    }else if(event.field === 'localizationLabel'){
      if(a.localizationLabel !== null && b.localizationLabel !== null){
        nestedField(a, event.field).toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
        nestedField(b, event.field).toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
      }
    }else{
      nestedField(a, event.field).toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
      nestedField(b, event.field).toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
    }

      if(event.order === 1){
        while(ax.length && bx.length) {
          var an = ax.shift();
          var bn = bx.shift();

          var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1],'en', { sensitivity: 'base' });
          if(nn) return nn;
        }
        return ax.length - bx.length;
      }
      if(event.order === -1){
        console.log("-1");
        while(ax.length && bx.length) {
          var an = ax.shift();
          var bn = bx.shift();
          var nn = (bn[0] - an[0]) || bn[1].localeCompare(an[1],'en', { sensitivity: 'base' });
          if(nn) return nn;
        }
        return bx.length - ax.length;
      }

    });

  }

  


  public naturalSortPage(event: LazyLoadEvent, data: any){
    console.log("//////");
    console.log(event.sortField);
    console.log("//////");

    data.sort((a, b) => {

      var ax = [], bx = [];

      const nestedField = (obj, path) => (path.split('.').reduce((value,el) => value[el], obj));

      nestedField(a, event.sortField).toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
      nestedField(b, event.sortField).toString().replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });


      if(event.sortOrder === 1){
        while(ax.length && bx.length) {
          var an = ax.shift();
          var bn = bx.shift();

          var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1],'en', { sensitivity: 'base' });
          if(nn) return nn;
        }
        return ax.length - bx.length;
      }
      if(event.sortOrder === -1){
        console.log("-1");
        while(ax.length && bx.length) {
          var an = ax.shift();
          var bn = bx.shift();
          var nn = (bn[0] - an[0]) || bn[1].localeCompare(an[1],'en', { sensitivity: 'base' });
          if(nn) return nn;
        }
        return bx.length - ax.length;
      }

    });

  }



}
