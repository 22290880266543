import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { workOrderCommand } from '@data/schema/intervention/workOrderCommand';
import { Observable } from 'rxjs';
import {EnvService} from "@core/service/env.service";

@Injectable({
  providedIn: 'root'
})
export class CommandApiService {
  public apiURL = this.environment?.readConfig()?.urlApiGateway + "wocmd/";

  constructor(private http: HttpClient, private environment: EnvService,) { }


    public commandTrigger(commandPayload: workOrderCommand []): Observable<any> {
      return this.http.post(this.apiURL + 'change-wo' , commandPayload, {
        observe: 'response' as 'body',
      });
    }
}
