<app-kober-menu module="kober-services"></app-kober-menu>
<div class="p-grid p-d-flex p-ml-2 p-pl-2 main-page">
  <div class="p-mt-3 p-pt-3 p-ml-3 p-pl-3">
    <p-breadcrumb [model]="items"></p-breadcrumb>
  </div>
</div>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-ai-center vertical-container p-jc-center">

    <div class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <div class="p-grid p-jc-between p-p-3">

        <h2>{{'menu_PRESTATIONS'|translate}}</h2>
        <div>
          <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire"]' routerLink="/phoenix/new-work" class="p-mr-5 p-mt-5" pButton type="button" label="{{'CREER'|translate}}"></button>
          <!--button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO"]' class="p-mr-5 p-mt-5" (click)="showDialogImportFile()" pButton  type="button" label="Importer" pTooltip="Importer un fichier csv ou excel" tooltipPosition="bottom"
                  showDelay="250"
                  tooltipStyleClass="tooltip"></button-->

          <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire"]' type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-button-info p-mr-2" pTooltip="{{'tooltip_EXPORTER_CSV'|translate}}" tooltipPosition="bottom"></button>
          <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire"]' type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportToExcel()" class="p-button-success p-mr-2"  pTooltip="{{'tooltip_EXPORTER_EXCEL'|translate}}" tooltipPosition="bottom"></button>
          <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire"]' type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportToPdf()"  class="p-button-help p-mr-2"  pTooltip="{{'tooltip_EXPORTER_PDF'|translate}}" tooltipPosition="bottom"></button>

        </div>
      </div>
      <p-table
        selectionMode="multiple"
        (sortFunction)="naturalSortWork($event)"
        [customSort]="true"
        sortField="name" [sortOrder]='1'
        [(selection)]="selectedWorks"
        #dt1
        [value]="works"
        class="table"
        [scrollable]="true"
        scrollHeight="40vh"
        [paginator]="true"
        [rows]="10"
        styleClass="p-datatable-sm p-datatable-striped"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'PAGINATION'|translate}}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="[
          'name',
          'description',
        ]">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
              <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
              <input
              [style]="{'height': '45px'}"
                p pInputText
                type="text"
                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="{{'recherche'|translate}}"
              />

            </span>

          </div>
        </ng-template>


        <ng-template pTemplate="header" >
          <tr>
            <th id="name" pSortableColumn="name">
              {{'NOM'|translate}}
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th id="description" pSortableColumn="description">
              {{'DESCRIPTION'|translate}}
              <p-sortIcon field="description"></p-sortIcon>
            </th>
            <th id="actions" colspan="2" class="p-text-center table-10"></th>

        </ng-template>
        <ng-template pTemplate="body" let-works>
          <tr>
            <td>{{ works.name }}</td>

            <td>{{ works.description }}</td>
            <td class="p-text-center table-5">
              <a href="/phoenix/show-work/{{ works.idWork }}"><em class="pi pi-search"></em></a>
            </td>
            <td class="p-text-center table-5">
              <button *ifRoles='["administrateur", "admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire"]' pButton pRipple
                      type="button" icon="pi pi-ellipsis-h"
                      (click)="getRowIndex(works.idWork);op.toggle($event)"
                      class="p-button-rounded p-button-text p-button-plain"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-overlayPanel  #op appendTo="body" baseZIndex="2">
        <ng-template pTemplate>
          <div  #actualTarget>
            <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire"]' pButton pRipple type="button"
                    icon="pi pi-pencil" class="p-button-rounded p-button-text"
                    [style]="{'margin-right': '5px'}" label="Modifier" (click)="isWorkInUseBeforeUpdate()"></button>
            <br>
            <button *ifRoles='["administrateur","admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire"]' pButton pRipple type="button"
                    icon="pi pi-trash" (click)="isWorkInUseBeforeDelete()" class="p-button-rounded p-button-text"
                    label="Supprimer"></button>
          </div>
        </ng-template>
      </p-overlayPanel>


      <p-dialog header="Importer une liste de prestations" [(visible)]="displayImportFile"
                [modal]="true" [style]="{width: '50vw'}"
                [baseZIndex]="10000"
                [draggable]="false" [resizable]="false">

        <p-fileUpload
          #fileUpload
          [multiple]="false"
          chooseLabel="Choisir"
          [showCancelButton] = "false"
          uploadLabel="Charger"
          accept=".xlsx,.csv"
          customUpload="true"
          (uploadHandler)="getFile($event,fileUpload)"
          (onRemove)="fileRemoved()"
          (onSelect)="fileSelected()"
        ></p-fileUpload>


        <br><br>
        <div [style]="{'float': 'right'}">
          <button pButton pRipple type="button" label="Annuler"  class="p-mr-2 p-mb-2  p-button-outlined"
                  (click)="cancelUpload(fileUpload)"></button>
        </div>


      </p-dialog>
      <p-confirmDialog baseZIndex=5 key="isInUseWhenDelete" appendTo="body" styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>
      <p-confirmDialog baseZIndex=5 key="isInUseWhenUpdate" appendTo="body" styleClass="main-page"  [style]="{width: '60%'}"></p-confirmDialog>
      <p-confirmDialog baseZIndex=5 key="confirmDelete" styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>
      <p-confirmDialog baseZIndex=5 key="export" styleClass="main-page" [style]="{width: '60%'}"></p-confirmDialog>

      <!--p-dialog header="Liste de prestations à corriger" [(visible)]="displayErrorFile"

                [modal]="true" [style]="{width: '60vw'}"
                [baseZIndex]="10000"
                [draggable]="false" [resizable]="false">
        <div >

          <p-table
            class="table"
            #dt2
            styleClass="p-datatable-gridlines p-mb-4"
            [style]="{'font-family': 'calibri'}"
            [value]="worksError"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{{'PAGINATION'|translate}}"
            [rowsPerPageOptions]="[10, 25, 50]"
            [globalFilterFields]="[
      'work.name',
      'work.description',
      'work.cost',
      'work.currency'
      ]"

          >
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
      <span class="p-input-icon-left p-ml-auto">
      <i class="pi pi-search"></i>
      <input
        pInputText
        type="text"
        (input)="dt2.filterGlobal($event.target.value, 'contains')"
        placeholder="Rechercher"
      />
      </span>
            </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Numéro de ligne</th>
                <th>Nom</th>
                <th>Description</th>
                <th>Coût</th>
                <th>Devise</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-worksError let-ri="rowIndex" >
              <tr>
                <td>{{worksError.work.index}}</td>
                <td [ngStyle]="{'background-color': worksError.message.code === 600 || worksError.message.code === 601 || worksError.message.code === 606 || worksError.message.code === 607  ? '#F5B7B1' : '#FFFFFF' }" [pTooltip]="worksError.message.code === 600 || worksError.message.code === 601 || worksError.message.code === 606 || worksError.message.code === 607 ? worksError.message.message : '' " tooltipZIndex="100000000000">{{worksError.work.name}}</td>
                <td >{{worksError.work.description}}</td>
                <td [ngStyle]="{'background-color': worksError.message.code === 603 ? '#F5B7B1' : '#FFFFFF' }" [pTooltip]="worksError.message.code === 603 ? worksError.message.message : '' " tooltipZIndex="100000000000">{{worksError.work.cost}}</td>
                <td [ngStyle]="{'background-color': worksError.message.code === 604 || worksError.message.code === 605 ? '#F5B7B1' : '#FFFFFF' }" [pTooltip]="worksError.message.code === 604 || worksError.message.code === 605 ? worksError.message.message : '' " tooltipZIndex="100000000000">{{worksError.work.currency}}</td>

              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-dialog-->

    </div>
  </div>
</div>

