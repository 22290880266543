<div class="p-grid p-col-12 p-d-flex">
    <p-tabView>
      <p-tabPanel header="Prestations">
        <app-work-order-form-report-advance *ngIf="!editMode" [(workOrder)]="workOrder"></app-work-order-form-report-advance>
        <app-work-order-form-list-works *ngIf="editMode && workOrder.project" [(workOrder)]="workOrder" (isChangeListWork)="updateListCompany($event)"></app-work-order-form-list-works>
        <app-work-order-form-list-selected-works [(editMode)]="editMode" [(workOrder)]="workOrder" (isChangeListWork)="updateListCompany($event)"></app-work-order-form-list-selected-works>
      </p-tabPanel>
      <p-tabPanel header="Matériel">
        <app-work-form-list-materials
          [id]="id" [fromWorKOrder]="fromWorKOrder" [editMode]="editMode" [attachedCompany]="workOrder.attachedToCompany" [listWorkOrderProjectWork]="workOrder.listWorkOrderProjectWork"
        >
        </app-work-form-list-materials>
      </p-tabPanel>
      <p-tabPanel header="Budget"   *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO", "manager_operationnel" , "manager_operationnel_prestataire", "manager_CA"]' >
        <app-work-form-list-cost
          [listWorkOrderProjectWork]="workOrder.listWorkOrderProjectWork"
          [listWorksCompany]="workOrder.listWorksCompany"
          [currency]="currency"
        ></app-work-form-list-cost>
      </p-tabPanel>
      <p-tabPanel *ngIf="workOrder.id" header="Historique">
        <app-work-form-history [listHistoryProgress]= "workOrder.listHistoryProgress" [listVoucherforot]="workOrder.listVoucherforot"></app-work-form-history>
      </p-tabPanel>
    </p-tabView>
  </div>
