import { Pipe, PipeTransform } from '@angular/core';
import { Item, Textbox } from '@data/schema/intervention/Form';

@Pipe({
  name: 'isTextBox'
})
export class IsTextBoxPipe implements PipeTransform {

  transform(item :Item): item is Textbox {
    return item.itemType?.code === "Text";
  }

}
