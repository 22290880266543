import {Injectable} from '@angular/core';
import jwtDecode from "jwt-decode";
import {LoginService} from "./login.service";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {EnvService} from "@core/service/env.service";

const TOKEN_KEY = 'auth-token';
const ROLE = 'auth-role';
const REFRESH_TOKEN = 'refresh-token';
const PERMISSION = 'auth-scope';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private apiURL = this.environment?.readConfig()?.urlApiGateway + "authentication/";


  constructor(private http: HttpClient, private loginService: LoginService,
              private environment: EnvService,
  ) {
  }

  public saveAccessToken(token: string): void {
    sessionStorage.setItem(TOKEN_KEY, token);
    sessionStorage.setItem(ROLE, this.decodeToken().realm_access?.roles[0]);
    sessionStorage.setItem(PERMISSION, this.decodeToken().scope);
  }

  public getToken(): string | null {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public getRole(): string | null {
      return sessionStorage.getItem(ROLE);
  }

  public getPermission(): string | null {
    return sessionStorage.getItem(PERMISSION);
}
  public getRefreshToken() {
    return sessionStorage.getItem(REFRESH_TOKEN);
  }

  public decodeToken(): any {
    const user = sessionStorage.getItem(TOKEN_KEY);
    if (user) {
      return jwtDecode(JSON.stringify(user));
    }
    return {};
  }

  public getTokenExpirationDate(token: string): Date {
    const decoded: any = jwtDecode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  public isTokenExpired(token?: string, tokenRefresh?: string): boolean {
    if (!token) {
      token = this.getToken();
    }
    if (!tokenRefresh) {
      tokenRefresh = this.getRefreshToken();
    }
    if (!token && !tokenRefresh) {
      return true;
    }

    const dateT = this.getTokenExpirationDate(token);
    const dateR = this.getTokenExpirationDate(tokenRefresh);

    if (dateT.valueOf() < new Date().valueOf()) {
      if (dateR.valueOf() < new Date().valueOf()) {
        this.loginService.logout();
        return true;
      }
      return false;
    }
    return false;
  }

  public saveRefreshToken(refresh_token: any) {
    sessionStorage.setItem(REFRESH_TOKEN, refresh_token);
  }

  public refreshToken(refresh_token?: string) {
    return this.http.post<any>(this.apiURL + "v2/accessTokenWithRefreshToken",{refreshToken: refresh_token, client: null}, { observe: 'response' as 'body' })
    .pipe(tap((res: any) => {
      this.saveAccessToken(res.body.access_token);
      this.saveRefreshToken(res.body.refresh_token);
    }));
  }
}
