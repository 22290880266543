<div *ngIf="mode === 'consultation' || mode === 'update'">
  <div class="p-col-12">
    <div class="p-grid p-jc-between p-p-3">
      <h2 class="title">{{'ENTREPRISE'|translate}}</h2>
    </div>
    <div class="p-grid p-field">
     <div class="p-col-8">
        <div class="p-field p-grid">
          <!--<label class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">Nom de l'entreprise</label><br>
          <div class="p-col-12 p-md-8 p-pl-5 p-pr-5">
            <label class="font">{{contracts?.company?.name }}</label>
          </div>-->
        </div>
      </div>
      <div class="p-col-4" style="margin-top: -10%;">
        <img *ngIf="contracts?.company?.logo" style="object-fit: contain;" height="150" width="150" src="{{contracts?.company?.logo}}"/>
        <img *ngIf="!contracts?.company?.logo" style="object-fit: contain;" width="150" height="150" src="assets/img/empty-image.png"/>
      </div>
    </div>
    <div class="p-grid p-field">
      <div class="p-col-6">
        <div class="p-fluid">
          <div class="p-field p-grid">
            <label for="nom" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              {{'NOM'|translate}}
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{contracts?.company?.name }}</label>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="mail" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              {{'EMAIL'|translate}}
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{ contracts?.company?.email }}</label>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="type" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              {{'TYPE'|translate}}
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{contracts?.company?.type}}</label>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="manager" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              {{'DIRIGEANT'|translate}}
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{ contracts?.company?.manager }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-fluid">
          <div class="p-field p-grid">
            <label for="phone" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              {{'TELEPHONE'|translate}}
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{ contracts?.company?.phone }}</label>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="adress" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              {{'ADRESSE'|translate}}
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{ contracts?.company?.address }}</label>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="siret" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              Siret
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{ contracts?.company?.siret }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div [formGroup]="formC" class="p-grid" *ngIf="mode === 'create'">
  <h2 class="p-col-12 p-md-12 p-lg-12">{{'ENTREPRISE'|translate}}</h2>
  <div class="p-col-12 p-md-12 p-lg-6">
    <label>{{'ENTREPRISE'|translate}}</label><br>
    <p-dropdown placeholder="Sélectionner l'entreprise"
                [options]="listOfCompany" filterBy="name" [filter]="true" [showClear]="true" formControlName="company"
                optionLabel="name" (onChange)="isContractDataValidForSave($event)"></p-dropdown>
    <div
      *ngIf="formC.controls.company.invalid && (formC.controls.company.dirty || formC.controls.company.touched || isSubmit)"
      class="alert alert-danger">
      <div *ngIf="formC.controls.company.errors.required">
        <p-message severity="error" class="companyError" text="Champ obligatoire."></p-message>
      </div>
      <div *ngIf="formC.controls.company.errors.required">
        <p-message *ngIf="formC?.controls?.company?.errors?.forbiddenCompany" severity="error"
                   class="contratNameError"
                   text="Entreprise non valide"></p-message>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-md-12 p-lg-6" style="padding-left: 15%;">

    <img *ngIf="formC.value?.company?.logo" height="150" width="150" style="object-fit: contain;" src="{{formC.value?.company?.logo}}"/>
    <img *ngIf="formC.value?.company && !formC.value?.company?.logo" height="150" width="150" style="object-fit: contain;" src="assets/img/empty-image.png"/>
  </div>
  <br>
  <br>
  <div *ngIf="formC?.value?.company" class="p-col-12 p-md-12 p-lg-12">
    <label style="font-size: 22px;">{{'DESCRIPTION'|translate}}</label><br>
    <div style="padding-top: 4%;" class="p-grid p-col-12 p-d-flex">
      <div class="p-col-6">
        <div class="p-fluid">
          <div class="p-field p-grid">
            <label for="nom" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              {{'NOM'|translate}}
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{formC.value?.company?.name }}</label>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="mail" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              {{'EMAIL'|translate}}
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{ formC.value?.company?.mail }}</label>
            </div>
          </div>

          <div class="p-field p-grid">
            <label for="type" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              {{'TYPE'|translate}}
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{formC.value?.company?.type}}</label>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="manager" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              {{'DIRIGEANT'|translate}}
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{formC.value?.company?.manager }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-fluid">
          <div class="p-field p-grid">
            <label for="phone" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              Télephone
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{ formC.value?.company?.phone }}</label>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="adress" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              Adresse
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{ formC.value?.company?.adress }}</label>
            </div>
          </div>

          <div class="p-field p-grid">
            <label for="siret" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">
              Siret
            </label>
            <div class="p-col-12 p-md-8 p-pl-5 p-pr-5 breakWord">
              <label class="font">{{ formC.value?.company?.siret }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
