<app-kober-menu module="kober-setting"></app-kober-menu>
<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3">
    <app-setting-tag [(setting)]="setting"></app-setting-tag>
  </div>
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-2">
    <div class="p-grid p-jc-between p-p-3">
      <h2>Tags</h2>
      <button class="p-mr-5 p-mt-5" pButton  routerLink="/phoenix/new-tag" type="button" label=" Créer"></button>
      <p-tabView>
        <p-tabPanel header="{{ 'TOUS' | translate }}">
          <app-list-tag [tags]="tags" (isBookmarked)="updateAllTag($event)"></app-list-tag>
        </p-tabPanel>
        <p-tabPanel header="{{ 'FAVORIS' | translate }}">
          <app-list-tag [tags]="tagsbookmarked" (isBookmarked)="updateAllTag($event)"></app-list-tag>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>

</div>
