import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { WorkOrder } from '@data/schema/WorkOrder';
import { Project } from '@data/schema/Project';
import { WorkOrderService } from '@data/service/work-order.service';
import { WorkOrderFormGeneralComponent } from '../../components/work-order/work-order-form-general/work-order-form-general.component';
import {Company} from "@data/schema/Company";
import {CurrencyService} from "@data/service/currency.service";
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-new-work-order',
  templateUrl: './new-work-order.component.html',
  styleUrls: ['./new-work-order.component.css'],
})
export class NewWorkOrderComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild(WorkOrderFormGeneralComponent)
  private workOrderGeneral!: WorkOrderFormGeneralComponent;

  public submited: BehaviorSubject<boolean>;

  //@HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (this.submited.value || !this.workOrder.isDefined() ? true : false)
  }

  workOrder: WorkOrder = new WorkOrder();

  project: Project = new Project();

  listCompanyByWork : Company[] = [];

  items: MenuItem[];

  constructor(
    private router: Router,
    public workOrderService: WorkOrderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private currencyService: CurrencyService
  ) {
    this.submited = new BehaviorSubject(false);
  }

  ngOnInit(): void {

    this.items = [
      {label:'Ordre de travail', routerLink:'/phoenix/work-order'},
      {label:"Création d'un OT", styleClass:'ariane-current'}
    ];
    this.isCurrencyAlreadyConfigured();
  }

  back() {

    if (this.submited.value || !this.workOrder.isDefined()) {

      this.gotoList();

    }

    else{

      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez quitter la page, les données seront perdues.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        accept: () => {
          this.gotoList();
          },
        });

    }

  }

  submit() {

    let validForm = true;
    if (this.workOrderGeneral.checkAllConditions()) {
      validForm = false;
    }
    if (!validForm) {
      this.messageService.add({
        severity: 'error',
        summary: 'Échec de l’opération : veuillez remplir tous les champs',
      });
    } else {
      this.save();
    }

  }

  save() {

    this.workOrderService.createWorkOrder(this.workOrder).subscribe(
      (res) => {
        if (res.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        }
      },
      (error) => {
        if (error) {
          if (error.status === 500) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
          if (error.status === 409) {
            this.messageService.add({
              severity: 'error',
              summary: 'Cet élément existe déjà',
            });
          }
        }
      },
      () => {

        this.gotoList();
      }
    );
  }

  // redirect to list page
  gotoList() {

    this.redirectTo('/phoenix/work-order');
  }
  redirectTo(uri: string) {
    this.submited.next(true);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  isCurrencyAlreadyConfigured(){

    this.currencyService.currencyAlreadyConfigured().subscribe(
      (res)=>{
        console.log(res.body);
        if(!res.body){
          this.confirmationService.confirm({
            header: 'Action impossible',
            message:
              "La devise n’est pas paramétrée.",
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: 'null',
            acceptLabel: "J'ai compris",
            rejectVisible: false,

            accept: () => {
              this.redirectTo('/phoenix/work-order');
            },
            reject: () => {
              this.redirectTo('/phoenix/work-order');
            },
          });
        }
      }
    )
  }
}
