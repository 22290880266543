import {Component, Input, OnInit} from '@angular/core';
import {Tag} from "@data/schema/Tag";
import {ConfirmationService, MessageService} from "primeng/api";
import {TagService} from "@data/service/tag.service";

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {
  tags: Tag[] = [];
  tagsbookmarked: Tag[] = [];
  setting: string = "Tags";

  constructor(private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private tagService: TagService) {
  }


  ngOnInit(): void {
    this.getTagAll();
    this.takeAllTagsBookmarked();
  }

  getTagAll(){
    this.tagService.getAllTags().subscribe(
      (res) => {
        if (res.status === 200) {
          this.tags = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

  takeAllTagsBookmarked(){
    this.tagService.getAllTagsBookmarkted().subscribe(
      (res) => {
        if (res.status === 200) {
          this.tagsbookmarked = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }
  updateAllTag(a:boolean){
    this.getTagAll();
    this.takeAllTagsBookmarked();
  }
}
