<h2>Créer un tag</h2>
<div class="p-grid p-col-12 p-d-flex">
  <div class="p-col-6">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="label" class="p-mb-2 p-md-2 p-mb-md-0"> Nom * </label>
        </div>
        <div class="p-col-10">
          <p-autoComplete
            id="label"
            [(ngModel)]="tag.label"
            [suggestions]="outputNameTag"
            (completeMethod)="search($event)"
            (onKeyUp)="nameTagFieldValidator()"
            (onSelect)="nameTagFieldValidator()"
            (ngModelChange)="change()"
            [minLength]="1"
          ></p-autoComplete>
          <p-message
            *ngIf="errorNameTagRequired"
            severity="error"
            text="Champ obligatoire."
          ></p-message>
          <p-message
            *ngIf="errorforbiddenNameTag"
            severity="error"
            text="Nom déja existant."
          ></p-message>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-6">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="color" class="p-mb-2 p-md-2 p-mb-md-0"> Couleur tag </label>
        </div>
        <div class="p-col-10">
          <div class="p-inputgroup">
            <input type="color" [(ngModel)]="tag.colorCode" (ngModelChange)="change()" class="colorPickerTag">
            <input type="text"
                   [(ngModel)]="tag.colorCode"
                   (ngModelChange)="change()"
                   pInputText>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-6">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="description" class="p-mb-2 p-md-2 p-mb-md-0">
            Description
          </label>
        </div>
        <div class="p-col-10">
          <textarea
            [rows]="4"
            [(ngModel)]="tag.description"
            (ngModelChange)="change()"
            pInputTextarea
          >
          </textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-6">
    <div class="p-fluid">
      <div class="p-grid">
        <div class="p-col-10">
          <label for="color" class="p-mb-2 p-md-2 p-mb-md-0"> Choisir parmis les suggestions </label>
        </div>
        <div class="p-col-10">

          <button class="myButton red"  (click)="changeColor('#FFADAD')" [style]="{ 'padding-right': '8px'}"></button>
          <button class="myButton orange"  (click)="changeColor('#FFD6A5')" [style]="{ 'padding-right': '8px'}"></button>
          <button class="myButton yellow"  (click)="changeColor('#FDFFB6')" [style]="{ 'padding-right': '8px'}"></button>
          <button class="myButton green"  (click)="changeColor('#CAFFBF')" [style]="{ 'padding-right': '8px'}"></button>
          <button class="myButton light-blue"  (click)="changeColor('#9BF6FF')" [style]="{ 'padding-right': '8px'}"></button>
          <button class="myButton dark-blue"  (click)="changeColor('#A0C4FF')" [style]="{ 'padding-right': '8px'}"></button>
          <button class="myButton purple"  (click)="changeColor('#BDB2FF')" [style]="{ 'padding-right': '8px'}"></button>
          <button class="myButton pink"  (click)="changeColor('#FFC6FF')" [style]="{ 'padding-right': '8px'}"></button>

        </div>
      </div>
    </div>
  </div>
</div>
