<app-kober-menu module="kober-skill"></app-kober-menu>
<div class="p-grid p-d-flex p-jc-center p-m-2 p-p-2 main-page margin-b-20">
  <div class="p-grid p-col-12 p-md-11 p-m-3 p-p-3 main-content p-shadow-4">
    <div class="p-col-12">
      <h2>Créer une entreprise</h2>
      <form #myForm="ngForm" style="position: relative">
        <div class="profilepic">

          <img *ngIf="company?.logo" class="profilepic__image" src="{{company?.logo}}" width="150" height="150" alt="Profibild" />
          <img *ngIf="!company?.logo" class="profilepic__image" width="150" height="150"  src="assets/img/empty-image.png" alt="Profibild" />
          <div class="profilepic__content">
            <!-- <span class="profilepic__text">Modifier la photo</span><br> -->
            <p-fileUpload
                mode="basic"
                (onSelect)="onSelection($event,ftl)"
                #ftl
                multiple="false"
                fileLimit="1"
                accept="image/*"

                invalidFileSizeMessageSummary="Impossible de charger cette photo (taille max autorisée 1MB)."
                invalidFileSizeMessageDetail="L'image ne sera pas enregistrée."
                [showUploadButton]="false"
                cancelLabel="Annuler"
                class="p-pb-1"
                title = "Modifier la photo"
              >

              </p-fileUpload>
              <div *ngIf="company?.logo" class="suppr" (click)="deletePhoto()" title="Supprimer la photo"><i class="pi pi-times p-mt-1"></i></div>


          </div>
        </div>
        <div class="p-grid p-col-12 p-d-flex p-jc-center">
          <div class="p-col-6">
            <div class="p-field p-grid p-fluid">
              <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                Nom *
              </label>
              <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                <p-autoComplete
                  required
                  firstName
                  [(ngModel)]="company.name"
                  [suggestions]="outputName"
                  (completeMethod)="filterName($event)"
                  id="name"
                  name="name"
                  [minLength]="1"
                  pattern="[a-zA-Z0-9\s-éçèà]+"
                  [ngClass]="{ 'ng-invalid ng-dirty': errorNameRequired }"
                  (onBlur)="nameFieldValidator($event)"
                >
                </p-autoComplete>
                <div
                  *ngIf="
                    myForm.controls['name']?.invalid &&
                    myForm.controls['name']?.touched
                  "
                >
                  <p-message
                    *ngIf="errorNameRequired"
                    severity="error"
                    text="Champ obligatoire."
                  >
                  </p-message>
                </div>
                <div *ngIf="myForm.controls['name']?.errors?.pattern">
                  <p-message
                    severity="error"
                    text="Format non valide (caractères acceptés : alphanumérique et - uniquement)."
                  >
                  </p-message>
                </div>
              </div>
            </div>
            <div
              class="p-field p-grid p-fluid"
              [class.has-error]="email.invalid && email.touched"
            >
              <label
                for="email"
                class="p-col-12 p-mb-2 p-md-2 p-mb-md-0 control-label"
              >
                Email
              </label>
              <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                <input
                  id="email"
                  email
                  name="email"
                  pInputText
                  #email="ngModel"
                  [(ngModel)]="company.email"
                  type="text"
                />

                <p-message
                  *ngIf="email.errors?.email && email.touched"
                  severity="error"
                  text="Format non valide (exemple@domaine.com)."
                ></p-message>
              </div>
            </div>

            <div class="p-field p-grid p-fluid">
              <label for="type" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                Type
              </label>
              <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                <p-autoComplete
                  firstName
                  [(ngModel)]="company.type"
                  [suggestions]="filterCompany"
                  (completeMethod)="companyType($event)"
                  [dropdown]="true"
                  id="type"
                  name="type"
                >
                </p-autoComplete>
              </div>
            </div>
          </div>
          <div class="p-col-6">
            <div class="p-fluid">
              <div class="p-field p-grid">
                <label for="manager" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                  Dirigeant
                </label>
                <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                  <input
                    type="text"
                    class="form-control"
                    pInputText
                    id="manager"
                    [(ngModel)]="company.manager"
                    name="manager"
                    value=""
                  />
                </div>
              </div>
              <div class="p-field p-grid">
                <label for="phone" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                  Téléphone
                </label>
                <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                  <input
                    type="tel"
                    name="phone"
                    pInputText
                    [(ngModel)]="company.phone"
                    (input)="validPhoneFormat($event)"
                  />
                  <div *ngIf="errorPhone">
                    <p-message
                      severity="error"
                      text="Format non valide (caractères acceptés : numériques et +
                    uniquement)."
                    ></p-message>
                  </div>
                </div>
              </div>

              <div class="p-field p-grid">
                <label for="address" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                  Adresse
                </label>
                <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                  <p-autoComplete
                    [(ngModel)]="company.address"
                    [suggestions]="outputAddress"
                    (completeMethod)="filterAddress($event)"
                    class="form-control"
                    id="address"
                    name="address"
                    [minLength]="1"
                  >
                  </p-autoComplete>
                </div>
              </div>

              <div class="p-field p-grid">
                <label for="siret" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                  Siret
                </label>
                <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                  <input
                    type="text"
                    class="form-control"
                    pInputText
                    id="siret"
                    [(ngModel)]="company.siret"
                    name="siret"
                    value=""
                  />
                </div>
              </div>

              <div class="p-field p-grid">
                <label for="enable" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                  Active
                </label>
                <div class="p-col-12 p-md-10 p-pl-5 p-pr-5">
                  <p-inputSwitch
                    id="enable"
                    [(ngModel)]="company.enabled"
                    name="enable"
                  ></p-inputSwitch>
                </div>
              </div>
            </div>

            <p-confirmDialog
              baseZIndex="5"
              styleClass="main-page"
              [style]="{ width: '60%' }"
            ></p-confirmDialog>
            <p-dialog
              [(visible)]="display"
              [draggable]="false"
              styleClass="main-page"

            >
              <image-cropper
                [imageBase64]="imageBase64"
                [maintainAspectRatio]="false"
                [containWithinAspectRatio]="containWithinAspectRatio"
                [resizeToWidth]="200"
                [cropperMinWidth]="128"
                [onlyScaleDown]="true"
                [canvasRotation]="canvasRotation"
                [transform]="transform"
                [alignImage]="'center'"
                [style.display]="showCropper ? null : 'none'"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
              ></image-cropper>
              <ng-template pTemplate="footer">
                <p-button (click)="onUpload()">Valider</p-button>
              </ng-template>
            </p-dialog>

            <p-sidebar
              class="p-text-right"
              [style]="{ height: '13.5vh' }"
              [visible]="true"
              [blockScroll]="true"
              [dismissible]="false"
              [showCloseIcon]="false"
              [modal]="false"
              position="bottom"
            >
              <button
                class="p-mr-2 p-mb-2 p-button-outlined"
                pButton
                type="button"
                label="Annuler"
                (click)="confirm()"
              ></button>

              <button
                class="p-mr-2 p-mb-2"
                pButton
                type="button"
                label="Valider"
                [disabled]="!myForm.form.valid"
                (click)="submit()"
              ></button>
            </p-sidebar>
          </div>
          <div class="p-text-left">
            <p-checkbox (onChange)="wantToCreate($event)"></p-checkbox>
            Créer une autre entreprise
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
