
<div class="card">
  <p-table
    (sortFunction)="naturalSortWork($event)"
    [customSort]="true"
    sortField="name" [sortOrder]='1'
    styleClass="p-datatable-sm p-datatable-striped"
    #dt1
    class="table"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{'PAGINATION'|translate}}"
    [rowsPerPageOptions]="[10, 25, 50]"
    [value]="works"
    [globalFilterFields]="['name', 'description']"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between p-m-3">
        <div>
          <span [style]="{'font-weight': 'bold'}">Toutes les prestations</span>
        </div>
        <div>
          <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-left':'-30px'}">
            <i class="pi pi-search" [style]="{'margin-left':'10%','height': '45px','margin-top':'5px'}"></i>
            <input
            [style]="{'margin-left':'10%','height': '45px'}"
              pInputText
              type="text"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Rechercher"
            />
        </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="table-5"></th>
        <th>Prestations</th>
        <th>Description</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-work>
      <tr>
        <td class="table-5">
          <button
            (click)="addWork(work)"
            pButton
            pRipple
            type="button"
            icon="pi pi-plus"
            class="p-button-rounded p-button-text"
          ></button>
        </td>
        <td>{{ work.name }}</td>
        <td>{{ work.description }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [draggable]="false"
  header="{{ addedWork.work?.name }}"
  [(visible)]="displayModal"
  [modal]="true"
  styleClass="main-page"
  [style]="{ width: '60%' }"
  (onShow)="setFocus()"
>
  <div class="p-grid">
    <div class="p-col-12">
      <label for="quantite" class="p-mb-2 p-md-2 p-mb-md-0"> Quantité </label>
      <div class="p-m-2">
        <p-inputNumber
          [(ngModel)]="addedWork.quantityTotal"
          mode="decimal"
          [showButtons]="true"
          buttonLayout="horizontal"
          spinnerMode="horizontal"
          decrementButtonClass="p-button-primary"
          incrementButtonClass="p-button-primary"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"

          size="7"
          #input
        >
        </p-inputNumber>
      </div>
      <div>
        <p-message
        *ngIf="addedWork.quantityTotal < 1"
        severity="error"
        text="Veuillez sélectionner une quantité pour la prestation"
      ></p-message>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <label for="description" class="p-mb-2 p-md-2 p-mb-md-0">
        Commentaire
      </label>
    </div>
    <div class="p-col-12 p-fluid">
      <!-- <textarea [(ngModel)]="project.description" (ngModelChange)="change()" [rows]="4" pInputTextarea> </textarea>  -->
      <textarea
        [(ngModel)]="addedWork.comment"
        [rows]="6"
        pInputTextarea
      >
      </textarea>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="p-text-right">
      <!--RETOUR A LA LISTE-->
      <p-button
        styleClass="p-button-outlined p-mr-2"
        label="Annuler"
        (click)="cancel()"
      ></p-button>

      <p-button
        styleClass="p-mr-2"
        label="Valider"
        (click)="validWork()"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
