import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {PersonaService} from '@data/service/persona.service';
import {Skill} from '@data/schema/Skill';
import {Persona, PersonaSkills} from '@data/schema/Persona';
import {DataService} from '@shared/service/data.service';
import {CustomSortService} from "@shared/service/custom-sort.service";
import { SortEvent } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-persona-add-skill',
  templateUrl: './add-skill.component.html',
  styleUrls: ['./add-skill.component.css'],
})
export class AddSkillComponent implements OnInit {

  skillSelected: Skill[];
  skill: Skill[];
  assignedSkills: PersonaSkills[] = [];
  persona: Persona = new Persona();
  listSkillIdOfPersona : any[];
  typeOfTag: string = "skill";

  constructor(
    public personaService: PersonaService,
    private ref: DynamicDialogRef,
    private dataService: DataService,
    private customSortService: CustomSortService,
    private translate: TranslateService
  ) {
  }

  filterArrayByTag(newArrayFiltered: any) {
    // change filtre pour récupérer que les filtres qui ne sont pas déja assignés au persona
    newArrayFiltered = newArrayFiltered.filter(x => !this.listSkillIdOfPersona.includes(x.id))
    this.skill = newArrayFiltered;
  }

  ngOnInit() {
    this.persona = this.personaService.personaInformation.persona;

    this.skill = this.personaService.listSelectableSkills;
    this.alphaNumericSort(this.skill)
    this.skill.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    // récupération des skill id
    this.listSkillIdOfPersona = this.persona.listSkill.map(x => x.idSkill);
  }

  save() {
    for (let i = 0; i < this.assignedSkills.length; i++) {
      this.assignedSkills[i].idPersona = this.persona.id;
    }
    this.ref.close(this.assignedSkills);
  }

  // Calculate end date based on parameters values
  calculateEndDate(date, days, months, years) {
    var d = new Date(date);

    d.setDate(d.getDate() + days);
    d.setFullYear(d.getFullYear() + years);
    d.setMonth(d.getMonth() + +months);

    return d;
  }

  // auto calculate
  startDateOnChange(index: number) {

    this.alphaNumericSort(this.skillSelected)
    let days = this.skillSelected[index].day_duration
    let month = this.skillSelected[index].month_duration
    let year = this.skillSelected[index].year_duration

    this.addTimeZoneOffsetToCalendarTime(this.assignedSkills[index].startDate)
    this.assignedSkills[index].endDate = this.calculateEndDate(this.assignedSkills[index].startDate, days, month, year)
  }

  addTimeZoneOffsetToCalendarTime(event) {
    /*To be removed when primeng fixes the bug related to p calendar or when migrating to another library*/
    event.setTime(event.getTime() - (new Date().getTimezoneOffset() * 60 * 1000));
    /**/
  }

  // Add skill of assignedSkill based on checkbox event
  assignSkills(event: any) {
    let skill = event.data;
    var now = new Date(Date.now());
    let personaSkill = {
      id: null,
      idPersona: null,
      idSkill: skill.id,
      skill: skill,
      startDate: now,
      endDate: this.calculateEndDate(
        now,
        skill.day_duration,
        skill.month_duration,
        skill.year_duration
      )
    };
    this.assignedSkills.push(personaSkill);
  }

  removeSkills(event: any) {

    this.assignedSkills = this.assignedSkills.filter(x => x !== event.data);

  }

  // Removed skill from checkbox and assignedSkills when removing a chip
  removeSkill(event: any, skill: Skill) {

    this.skillSelected = this.skillSelected.filter(x => x !== skill);
    this.alphaNumericSort(this.skillSelected)
    this.assignedSkills = this.assignedSkills.filter(
      (item) => item.idSkill !== skill.id
    );
  }

  naturalSort(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

  alphaNumericSort = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.name.match(/\d+|\D+/g);
    const bPart = b.name.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };


  getValidity(year: number, month: number, day: number) {
    var message = "Validité : "
    if (year > 0) {
      message += year + " " + this.translate.instant('ANNEE_S')
    }
    if (month > 0) {
      if (year > 0) {
        message += ", "
      }
      message += month + " " + this.translate.instant('MOIS_S')
    }
    if (day > 0) {
      if (year > 0 || month > 0) {
        message += ", "
      }
      message += day + " " + this.translate.instant('JOUR_S')
    }
    return message;
  }
}
