import { Component, OnInit, HostListener } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Skill } from '@data/schema/Skill';
import { Technician } from '@data/schema/Technician';
import { PersonaService } from '@data/service/persona.service';
import { PersonaSkills, Persona } from '@data/schema/Persona';
import { QrDataFormaterService } from '@shared/service/qr-data-formater.service';
import { SkillService } from '@data/service/skill.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenStorageService } from '@core/service/token-storage.service';
import { CompanyService } from '@data/service/company.service';
import { Company } from '@data/schema/Company';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { FileUpload } from 'primeng/fileupload';

import { Location } from '@angular/common';
import { AddSkillComponent } from 'src/app/kober-skill/persona/show-persona/add-skill.component';
import { StockService } from '@data/service/stock.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { CreationTeamService } from '@data/service/intervention/save-team.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-show-technician',
  templateUrl: './show-technician.component.html',
  styleUrls: ['./show-technician.component.css'],
})
export class ShowTechnicianComponent implements OnInit, ComponentCanDeactivate {
  public submited: BehaviorSubject<boolean>;
  isPartOfATeam: boolean = false;
  //@HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    return (!this.editPersonaMode &&
      !this.editSkillMode &&
      !this.editIntervenantMode) ||
      this.submited.value
      ? true
      : false;
  }

  id: number;
  qrData: string = null;
  company: Company = new Company();
  listCompany: Company[];
  filteredCompanies: any[];
  sub: any;
  ref: DynamicDialogRef;
  listSkill: Skill[]; // Tableau contenant tout les skills recup onInit
  // skillOfPersona: Skill[];
  editSkillMode: boolean = false;
  editIntervenantMode: boolean = false;
  persona: Persona = new Persona();
  intervenant: Technician = new Technician();
  newSkillsOfPersona: Skill[] = []; //Tableau contenant les skills ajoutées à afficher
  gender: any[];
  listAddSkills: PersonaSkills[] = []; // Array envoyé à l'api pour ajouter les skills au persona
  listDeleteSkills: PersonaSkills[] = []; // Array envoyé à l'api contenant la liste des skills à delete du persona
  listChangeSkills: PersonaSkills[] = []; // Array envoyé à l'api contenant la liste des skills à mettre à jour
  typeOfTag: string = 'skill';

  //Gestion Photo
  display: boolean = false;
  imageBase64: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = true;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  //Variables lié a l'update
  editPersonaMode: boolean = false; // Boolean to trigger the edit mod in a persona details
  personaEditHistory: Persona;
  skillOfPersonaEditHistory: PersonaSkills[];

  currentDate: Date = new Date();
  tmpPersonaListSkill: any;
  personaListSkill: any;

  errorPhone: boolean = false;
  errorLastNameRequired: boolean = false;
  errorFirstNameRequired: boolean = false;
  errorMailRequired: boolean = false;
  errorMailFormat: boolean = false;
  errorGenderRequired: boolean = false;
  errorCompanyRequired: boolean = false;
  hasStock: boolean = false;
  hasManyPosts: boolean = false;




  existPhoto: boolean; // Boolean to display photo if it exist or to hide it if it doesn't exist

  activeState: boolean[] = [];

  constructor(
    private dialogService: DialogService,
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private personaService: PersonaService,
    private qrDataFormaterService: QrDataFormaterService,
    private confirmationService: ConfirmationService,
    private stockService: StockService,
    private skillService: SkillService,
    private tokenStorageService: TokenStorageService,
    private companyService: CompanyService,
    private creationTeamService: CreationTeamService,
    private translate: TranslateService,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.editMode) {
        this.editPersonaMode = true;
        this.editIntervenantMode = true;
      }
    });
    this.submited = new BehaviorSubject(false);
  }

  alphaNumericSortTag = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.label.match(/\d+|\D+/g);
    const bPart = b.label.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };


  filterArrayByTag(newArrayFiltered: any) {
    this.persona.listSkill = [];
    if (newArrayFiltered.length === this.listSkill.length) {
      this.persona.listSkill = this.tmpPersonaListSkill;
    } else {
      for (var i = 0; i < this.tmpPersonaListSkill.length; i++) {
        for (var j = 0; j < newArrayFiltered.length; j++) {
          if (this.tmpPersonaListSkill[i].idSkill === newArrayFiltered[j].id) {
            this.persona.listSkill.push(this.tmpPersonaListSkill[i]);
          }
        }
      }
    }
  }

  ngOnInit(): void {
    if (
      this.tokenStorageService.decodeToken().realm_access.roles[0] ===
        'manager_operationnel' ||
      this.tokenStorageService.decodeToken().realm_access.roles[0] ===
        'manager_CA'
    ) {
      this.editPersonaMode = false;
    }
    //this.skillOfPersona = [];

    this.gender = [
      { name: 'Femme', value: 'Femme' },
      { name: 'Homme', value: 'Homme' },
      { name: 'Autre', value: 'Autre' },
    ];
    // Get all the companies
    this.companyService.getListAllEnableCompany().subscribe((res) => {
      if (res.status === 200) {
        this.listCompany = res.body;
      }
    });
    // Get all the skills
    this.skillService.getListSkillWithTags().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listSkill = res.body;
          for (let element of this.listSkill ){
            this.alphaNumericSortTag(element.tags)
          }
          this.personaListSkill = this.listSkill;

        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );

    // Get the id from the route param in the url
    this.sub = this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    // Get the specific Persona with his id
    this.personaService.getPersona(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.persona = new Persona(res.body);

          this.tmpPersonaListSkill = this.persona.listSkill;

          for (let i = 0; i < this.persona.listSkill.length; i++) {
            for (let j = 0; j < this.personaListSkill.length; j++) {
              if (
                this.persona.listSkill[i].idSkill ===
                this.personaListSkill[j].id
              ) {
                this.persona.listSkill[i].skill.tags =
                  this.personaListSkill[j].tags;
              }
            }
          }
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        // done when subscribe end
        // existPhoto enable to show the photo only if it exist and hide it when it doesn't exist
        this.existPhoto = this.checkExistPhoto();
        // A OPTIMIZER avec le back : extrait les skills depuis les PersonaSkills de Persona (Persona ne contient que les relations)
        // for (const skillOfPersona of this.persona.listSkill){
        //   for (const skill of this.listSkill){
        //     if (skillOfPersona.idSkill === skill.id){
        //       this.skillOfPersona.push(skill);
        //     }
        //   }
        // }

        this.activeState = new Array(this.persona.listSkill.length).fill(false);

        this.personaEditHistory = new Persona(this.persona);

        let content = {
          id: this.persona.id,
        };
        this.qrData = this.qrDataFormaterService.generateQrData(
          'persona',
          content
        );
      }
    );

    this.personaService.getContributorByPersonaId(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.intervenant = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },

      // () => {
      //   var lenghtIntervenant = this.listAllIntervenant.length;
      //   for (let i = 0; i < lenghtIntervenant; i++) {
      //     if (this.listAllIntervenant[i].idPersona == this.id) {
      //       this.intervenant = this.listAllIntervenant[i];


      //     }
      //   }
      // }
    );
  }

  filterCompany(event) {
    this.filteredCompanies = this.listCompany
      .filter((c) => c.name.toLowerCase().startsWith(event.query.toLowerCase()))
      .map((company) => company);
  }

  switchEditPersonaMode(value: boolean) {
    this.editPersonaMode = value;
    this.personaEditHistory = new Persona(this.persona);
  }

  switchEditSkillsMode(value: boolean) {
    this.editSkillMode = value;
    this.skillOfPersonaEditHistory = [...this.persona.listSkill];
    this.toggleAll(value);
  }

  switchEditIntervenantMode(value: boolean) {
    //console.log('uieuruerur');
    this.editIntervenantMode = value;
  }

  checkExistPhoto() {
    if (this.persona.photo === null) {
      return false;
    } else {
      return true;
    }
  }

  toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
  }

  toggleAll(value: boolean) {
    this.activeState = new Array(this.activeState.length).fill(value);
  }

  // Calculate end date based on parameters values
  calculateEndDate(date, days, months, years) {
    var d = new Date(date);

    d.setDate(d.getDate() + days);
    d.setFullYear(d.getFullYear() + years);
    d.setMonth(d.getMonth() + +months);

    return d;
  }

  // auto calculate
  startDateOnChange(index: number) {
    let days = this.persona.listSkill[index].skill.day_duration;
    let month = this.persona.listSkill[index].skill.month_duration;
    let year = this.persona.listSkill[index].skill.year_duration;

    this.addTimeZoneOffsetToCalendarTime(this.persona.listSkill[index].startDate)

    this.persona.listSkill[index].endDate = this.calculateEndDate(
      this.persona.listSkill[index].startDate,
      days,
      month,
      year
    );
  }

  addTimeZoneOffsetToCalendarTime(event) {
    /*To be removed when primeng fixes the bug related to p calendar or when migrating to another library*/
    event.setTime(event.getTime() - (new Date().getTimezoneOffset() * 60 * 1000));
    /**/
  }

  // auto calculate
  addedStartDateOnChange(index: number) {
    let days = this.listAddSkills[index].skill.day_duration;
    let month = this.listAddSkills[index].skill.month_duration;
    let year = this.listAddSkills[index].skill.year_duration;

    this.listAddSkills[index].endDate = this.calculateEndDate(
      this.listAddSkills[index].startDate,
      days,
      month,
      year
    );
  }

  validPhoneFormat(event: any) {
    if (
      event.target.value.charAt(0) === '+' &&
      !isNaN(event.target.value.substr(1))
    ) {
      this.errorPhone = false;
    } else if (!isNaN(event.target.value)) {
      this.errorPhone = false;
    } else {
      this.errorPhone = true;
    }
  }

  valideUpdate() {
    if (this.persona.lastName === '') {
      this.errorLastNameRequired = true;
    }
    if (this.persona.firstName === '') {
      this.errorFirstNameRequired = true;
    }
    if (this.persona.mail === '') {
      this.errorMailRequired = true;
    }
    if (!this.persona.gender) {
      this.errorGenderRequired = true;
    }
    if (this.persona.company === null) {
      this.errorCompanyRequired = true;
    }
    if (
      this.errorLastNameRequired === false &&
      this.errorFirstNameRequired === false &&
      this.errorMailRequired === false &&
      this.errorMailFormat === false &&
      this.errorGenderRequired === false &&
      this.errorCompanyRequired === false
    ) {
      this.updatePersona();
    }
  }

  firsNametFieldValidator(event: any) {
    if (event.target.value === '') {
      this.errorFirstNameRequired = true;
    } else {
      this.errorFirstNameRequired = false;
    }
  }

  lastNametFieldValidator(event: any) {
    if (event.target.value === '') {
      this.errorLastNameRequired = true;
    } else {
      this.errorLastNameRequired = false;
    }
  }

  mailFieldValidator(event: any) {
    var pattern = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');
    if (event.target.value === '') {
      this.errorMailRequired = true;
    } else {
      this.errorMailRequired = false;
    }

    if (!pattern.test(event.target.value)) {
      this.errorMailFormat = true;
    } else {
      this.errorMailFormat = false;
    }
  }

  genderFieldValidator(event: any) {
    if (!this.persona.gender) {
      this.errorGenderRequired = true;
    } else {
      this.errorGenderRequired = false;
    }
  }

  companyFieldValidator(event: any) {
    if (!event.target.value) {
      this.errorCompanyRequired = true;
    } else {
      this.errorCompanyRequired = false;
    }
  }

  updatePersona() {
    if(this.persona.birthDate){
      this.addTimeZoneOffsetToCalendarTime(this.persona.birthDate);
    }
    // Call API in Service
    this.personaService.updatePersona(this.persona.id, this.persona).subscribe(
      (res) => {
        // Updated
        if (res.status === 200) {
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        // Show photo only if it exist
        this.existPhoto = this.checkExistPhoto();
      }
    );

    this.switchEditPersonaMode(false);
  }

  updateIntervenant() {
    let characteristics = {};
    characteristics["address"] = this.intervenant.address;
    this.intervenant.idParty = this.persona.idParty;
    this.intervenant.characteristics = characteristics;
    this.personaService.updateTechnician(this.intervenant).subscribe(
      (res) => {
        // Updated
        if (res.status === 200) {
          //console.log(res.body);
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );
    this.switchEditIntervenantMode(false);
  }

  deletePersona() {
    // Call API in the service to soft delete Persona

    this.personaService.deletePersona(this.persona.id, this.persona).subscribe(
      (res) => {
        // Deleted
        if (res.status === 200) {
          //console.log(res.body);
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );
  }

  activatePersona() {
    // Call API in the service to activate Persona
    this.personaService
      .activatePersona(this.persona.id, this.persona)
      .subscribe(
        (res) => {
          // Activated
          if (res.status === 200) {
            //console.log(res.body);
          }
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
        },
        () => {}
      );
  }

  // return an array of all skills - skills of persona & skills added to the persona
  initializeSelectableSkills() {
    // copy of the list of all skills
    let selectableSkills = this.listSkill;

    // remove skill from base persona
    for (let i = 0; i < this.persona.listSkill.length; i++) {
      selectableSkills = selectableSkills.filter(
        (item) => item.id !== this.persona.listSkill[i].idSkill
      );
    }

    // enlève les skills de selectable skill les skills ajouté
    for (let i = 0; i < this.newSkillsOfPersona.length; i++) {
      selectableSkills = selectableSkills.filter(
        (item) => item.id !== this.newSkillsOfPersona[i].id
      );
    }

    // return array of skill - skill of persona - skill added to the persona
    return selectableSkills;
  }

  // Affiche et gere dynamicdialog pour l'ajout de skills
  addNewSkills() {
    //Tableaux contenant les skills que le persona n'a pas (utile pour l'ajout)
    this.personaService.listSelectableSkills =
      this.initializeSelectableSkills();
    this.personaService.personaInformation.persona = this.persona;

    // open dialog with the component
    this.ref = this.dialogService.open(AddSkillComponent, {
      header: 'Ajout de Compétences',
      width: '50%',
    });

    // on close of the dialog, do this
    this.ref.onClose.subscribe((assignedSkills: PersonaSkills[]) => {
      // if return variable assignedSkills is not empty/null, add all skills selected to add
      if (assignedSkills) {
        // loop all assigned skills
        for (let i = 0; i < assignedSkills.length; i++) {
          // add all persona skills
          this.listAddSkills.push(assignedSkills[i]);

          // loop all existing skills
          for (let j = 0; j < this.listSkill.length; j++) {
            // if it is the skill of persona skills, add to the array that display new skills
            if (assignedSkills[i].idSkill === this.listSkill[j].id) {
              this.newSkillsOfPersona.push(this.listSkill[j]);
            }
          }
        }
      }
    });
  }

  // Delete one skill of the persona
  deleteSkill(skill: Skill) {
    // add the persona skill of the persona in the toDelete array
    for (let i = 0; i < this.persona.listSkill.length; i++) {
      if (this.persona.listSkill[i].idSkill == skill.id) {
        this.listDeleteSkills.push(this.persona.listSkill[i]);
      }
    }

    // // Delete the persona skill from persona
    // this.persona.listSkill = this.persona.listSkill.filter(
    //   (item) => item.idSkill !== skill.id
    // );

    // delete the skill of the persona, showed on front
    this.persona.listSkill = this.persona.listSkill.filter(
      (item) => item.idSkill !== skill.id
    );
  }

  // Delete one of the skill added to the persona
  // ne delete pas quand (add skill, enregistre, modifie, puis delete, enregistre)
  // => temporary fix window.location.reload() on updateSkills()
  deleteAddedSkill(skill: Skill) {
    this.listAddSkills = this.listAddSkills.filter(
      (item) => item.idSkill !== skill.id
    );

    this.newSkillsOfPersona = this.newSkillsOfPersona.filter(
      (item) => item.id !== skill.id
    );
  }

  updateSkills() {
    let listModifsSkills = [];
    listModifsSkills.push(this.listAddSkills);
    listModifsSkills.push(this.persona.listSkill);
    listModifsSkills.push(this.listDeleteSkills);

    this.personaService.updateAllSkills(listModifsSkills).subscribe(
      (res) => {
        if (res.status === 200) {
          //console.log(res.body);
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },

      () => {
        this.listAddSkills = [];
        this.listChangeSkills = [];
        this.listDeleteSkills = [];
        this.switchEditSkillsMode(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });
        this.submited.next(true);
        window.location.reload();
      }
    );
  }

  //This methode is to rate Persona
  handleRate(event) {
    this.persona.globalRating = event.value;
  }

  //delete intervenant
  deleteIntervenant() {
    // Call API in the service to soft delete technician
    // console.log('ID');
    // console.log(this.intervenant.id);
    this.intervenant.idParty = this.persona.idParty;
    this.personaService.deleteTechnician(this.intervenant).subscribe(
      (res) => {
        // Deleted
        if (res.status === 200) {
          //console.log(res.body);
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {}
    );
  }

  confirmReturnPersona() {
    if (
      JSON.stringify(this.persona) !== JSON.stringify(this.personaEditHistory)
    ) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez quitter la modification, les données saisies seront perdues.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        accept: () => {
          this.persona = this.personaEditHistory;
          this.errorPhone = false;
          this.errorLastNameRequired = false;
          this.errorFirstNameRequired = false;
          this.errorMailRequired = false;
          this.errorMailFormat = false;
          this.errorGenderRequired = false;
          this.errorCompanyRequired = false;
          this.switchEditPersonaMode(false);
        },
      });
    } else {
      this.errorPhone = false;
      this.errorLastNameRequired = false;
      this.errorFirstNameRequired = false;
      this.errorMailRequired = false;
      this.errorMailFormat = false;
      this.errorGenderRequired = false;
      this.errorCompanyRequired = false;
      this.switchEditPersonaMode(false);
    }
  }

  canTechBeDeleted() {
    //console.log(this.hasStock);
    this.stockService
      .technicianHasStock(this.intervenant.id)
      .subscribe(async (res) => {
        if (res.status === 200) {
          this.hasStock = res.body;
          if(this.hasStock){
            await this.confirm();
          }else{
            this.isTechPartOfTeam(this.persona.idParty);
          }

        }
      });
  }

  isTechPartOfTeam(partyID : any){
    this.creationTeamService.isTechPartOfATeam(partyID).subscribe(
      async res => {
        if(res.status === 200){
          this.isPartOfATeam = res.body;
          await this.confirm();
        }
      }
    )
  }

  confirm() {
    if(!this.hasStock && !this.isPartOfATeam){
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          "Vous allez supprimer l'intervenant et les informations qui lui sont associées (le persona sera toujours actif)." +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        key:'deleteTech',
        accept: () => {
          this.submited.next(true);
          this.deleteIntervenant();
          this.router.navigate(['/phoenix/technician']);
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
        },
      });

    }
    else if(this.hasStock){
      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message: "Cet intervenant dispose d'un stock intervenant (matériel embarqué ou à récupérer) : vous devez supprimer le stock pour supprimer l'intervenant",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false,
        key:'noStock',
        accept: () => {
          this.submited.next(true);
          this.router.navigate(['/phoenix/technician']);
        },
      });
    }else if(this.isPartOfATeam){
      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message: "Cet intervenant est associé à une équipe : vous devez supprimer l’association pour supprimer l'intervenant.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J’ai compris",
        rejectVisible: false,
        key:'partOfTeam',
        accept: () => {
          this.submited.next(true);
          this.router.navigate(['/phoenix/technician']);
        },
      });
    }
    this.hasStock = false;
    this.isPartOfATeam = false;
  }

  confirmReturnIntervenant() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez quitter la modification, les données saisies seront perdues.' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.switchEditIntervenantMode(false);
      },
    });
  }

  confirmReturnBottom() {
    if (
      this.editPersonaMode ||
      this.editSkillMode ||
      this.editIntervenantMode
    ) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez quitter la page, les données saisies seront perdues.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        accept: () => {
          this.goBack();
        },
      });
    } else {
      this.goBack();
    }
  }

  confirmReset() {
    if (
      JSON.stringify(this.persona.listSkill) !==
        JSON.stringify(this.skillOfPersonaEditHistory) ||
      this.listAddSkills.length !== 0
    ) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez quitter la modification, les données saisies seront perdues.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        accept: () => {
          this.persona.listSkill = this.skillOfPersonaEditHistory;
          this.newSkillsOfPersona = [];
          // this.listAddSkills = [];
          // this.listDeleteSkills = [];
          // this.listChangeSkills = [];
          this.switchEditSkillsMode(false);
        },
      });
    } else {
      this.switchEditSkillsMode(false);
    }
  }

  confirmUpdatePersona() {
    this.updatePersona();
    this.messageService.add({
      severity: 'success',
      summary: 'Opération réussie',
    });
  }

  goBack() {
    this.submited.next(true);
    this._location.back();
  }

  getValidity(year: number, month: number, day: number) {
    var message = '';
    if (year > 0) {
      message += year + " " + this.translate.instant('ANNEE_S')
    }
    if (month > 0) {
      if (year > 0) {
        message += ', ';
      }
      message += month + " " + this.translate.instant('MOIS_S')
    }
    if (day > 0) {
      if (year > 0 || month > 0) {
        message += ', ';
      }
      message += day + " " + this.translate.instant('JOUR_S')
    }
    return message;
  }

  checkValidDate(personaSkill: PersonaSkills) {
    if (
      personaSkill.skill.type === 'HABILITATION' &&
      personaSkill.skill.validity &&
      new Date(personaSkill.endDate).getTime() < this.currentDate.getTime()
    ) {
      return false;
    }
    return true;
  }

  onSelection(event, fileUpload: FileUpload) {
    this.display = true;
    let fileReader = new FileReader();

    let file = event.files[0];

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      // Store the base64 string of the image file
      this.imageBase64 = fileReader.result.toString();
    };
  }

  // Used to upload an image file that catch the event
  onUpload(fileUpload: FileUpload) {
    this.display = false;
    fileUpload.clear();
  }

  imageCropped(event: ImageCroppedEvent) {
    let picSize = ((4 * event.base64.length) / 3 + 3) & ~3;

    this.persona.photo = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
    //console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    //console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    //console.log('Load failed');
  }

  clear(fileUpload: FileUpload) {
    fileUpload.clear();
  }

  deletePhoto() {
    this.persona.photo = null;
  }

    //check if persona has one party role who is tech
    technicianHasOtherProfiles(){
      this.personaService.technicianHasOtherProfiles(this.persona.id).subscribe(
        (res) => {
          if (res.status === 200) {
            this.hasManyPosts = res.body;
            if(this.hasManyPosts ){
              this.canTechBeDeleted();
            }else{
              this.confirmationService.confirm({
                header: 'Suppression impossible',
                message: "Cet intervenant ne peut pas être supprimé.",
                icon: 'pi pi-exclamation-triangle',
                acceptIcon: 'null',
                rejectIcon: 'null',
                acceptLabel: "J’ai compris",
                rejectVisible: false,
                key:'deleteTech',
                accept: () => {
                  this.router.navigate(['/phoenix/technician']);
                },
              });
            }
          }
        },
        (error) => {
          if (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
        }
      );
    }
}
