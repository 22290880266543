import {Directive} from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

@Directive({
  selector: '[appDuplication]'
})
export class DuplicationDirective {

  constructor() {
  }

}

export function forbiddenDuplicationNameValidator(labelsContract): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let forbiddenArr = labelsContract.filter(x => x.toLowerCase() === control.value.toLowerCase().trim());
    let forbidden = false;
    if(forbiddenArr.length > 0) {
      forbidden = true;
    }
    return forbidden ? {forbiddenName: {value: control.value}} : null;
  };
}

function checkIfArrayIsUnique(myArray) {
  console.log(myArray);
  if (myArray.length > 0){
    return myArray.length === new Set(myArray).size;
  } else {
    return false;
  }
}
