<app-kober-menu module="kober-services"></app-kober-menu>
<div class="p-grid p-d-flex p-ml-2 p-pl-2 main-page">
  <div class="p-mt-3 p-pt-3 p-ml-3 p-pl-3">
    <p-breadcrumb [model]="items"></p-breadcrumb>
  </div>
</div>
<div class="p-d-flex p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-ai-center vertical-container p-jc-center">
    <div
      class="p-col-12 p-md-11 p-jc-center p-m-3 p-p-3 main-content p-shadow-4"
    >
      <div class="p-grid p-jc-between p-p-3">
        <h2>{{'ordre_de_travail'|translate}}</h2>
        <button
          *ifRoles="['administrateur', 'admin_metier', 'admin_responsable_DPO', 'manager_operationnel','manager_operationnel_prestataire','ca_prestataire', 'manager_CA', 'charge_de_pilotage']"
          class="p-mr-5 p-mt-5"
          pButton
          (click)="redirectionWorkOrder()"
          type="button"
          label="{{'CREER'|translate}}"
        ></button>
      </div>
      <p-tabView (onChange)="handleChangeTabWorkOrder($event,'str',dt1,dt2,dt3,dt4)">
      <p-tabPanel header="Tous" >
      <p-table
        #dt1
        [value]="listProjectWorkOrder"
        (sortFunction)="naturalSortWorkOrder($event)"
        [customSort]="true"
        sortField="workOrder.name"
        [sortOrder]='1'
        class="table"
        [scrollable]="true"
        scrollHeight="40vh"
        [paginator]="true"
        [rows]="10"
        styleClass="p-datatable-sm p-datatable-striped"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'PAGINATION'|translate}}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="['workOrder.name', 'workOrder.description', 'company.name', 'project.name','workOrder.status']"
      >
        <ng-template pTemplate="caption">

          <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
              <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
              <input
              [style]="{'height': '45px'}"
                p
                pInputText
                type="text"
                [value]="workOrderRecherche!== undefined?workOrderRecherche:''"
                (input)="filterTableInputChangeTabWorkOrder($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                placeholder="{{'recherche'|translate}}"
              />
            </span>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th id="name" pSortableColumn="workOrder.name">
              {{'ordre_de_travail'|translate}}
              <p-columnFilter type="text" field="workOrder.name" display="menu"></p-columnFilter>
              <p-sortIcon field="workOrder.name"></p-sortIcon>
            </th>
            <th id="type" pSortableColumn="workOrder.description">
              {{'DESCRIPTION'|translate}}
              <p-columnFilter type="text" field="workOrder.description" display="menu"></p-columnFilter>
              <p-sortIcon field="workOrder.description"></p-sortIcon>
            </th>
            <th id="type" pSortableColumn="company.name">
              {{'ENTREPRISE'|translate}}
              <p-columnFilter type="text" field="company.name" display="menu"></p-columnFilter>
              <p-sortIcon field="company.name"></p-sortIcon>
            </th>
            <th id="type" pSortableColumn="project.name">
              {{'PROJET'|translate}}
              <p-columnFilter type="text" field="project.name" display="menu"></p-columnFilter>
              <p-sortIcon field="project.name"></p-sortIcon>
            </th>
            <th id="type">
              {{'liste_ot_STATUT'|translate}}
            </th>
            <th id="type">
              {{'liste_ot_AVANCEMENT'|translate}}
            </th>
            <th id="actions" class="p-text-center table-10"></th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-projectWorkOrder
          let-ri="rowIndex"
          let-columns
        >
          <tr>
            <td>{{ projectWorkOrder.workOrder.name }}</td>
            <td>{{ projectWorkOrder.workOrder.description }}</td>

            <td *ngIf = "projectWorkOrder?.attachedToCompany" >{{ projectWorkOrder?.company.name }}</td>
            <td *ngIf = "!projectWorkOrder?.attachedToCompany" >  </td>
            <td>{{ projectWorkOrder.project.name }}</td>


            <td *ngIf="projectWorkOrder.workOrder.status === 'TODO'">
              <span class="status-todo"> {{'etat_a_faire'|translate}} </span>
            </td>

            <td *ngIf="projectWorkOrder.workOrder.status === 'DOING'">
              <span class="status-doing"> {{'menu_EN_COURS'|translate}} </span>
            </td>

            <td *ngIf="projectWorkOrder.workOrder.status === 'DONE'">
              <span class="status-done"> {{'etat_termine'|translate}}  </span>
            </td>


            <td>
              <app-progress-bar
              [progress]="getProgress(projectWorkOrder.workOrder)"
              [total]="100"
              color="blue">
              </app-progress-bar>
            </td>


            <td class="p-text-center table-5">
              <button
                routerLink="/phoenix/show-work-order/{{
                  projectWorkOrder.workOrder.id
                }}"
                pButton
                pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-rounded p-button-text p-button-plain"
              ></button>
            </td>
            <td class="p-text-center table-5">
              <button
                *ifRoles="[
                  'administrateur',
                  'admin_metier',
                  'admin_responsable_DPO',
                  'manager_operationnel',
                  'manager_operationnel_prestataire',
                  'ca_prestataire',
                  'manager_CA',
                  'charge_de_pilotage',
                ]"
                pButton
                pRipple
                type="button"
                icon="pi pi-ellipsis-h"
                (click)="
                  getRowProjects(projectWorkOrder.workOrder); op.toggle($event)
                "
                class="p-button-rounded p-button-text p-button-plain"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="A faire" >
      <p-table
      #dt2
      [value]="listProjectWorkOrderToDo"
      [customSort]="true"
      (sortFunction)="naturalSortWorkOrder($event)"
      sortField="workOrder.name" [sortOrder]='1'
      class="table"
      [scrollable]="true"
      scrollHeight="40vh"
      [paginator]="true"
      [rows]="10"
      styleClass="p-datatable-sm p-datatable-striped"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{'PAGINATION'|translate}}"
      [rowsPerPageOptions]="[10, 25, 50]"
      [globalFilterFields]="['workOrder.name', 'workOrder.description', 'company.name', 'project.name','workOrder.status']"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
            <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
            <input
            [style]="{'height': '45px'}"
              p
              pInputText
              type="text"
              [value]="workOrderRecherche!== undefined?workOrderRecherche:''"
              (input)="filterTableInputChangeTabWorkOrder($event.target.value,'contains', dt1,dt2,dt3,dt4)"
              placeholder="Rechercher"
            />
          </span>
        </div>
      </ng-template>


      <ng-template pTemplate="header">
        <tr>
          <th id="name" pSortableColumn="workOrder.name">
            {{'ordre_de_travail'|translate}}
            <p-sortIcon field="workOrder.name"></p-sortIcon>
          </th>
          <th id="type" pSortableColumn="workOrder.description">
            {{'DESCRIPTION'|translate}}
            <p-sortIcon field="workOrder.description"></p-sortIcon>
          </th>
          <th id="type" pSortableColumn="company.name">
            {{'ENTREPRISE'|translate}}
            <p-sortIcon field="company.name"></p-sortIcon>
          </th>
          <th id="type" pSortableColumn="project.name">
            {{'PROJET'|translate}}
            <p-sortIcon field="project.name"></p-sortIcon>
          </th>
          <th id="type">
            {{'status'|translate}}
          </th>
          <th id="type">
            {{'liste_ot_AVANCEMENT'|translate}}
          </th>
          <th id="actions" class="p-text-center table-10"></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-listProjectWorkOrderToDo
        let-ri="rowIndex"
        let-columns
      >
        <tr>
          <td>{{ listProjectWorkOrderToDo.workOrder.name }}</td>
          <td>{{ listProjectWorkOrderToDo.workOrder.description }}</td>

          <td *ngIf = "listProjectWorkOrderToDo?.attachedToCompany" >{{ listProjectWorkOrderToDo?.company.name }}</td>
          <td *ngIf = "!listProjectWorkOrderToDo?.attachedToCompany" >  </td>
          <td>{{ listProjectWorkOrderToDo.project.name }}</td>


          <td *ngIf="listProjectWorkOrderToDo.workOrder.status === 'TODO'">
            <span class="status-todo"> {{'etat_a_faire'|translate}} </span>
          </td>

          <td *ngIf="listProjectWorkOrderToDo.workOrder.status === 'DOING'">
            <span class="status-doing"> {{'menu_EN_COURS'|translate}} </span>
          </td>

          <td *ngIf="listProjectWorkOrderToDo.workOrder.status === 'DONE'">
            <span class="status-done"> {{'etat_termine'|translate}}  </span>
          </td>


          <td>
            <app-progress-bar
            [progress]="getProgress(listProjectWorkOrderToDo.workOrder)"
            [total]="100"
            color="blue">
            </app-progress-bar>
          </td>


          <td class="p-text-center table-5">
            <button
              routerLink="/phoenix/show-work-order/{{
                listProjectWorkOrderToDo.workOrder.id
              }}"
              pButton
              pRipple
              type="button"
              icon="pi pi-search"
              class="p-button-rounded p-button-text p-button-plain"
            ></button>
          </td>
          <td class="p-text-center table-5">
            <button
              *ifRoles="[
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'manager_operationnel',
                'manager_CA',
                'manager_operationnel_prestataire',
                'ca_prestataire',
                'charge_de_pilotage',
              ]"
              pButton
              pRipple
              type="button"
              icon="pi pi-ellipsis-h"
              (click)="
                getRowProjects(listProjectWorkOrderToDo.workOrder); op.toggle($event)
              "
              class="p-button-rounded p-button-text p-button-plain"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    </p-tabPanel>
    <p-tabPanel header="En cours" >
      <p-table
        #dt3
        [value]="listProjectWorkOrderDoing"
        [customSort]="true"
        (sortFunction)="naturalSortWorkOrder($event)"
        sortField="workOrder.name" [sortOrder]='1'
        class="table"
        [scrollable]="true"
        scrollHeight="40vh"
        [paginator]="true"
        [rows]="10"
        styleClass="p-datatable-sm p-datatable-striped"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'PAGINATION'|translate}}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="['workOrder.name', 'workOrder.description', 'company.name', 'project.name','workOrder.status']"
      >

        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
              <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
              <input
              [style]="{'height': '45px'}"
                p
                pInputText
                type="text"
                [value]="workOrderRecherche!== undefined?workOrderRecherche:''"
                (input)="filterTableInputChangeTabWorkOrder($event.target.value,'contains', dt1,dt2,dt3,dt4)"
                placeholder="Rechercher"
              />
            </span>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th id="name" pSortableColumn="workOrder.name">
              {{'ordre_de_travail'|translate}}
              <p-sortIcon field="workOrder.name"></p-sortIcon>
            </th>
            <th id="type" pSortableColumn="workOrder.description">
              {{'DESCRIPTION'|translate}}
              <p-sortIcon field="workOrder.description"></p-sortIcon>
            </th>
            <th id="type" pSortableColumn="company.name">
              {{'ENTREPRISE'|translate}}
              <p-sortIcon field="company.name"></p-sortIcon>
            </th>
            <th id="type" pSortableColumn="project.name">
              {{'PROJET'|translate}}
              <p-sortIcon field="project.name"></p-sortIcon>
            </th>
            <th id="type">
              {{'status'|translate}}
            </th>
            <th id="type">
              {{'liste_ot_AVANCEMENT'|translate}}
            </th>
            <th id="actions" class="p-text-center table-10"></th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-listProjectWorkOrderDoing
          let-ri="rowIndex"
          let-columns
        >
          <tr>
            <td>{{ listProjectWorkOrderDoing.workOrder.name }}</td>
            <td>{{ listProjectWorkOrderDoing.workOrder.description }}</td>

            <td *ngIf = "listProjectWorkOrderDoing?.attachedToCompany" >{{ listProjectWorkOrderDoing?.company.name }}</td>
            <td *ngIf = "!listProjectWorkOrderDoing?.attachedToCompany" >  </td>
            <td>{{ listProjectWorkOrderDoing.project.name }}</td>


            <td *ngIf="listProjectWorkOrderDoing.workOrder.status === 'TODO'">
              <span class="status-todo"> A faire </span>
            </td>

            <td *ngIf="listProjectWorkOrderDoing.workOrder.status === 'DOING'">
              <span class="status-doing"> En cours </span>
            </td>

            <td *ngIf="listProjectWorkOrderDoing.workOrder.status === 'DONE'">
              <span class="status-done"> Terminé  </span>
            </td>


            <td>
              <app-progress-bar
              [progress]="getProgress(listProjectWorkOrderDoing.workOrder)"
              [total]="100"
              color="blue">
              </app-progress-bar>
            </td>


            <td class="p-text-center table-5">
              <button
                routerLink="/phoenix/show-work-order/{{
                  listProjectWorkOrderDoing.workOrder.id
                }}"
                pButton
                pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-rounded p-button-text p-button-plain"
              ></button>
            </td>
            <td class="p-text-center table-5">
              <button
                *ifRoles="[
                  'administrateur',
                  'admin_metier',
                  'admin_responsable_DPO',
                  'manager_operationnel',
                  'manager_CA',
                  'manager_operationnel_prestataire',
                  'ca_prestataire',
                  'charge_de_pilotage',
                ]"
                pButton
                pRipple
                type="button"
                icon="pi pi-ellipsis-h"
                (click)="
                  getRowProjects(listProjectWorkOrderDoing.workOrder); op.toggle($event)
                "
                class="p-button-rounded p-button-text p-button-plain"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="Terminé" >
      <p-table
      #dt4
      [value]="listProjectWorkOrderDone"
      (sortFunction)="naturalSortWorkOrder($event)"
      [customSort]="true"
      sortField="workOrder.name" [sortOrder]='1'
      class="table"
      [scrollable]="true"
      scrollHeight="40vh"
      [paginator]="true"
      [rows]="10"
      styleClass="p-datatable-sm p-datatable-striped"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{'PAGINATION'|translate}}"
      [rowsPerPageOptions]="[10, 25, 50]"
      [globalFilterFields]="['workOrder.name', 'workOrder.description', 'company.name', 'project.name','workOrder.status']"
    >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto" [style]="{'height':'20px','margin-bottom':'30px'}">
          <i class="pi pi-search" [style]="{'height': '45px','margin-top':'5px'}"></i>
          <input
          [style]="{'height': '45px'}"
            p
            pInputText
            type="text"
            [value]="workOrderRecherche!== undefined?workOrderRecherche:''"
            (input)="filterTableInputChangeTabWorkOrder($event.target.value,'contains', dt1,dt2,dt3,dt4)"
            placeholder="Rechercher"
          />
        </span>
      </div>
    </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th id="name" pSortableColumn="workOrder.name">
            {{'ordre_de_travail'|translate}}
            <p-sortIcon field="workOrder.name"></p-sortIcon>
          </th>
          <th id="type" pSortableColumn="workOrder.description">
            {{'DESCRIPTION'|translate}}
            <p-sortIcon field="workOrder.description"></p-sortIcon>
          </th>
          <th id="type" pSortableColumn="company.name">
            {{'ENTREPRISE'|translate}}
            <p-sortIcon field="company.name"></p-sortIcon>
          </th>
          <th id="type" pSortableColumn="project.name">
            {{'PROJET'|translate}}
            <p-sortIcon field="project.name"></p-sortIcon>
          </th>
          <th id="type">
            {{'status'|translate}}
          </th>
          <th id="type">
            {{'liste_ot_AVANCEMENT'|translate}}
          </th>
          <th id="actions" class="p-text-center table-10"></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-listProjectWorkOrderDone
        let-ri="rowIndex"
        let-columns
      >
        <tr>
          <td>{{ listProjectWorkOrderDone.workOrder.name }}</td>
          <td>{{ listProjectWorkOrderDone.workOrder.description }}</td>

          <td *ngIf = "listProjectWorkOrderDone?.attachedToCompany" >{{ listProjectWorkOrderDone?.company.name }}</td>
          <td *ngIf = "!listProjectWorkOrderDone?.attachedToCompany" >  </td>
          <td>{{ listProjectWorkOrderDone.project.name }}</td>


          <td *ngIf="listProjectWorkOrderDone.workOrder.status === 'TODO'">
            <span class="status-todo"> A faire </span>
          </td>

          <td *ngIf="listProjectWorkOrderDone.workOrder.status === 'DOING'">
            <span class="status-doing"> En cours </span>
          </td>

          <td *ngIf="listProjectWorkOrderDone.workOrder.status === 'DONE'">
            <span class="status-done"> Terminé  </span>
          </td>


          <td>
            <app-progress-bar
            [progress]="getProgress(listProjectWorkOrderDone.workOrder)"
            [total]="100"
            color="blue">
            </app-progress-bar>
          </td>


          <td class="p-text-center table-5">
            <button
              routerLink="/phoenix/show-work-order/{{
                listProjectWorkOrderDone.workOrder.id
              }}"
              pButton
              pRipple
              type="button"
              icon="pi pi-search"
              class="p-button-rounded p-button-text p-button-plain"
            ></button>
          </td>
          <td class="p-text-center table-5">
            <button
              *ifRoles="[
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'manager_operationnel',
                'manager_operationnel_prestataire',
                'ca_prestataire',
                'manager_CA',
                'charge_de_pilotage',
              ]"
              pButton
              pRipple
              type="button"
              icon="pi pi-ellipsis-h"
              (click)="
                getRowProjects(listProjectWorkOrderDone.workOrder); op.toggle($event)
              "
              class="p-button-rounded p-button-text p-button-plain"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    </p-tabPanel>
    </p-tabView>
      <p-overlayPanel #op appendTo="body" baseZIndex="2">
        <ng-template pTemplate>
          <div #actualTarget>
            <button
              *ifRoles="[
                  'administrateur',
                  'admin_metier',
                  'admin_responsable_DPO',
                  'manager_operationnel',
                  'manager_CA',
                  'manager_operationnel_prestataire',
                  'ca_prestataire',
                  'charge_de_pilotage',
                ]"
              pButton
              pRipple
              type="button"
              icon="pi pi-pencil"
              class="p-button-rounded p-button-text"
              [style]="{ 'margin-right': '5px' }"
              label="Modifier"
              (click)="update()"
            ></button
            ><br/>
            <ng-container
              *ifRoles="[
                'administrateur',
                'admin_metier',
                'admin_responsable_DPO',
                'manager_operationnel',
                'manager_CA',
                'manager_operationnel_prestataire',
                'ca_prestataire',
                'charge_de_pilotage'
              ]"
            >
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                (click)="delete()"
                class="p-button-rounded p-button-text"
                label="Supprimer"
              ></button>
            </ng-container>
            <ng-container

            >
              <!-- <button
                *ngIf="!disabled"
                pButton
                pRipple
                type="button"
                icon="pi pi-undo"
                (click)="confirm()"
                class="p-button-rounded p-button-text"
                label="Annuler"
              ></button> -->
            </ng-container>
          </div>
        </ng-template>
      </p-overlayPanel>
      <p-confirmDialog
      appendTo="body"
        baseZIndex="5"
        styleClass="main-page"
        [style]="{ width: '60%' }"
      ></p-confirmDialog>
    </div>
  </div>
</div>
