import { Component, Input, IterableDiffers, OnInit } from '@angular/core';
import { Project } from '@data/schema/Project';
import { WorkOrder } from '@data/schema/WorkOrder';

@Component({
  selector: 'app-work-order-show-advancement',
  templateUrl: './work-order-show-advancement.component.html',
  styleUrls: ['./work-order-show-advancement.component.css']
})
export class WorkOrderShowAdvancementComponent implements OnInit {

  @Input() workOrder?: WorkOrder;
  @Input() project?: Project;

  progress : number = 0;
  total : number = 0;

  unaffiliatedWork: number = 0;

  // allow to check if array input changed
  iterableDiffer: any;

  constructor(
    private iterableDiffers: IterableDiffers
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit(): void {

    if (this.workOrder){
      this.calculateAdvancement();
    }
    else if (this.project){
      this.calculateAdvancementProject();
      this.calculateUnaffiliatedWork();
    }

  }

  ngDoCheck() {
    if (this.workOrder){
      let changes = this.iterableDiffer.diff(this.workOrder.listWorkOrderProjectWork);
      if (changes) {
        this.calculateAdvancement();
      }
    }
    if (this.project){
      let changes = this.iterableDiffer.diff(this.project.projectWorkOrders);
      if (changes) {
        this.calculateAdvancementProject();
      }
      changes = this.iterableDiffer.diff(this.project.projectWorks);
      if (changes) {
        this.calculateUnaffiliatedWork();
      }
    }
  }

  calculateAdvancementProject() {
    this.progress = 0;
    this.total = 0;
    for (const pwo of this.project.projectWorkOrders){
      for (const wopw of pwo.workOrder.listWorkOrderProjectWork){
        this.progress += wopw.quantityDone;
        //this.total += wopw.quantityTotal;
      }
    }

    for (const pw of this.project.projectWorks){
      this.total += pw.quantityTotal;
    }

    //console.log(this.progress)
    //console.log(this.total)
  }

  calculateAdvancement(){
    this.progress = 0;
    this.total = 0;
    for (const wopw of this.workOrder.listWorkOrderProjectWork){
      this.progress += wopw.quantityDone;
      this.total += wopw.quantityTotal;
    }
  }

  calculateUnaffiliatedWork(){
    this.unaffiliatedWork = 0;
    for (const pw of this.project.projectWorks){
      if (pw.quantityFree > 0){
        this.unaffiliatedWork += pw.quantityFree;
      }
    }
  }
}
