import { Component, OnInit, ViewChild, EventEmitter, HostListener } from '@angular/core';
import { Materiel } from '@data/schema/Materiel';
import { MaterialWork, SkillWork, Work } from '@data/schema/Work';
import { MaterialService } from '@data/service/material.service';
import { WorkService } from '@data/service/work.service';
import { ConfirmationService, MessageService } from 'primeng/api';

import { QrDataFormaterService } from '@shared/service/qr-data-formater.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkDetailGeneralComponent } from 'src/app/kober-services/components/work/work-detail-general/work-detail-general.component';
import { WorkDetailCostComponent } from 'src/app/kober-services/components/work/work-detail-cost/work-detail-cost.component';
import { WorkDetailSkillComponent } from 'src/app/kober-services/components/work/work-detail-skill/work-detail-skill.component';
import { WorkDetailConsumableComponent } from 'src/app/kober-services/components/work/work-detail-consumable/work-detail-consumable.component';
import { WorkDetailToolsComponent } from 'src/app/kober-services/components/work/work-detail-tools/work-detail-tools.component';
import { CurrencyService } from '@data/service/currency.service';
import { Currency } from '@data/schema/Currency';
import { LocalStorageService } from '@shared/service/local-storage.service';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-show-work',
  templateUrl: './show-work.component.html',
  styleUrls: ['./show-work.component.css'],
})
export class ShowWorkComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(WorkDetailGeneralComponent)
  private workGeneral!: WorkDetailGeneralComponent;

  @ViewChild(WorkDetailCostComponent)
  private workCost: WorkDetailCostComponent;

  @ViewChild(WorkDetailSkillComponent)
  private workSkill!: WorkDetailSkillComponent;

  @ViewChild(WorkDetailConsumableComponent)
  private workConsumable!: WorkDetailConsumableComponent;

  @ViewChild(WorkDetailToolsComponent)
  private workTools!: WorkDetailToolsComponent;

  public submited: BehaviorSubject<boolean>;

  //@HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (!this.editMode || this.submited.value ? true : false)
  }

  qrData: string = null;
  sub: any;
  id: number;
  work: Work = new Work();
  listSkillSelected: SkillWork[];
  listConsumableSelected: MaterialWork[] = [];
  listToolsSelected: MaterialWork[] = [];
  listMaterial: Materiel[];
  listConsumable: Materiel[];
  listTools: Materiel[];
  editMode: boolean = false;
  label: string = 'Modifier';
  items: any = {};
  currency: Currency;
  workIsInUse: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private workService: WorkService,
    private router: Router,
    private qrDataFormaterService: QrDataFormaterService,
    private confirmationService: ConfirmationService,
    private _location: Location,
    private materialService: MaterialService,
    private localStorage: LocalStorageService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.editMode) {
        this.editMode = true;
        this.label = 'Valider';
      }
    });
    this.currency = new Currency(JSON.parse(this.localStorage.getLocalStorageItem("currency")));
    this.submited = new BehaviorSubject(false);
  }

  generateBreadCrumb(){
    this.items.update = [
      {label:'Prestation', routerLink:'/phoenix/work-order'},
      {label:"Modification de la prestation "+ this.work.name, styleClass:'ariane-current'}
    ];
    this.items.show = [
      {label:'Prestation', routerLink:'/phoenix/work-order'},
      {label:"Prestation "+ this.work.name, styleClass:'ariane-current'}
    ];
  }

  confirmUpdateWork() {
    if (this.editMode === false) {
      this.label = 'Valider';
      this.editMode = true;
    } else if (this.editMode == true) {
      this.submit();
    }
  }

  alphaNumericSortMaterial = (arr = []) => {
    const sorter = (a, b) => {
    const isNumber = (v) => (+v).toString() === v;
    const aPart = a.materialDto.label.match(/\d+|\D+/g);
    const bPart = b.materialDto.label.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };


  alphaNumericSortSkill = (arr = []) => {
    const sorter = (a, b) => {
    const isNumber = (v) => (+v).toString() === v;
    const aPart = a.skillDto.name.match(/\d+|\D+/g);
    const bPart = b.skillDto.name.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };


  submit() {
    // test quand un des deux tableau est pas rempli
    let material = this.listConsumableSelected.concat(this.listToolsSelected);
    this.work.materialsList = material;

    let validForm = true;

    if (this.workGeneral.nameFieldValidator()) {
      validForm = false;
    }


    if (this.workSkill.checkAllConditions()) {
      validForm = false;
    }
    if (this.workConsumable.checkAllConditions()) {
      validForm = false;
    }
    if (this.workTools.checkAllConditions()) {
      validForm = false;
    }
    if (!validForm) {
      this.messageService.add({
        severity: 'error',
        summary: 'Échec de l’opération : veuillez remplir tous les champs',
      });
    } else {
      this.updateWork();
    }
  }
  getWorkById(){
    this.workService.getWorkByIdWork(this.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.work = res.body;

          this.alphaNumericSortSkill(this.work.listSkillDto)

          if(this.currency?.currencyCode){
            this.work.currency = this.currency.currencyCode;
          }

        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {

        for (const materialWork of this.work.materialsList) {

          if (
            materialWork.materialDto.categorie.code === 'CSM' ||
            materialWork.materialDto.categorie.code === 'SER'
          ) {
            this.listConsumableSelected.push(materialWork);
          } else if (
            materialWork.materialDto.categorie.code === 'OUT' ||
            materialWork.materialDto.categorie.code === 'POO'
          ) {
            this.listToolsSelected.push(materialWork);

          }
        }

        this.alphaNumericSortMaterial(this.listToolsSelected)
        this.alphaNumericSortMaterial(this.listConsumableSelected)

        this.generateBreadCrumb();
      }
    );
  }
  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
    this.getWorkById();


    this.materialService.ListMateriel().subscribe(
      (res) => {
        if (res.status === 200) {
          this.listMaterial = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.listConsumable = this.getConsummable(this.listMaterial);
        this.listTools = this.getTools(this.listMaterial);
      }
    );

    let content = {
      id: this.id,
    };
    this.qrData = this.qrDataFormaterService.generateQrData('work', content);
  }

  getConsummable(list: Materiel[]) {
    let array = [];
    for (const material of list) {
      if (
        material.categorie.code === 'CSM' ||
        material.categorie.code === 'SER'
      ) {
        array.push(material);
      }
    }
    return array;
  }

  getTools(list: Materiel[]) {
    let array = [];
    for (const material of list) {
      if (
        material.categorie.code === 'OUT' ||
        material.categorie.code === 'POO'
      ) {
        array.push(material);
      }
    }
    return array;
  }

  updateWork() {
    //tester si le nom existe déja
    let material = this.listConsumableSelected.concat(this.listToolsSelected);
    this.work.materialsList = material;

    this.workService.updateWork(this.work).subscribe(
      (res) => {
        if (res.status === 200) {
          //console.log(res.body);
        }
      },
      (error) => {
        if (error) {
          if (error.status === 500) {
            this.messageService.add({
              severity: 'error',
              summary: 'Échec de l’opération : veuillez réessayer',
            });
          }
          if (error.status === 409) {
            this.messageService.add({
              severity: 'error',
              summary: 'Cet élément existe déjà',
            });
          }
        }
      },
      () => {
        this.editMode = false;
        this.label = 'Modifier';
        this.redirectTo('/phoenix/work');
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });
      }
    );
  }
  redirectTo(uri: string) {
    this.submited.next(true);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
  back() {


      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Vous allez quitter la page, les données seront perdues.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        key: 'back',
        accept: () => {
          this.submited.next(true);
          this.router.navigate(['/phoenix/work']);
        },
      });

  }
  confirmDeletion(message:string) {
    if(this.workIsInUse){
      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message:
          message,
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'J\'ai compris',
        rejectVisible: false,
        key: 'isInUseWhenDelete',
        accept: () => {
        },
      });
      this.workIsInUse = false;
    }else{
    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Les données seront supprimées définitivement.' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Supprimer',
      rejectLabel: 'Annuler',
      key: 'confirmDelete',
      accept: () => {
        this.delete();
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });
      },
    });
  }
  }
  confirmUpdate(message:string) {
    if(this.workIsInUse){
      this.confirmationService.confirm({
        header: 'Modification impossible',
        message:
          message,
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'J\'ai compris',
        rejectVisible: false,
        key: 'isInUseWhenUpdate',
        accept: () => {
        },
      });
      this.workIsInUse = false;
    }else{
      this.confirmUpdateWork();
    }
  }

  delete() {
    this.workService.deleteWork(this.work.idWork).subscribe(
      (res) => {
        if (res.status === 200) {
          //console.log(res.body);
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },

      () => {
        this.redirectTo('/phoenix/work');
      }
    );
  }

  isWorkInUseBeforeDelete(){
    this.workService.isWorkAlreadyInUse(this.work.idWork).subscribe(
      res => {
        if(res.status === 200){
          this.workIsInUse = res.body.workUsed;
          this.confirmDeletion(res.body.message);
        }
      }
    );
  }

  isWorkInUseBeforeUpdate(){
      this.workService.isWorkAlreadyInUse(this.work.idWork).subscribe(
        res => {
          if(res.status === 200){
            this.workIsInUse = res.body.workUsed;
            this.confirmUpdate(res.body.message);
          }
        }
      );
    }
}
