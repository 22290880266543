import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Skill} from '@data/schema/Skill';
import {SkillWork, Work} from '@data/schema/Work';
import {SkillService} from '@data/service/skill.service';
import {MessageService} from 'primeng/api';
import { SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-work-detail-skill',
  templateUrl: './work-detail-skill.component.html',
  styleUrls: ['./work-detail-skill.component.css']
})
export class WorkDetailSkillComponent implements OnInit {

  @Input() work: Work;
  @Output() workChange = new EventEmitter();
  listSkill: Skill[][] = [];
  arrayFiltered: Skill[];
  typeOfTag: string = "skill";
  indexOfListSkill: number = 0;
  changesWorkEvent = 1;
  change() {
    this.workChange.emit(this.work);
  }

  filterArrayByTag(newArrayFiltered: any) {
    this.arrayFiltered = newArrayFiltered;
    this.listSkill[this.indexOfListSkill] = this.arrayFiltered;
  }


  errorNotFilled = false;
  errorDuplicate = false;

  constructor(
    public skillService: SkillService,
    private messageService: MessageService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getListSkill();
  }


  

  alphaNumericSort = (arr = []) => {
      const sorter = (a, b) => {
      const isNumber = (v) => (+v).toString() === v;
      const aPart = a.name.match(/\d+|\D+/g);
      const bPart = b.name.match(/\d+|\D+/g);
      let i = 0; let len = Math.min(aPart.length, bPart.length);
      while (i < len && aPart[i] === bPart[i]) { i++; };
        if (i === len) {
            return aPart.length - bPart.length;
      };
      if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
      };
      return aPart[i].localeCompare(bPart[i]); };
      arr.sort(sorter);
  };

  ngOnInit(): void {
  }

  getListSkill() {
    this.skillService.getListSkill().subscribe(
      (res) => {
        if (res.status === 200) {
          this.arrayFiltered = res.body;
          if(this.arrayFiltered){
            this.alphaNumericSort(this.arrayFiltered);
          }
          
          if(this.listSkill.length === 0) {
            this.listSkill[0] = this.arrayFiltered;
          }
          console.log(this.work.listSkillDto);
          if ((this.work.listSkillDto.length > 0 && this.changesWorkEvent === 2) || (this.work.listSkillDto.length > 0 && this.work.name.length > 0 && this.changesWorkEvent === 1)) {
            for (let i = 0; i < this.work.listSkillDto.length; i++) {
              this.listSkill[i] = res.body;
            }
            this.indexOfListSkill = this.listSkill.length;
            this.listSkill[this.indexOfListSkill] = this.arrayFiltered;
          }
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.changesWorkEvent ++;
      }
    );
  }

  addSkill() {
    if (!this.checkAllConditions()) {
      let newSkillWork = new SkillWork()
      newSkillWork.skillDto = new Skill()
      this.work.listSkillDto.push(newSkillWork)
      this.indexOfListSkill++;
      this.listSkill[this.indexOfListSkill] = this.arrayFiltered;
    }
  }

  selectSkill() {
    this.checkAllConditions()

  }

  deleteSkill(index: number) {
    this.indexOfListSkill--;
    this.work.listSkillDto.splice(index, 1)
    this.listSkill.splice(index, 1);
    this.checkAllConditions()
  }

  checkLastFilled() {

    if (this.work.listSkillDto.length > 0) {
      let lastElement = this.work.listSkillDto.slice(-1)[0];
      if (lastElement && !lastElement.skillDto.name) {
        return false;
      }
    }
    return true;
  }

  checkDuplicate() {

    let result = this.work.listSkillDto.map(function (skill) {
      return skill.skillDto.id
    })

    return result.some(function (item, skill) {
      return result.indexOf(item) != skill
    })
  }

  checkIsDuplicate(skill: Skill) {
    let result = this.work.listSkillDto.filter(s => s.skillDto.id == skill.id)
    return (result.length > 1)
  }

  checkAllConditions() {
    if (this.checkDuplicate()) {
      this.errorDuplicate = true;
    } else {
      this.errorDuplicate = false;
    }

    if (this.checkLastFilled()) {
      this.errorNotFilled = false;
    } else {
      this.errorNotFilled = true;
    }

    return (this.errorDuplicate || this.errorNotFilled)
  }

  
}
