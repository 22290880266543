import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[digits-only]'
})
export class DigitsOnlyDirective {

  constructor() { }

  @HostListener('keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {
    var regex: RegExp = new RegExp(/[^0-9]/);
    let isNotDigit = regex.test(event.key);
    let isAfterDeadCharacter = event.key.length === 2;
    let isOneCharacter = event.key.length === 1
      if (( isOneCharacter || isAfterDeadCharacter) && isNotDigit) {
          event.preventDefault();
      }
  }

}
