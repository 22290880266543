import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Persona } from '@data/schema/Persona';
import { PersonaService } from '@data/service/persona.service';
import { CompanyService } from '@data/service/company.service';
import { Company } from '@data/schema/Company';
import { FileUpload } from 'primeng/fileupload';
import { Observable, BehaviorSubject } from 'rxjs';
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { TranslateService } from '@ngx-translate/core';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { OrganizationService } from '@data/service/organization.service';

@Component({
  selector: 'app-new-persona',
  templateUrl: './new-persona.component.html',
  styleUrls: ['./new-persona.component.css'],
})
export class NewPersonaComponent implements OnInit, ComponentCanDeactivate {
  public submited: BehaviorSubject<boolean>;

  //@HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.submited.value || !this.persona.isDefined() ? true : false;
  }

  persona: Persona = new Persona();
  company: Company = new Company();
  technicianAddress : string ;
  listPersona: Persona[]; // Store all persona one time to filter
  listCompany: Company[];
  listPosts: any[] = [];
  panelClosed: boolean = false;
  selectedPosts: any[];
  displayTech: boolean = false;
  compasave: any;
  profile: string;
  fr: any;
  errorPhone: boolean = false; // Flag to check if phone format is good
  gender: any[];
  selected: any[];
  filteredCompanies: any[];
  filteredPosts: any[];
  personaCompany: any;
  errorCompanyRequired: boolean = false;
  numpersona: any;
  public calendar_fr: any;

  //Gestion Photo
  display: boolean = false;
  imageBase64: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = true;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  constructor(
    private messageService: MessageService,
    private companyService: CompanyService,
    private personaService: PersonaService,
    private router: Router,
    public organizationService: OrganizationService,
    private confirmationService: ConfirmationService,
    private translate : TranslateService
  ) {
    this.submited = new BehaviorSubject(false);
  }

  // Function that filter an object by its property name
  filterObject(obj, fn) {
    return Object.fromEntries(Object.entries(obj).filter(fn));
  }
  // Function that fill perso list of posts
  changePost(e) {
    this.persona.listProfil = this.selectedPosts.reduce((acc, curr) => {
      acc.push({
        id: curr.id,
        name: curr.name,
        code: curr.code,
        characteristics: {}
      });
      return acc;
    }, []);
    if(e.itemValue?.code === 'TECH' && e.value.some((x)=> x.code === e.itemValue?.code )){
      this.displayTech = true;
    }

  }
  /* LIFECYCLE METHOD */

  ngOnInit(): void {

    //get Num Persona
    this.personaService.getNumPersona().subscribe((res) => {
      if (res.status === 200) {
        this.numpersona = res.body;
        if (this.numpersona.length < 5) {
          let size = 5 - this.numpersona.length;
          for (let i = 0; i < size; i++) {
            this.numpersona = '0' + this.numpersona;
          }
        }

        this.persona.personalNumber = this.numpersona;
      }
    });
    this.personaService.getProfile().subscribe((res) => {
      if (res.status === 200) {
        this.profile = res.body;
      }
    });
    this.companyService.getListAllEnableCompany().subscribe((res) => {
      if (res.status === 200) {
        this.listCompany = res.body;
      }
    });
    this.gender = [
      { name: 'Femme', value: 'Femme' },
      { name: 'Homme', value: 'Homme' },
      { name: 'Autre', value: 'Autre' },
    ];

    this.calendar_fr = {
      closeText: 'Fermer',
      prevText: 'Précédent',
      nextText: 'Suivant',
      currentText: "Aujourd'hui",
      monthNames: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ],
      monthNamesShort: [
        'janv.',
        'févr.',
        'mars',
        'avr.',
        'mai',
        'juin',
        'juil.',
        'août',
        'sept.',
        'oct.',
        'nov.',
        'déc.',
      ],
      dayNames: [
        'dimanche',
        'lundi',
        'mardi',
        'mercredi',
        'jeudi',
        'vendredi',
        'samedi',
      ],
      dayNamesShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
      dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      weekHeader: 'Sem.',
      dateFormat: 'dd/mm/yy',
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: '',
    };
    // Get list of posts
    this.organizationService.getAllProfilesPersona().subscribe({
      next: (res) => {
        this.listPosts = res;
      },
    });
    // Allow to keep form input if user navigate to other page
    if (this.personaService.personaInformation.persona.mail) {
      this.persona = this.personaService.personaInformation.persona;
    }

  }




  filterCompany(event) {
    //afficher le nom company
    this.filteredCompanies = this.listCompany
      .filter((c) => c.name.toLowerCase().startsWith(event.query.toLowerCase()))
      .map((company) => company);
  }

  filterPosts(event) {
    //afficher le nom company
    this.filteredPosts = this.listPosts
      .filter((c) => c.name.toLowerCase().startsWith(event.query.toLowerCase()))
      .map((company) => company);
  }

  // redirect to list page
  gotoList() {
    this.redirectTo('/phoenix/persona');
  }

  redirectTo(uri: string) {
    this.submited.next(true);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  // save persona data to service and go to step2 to assign skill to the persona
  nextPage() {
    this.submited.next(true);
    this.personaService.personaInformation.persona = this.persona;
    this.router.navigate(['/phoenix/new-persona/assign-skill']);
  }

  /* CUSTOM METHODS TO HANDLE ERRORS MESSAGES */

  // Check if user input is a valid phone format (start with + or number only)
  validPhoneFormat(event: any) {
    if (
      event.target.value.charAt(0) === '+' &&
      !isNaN(event.target.value.substr(1))
    ) {
      this.errorPhone = false;
    } else if (!isNaN(event.target.value)) {
      this.errorPhone = false;
    } else {
      this.errorPhone = true;
    }
  }

  addTimeZoneOffsetToCalendarTime(event) {
    /*To be removed when primeng fixes the bug related to p calendar or when migrating to another library*/
    event.setTime(event.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
    /**/
  }

  /* CUSTOM METHODS TO HANDLE LOGIC */

  // submit create persona form
  save() {
    this.persona.personalNumber = this.persona.personalNumber.toUpperCase();
    if (this.persona.birthDate) {
      this.addTimeZoneOffsetToCalendarTime(this.persona.birthDate);
    }
    this.personaService.createPersona(this.persona).subscribe(
      (data) => {
        if (data.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('toast_OPERATION_REUSSI'),
          });
        }
      },
      (error) => {
        if (error.status === 500) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('toast_ECHEC_OPERATION_REESSAYER'),
          });
        }
        if (error.status === 409) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('form_message_ELEMENT_EXISTE'),
          });
        }
      },
      () => {
        this.persona = new Persona();
        this.personaService.personaInformation.persona = this.persona;
        this.gotoList();
      }
    );
  }

  onSelection(event, fileUpload: FileUpload) {
    this.display = true;
    let fileReader = new FileReader();

    let file = event.files[0];

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      // Store the base64 string of the image file
      this.imageBase64 = fileReader.result.toString();
    };
  }

  // Used to upload an image file that catch the event
  onUpload(fileUpload: FileUpload) {
    this.display = false;
    console.log(fileUpload);
    this.clear(fileUpload);
  }

  imageCropped(event: ImageCroppedEvent) {
    let picSize = ((4 * event.base64.length) / 3 + 3) & ~3;

    this.persona.photo = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  clear(fileUpload: FileUpload) {
    fileUpload.clear();
  }

  deletePhoto() {
    this.persona.photo = null;
  }
  createTechnicien() {
    this.displayTech = false;
    let technicianPost = this.persona.listProfil.filter(p => p.code === 'TECH');
    technicianPost.map((post) => {
      post.characteristics.address = this.technicianAddress
    })
  }
  showDialog() {
    this.displayTech = true;
  }

  confirm() {
    this.confirmationService.confirm({
      header:  this.translate.instant('titre_message_exception_CONFIRMATION'),
      message:
          this.translate.instant('texte_message_exception_QUITTER_PAGE'),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.router.navigate(['/phoenix/persona']);
        this.personaService.erasePersonaInformation();
      },
    });
  }
}
