import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Category } from '@data/schema/Category';
import { Materiel } from '@data/schema/Materiel';
import { Unit } from '@data/schema/Unit';
import { MaterialWork } from '@data/schema/Work';
import { MessageService } from 'primeng/api';
import { MaterialService } from '@data/service/material.service';
@Component({
  selector: 'app-work-detail-tools',
  templateUrl: './work-detail-tools.component.html',
  styleUrls: ['./work-detail-tools.component.css']
})
export class WorkDetailToolsComponent implements OnInit {

  @Input() listTools: Materiel[];
  @Input() listToolsSelected: MaterialWork[];
  arrayFiltered: Materiel[];
  @Output() listToolsSelectedChange = new EventEmitter();
  listMaterials : Materiel[][] = [];
  indexOfListMaterial: number = 0;
  typeOfTag: string = "material";
  changesWorkEvent = 1;
  change() {
    this.listToolsSelectedChange.emit(this.listToolsSelected);
  }

  errorNotFilled = false;
  errorDuplicate = false;

  constructor(private messageService: MessageService, private materialService: MaterialService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.getListMaterials();
  }

  ngOnInit(): void {
  }

  filterArrayByTag(newArrayFiltered: any) {
    this.arrayFiltered = this.materialService.getTools(newArrayFiltered);
    this.listMaterials[this.indexOfListMaterial] = this.arrayFiltered;
  }

  getListMaterials() {
    this.materialService.ListMateriel().subscribe(
      (res) => {
        if (res.status === 200) {
          this.arrayFiltered = this.materialService.getTools(res.body);
          if(this.listMaterials.length === 0) {
            this.listMaterials[0] = this.materialService.getTools(this!.arrayFiltered);
          }
          if ((this.listToolsSelected.length > 0 && this.changesWorkEvent === 2) || (this.listToolsSelected.length > 0 && this.changesWorkEvent === 1)) {
            for (let i = 0; i < this.listToolsSelected.length; i++) {
              this.listMaterials[i] = this.materialService.getTools(res.body);
            }
            this.indexOfListMaterial = this.listMaterials.length;
            this.listMaterials[this.indexOfListMaterial] = this.materialService.getTools(this.arrayFiltered);;
          }
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.changesWorkEvent ++;
      }
    );
  }

  addTool(){

    if (!this.checkAllConditions()){
      let consumable = {} as Materiel
      consumable.label = ""
      consumable.categorie = {} as Category
      consumable.unit = {} as Unit
      consumable.description = ""
      let newConsumable = new MaterialWork();
      newConsumable.materialDto = consumable;
      this.listToolsSelected.push(newConsumable)
      this.indexOfListMaterial++;
      this.listMaterials[this.indexOfListMaterial] = this.materialService.getTools(this.arrayFiltered);
    }

  }

  selectTool(){

    this.checkAllConditions()
  }

  deleteTool(index: number){
    this.indexOfListMaterial--;
    this.listToolsSelected.splice(index,1)
    this.listMaterials.splice(index, 1);
    this.checkAllConditions()
  }

  checkLastFilled(){

    if(this.listToolsSelected.length>0){
      let lastElement = this.listToolsSelected.slice(-1)[0];
      if (lastElement && !lastElement.materialDto.label ){
        return false;
      }
    }
    return true;
  }

  checkDuplicate(){

    let result =this.listToolsSelected.map(function(material){
      return material.materialDto.id
    })

    return result.some(function(item, material){
      return result.indexOf(item) != material
    })
  }

  checkIsDuplicate(material: Materiel){
    let result = this.listToolsSelected.filter(s => s.materialDto.id == material.id)
    return (result.length > 1)
  }

  checkAllConditions(){
    if(this.checkDuplicate()){
      this.errorDuplicate = true;
    }
    else{
      this.errorDuplicate = false;
    }

    if(this.checkLastFilled()){
      this.errorNotFilled = false;
    }
    else{
      this.errorNotFilled = true;
    }

    return (this.errorDuplicate || this.errorNotFilled)
  }

}
