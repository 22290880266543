<app-kober-menu module="kober-services" ></app-kober-menu>
<div class="p-grid p-d-flex p-ml-2 p-pl-2 main-page">
  <div class="p-mt-3 p-pt-3 p-ml-3 p-pl-3">
    <p-breadcrumb *ngIf="mode==='update'" [model]="items.update"></p-breadcrumb>
    <p-breadcrumb *ngIf="mode==='consultation'" [model]="items.show"></p-breadcrumb>
  </div>
</div>
<div class="p-jc-center p-m-2 p-p-2 main-page">
  <div class="p-grid p-jc-center overFlow">

    <div class="p-col-12 p-md-11 p-lg-5 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <app-contract-form-general [contractIsAttachedToWorkOrder]="contractIsAttachedToWorkOrder" [prestationsFrom]="prestationsFrom"
                                 [contractForm]="contractForm" [mode]="mode" [contracts]="contracts"></app-contract-form-general>
    </div>

    <div class="p-col-12 p-md-11 p-lg-6 p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <app-contract-form-company [formC]="contractForm" [mode]="mode" [contracts]="contracts"></app-contract-form-company>
    </div>

    <div class="p-col-12 p-md-12 p-lg-11-custom p-jc-center p-m-3 p-p-3 main-content p-shadow-4">
      <app-contract-form-select-prestation (oldPrestationsPriceChanged)="priceHasChanged($event)" [prestationsFrom]="prestationsFrom" [contractIsAttachedToWorkOrder]="contractIsAttachedToWorkOrder" [contractForm]="contractForm" [mode]="mode" [contracts]="contracts" ></app-contract-form-select-prestation>
    </div>


  </div>
</div>

<p-confirmDialog appendTo="body"
  baseZIndex="5"
  styleClass="main-page"
  [style]="{ width: '60%' }"
></p-confirmDialog>

<p-sidebar
  class="p-text-right"
  [style]="{ height: '13vh' }"
  [visible]="true"
  [blockScroll]="true"
  [dismissible]="false"
  [showCloseIcon]="false"
  [modal]="false"
  position="bottom"
>
  <div>

    <!--RETOUR A LA LISTE-->
    <p-button *ngIf="mode === 'consultation'" styleClass="p-button-outlined p-mr-2" (click)="returnList()" label="Retour"></p-button>
    <p-button *ngIf="mode === 'update'" styleClass="p-button-outlined p-mr-2" (click)="back()" label="Annuler"></p-button>

    <ng-container *ifRoles='["administrateur",  "admin_metier","admin_responsable_DPO"]' >
    <p-button   *ngIf="mode=== 'consultation' && contracts?.status?.code !== 'TERMINE' " styleClass=" p-mr-2"  (click)="update()" label="Modifier"></p-button>
  </ng-container>
    <p-button *ngIf="mode === 'update'" styleClass="p-mr-2" [disabled]="contractForm?.invalid" (click)="submit()" label="Valider"></p-button>
  </div>
</p-sidebar>
