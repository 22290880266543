import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Tag} from "@data/schema/Tag";
import {ColorPicker} from "primeng/colorpicker";

@Component({
  selector: 'app-detail-tag',
  templateUrl: './detail-tag.component.html',
  styleUrls: ['./detail-tag.component.css']
})
export class DetailTagComponent implements OnInit {

  @Input() tag: Tag;
  @Input() listTag: Tag[];
  @Output() tagChange = new EventEmitter();
  @Output() validatorName = new EventEmitter();
  @Input() editMode: boolean;
  @Input() nameTagEdit: string;
  lastValueNameTag: string;

  change() {
    this.tagChange.emit(this.tag);
  }
  validatorNameTagActiveSubmit(errorName: boolean){
      this.validatorName.emit(errorName);
  }

  outputNameTag: any[] = [];
  tabColor: any[] = [];
  errorNameTagRequired: boolean = false;
  errorforbiddenNameTag: boolean = false;


  rouge: string = "#FFADAD";
  orange: string = "#FFD6A5";
  jaune: string = "#FDFFB6";
  vert: string = "#CAFFBF";
  bleu_ciel: string = "#9BF6FF";
  bleu_foncée: string = "#A0C4FF";
  violet: string = "#BDB2FF";
  rose: string = "#FFC6FF";

  constructor() {
  }

  ngOnInit(): void {
    //initialize colors
    this.initColors();
    if (!this.editMode) {
      this.tag.colorCode = this.getRandomColorFromPalette();
    }
  }

  getRandomColorFromPalette() {
    // var letters = '0123456789ABCDEF';
    // var color = '#';
    let color = this.tabColor[Math.floor(Math.random() * 8)];

    return color;
  }

  initColors() {
    this.tabColor.push(this.rouge);
    this.tabColor.push(this.orange);
    this.tabColor.push(this.jaune);
    this.tabColor.push(this.violet);
    this.tabColor.push(this.vert);
    this.tabColor.push(this.bleu_ciel);
    this.tabColor.push(this.bleu_foncée);
    this.tabColor.push(this.rose);

  }

  changeColor(color: string) {
    this.tag.colorCode = color;
  }

  nameTagFieldValidator() {
    let labelTag: string[] = [];

    //put the names of the tags in an array of string
    for (let t of this.listTag) {
      labelTag.push(t.label);
    }

    //get name tag edit
    this.lastValueNameTag = this.nameTagEdit;

    //check if the tag name exists
    let filteredForbiddenTag = labelTag.filter(x => x === this.tag.label.toLowerCase().trim());

    if (filteredForbiddenTag.length > 0 && this.tag.label.toLowerCase().trim() !== this.lastValueNameTag.toLowerCase().trim()) {
      this.errorforbiddenNameTag = true;
    } else {
      this.errorforbiddenNameTag = false;
    }

    if (!this.tag.label.trim()) {
      this.errorNameTagRequired = true;

    } else {
      this.errorNameTagRequired = false;
    }

    if(this.errorforbiddenNameTag || this.errorNameTagRequired){
      this.validatorNameTagActiveSubmit(true);
    }
    else{
      this.validatorNameTagActiveSubmit(false);
    }

    return this.errorforbiddenNameTag;
  }

  search(event) {
    this.outputNameTag = this.listTag.filter(c => c.label.toLowerCase().startsWith(event.query.toLowerCase()))
      .map((tag) => tag.label);
  }

}
