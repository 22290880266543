import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {EnvService} from "@core/service/env.service";
import { SBEnumAppCommentTargetTypes } from '@data/schema/SbEnumAppCommentTargetTypes';
import {Intervention} from "@data/schema/intervention/interventionSchema";

@Injectable({
  providedIn: 'root'
})
export class SellerbotService {

  private baseUrl = this.environment?.readConfig()?.baseApiURL;
  private target_type = SBEnumAppCommentTargetTypes.KOBER_WORK_ORDER // Target_type column in SB APP_Comment table. References the type of the note associated with the target_id

  constructor(private http: HttpClient,private environment: EnvService) { }

  public getLightCurrentUser(): Observable<any> {
    return this.http.get(this.baseUrl + "/user/light/current").pipe(
      map(res => {
        console.log(res)
        return res;
      })
    )
  }

  public getRightSurvey(): Observable<any> {
    return this.http.get(this.baseUrl + "/questionnaire/survey/parser/source/source",{
      responseType: 'text',
   }).pipe(
      map(res => {
        console.log(res)
        return res;
      })
    )
  }

   //Search all notes associated with KOBER WorkOrder (KB_WO) externalNumber
   public ListNotesByWOExternalNumber(externalNumber: string): Observable<any> {
    return this.http.get(this.baseUrl + "/comment/list/" + this.target_type + "/" + externalNumber, {observe: 'response' as 'body'}).pipe(
      map(res => {
        console.log(res)
        return res;
      })
    )
  }

  public getSlotList(startDate: any): Observable<any> {
    return this.http.post(this.baseUrl + "/activity/process/slot/search/criteria2", startDate).pipe(
      map(res => {
        return res;
      })
    )
  }

  public assignInterventions(intervention: Intervention[], techId: string): Observable<any> {
    return this.http.post(this.baseUrl + "/activity/process/tour/launch", {
      interventionExternalIds: intervention.map(value => value.reference),
      technicianExternalIds3: [techId],
      unassign: true,
      deprogram: true
    },{observe: 'response'});
  }
  public getIdByidKeyCloack(idKeycloak: string): Observable<any> {
    return this.http.get(this.baseUrl + "/activity/instance/technician/externalId2/" + idKeycloak).pipe(
      map(res => {
        return res;
      })
    )
  }

}
