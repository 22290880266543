import {OnInit} from '@angular/core';
import {Component, ChangeDetectorRef} from '@angular/core';
import {Skill} from '@data/schema/Skill';
import {RejectedSkill} from '@data/schema/RejectedSkill';
import {SkillService} from '@data/service/skill.service';
import {TagService} from '@data/service/tag.service';
import {ExportService} from '@shared/service/export.service';
import {BrowserStorageService} from '@shared/service/browser-storage.service';
import {Router, NavigationExtras} from '@angular/router';
import {ConfirmationService, MessageService, ConfirmEventType, SortEvent} from 'primeng/api';
import {FileUpload} from 'primeng/fileupload';
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver';
import {Tag} from "@data/schema/Tag";
import {DataService} from '@shared/service/data.service';
import {CustomSortService} from "@shared/service/custom-sort.service";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EnumInfoDto } from '@data/schema/EnumInfoDto';


@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.css'],
  animations: [
    trigger('openClose', [
      state('true', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      state('false', style({
        opacity: 0,
        transform: 'scale(0.5)'
      })),
      transition('true <=> false', animate('300ms ease-in-out'))
    ])
  ]

})
export class SkillComponent implements OnInit {

  typeOfTag: string = "skill";
  selectedSkillId: number;
  visibleSidebar: boolean = false;
  checkSize: boolean = true;
  size: boolean;
  outputLabel: string[];
  dragedTagToAssign: Tag;
  selectedSkillsOnAssign: Skill[] = [];
  selectedSkills: Skill[] = [];
  skill: Skill[] = [];
  skills: Skill[] = [];
  hab: boolean = false;
  sf: boolean = false;
  se: boolean = false;
  skillIdOnToggle: number;
  display: boolean = false;
  showByType: string = "ALL";
  listHabilitation:Skill[] = [];
  listSavoirFaire: Skill[] = [];
  listSavoirEtre: Skill[] = [];
  tags: Tag[] = [];
  tagsFilterded: Tag[] = [];
  skillsError: RejectedSkill[] = [];
  skillsImporter: any[] = [];
  allowEmptyString: boolean;
  productDialog: any;
  displayImportFile: boolean = false;
  file: File = null;
  arrayBuffer: any;
  worksheet: any;
  displayErrorFile: boolean = false;
  fileReadyForUpload: boolean = false;
  skillChoosen: any;
  fileReaded: any;
  arrayFiltered: any;
  refreshTag: boolean = false;
  today: number = Date.now();
  skillExport: Skill;
  exportColumns: any[];
  dateDay: any = new Date();
  dateD: any = this.dateDay.getDay() + '/' + this.dateDay.getMonth() + '/' + this.dateDay.getFullYear() + '/' + this.dateDay.getHours() + ':' + this.dateDay.getMinutes() + ':' + this.dateDay.getSeconds();
  cols: any[];
  headers: string [] = [];
  headers_: string;
  isOpen: boolean = false;
  enumsInfo: EnumInfoDto[];
  enumImportDescription: string;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private skillService: SkillService,
    private tagService: TagService,
    private router: Router,
    private exportService: ExportService,
    public browserDataService: BrowserStorageService,
    private cdref: ChangeDetectorRef,
    private dataService: DataService,
    private customSortService: CustomSortService
  ) {
  }

  activeState: boolean[] = [true, false, false];

  toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
  }

  filterArrayByTag(newArrayFiltered: any) {

    this.arrayFiltered = newArrayFiltered;
    this.skill = this.arrayFiltered;

    if (this.hab) {
      this.skill = this.skill.filter(x => x.type === 'HABILITATION');
    }
    if (this.sf) {
      this.skill = this.skill.filter(x => x.type === 'SAVOIR_FAIRE');
    }
    if (this.se) {
      this.skill = this.skill.filter(x => x.type === 'SAVOIR_ETRE');
    }
  }


  getTagAll() {
    this.tagService.getAllTags().subscribe(
      (res) => {
        if (res.status === 200) {

            this.tags = res.body;
            this.alphaNumericSortTag(this.tags)
            this.tagsFilterded = res.body;
            this.alphaNumericSortTag(this.tags)
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }
  drag(event:any,tag:Tag){

    this.dragedTagToAssign = tag;

  }

  drop(event:any, skill:Skill){

     if(this.selectedSkills.length!=0){

        this.selectedSkillsOnAssign = []

        for (let element of this.selectedSkills) {

          let skillToBeAssinged = JSON.parse(JSON.stringify(element));
          skillToBeAssinged.tags = [];
          if(element.tags.filter(value => value.tagId === this.dragedTagToAssign.tagId).length > 0){
            this.confirmationService.confirm({
              header: 'Erreur',
              message:
                'Au moins une des compétences dispose déjà de ce tag.',
              icon: 'pi pi-exclamation-triangle',
              rejectButtonStyleClass: 'p-button',
              acceptIcon: 'null',
              acceptLabel: "J'ai compris",
              rejectVisible: false,

            });
          }
          else{
            element.tags.push(this.dragedTagToAssign);
            this.alphaNumericSortTag(element.tags)
            skillToBeAssinged.tags.push(this.dragedTagToAssign);
            this.selectedSkillsOnAssign.push(skillToBeAssinged);
          }
        }
     }
     else
     {
        if(skill.tags.filter(value=> value.tagId === this.dragedTagToAssign.tagId).length > 0){

          this.confirmationService.confirm({
            header: 'Erreur',
            message:
              'Cette compétence dispose déjà de ce tag.',
            icon: 'pi pi-exclamation-triangle',
            rejectButtonStyleClass: 'p-button',
            acceptIcon: 'null',
            acceptLabel: "J'ai compris",
            rejectVisible: false,

          });

        }
        else{
          let skillToBeAssinged = JSON.parse(JSON.stringify(skill));
          this.selectedSkillsOnAssign = [];
          skillToBeAssinged.tags = [];
          skill.tags.push(this.dragedTagToAssign);
          this.alphaNumericSortTag(skill.tags)
          skillToBeAssinged.tags.push(this.dragedTagToAssign);
          this.selectedSkillsOnAssign.push(skillToBeAssinged)

        }
        this.selectedSkillId = 0;
      }

      if (this.selectedSkillsOnAssign.length!=0)
      {

        this.skillService.assignTagToSkills(this.selectedSkillsOnAssign).subscribe(
          (res) => {
            if (res.status === 200) {
              this.refreshTag = true;
              this.dataService.changeMessage(this.refreshTag);
              this.messageService.add({
                severity: 'success',
                summary: 'Opération réussie',
              });

             this.selectedSkillId = 0;
            }
          },
          (error) => {
            if (error) {
              this.messageService.add({
                severity: 'error',
                summary: 'Échec de l\'opération : veuillez réessayer',
              });
              this.visibleSidebar = true;
            }
          },
          () => {
          }
        );
      }

}

  showListSkillbyType(event: any, type: string) {

    if (event.checked) {
      this.showByType = type
      if (type === "HABILITATION") {
        this.skill = this.listHabilitation
        this.se = false
        this.sf = false
      }
      if (type === "SAVOIR_ETRE") {
        this.skill = this.listSavoirEtre
        this.hab = false
        this.sf = false
      }
      if (type === "SAVOIR_FAIRE") {
        this.skill = this.listSavoirFaire
        this.hab = false
        this.se = false
      }
    } else {
      this.showByType = "ALL";
      this.skill = this.skills
    }
  }


  showTypeSkill(event: any) {
    let skillHab: Skill[] = [];
    let skillSf: Skill[] = [];
    let skillSe: Skill[] = [];

    if (this.hab && this.sf && !this.se ) {
      this.skill = [];
       skillHab = this.arrayFiltered.filter(x => x.type === 'HABILITATION');
       skillSf = this.arrayFiltered.filter(x => x.type === 'SAVOIR_FAIRE');
      this.skill = this.skill.concat(skillHab, skillSf);
    }else if(this.hab && !this.sf && !this.se ){
      this.skill = [];
      this.skill = this.arrayFiltered.filter(x => x.type === 'HABILITATION');

    } else if(this.sf && this.se && !this.hab ){
      this.skill = [];
      skillSf = this.arrayFiltered.filter(x => x.type === 'SAVOIR_FAIRE' );
      skillSe = this.arrayFiltered.filter(x => x.type === 'SAVOIR_ETRE');
      this.skill = this.skill.concat(skillSf, skillSe);
    }else if(this.sf === true && this.se === false && this.hab === false){
      this.skill = [];
      this.skill = this.arrayFiltered.filter(x => x.type === 'SAVOIR_FAIRE');

    } else if(this.se  && this.hab  && !this.sf ){
      this.skill = [];
      skillSe = this.arrayFiltered.filter(x => x.type === 'SAVOIR_ETRE');
      skillHab = this.arrayFiltered.filter(x => x.type === 'HABILITATION');
      this.skill = this.skill.concat(skillSe, skillHab);
    }else if( this.se  && !this.hab && !this.sf ){
      this.skill = [];
      this.skill = this.arrayFiltered.filter(x => x.type === 'SAVOIR_ETRE');
      
    }else {
      this.skill = this.arrayFiltered;
    }
  }


  showSidebar(event: any) {

    this.visibleSidebar = true;

  }

  hideSidebar(event: any) {

    this.visibleSidebar = false;

  }

  filterTag(event: any) {

    let query: string = '';

    let filtered: any[] = [];

    query += event.target.value;

    for (let item of this.tagsFilterded) {

      if (item.label.toLowerCase().includes(query.toLowerCase())) {

        filtered.push(item);

      }

    }

    this.tags = filtered;
    this.alphaNumericSortTag(this.tags)

  }

  removeTag(event:any,tag:Tag, skill : Skill){

      if( this.selectedSkills.length!=0 ){
        this.selectedSkillsOnAssign =[];
        this.selectedSkillsOnAssign =  this.selectedSkills;
        for (let   i = 0; i< this.selectedSkillsOnAssign.length; i++ ){
          this.selectedSkillsOnAssign[i].tags = this.selectedSkillsOnAssign[i].tags.filter((x) => x.tagId !== tag.tagId);
        }

      }
      else{
        this.selectedSkillsOnAssign =[];
        this.selectedSkillsOnAssign.push(skill);
        for (let   i = 0; i< this.selectedSkillsOnAssign.length; i++ ){
            this.selectedSkillsOnAssign[i].tags = this.selectedSkillsOnAssign[i].tags.filter((x) => x.tagId !== tag.tagId);
        }

      }
       this.skillService.updateSkillsWithTags(this.selectedSkillsOnAssign).subscribe(
          (res) => {
            if (res.status === 200) {
              this.refreshTag = true;
              this.dataService.changeMessage(this.refreshTag);
              this.messageService.add({
                severity: 'success',
                summary: 'Opération réussie',
              });

            }
          },
          (error) => {
            if (error) {
              this.messageService.add({
                severity: 'error',
                summary: 'Échec de l’opération : veuillez réessayer',
              });
            }
          },
          () => {
          }
        );

  }


  ngOnInit() {

    this.getTagAll();

    this.skillService.getListSkillWithTags().subscribe(
      (res) => {
        if (res.status === 200) {

          this.skill = res.body;
          for (let e of this.skill){
            this.alphaNumericSortTag(e.tags)
          }
          this.arrayFiltered = this.skill;

        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {

        for (const skill of this.skill) {
          if (skill.type === "HABILITATION") {
            this.listHabilitation.push(skill)
          }
          if (skill.type === "SAVOIR_FAIRE") {
            this.listSavoirFaire.push(skill)
          }
          if (skill.type === "SAVOIR_ETRE") {
            this.listSavoirEtre.push(skill)
          }
        }
      }
    );

    this.cols = [
      {field: 'month_duration', header: 'Durée'},
      {field: 'name', header: 'Nom'},
      {field: 'validity', header: 'Validité'},
      {field: 'year_duration', header: 'Durée'}
    ];

    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

    this.initLegend();
  }

  getRowIndex(id: any) {
    this.skillIdOnToggle = id;
  }

  update() {
  console.log()
    let navigationExtras: NavigationExtras = {
      queryParams: {
        editMode: true
      }
    }
    this.router.navigate(['/phoenix/show-skills/', this.skillIdOnToggle], navigationExtras);
  }

  confirm() {
    this.browserDataService.copySessionStorage();

    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Les données seront supprimées définitivement.' +
        '<br>' +
        'Si cette compétence est affectée à un ou plusieurs personas, sa suppression effacera toutes les associations avec les personas (vérifier les associations <a href="/phoenix/personas-of-skills" target="_blank">ici</a>).' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Supprimer',
      rejectLabel: 'Annuler',
      accept: () => {
        this.delete(this.skillIdOnToggle);

      },
      reject: () => {
      },
    });
  }

  delete(id: number) {
    this.skill = this.skill.filter(skill => skill.id !== id)
    // Call API in the service to soft delete Persona
    this.skillService.deleteSkill(id).subscribe(
      (res) => {
        // Deleted
        if (res.status === 200) {
          this.router.navigate(['/phoenix/skill']);
              this.messageService.add({
                severity: 'success',
                summary: 'Opération réussie',
              });
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

  showDialogImportFile() {
    this.displayImportFile = true;
  }

  fileRemoved() {
    this.fileReadyForUpload = false;

  }

  fileSelected() {
    this.fileReadyForUpload = true;
  }


  fileTemplateIsValidXls(worksheet: any) {

    if (worksheet['A1'] !== undefined && worksheet['B1'] !== undefined && worksheet['C1'] !== undefined && worksheet['D1'] !== undefined && worksheet['E1'] !== undefined && worksheet['F1'] !== undefined && worksheet['G1'] !== undefined
      && worksheet['H1'] !== undefined) {
      if ((worksheet['A1'].w === 'TYPE' || worksheet['A1'].h === 'TYPE') &&
        (worksheet['B1'].w === 'NOM' || worksheet['B1'].h === 'NOM') &&
        (worksheet['C1'].w === 'DESCRIPTION' || worksheet['C1'].h === 'DESCRIPTION') &&
        (worksheet['D1'].w === 'VALIDITE' || worksheet['D1'].h === 'VALIDITE') &&
        (worksheet['E1'].w === 'ANNEES' || worksheet['E1'].h === 'ANNEES') &&
        (worksheet['F1'].w === 'MOIS' || worksheet['F1'].h === 'MOIS') &&
        (worksheet['G1'].w === 'JOURS' || worksheet['G1'].h === 'JOURS') &&
        (worksheet['H1'].w === 'TAGS' || worksheet['H1'].h === 'TAGS')) {
        return true;
      }
    }

    return false;

  }

  fileTemplateIsValidCsv() {

    this.headers_ = String(this.headers);

    if (((this.headers_.indexOf("TYPE")) !== -1) && ((this.headers_.indexOf("NOM")) !== -1) && ((this.headers_.indexOf("DESCRIPTION")) !== -1) &&
      ((this.headers_.indexOf("VALIDITE")) !== -1) && ((this.headers_.indexOf("ANNEES")) !== -1) &&
      ((this.headers_.indexOf("MOIS")) !== -1) && ((this.headers_.indexOf("JOURS")) !== -1) && ((this.headers_.indexOf("TAGS")) !== 1)
    ) {
      return true;
    }

    return false;

  }

  cancelUpload(fileUpload: FileUpload) {
    if (this.fileReadyForUpload) {

      this.confirmationService.confirm({
        message: 'Vous allez quitter la page, les données seront perdues.' + '<br>' +
          'Voulez vous continuer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Quitter',
        rejectLabel: 'Annuler',
        acceptButtonStyleClass: 'p-button-outlined',
        accept: () => {
          fileUpload.clear();
          fileUpload.clearInputElement();
          this.getSkillList();
          this.messageService.add({severity: 'info', summary: 'Importation annulée', detail: ''});
          this.productDialog = false;
          this.displayImportFile = false;
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              break;
            case ConfirmEventType.CANCEL:
              break;
          }
        }
      });
    } else {
      this.displayImportFile = false;


    }
  }

  getSkillList() {
    this.skillService.getListSkillWithTags().subscribe(res => {
        if (res.status === 200) {
          this.skill = res.body;
          for(let element of this.skill){
            this.alphaNumericSortTag(element.tags)
          }
        }
      },
      error => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer'
          });

        }
      },
      () => {
      })

  }

  sendFileWithService() {

    this.displayImportFile = false;

    this.skillsImporter.forEach((element, index) => {

      if (element.validity === "Oui") {
        this.skillsImporter[index].validity = true;
      }

      if (element.validity === "Non") {
        this.skillsImporter[index].validity = false;
      }

      if (element.validity === null || element.validity === undefined) {
        this.skillsImporter[index].validity = false;
      }

      if (element.type === "Savoir-être") {
        this.skillsImporter[index].type = "SAVOIR_ETRE";
      }

      if (element.type === "Savoir-faire") {
        this.skillsImporter[index].type = "SAVOIR_FAIRE";
      }

      if (element.type === "Habilitation") {
        this.skillsImporter[index].type = "HABILITATION";
      }

    });
    this.skillService.createSkills(this.skillsImporter).subscribe(
      res => {
        if (res.status === 200) {
          this.messageService.add({severity: 'success', summary: 'Opération réussie', detail: res.message});

          this.getSkillList();
        }
      },

      error => {
        if (error.status === 417) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération',
            detail: "Veuillez modifier le fichier et réessayer"
          });

          this.skillsError = JSON.parse(JSON.stringify(error.error)).listRejectedRecord;
          console.log(this.skillsError);

          this.displayErrorFile = true;
        } else if (error.status === 500) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: "Erreur 500 : veuillez contacter votre administrateur",
            sticky: true
          });
          this.router.navigate(['/phoenix/skill']);
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Échec de l’opération : veuillez réessayer',
            sticky: true
          });
        }
      }
    );
  }

  private matchingCellXls(worksheet: any, skill: Skill) {
    this.skillsImporter = [];
    for (let i = 0; i < worksheet.length; i++) {
      const worksheetLine = worksheet[i];
      const skillFinal: Skill = {
        id: null,
        type: worksheetLine.TYPE,
        name: worksheetLine.NOM,
        description: worksheetLine.DESCRIPTION,
        validity: worksheetLine.VALIDITE,
        year_duration: worksheetLine.ANNEES,
        month_duration: worksheetLine.MOIS,
        day_duration: worksheetLine.JOURS,
        tags: this.transformTagToArray(worksheetLine.TAGS),
        index: i + 2,
        isDefined: null
      };

      this.skillsImporter.push(skillFinal);

    }

    this.sendFileWithService();

  }

  fileReaderCsv(csv: any) {

    this.fileReaded = csv.target.files[0];

    let reader: FileReader = new FileReader();
    reader.readAsText(this.fileReaded);

    reader.onload = (e) => {
      let csv_: string | ArrayBuffer = reader.result;

      let allTextLines = (<string>csv_).split(/\r|\n|\r/);
      this.headers = allTextLines[0].split(',');
      let lines = [];

      if (this.fileTemplateIsValidCsv()) {

        for (let i = 0; i < allTextLines.length; i++) {
          // split content based on comma
          let data = allTextLines[i].split(',');
          if (data.length === this.headers.length) {
            let tarr = [];
            for (let j = 0; j < this.headers.length; j++) {
              tarr.push(data[j]);
            }

            // log each row to see output
            lines.push(tarr);
            return true;

          } else {
            return false;
          }

        }

      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Échec de l’opération',
          detail: "Format du fichier non conforme"
        });
        return;
      }
    }
  }

  transformTagToArray(tags: string) {
    let tagStrings: string[] = [];
    let tag: Tag[] = [];
    if (tags !== undefined) {
      tagStrings = tags.split(';');
      for (let tagString of tagStrings) {
        tag.push({label: tagString});
      }
    }
    return tag;
  }

  fileReader(fichier: File, skill: Skill) {

    let fileReader = new FileReader();
    this.skillsImporter = [];

    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();

      for (let i = 0; i !== data.length; i++) {
        arr[i] = String.fromCharCode(data[i]);
      }

      const bstr = arr.join('');
      const workbook = xlsx.read(bstr, {type: 'binary', cellDates: true});
      const first_sheet_name = workbook.SheetNames[0];


      const worksheet = workbook.Sheets[first_sheet_name];
      this.worksheet = xlsx.utils.sheet_to_json(worksheet, {raw: true});
      console.log(worksheet);
      if (this.fileTemplateIsValidXls(worksheet)) {
        console.log("test ok");
        this.matchingCellXls(this.worksheet, skill);
        this.worksheet = null;
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Échec de l’opération',
          detail: "Format du fichier non conforme"
        });
        return;
      }
      /**
       * Call matching function
       */


    };
    fileReader.readAsArrayBuffer(fichier);
    fichier = null;
  }

  getFile(event: any, fileUpload: FileUpload) {

    this.skillsImporter = [];
    this.file = event.files[0];
    let skill: Skill = null;
    this.fileReader(this.file, skill);
    fileUpload.clear();
    fileUpload.clearInputElement();

  }

  exportCsv() {

    const filename = 'Compétences';
    let fileName = filename + '_export_' + this.dateD;

    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez exporter les compétences au format csv' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Exporter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.exportService.downloadFileCsv(this.skill, fileName);
        this.router.navigate(['/phoenix/skill']);
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });

      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      },
    });

  }

  exportExcel() {

    const filename = 'Compétences';
    let fileName = filename + '_export_' + this.dateD;

    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez exporter les compétences au format Excel' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Exporter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.exportService.downloadFileExcel(this.skill, fileName);

      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      },
    });

  }

  exportToExcel() {

    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez exporter les compétences au format Excel' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Exporter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.skillService.getExcelData().subscribe(
          (responseMessage) => {

            if (responseMessage) {

              if (window.navigator) {
                let file = new Blob([responseMessage], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;'});
                let fileURL = URL.createObjectURL(file);
                window.open(fileURL);
              } else {

                let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;";
                let EXCEL_EXTENSION = '.xlsx';
                const fileName = 'Compétences';
                const blob = new Blob([responseMessage], {
                  type: EXCEL_TYPE
                });
                FileSaver.saveAs(blob, fileName + '_export_' + this.dateD + EXCEL_EXTENSION);

              }

              this.router.navigate(['/phoenix/skill']);
              this.messageService.add({
                severity: 'success',
                summary: 'Opération réussie',
              });

            }
          },
          error => {
            if (error.status === 500) {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur',
                detail: "Erreur 500 : veuillez contacter votre administrateur",
                sticky: true
              });
              this.router.navigate(['/phoenix/skill']);
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur',
                detail: 'Échec de l’opération : veuillez réessayer',
                sticky: true
              });
            }
          }
        )

      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      },
    });


  }

  exportToCsv() {

    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez exporter les compétences au format csv' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Exporter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.skillService.getCsvData().subscribe(
          (responseMessage) => {
            if (responseMessage) {

              if (window.navigator) {
                let file = new Blob([responseMessage], {type: 'text/csv;charset=utf-8;'});
                let fileURL = URL.createObjectURL(file);
                window.open(fileURL);
              } else {// other browsers

                let CSV_TYPE = "text/csv;charset=utf-8;";
                let CSV_EXTENSION = '.csv';
                const fileName = 'Compétences';
                const blob = new Blob([responseMessage], {
                  type: CSV_TYPE
                });
                FileSaver.saveAs(blob, fileName + '_export_' + this.dateD + CSV_EXTENSION);

              }
              this.router.navigate(['/phoenix/skill']);
              this.messageService.add({
                severity: 'success',
                summary: 'Opération réussie',
              });
            }
          },
          error => {
            if (error.status === 500) {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur',
                detail: "Erreur 500 : veuillez contacter votre administrateur",
                sticky: true
              });
              this.router.navigate(['/phoenix/skill']);
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur',
                detail: 'Échec de l’opération : veuillez réessayer',
                sticky: true
              });
            }
          }
        )

      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      },
    });

  }

  exportToPdf() {

    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez exporter les compétences au format pdf' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Exporter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.skillService.getPdfData().subscribe(
          (responseMessage) => {
            if (responseMessage) {

              if (window.navigator) {
                let file = new Blob([responseMessage], {type: 'application/pdf;charset=utf-8;'});
                let fileURL = URL.createObjectURL(file);
                window.open(fileURL);
              } else {

                let PDF_TYPE = "application/pdf;charset=utf-8;";
                let PDF_EXTENSION = '.pdf';
                const fileName = 'Compétences';
                const blob = new Blob([responseMessage], {
                  type: PDF_TYPE
                });
                FileSaver.saveAs(blob, fileName + '_export_' + this.dateD + PDF_EXTENSION);

              }

              this.router.navigate(['/phoenix/skill']);
              this.messageService.add({
                severity: 'success',
                summary: 'Opération réussie',
              });

            }
          },
          error => {
            if (error.status === 500) {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur',
                detail: "Erreur 500 : veuillez contacter votre administrateur",
                sticky: true
              });
              this.router.navigate(['/phoenix/skill']);
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur',
                detail: 'Échec de l’opération : veuillez réessayer',
                sticky: true
              });
            }
          }
        )

      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      },
    });
  }

  alphaNumericSortTag = (arr = []) => {
    const sorter = (a:any, b:any) => {
    const isNumber = (v:any) => (+v).toString() === v;
    const aPart = a.label.match(/\d+|\D+/g);
    const bPart = b.label.match(/\d+|\D+/g);

    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
      if (i === len) {
          return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]); };
    arr.sort(sorter);
  };



  dragEnter(skill:Skill){

    this.selectedSkillId = skill.id;

  }

  count(skill: Skill){
    let name = "skill-" + skill.id;
    let list = document.getElementById(name).getElementsByTagName('p-chip')
    let first;
    let count = 0;
    for (const menuKey of Object.keys(list)){
      if (!first){
        first =  this.getOffset(list[menuKey]).top
      }
      else{
        if (this.getOffset(list[menuKey]).top !== first){
          count++;
        }
      }
    }

    if (count > 0){
      return count
    }
    else{
      return 0
    }
  }

  getOffset(element)
  {
      if (!element.getClientRects().length)
      {
        return { top: 0, left: 0 };
      }

      let rect = element.getBoundingClientRect();
      let win = element.ownerDocument.defaultView;
      return (
      {
        top: rect.top + win.pageYOffset,
        left: rect.left + win.pageXOffset
      });
  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  naturalSortSkill(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

  downloadTemplate() {

    this.skillService.getTemplateXLSX()
    .then((Blob: Blob) => {
       console.log('Received XLSX Blob:', Blob);

       const url = URL.createObjectURL(Blob);
       const link = document.createElement('a');
       link.href = url;

       link.download = 'Myndea_Import_Compétences.xlsx';

       document.body.appendChild(link);

       link.click();

       document.body.removeChild(link);
       window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
        console.error('Error fetching XLSX file:', error);
    });
    }

  openClose() {
    this.isOpen = !this.isOpen;
    console.log(this.isOpen);
  }

  initLegend() {
    this.skillService.getLegendEnumNames("fr").subscribe(value => {
    this.enumImportDescription = value[0].generalDescription; // only the first element is the general description
    this.enumsInfo = value.slice(1);
    }); 
  }
}
