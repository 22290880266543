import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Project, ProjectWork } from '@data/schema/Project';
import { ConfirmationService, MessageService, SortEvent } from 'primeng/api';
import {CustomSortService} from "@shared/service/custom-sort.service";

@Component({
  selector: 'app-work-form-selected-works',
  templateUrl: './work-form-selected-works.component.html',
  styleUrls: ['./work-form-selected-works.component.css'],
})
export class WorkFormSelectedWorksComponent implements OnInit {
  @Input() project: Project;
  @Input() editMode?: boolean;

  modifMod: boolean = false;
  index: number;
  editedProjectWork: ProjectWork = new ProjectWork();
  minQtyPlanned: number = 0;
  historyProject: Project;

  @ViewChild("input") elm;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private customSortService: CustomSortService
  ) {}

  ngOnInit(): void {}

  update(projectWork: ProjectWork, index: number) {
    if (!this.historyProject){
      this.historyProject = JSON.parse(JSON.stringify(this.project));
    }
    if (this.project.status === 'DOING') {
      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'La modification des prestations impactera le budget du projet.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.index = index;
          this.modifMod = true;
          this.editedProjectWork = JSON.parse(JSON.stringify(projectWork));
          this.minQtyPlanned = this.minPossibleByQuantityFree();
          //console.log(projectWork);
        },
      });
    } else {
      this.index = index;
      this.modifMod = true;
      this.editedProjectWork = JSON.parse(JSON.stringify(projectWork));
      this.minQtyPlanned = this.minPossibleByQuantityFree();
      //console.log(projectWork)
    }
  }
  saveUpdate() {
    // SAVOIR SI CREATION OU SI UPDATE PROJET
    // SI CREATION QUANTITY FREE = 1 && QUANTITY TOTAL = ngmodel et id project Work = null
    // SI UPDATE SANS OT QUANTITY FREE = QUANTITY TOTAL et id projectWork != null
    // SI UPDATE AVEC OT QUANTITY FREE != QUANTITY TOTAL et id projectWork != null
    if (!this.editedProjectWork.id && this.editedProjectWork.quantityTotal > 0) {
      this.editedProjectWork.quantityFree =
        this.editedProjectWork.quantityTotal;
    } 
    else if (!this.editedProjectWork.id && this.editedProjectWork.quantityTotal < 1){
      return;
    }
    else if (this.editedProjectWork.id && this.editedProjectWork.quantityTotal < this.minQtyPlanned) {
      return;
    }
    else {
      this.manageQuantityFreeUpdatingProject();
    }

    this.project.projectWorks[this.index] = JSON.parse(
      JSON.stringify(this.editedProjectWork)
    );

    this.editedProjectWork = new ProjectWork();

    this.modifMod = false;
  }

  manageQuantityFreeUpdatingProject() {
    if (this.editedProjectWork.id && 
        this.editedProjectWork.quantityFree === this.editedProjectWork.quantityTotal
    ) {
      this.editedProjectWork.quantityFree = this.editedProjectWork.quantityTotal;
    } else if (
      this.editedProjectWork.id &&
      this.editedProjectWork.quantityFree !== this.editedProjectWork.quantityTotal
    ) {
      if (this.editedProjectWork.quantityTotal > this.project.projectWorks[this.index].quantityTotal) {
        this.editedProjectWork.quantityFree += this.editedProjectWork.quantityTotal - this.project.projectWorks[this.index].quantityTotal;
      } else if (this.editedProjectWork.quantityTotal < this.project.projectWorks[this.index].quantityTotal) {
        this.editedProjectWork.quantityFree -= this.editedProjectWork.quantityTotal - this.project.projectWorks[this.index].quantityTotal;
      }
    }
  }

  minPossibleByQuantityFree() {
    if (!this.historyProject.id && !this.editedProjectWork.id) {
      return 1;
    } 
    else if (this.historyProject.id && ! this.editedProjectWork.id){
      return 0;
    }
    else {
      return (
        this.historyProject.projectWorks[this.index].quantityTotal -
        this.historyProject.projectWorks[this.index].quantityFree
      );
    }
  }

  isInWorkOrder(projectWork: ProjectWork) {
    for (const pwo of this.project.projectWorkOrders) {
      for (const wopw of pwo.workOrder.listWorkOrderProjectWork) {
        if (projectWork.id === wopw.projectWork.id) {
          return false;
        }
      }
    }
    return true;
  }

  delete(index: number) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez supprimer les prestations du projet. Cela impactera le budget.' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Supprimer',
      rejectLabel: 'Annuler',
      accept: () => {
        this.deleteWorkInProject(index);
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });
      },
    });
  }

  deleteWorkInProject(index: number) {
    this.project.projectWorks.splice(index, 1);
  }
  annuler() {
    this.modifMod = false;
  }

  setFocus() {
    this.elm.input.nativeElement.focus();
  }

  naturalSortWorkSelected(event: SortEvent) {
    this.customSortService.naturalSort(event);
  }

}
