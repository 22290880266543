import {APP_INITIALIZER, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from './primeng.module';
import { IfRolesDirective } from '../directives/if-roles.directive';
import { PermissionDirective } from '../directives/permission.directive';
import { KoberMenuComponent } from '@shared/component/menu/kober-menu.component';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { CustomTranslateLoaderService } from './service/custom-translate-loader.service';
import { CustomMissingTranslationHandlerService } from './service/custom-missing-translation-handler.service';
import { ConfirmLeavingPageComponent } from './component/confirm-leaving-page/confirm-leaving-page.component';
import { CardComponent } from '@modules/kober-intervention/page/component/card/card.component';
import { IsInPossibleWorkOrderActions } from '../pipes/is-in-possible-work-order-actions';
import { MouseHoverDirective } from '../directives/mouse-hover.directive';
import { IsObjectNullPipe } from '../pipes/is-object-null.pipe';
import { TechDialogComponent } from './component/tech-dialog/tech-dialog.component';
import { IsStatusChecked } from '../pipes/is-status-checked';
import { PersonaCardComponent } from './component/persona-card/persona-card.component';
import { DigitsOnlyDirective } from '../directives/digits-only.directive';
import { IsSelectWithBoxAndQuantityPipe } from '../pipes/is-select-with-boxAndQuantity.pipe';
import { IsStringPipe } from '../pipes/is-string.pipe';
import { IsArrayPipe } from '../pipes/is-array.pipe';
import { IsInputLongTextPipe } from '../pipes/is-input-long-text.pipe';
import { IsInputNumberPipe } from '../pipes/is-input-number.pipe';
import { IsSelectWithoutQuantityPipe } from '../pipes/is-select-without-quantity.pipe';
import { IsSelectWithoutDisplayBoxPipe } from '../pipes/is-select-without-display-box.pipe';
import { IsRadioButtonPipe } from '../pipes/is-radio-button.pipe';
import { JSONParsePipe } from '../pipes/json-parse.pipe';
import { IsSignaturePipe } from '../pipes/signature-pipe';
import { JSONParseAndInitQuantityPipe } from '../pipes/jsonparse-and-init-quantity.pipe';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { MenuLeftComponent } from '@modules/kober-intervention/page/component/menu-left/menu-left.component';
import { SettingMenuLeftComponent } from '@modules/kober-setting/component/setting-menu-left/setting-menu-left.component';
import { TimeFromDateStringPipe } from '../pipes/time-from-date-stringPipe';
import { OccurencesPipe } from '../pipes/occurences.pipe';
import { TeamsListDialogComponent } from './component/teams-list-dialog/teams-list-dialog.component';
import { ElementsCountComponent } from './component/elements-count/elements-count.component';
import {EnvService} from "@core/service/env.service";
import { IsPhotoPipe } from '../pipes/photo-pipe';
import { IsTextBoxPipe } from '../pipes/is-text-box.pipe';
import { IsSeparatorPipe } from '../pipes/is-separator.pipe';
import { RangePipe } from "../pipes/range.pipe";
import {
   AutoCompleteTriggerEnforceSelectionDirective
} from "../kober-material/order/directives/auto-complete-trigger-enforce-selection.directive";
import { FiltreTagComponent } from 'src/app/kober-services/components/filtre-tag/filtre-tag.component';



@NgModule({
  declarations: [
    IfRolesDirective,
    PermissionDirective,
    ClickOutsideDirective,
    KoberMenuComponent,
    BreadcrumbComponent,
    ConfirmLeavingPageComponent,
    CardComponent,
    TeamsListDialogComponent,
    IsInPossibleWorkOrderActions,
    TimeFromDateStringPipe,
    IsSelectWithBoxAndQuantityPipe,
    MouseHoverDirective,
    IsInputNumberPipe,
    IsInputLongTextPipe,
    IsObjectNullPipe,
    IsSelectWithoutQuantityPipe,
    IsStatusChecked,
    IsArrayPipe,
    IsStringPipe,
    IsRadioButtonPipe,
    JSONParsePipe,
    JSONParseAndInitQuantityPipe,
    IsSignaturePipe,
    IsPhotoPipe,
    IsSelectWithoutDisplayBoxPipe,
    OccurencesPipe,
    TechDialogComponent,
    MenuLeftComponent,
    SettingMenuLeftComponent,
    PersonaCardComponent,
    DigitsOnlyDirective,
    ElementsCountComponent,
    IsTextBoxPipe,
    IsSeparatorPipe,
    RangePipe,
    AutoCompleteTriggerEnforceSelectionDirective,
    FiltreTagComponent,

  ],
  imports: [

    CommonModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule.forChild({
      loader: {provide: TranslateLoader, useClass: CustomTranslateLoaderService, deps: [HttpClient]},
      // compiler: {provide: TranslateCompiler, useClass: CustomCompiler},
      // parser: {provide: TranslateParser, useClass: CustomParser},
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandlerService}, 
      isolate: false
  }),
    // Imports for exported component
    PrimengModule,
    MaterialModule
    //RouterModule,
  ],
  providers: [
    TimeFromDateStringPipe, // Add the pipe to the providers array,

  ],
  exports: [
    CommonModule,
    FormsModule,
    ScrollingModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    // Custom Module
    PrimengModule,
    MaterialModule,
    // Custom Directives
    IfRolesDirective,
    ClickOutsideDirective,
    PermissionDirective,
    MouseHoverDirective,
    DigitsOnlyDirective,
    // Custom Components
    KoberMenuComponent,
    BreadcrumbComponent,
    CardComponent,
    PersonaCardComponent,
    ConfirmLeavingPageComponent,
    TeamsListDialogComponent,
    FiltreTagComponent,
    // Custom pipes
    IsInPossibleWorkOrderActions,
    TimeFromDateStringPipe,
    IsRadioButtonPipe,
    IsInputNumberPipe,
    IsSignaturePipe,
    IsPhotoPipe,
    IsSelectWithBoxAndQuantityPipe,
    IsInputLongTextPipe,
    IsObjectNullPipe,
    JSONParsePipe,
    JSONParseAndInitQuantityPipe,
    IsStatusChecked,
    IsSelectWithoutDisplayBoxPipe,
    OccurencesPipe,
    IsArrayPipe,
    IsSelectWithoutQuantityPipe,
    IsStringPipe,
    MenuLeftComponent,
    SettingMenuLeftComponent,
    ElementsCountComponent,
    IsTextBoxPipe,
    IsSeparatorPipe
  ],
})
export class SharedModule { }
