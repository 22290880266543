
<h1 fxLayoutAlign="space-between center" class="head dialog-spacing-one" mat-dialog-title>{{ "titre_message_exception_CONFIRMATION" | translate }}
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="close-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>

<div mat-dialog-content>
    <div fxLayout="row" class="dialog-spacing">
        <p style="margin-right:3%" fxLayoutAlign="start start"><mat-icon svgIcon="exclamationIcon"></mat-icon></p>
        <p>Aucun OT affiché sur les grilles en cours ne répond a vos critères. <br> Voulez-vous exécuter une recherche avancée ?</p>
    </div>
</div>
<div class="dialog-spacing" mat-dialog-actions align="end">
  <div class="actions">  
  <button mat-raised-button [mat-dialog-close]="false" class="btn-actions btn-color">{{ "ANNULER" | translate }}</button>
  <button  mat-raised-button color="primary" [mat-dialog-close]="true" class="btn-actions">Rechercher</button>
  </div>
</div>

