import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {WorkOrder} from '@data/schema/WorkOrder';
import {ProjectService} from '@data/service/project.service';
import {WorkOrderService} from '@data/service/work-order.service';
import {WorkOrderFormGeneralComponent} from '../../components/work-order/work-order-form-general/work-order-form-general.component';
import {BlService} from "@data/service/bl.service";
import {WorkOrderRequestAttributes} from "@data/schema/WorkOrderRequestAttributes";
import {Voucher} from "@data/schema/Voucher";
import { ComponentCanDeactivate } from '@core/guard/component-can-deactivate.guard';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-show-work-order',
  templateUrl: './show-work-order.component.html',
  styleUrls: ['./show-work-order.component.css']
})
export class ShowWorkOrderComponent implements OnInit, ComponentCanDeactivate {
  workOrder: WorkOrder = new WorkOrder();
  editMode: boolean = false;
  listCompany: any[] = [];
  listWorksCompany: any[];
  listVoucherforot: Voucher[] = [];

  hasWorks: boolean = false;

  sub: any;
  id: number;
  idCompany : number;

  items: any = {};

  @ViewChild(WorkOrderFormGeneralComponent)
  private workOrderGeneral!: WorkOrderFormGeneralComponent;

  public submited: BehaviorSubject<boolean>;

  //@HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (!this.editMode ||this.submited.value ? true : false)
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private workOrderService: WorkOrderService,
    private projectService: ProjectService,
    private blService: BlService
  ) {
    this.submited = new BehaviorSubject(false);
    this.route.queryParams.subscribe((params) => {
      if (params.editMode) {
        this.editMode = true;
      }
    });
  }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    // CALL API
    this.getWorkOrderByID();
  }

  generateBreadCrumb(){
    this.items.update = [
      {label:'Ordre de travail', routerLink:'/phoenix/work-order'},
      {label:"Modification de l'OT "+ this.workOrder.name, styleClass:'ariane-current'}
    ];
    this.items.show = [
      {label:'Ordre de travail', routerLink:'/phoenix/work-order'},
      {label:"OT "+ this.workOrder.name, styleClass:'ariane-current'}
    ];
  }

  async getWorkOrderByID(){
  this.workOrderService.getWorkOrderById(this.id).subscribe(
    (res) => {
      if (res.status === 200) {
        this.workOrder = res.body;
        this.hasWorks = this.workOrder.listWorkOrderProjectWork? true : false;
      }
    },
    (error) => {
      if (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Échec de l’opération : veuillez réessayer',
        });
      }
    },
    () => {
      if (this.workOrder.project) {
        if(this.workOrder.listWorkOrderProjectWork.length > 0){
          this.getCompaniesByWorks(this.workOrder.listWorkOrderProjectWork);

        }else{
          this.getCompany(this.workOrder);

        }

        if(this.workOrder.company){
          this.getWorkByProjectAndCompany();
          this.getWorksByCompany();
        }
        else{
          this.getProjectSelected(this.workOrder);
        }

        this.getHistory();
        // get voucher
        this.getVoucherforot(this.id);
      }
      this.generateBreadCrumb()
    }
  );}

  getWorkByProjectAndCompany() {
    if(!this.workOrder.company){
      return;
    }
    this.workOrder.project.projectWorks = [];
    this.workOrderService.projectWorksByCompany(this.workOrder.project.id, this.workOrder.company.companyId).subscribe(
      (res) => {
        if (res.status === 200) {
          this.workOrder.project.projectWorks = res.body;
        }
      });
  }

  getCompany(workOrder: WorkOrder) {
    const workOrderRequestAttributes: WorkOrderRequestAttributes = {
      projectId: workOrder.project.id,
      isAttachedToProject: this.workOrder.hasProject,
      workOrderID: this.workOrder.id,
      alreadyCreated: this.workOrder.id !== null && this.workOrder.id !== undefined
    }

    this.blService.listProjectCompany(workOrderRequestAttributes).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listCompany = res.body;
        }
      }
    );
  }
  updateIdCompanySelected(){
    if(this.workOrder.company !== null){
      this.idCompany = this.workOrder.company.companyId;
      this.workOrder.attachedToCompany = true;
    }
  }


  getVoucherforot(id: number) {
    this.listVoucherforot = [];
    this.blService.getVouchersForOT(id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listVoucherforot = res.body;
          this.workOrder.listVoucherforot = this.listVoucherforot;
        }
      }
    );
  }

  getProjectSelected(workOrder: WorkOrder) {
    this.projectService.getProjectById(workOrder.project.id).subscribe(
      (res) => {
        if (res.status === 200) {
          let projectSelected = res.body;
          this.workOrder.project.projectWorks = projectSelected.projectWorks;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

  getWorksByCompany(){
    const workOrderRequestAttributes: WorkOrderRequestAttributes = {
      //projectId: !this.errorProjectRequired? null :this.workOrder.project.id,
      projectId: this.workOrder.project.id,
      isAttachedToProject: this.workOrder.hasProject,
      workOrderID: this.workOrder.id,
      alreadyCreated: this.workOrder.id !== null && this.workOrder.id !== undefined

    }
    this.blService.getWorksCompany(this.workOrder.company.companyId, workOrderRequestAttributes).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listWorksCompany = res.body;
          this.workOrder.listWorksCompany = this.listWorksCompany;
          this.workOrder.attachedToCompany = true;
        }
      }
    );
  }

  getHistory() {
    this.workOrderService.getHistoryOtByIdWorkOrder(this.workOrder.id).subscribe(
      (res) => {
        if (res.status === 200) {
          this.workOrder.listHistoryProgress = res.body;
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
      }
    );
  }

  returnList() {
    this.submited.next(true);
    this.router.navigate(['/phoenix/work-order']);
  }

  delete() {

    if ( this.workOrder.status == "DOING"){

        this.confirmationService.confirm({
          header: 'Suppression impossible',
          message:
            'Cet OT est démarré.',
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: 'null',
          rejectIcon: 'null',
          acceptLabel: "J'ai compris",
          rejectVisible: false,
        });

      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message:
          'Cet ordre de travail est démarré.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
      });


    } else if (this.workOrder.status == "DONE") {

        this.confirmationService.confirm({
          header: 'Suppression impossible',
          message:
            'Cet OT est terminé.',
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: 'null',
          rejectIcon: 'null',
          acceptLabel: "J'ai compris",
          rejectVisible: false,
        });

      this.confirmationService.confirm({
        header: 'Suppression impossible',
        message:
          'Cet ordre de travail est terminé.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
      });

    } else {

      this.confirmationService.confirm({
        header: 'Confirmation',
        message:
          'Les données seront supprimées définitivement.' +
          '<br>' +
          'Voulez vous continuer ?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-outlined',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        accept: () => {
          this.deleteWorkOrder();
        },
      });

    }

  }


  deleteWorkOrder() {
    this.workOrderService.deleteWorkOrder(this.workOrder.id).subscribe(
      (res) => {
        if (res.status === 200) {
        }
      },
      (error) => {
        if (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Échec de l’opération : veuillez réessayer',
          });
        }
      },
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Opération réussie',
        });
        this.gotoList();
      }
    );
  }

  update() {

    if (this.workOrder.status == "DONE") {

      this.confirmationService.confirm({
        header: 'Modification impossible',
        message:
          'Cet OT est terminé.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptLabel: "J'ai compris",
        rejectVisible: false,
      });


    } else {

      this.editMode = true;

    }
  }


  submit() {

    let validForm = true;
    if (this.workOrderGeneral.nameFieldValidator()) {
      validForm = false;
    }
    if (!validForm) {
      this.messageService.add({
        severity: 'error',
        summary: 'Échec de l’opération : veuillez remplir tous les champs',
      });
    } else {
      this.updateWorkOrder();
    }

  }

  updateWorkOrder() {
  //console.log("OT to update",this.workOrder)
    this.workOrderService.updateWorkOrder(this.workOrder).subscribe(
      (res) => {
        if (res.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Opération réussie',
          });
          this.gotoList();
        }
      },
      (error) => {
        if (error) {
          if (error.status === 500) {
            this.messageService.add({severity: 'error', summary: 'Échec de l’opération : veuillez réessayer'});
          }
          if (error.status === 409) {
            this.messageService.add({severity: 'error', summary: 'Cet élément existe déjà'});
          }
        }
      },
      () => {

      }
    );
  }

  back() {

    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        'Vous allez quitter la page, les données seront perdues.' +
        '<br>' +
        'Voulez vous continuer ?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-outlined',
      acceptIcon: 'null',
      rejectIcon: 'null',
      acceptLabel: 'Quitter',
      rejectLabel: 'Annuler',
      accept: () => {
        this.submited.next(true);
        this.router.navigate(['/phoenix/work-order']);
      },
    });

  }

  // redirect to list page
  gotoList() {
    this.redirectTo('/phoenix/work-order');
  }

  redirectTo(uri: string) {
    this.submited.next(true);
    this.router
      .navigateByUrl('/', {skipLocationChange: true})
      .then(() => this.router.navigate([uri]));
  }

  updateListCompany(event: any) {
    if (this.workOrder.listWorkOrderProjectWork.length === 0) {
      this.getCompany(this.workOrder);
    }else if(this.hasWorks) {
      this.getCompaniesByWorks(this.workOrder.listWorkOrderProjectWork);
    }

  }

  getCompaniesByWorks(listWork){
   this.workOrderService.getListCompanyByWork(listWork).subscribe(
      (res) => {
        if (res.status === 200) {
          this.listCompany = res.body;
        }
      });
  }

}
