import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FormGroup} from "@angular/forms";
import {map} from 'rxjs/operators';
import {EnvService} from "@core/service/env.service";

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private apiURL: string = this.environment?.readConfig()?.urlApiGateway + "authentication/";

  constructor(private http: HttpClient,private environment: EnvService) {
  }

  isAuth: boolean;
  customColumnsName:string;

  isLogin() {
    if (sessionStorage.getItem(TOKEN_KEY) == null) {
      this.isAuth = false;
    } else {
      this.isAuth = true;
    }
    return this.isAuth;
  }

  public login(loginForm: FormGroup): Observable<any> {
    if(localStorage.getItem("browserHistory") !== null){
      localStorage.removeItem("browserHistory");
    }
    return this.http.post<any>(this.apiURL + "login", loginForm.value, {observe: 'response' as 'body'}).pipe(
      map(res => {
        return res;
      })
    );
  }

  public logout() {
    if(localStorage.getItem("browserHistory") !== null){
      localStorage.removeItem("browserHistory");
    }
    if (sessionStorage.getItem(TOKEN_KEY) != null) {
      
      const persona = sessionStorage.getItem('persona');
      const textPersonna = JSON.parse(persona);
      console.log(textPersonna);
      this.customColumnsName = 'customColumns'+textPersonna.idParty;

      sessionStorage.clear();
      // localStorage.clear();
      // Can be used to delete specific keys from session

      Object.keys(localStorage).forEach((key: string) => {
        if (key !== 'on-boarding-pool' && key !== 'on-boarding-organization' && !key.startsWith('customColumns') && key !== 'oldDisplayedColumns') {
          localStorage.removeItem(key);
        }
      });
      return this.http.get(this.apiURL + "logout", {observe: 'response' as 'body'}).pipe(
        map(res => {
          return res;
        })
      )
    }
  }
}
