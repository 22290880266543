import { Component, Input, OnInit } from '@angular/core';
import { WorkOrder } from '@data/schema/WorkOrder';

@Component({
  selector: 'app-work-order-show-general',
  templateUrl: './work-order-show-general.component.html',
  styleUrls: ['./work-order-show-general.component.css']
})
export class WorkOrderShowGeneralComponent implements OnInit {
  @Input() workOrder: WorkOrder;

  constructor() { }

  ngOnInit(): void {
  }

}
