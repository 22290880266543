import { Component, Input, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number;
  @Input() total: number;
  @Input() color: string;

  advance: number;

  constructor() {}

  ngOnInit(): void {
    this.calcul();
    
  }

  ngOnChanges(changes: SimpleChanges) {
    this.calcul();
  }

  calcul(){
    //if we don't have progress, set it to 0.
    if (!this.progress) {
      this.advance = 0;
    }
    //if we don't have a total aka no requirement, it's 0/100%.
    if (this.total === 0) {
      this.advance = 0;
    } else if (!this.total) {
      this.advance = 0;
    }
    //if the progress is greater than the total, it's also 100%.
    if (this.progress > this.total) {
      this.advance = 100;
    }

    if (this.total != 0){
      this.advance = Math.trunc(Math.round((this.progress / this.total) * 100));
    }
  }

}
