import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from '@data/schema/Project';

@Component({
  selector: 'app-project-show-general',
  templateUrl: './project-show-general.component.html',
  styleUrls: ['./project-show-general.component.css']
})
export class ProjectShowGeneralComponent implements OnInit {
  @Input() project: Project;
  @Output() projectChange = new EventEmitter();
  change() {
    this.projectChange.emit(this.project);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
