export class Tag{
    tagId ?: number;
    label: string ;
    description ?: string ;
    colorCode ?: string ;
    bookmarked ?: Boolean ;

    constructor(){
      this.tagId= null;
      this.label ="";
      this.description ="";
      this.colorCode = null;
      this.bookmarked = false
    }
}

